import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from '../../setup'
import ReactTable from '../../components/partials/react-table/ReactTable'
import ReactPaginate from 'react-paginate'
import '../cashier/CashierTable.scss'
import {
    getActivityClassifierRequest,
    getOneOrganizationRequest,
    getOneOrganizationSuccess,
    getOrganizationListRequest,
    getTaxationTypeRequest,
    setPropsToFilterOrganization
} from '../../store/actions/organizationAction'
import { Organization } from '../../models/organization/FileModel'
import { getCashierListSuccess, setOrgNameForFilter } from '../../store/actions/cashierActions'
import EditOrganizationModal from './EditOrganizationModal'
import './OrganizationTable.scss'
import { findPermission } from '../../helpers/findPermissionHelper'
import { Permission } from '../../models/coomonOFD/FileModel'
import { getOfdUsersSuccess, getUserPermissionsRequest } from '../../store/actions/commonOFDAction'
import UserUpdateConfirmationModal from './UserUpdateConfirmationModal'
import OrgInfoModal from './OrgInfoModal'
import LoaderOfd from '../../components/partials/loader/LoaderOfd'
import CreateOfdUserModal from './CreateOfdUserModal'

type tableState = {
    currentPage: number
    size: string
    search: {
        xin: string
        name: string
        juridicalAddress: string
    }
    sort: {
        name: string
        method: string
    }
    fetchNeed: boolean
}
type infoModal = {
    show: boolean
    orgId: number
    orgName: string
}

const OrganizationTable = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const ofdLoading = useSelector<RootState, boolean>(state => state.commonOFD.loading)
    const permissions = useSelector<RootState, Permission[]>(state => state.commonOFD.permissions)
    const totalPages = useSelector<RootState, number>(state => state.organization.totalPages)
    const organizationList = useSelector<RootState, Organization[]>(state => state.organization.organizationList)
    const [modal, setModal] = useState<boolean>(false)
    const [infoModal, setInfoModal] = useState<infoModal>({ show: false, orgId: 0, orgName: '' })
    const [updateConfirmationModal, setUpdateConfirmationModal] = useState<boolean>(false)
    const [createOfdUserModal, setCreateOfdUserModal] = useState<boolean>(false)
    const [state, setState] = useState<tableState>({
        currentPage: 1,
        size: '20',
        search: {
            xin: '',
            name: '',
            juridicalAddress: ''
        },
        sort: {
            name: '',
            method: 'default'
        },
        fetchNeed: true
    })
    const firstSimbolCheck = (props: string) => {
        if (props.length === 0) return '?'
        return '&'
    }
    const createPropsString = () => {
        let props = ''
        const search = { ...state.search }
        const sort = { ...state.sort }
        props += firstSimbolCheck(props)
        props += `page=${state.currentPage}`
        if (state.size !== '20') {
            props += firstSimbolCheck(props)
            props += `size=${state.size}`
        }
        if (search.xin.length > 2) {
            props += firstSimbolCheck(props)
            props += `search=xin=${search.xin}`
        }
        if (search.name.length > 2) {
            props += firstSimbolCheck(props)
            props += `search=name=${search.name}`
        }
        if (search.juridicalAddress.length > 2) {
            props += firstSimbolCheck(props)
            props += `search=juridicalAddress=${search.juridicalAddress}`
        }
        if (sort.method !== 'default' && sort.name !== '') {
            props += firstSimbolCheck(props)
            props += `sort=${sort.name}=${sort.method}`
        }
        dispatch(setPropsToFilterOrganization(props))
        return props
    }
    const makePropsForCashierListHandler = () => {
        const props = createPropsString()
        if (state.fetchNeed) {
            dispatch(getOrganizationListRequest(props))
        }
    }
    useEffect(() => {
        if (permissions.length === 0) dispatch(getUserPermissionsRequest())
    }, [])
    useEffect(() => {
        if (findPermission('LIST_ACTIVITY_CLASSIFIERS', permissions)) dispatch(getActivityClassifierRequest())
        if (findPermission('LIST_TAXATION_TYPES', permissions)) dispatch(getTaxationTypeRequest())
    }, [permissions])
    useEffect(() => {
        makePropsForCashierListHandler()
    }, [state.currentPage, state.search, state.size, state.sort])
    const setFiltersToDefault = () => {
        setState({
            currentPage: 1,
            size: '20',
            search: {
                xin: '',
                name: '',
                juridicalAddress: ''
            },
            sort: {
                name: '',
                method: 'default'
            },
            fetchNeed: true
        })
    }
    const createOrgHandler = () => {
        history.push('/fiscalization/organizations/create')
    }
    const handlePageClick = (e: any) => {
        setState({ ...state, currentPage: e.selected + 1, fetchNeed: true })
    }
    const changeFilterHandler = (value: string, name: string) => {
        if (name === 'xin' || name === 'name' || name === 'juridicalAddress') {
            if (value.length > 2) {
                setState({ ...state, search: { ...state.search, [name]: value }, fetchNeed: true })
            }
            else {
                setState({ ...state, search: { ...state.search, [name]: value }, fetchNeed: false })
            }
        }
        else if (name === 'size') {
            setState({ ...state, size: value, fetchNeed: true })
        }
    }
    const sortHandler = (name: string) => {
        if (state.sort.name !== name) {
            setState({ ...state, sort: { name, method: 'asc' }, fetchNeed: true })
        }
        else if (state.sort.name === name && state.sort.method === 'asc') {
            setState({ ...state, sort: { name, method: 'desc' }, fetchNeed: true })
        }
        else if (state.sort.name === name && state.sort.method === 'desc') {
            setState({ ...state, sort: { name: '', method: 'default' }, fetchNeed: true })
        }
    }
    const sortableColumnHanlder = (column: any) => {
        return (
            <span
                className={
                    state.sort.name === column.id ?
                        state.sort.method === 'asc' ?
                            'cashier-th-sort-asc cashier-th-sort'
                            :
                            state.sort.method === 'desc' ?
                                'cashier-th-sort-desc cashier-th-sort'
                                :
                                'cashier-th-sort'
                        :
                        'cashier-th-sort'}
                onClick={() => sortHandler(column.id)}
            >{column.render('Header')}</span>
        )
    }
    const columnFilterRender = (column: any) => {
        if (column.Header === 'Действие') {
            return (
                <>
                    <span className='cashier-th-sort'>{column.render('Header')}</span>
                    <input className='cashier-th-filter-input' disabled />
                </>
            )
        }
        else {
            const searchCopy: any = { ...state.search }
            let inputValue = ''
            Object.keys(searchCopy).map((item: string) => {
                if (item === column.id) inputValue = searchCopy[item]
            })
            return (
                <>
                    {sortableColumnHanlder(column)}
                    <input
                        value={inputValue}
                        className='cashier-th-filter-input'
                        onChange={(e) => changeFilterHandler(e.target.value, column.id)}
                        placeholder={`Введите 3 символа`}
                    />
                </>
            )
        }
    }
    const openCashierPage = (name: string) => {
        dispatch(setOrgNameForFilter(name))
        history.push('/fiscalization/cashiers')
    }
    const openEditModalHandler = (id: number) => {
        setModal(true)
        dispatch(getOneOrganizationRequest(id))
    }
    const closeEditModalHanlder = () => {
        setModal(false)
        dispatch(getOneOrganizationSuccess({
            id: 0,
            name: '',
            xin: '',
            juridicalAddress: '',
            taxationTypeCode: '',
            activityClassifierCode: '',
            blocked: false,
            registrationDate: ''
        }))
    }
    const openInfoModal = (orgId: number, orgName: string) => {
        setInfoModal({ show: true, orgId, orgName })
    }
    const closeInfoModal = () => {
        setInfoModal({ show: false, orgId: 0, orgName: '' })
        dispatch(getOneOrganizationSuccess({
            id: 0,
            name: '',
            xin: '',
            juridicalAddress: '',
            taxationTypeCode: '',
            activityClassifierCode: '',
            blocked: false,
            registrationDate: ''
        }))
        dispatch(setOrgNameForFilter(''))
        dispatch(getCashierListSuccess({ list: [], totalItems: 0, totalPages: 0 }))
        dispatch(getOfdUsersSuccess({ list: [], totalItems: 0, totalPages: 0 }))
    }
    const createOfdUserHandler = () => {
        setCreateOfdUserModal(true)
    }
    const closeCreateOfdUserModal = () => {
        setCreateOfdUserModal(false)
    }
    const CustomToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
        <button
            ref={ref}
            className='cashier-btn-create cashier-list-btn'
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            &#x25bc;
        </button>
    ));
    const columns: any[] = useMemo(() => [
        {
            Header: 'Название организации',
            accessor: 'name',
            Cell: (cell: any) => (
                <>
                    {findPermission('GET_ORGANIZATION', permissions) ?
                        <a href='#' onClick={() => openInfoModal(cell?.row?.original?.id, cell?.row?.original?.name)}>{cell?.row?.original?.name}</a>
                        :
                        cell?.row?.original?.name
                    }
                </>
            )
        },
        {
            Header: 'ИИН/БИН организации',
            accessor: 'xin',
        },
        {
            Header: 'Юридический адрес организации',
            accessor: 'juridicalAddress',
        },
        {
            Header: 'Действие',
            accessor: 'action',
            Cell: (cell: any) => {
                return (
                    <Dropdown>
                        <Dropdown.Toggle
                            id="dropdown-custom-components" as={CustomToggle}
                        >
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='w-250px'>
                            {findPermission('LIST_KKM', permissions) && (
                                <Dropdown.Item eventKey="1" className='fw-bold' onClick={() => openCashierPage(cell?.row?.original?.name)}>
                                    Кассы
                                </Dropdown.Item>
                            )}
                            {findPermission('EDIT_ORGANIZATION', permissions) && (
                                <>
                                    <Dropdown.Divider />
                                    <Dropdown.Item eventKey="2" className='fw-bold' onClick={() => openEditModalHandler(cell?.row?.original?.id)}>
                                        Редактировать
                                    </Dropdown.Item>
                                </>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                )
            }
        },
    ], [permissions])
    return (
        <>
            {ofdLoading && <LoaderOfd />}
            <OrgInfoModal   //модалка со всей информацией
                show={infoModal.show}
                orgId={infoModal.orgId}
                close={() => closeInfoModal()}
                orgName={infoModal.orgName} />
            <CreateOfdUserModal  //модалка создания нового юзера для организации
                show={createOfdUserModal}
                close={() => closeCreateOfdUserModal()} />
            <UserUpdateConfirmationModal  //модалка для отправки повторной ссылки на почту юзеру
                modalShow={updateConfirmationModal}
                closeModal={() => setUpdateConfirmationModal(false)}
            />
            <EditOrganizationModal  //модалка для редактирования организации
                modalShow={modal}
                closeModal={closeEditModalHanlder} />
            <Row className='d-flex space-betwwen ' >
                <Col className='m-b-20-px'>
                    <h6 className='cashier-list-title'>
                        Организации зарегистрированные в системе
                    </h6>
                </Col>
                <Col className='d-flex justify-content-end m-b-20-px align-items-center'>

                    <select
                        className='cashier-list-dd'
                        onChange={(e) => changeFilterHandler(e.target.value, 'size')}
                    >
                        <option value={20}>20 записей</option>
                        <option value={50}>50 записей</option>
                    </select>
                    {(findPermission('UPDATE_CONFIRMATION', permissions) || findPermission('CREATE_USER', permissions)) &&
                        <Dropdown className='m-l-10px'>
                            <Dropdown.Toggle
                                id="dropdown-custom-components" as={CustomToggle}
                            >
                                Пользователи <span></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='w-250px'>
                                {findPermission('UPDATE_CONFIRMATION', permissions) && (
                                    <Dropdown.Item eventKey="1" className='fw-bold' onClick={() => setUpdateConfirmationModal(true)}>
                                        Письмо с подтверждением
                                    </Dropdown.Item>
                                )}
                                {findPermission('CREATE_USER', permissions) && (
                                    <>
                                        <Dropdown.Divider />
                                        <Dropdown.Item eventKey="2" className='fw-bold' onClick={() => createOfdUserHandler()}>
                                            Создание пользователя
                                        </Dropdown.Item>
                                    </>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </Col>
                <Col className='d-flex justify-content-end m-b-20-px align-items-center '>
                    <button
                        className='cashier-btn-create cashier-list-btn cashier-list-header-btn'
                        onClick={setFiltersToDefault}
                    >
                        Сбросить фильтры
                    </button>
                    {findPermission('CREATE_ORGANIZATION', permissions) &&
                        <button
                            className='cashier-btn-create cashier-list-btn cashier-list-header-btn'
                            onClick={createOrgHandler}
                        >
                            Создать
                        </button>}

                </Col>
            </Row>
            <Card>
                <ReactTable
                    // data={cashierListMemorized}
                    data={organizationList}
                    columns={columns}
                    tableWrapperClassName='cashier-table-wrapper'
                    tableClassName='cashier-table'
                    thClassName='cashier-table-td cashier-table-th'
                    thClassNameRight='cashier-table-td-right'
                    tdClassName='cashier-table-td'
                    tdClassNameRight='cashier-table-td-right'
                    trClassNameHover='cashier-table-td-hover'
                    columnFilterRender={columnFilterRender}
                />
            </Card>
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                pageCount={totalPages}  //количество страниц
                onPageChange={handlePageClick}
                breakLabel='...'
                pageRangeDisplayed={2}
                containerClassName='cashier-pagination-box'
                previousLinkClassName='cashier-pagination-prevlink cashier-pagination-mainlink'
                nextLinkClassName='cashier-pagination-nextlink cashier-pagination-mainlink'
                pageLinkClassName='cashier-pagination-mainlink'
                activeLinkClassName='cashier-pagination-activelink'
                breakLinkClassName='cashier-pagination-breaklink'
            // marginPagesDisplayed={2}
            // forcePage={period.page}
            />
        </>
    )
}
export default OrganizationTable