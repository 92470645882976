import { useEffect, useState } from 'react'
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { toAbsoluteUrl } from '../../helpers'

export function AuthPage() {
	const [sizeCard, setSizeCard] = useState(false)
	const location =useLocation()
	useEffect(() => {
		document.body.classList.add('bg-white')
		return () => {
			document.body.classList.remove('bg-white')
		}
	}, [])
	useEffect(() => {
		if(location.pathname=== '/contacts/us'||location.pathname=== '/about/us'){
			setSizeCard(true)
		}
		return()=>{
			setSizeCard(false)
		}
	}, [location])

	const token: string | undefined = localStorage.token

    return <>
        <div
            className={` ${sizeCard ? "d-none" : "d-flex"} flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed`}
            style={{
                backgroundImage: `url(${toAbsoluteUrl(
                    '/media/illustrations/sketchy-1/14.png'
                )})`,
            }}
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid pb-lg-20'>
                {/* begin::Logo */}
                <a href='#' className='mb-12'>
                    <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/logos/logo-4.png')}
                        className='h-45px'
                    />
                </a>
                {/* end::Logo */}
                {/* begin::Wrapper */}
                <div className={`w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto`}>
                    <Switch>
                        <Route path='/auth/login' component={Login}/>
                        <Route path='/auth/registration' component={Registration}/>
                        <Route path='/auth/forgot-password' component={ForgotPassword}/>
                        <Route path='/contacts' render={() => <></>}/>
                        <Route path='/about' render={() => <></>}/>
                        <Redirect from='/auth' exact={true} to='/auth/login'/>
                        {token && token !== 'null' ? null : <Redirect to='/auth/login'/>}
                    </Switch>
                </div>
                {/* end::Wrapper */}
            </div>
            {/* end::Content */}
            {/* begin::Footer */}
            <div className='d-flex flex-center flex-column-auto p-10'>
                <div className='d-flex align-items-center fw-bold fs-6'>
                    <Link to='/about/us'>О нас</Link>
                    <Link to='/contacts/us'>Контакты</Link>
                </div>
            </div>
            {/* end::Footer */}
        </div>
    </>

}
