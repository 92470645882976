import {
	Box,
	Divider,
	Paper,
	Typography,
} from '@mui/material'
import moment from 'moment'
import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { toAbsoluteUrl } from '../../helpers'

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void
	name: string
}

const styles = {
	errorMessage: {
		fontFamily: 'Roboto',
		fontSize: '20px',
		fontWeight: '500',
		lineHeight: '23px',
		letterSpacing: '0em',
		textAlign: 'left',
		color: '#ffffff',
	},
	fieldName: {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '19px',
		color: '#4D565F',

		textAlign: 'left',
		alignSelf: 'center',
		width: 1 / 2,
		pl: 5,
	},
	fieldWrapper: {
		flexGrow: 1,
		flexBasis: 1,
		display: 'flex',
		justifyContent: 'center',
	},
	fieldValue: {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '16px',
		lineHeight: '19px',
		color: '#000D1A',

		textAlign: 'left',
		alignSelf: 'center',
		width: 1 / 2,
		pl: 5,
	},
	paymentSum: {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '20px',
		lineHeight: '23px',
		color: '#000D1A',


		textAlign: 'left',
		alignSelf: 'center',
		width: 1 / 2,
		pl: 5,
	},
	com: {
		fonFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '20px',
		lineHeight: '23px',
		color: '#000D1A',

		textAlign: 'left',
		alignSelf: 'center',
		width: 1 / 2,
		pl: 5,
	},
	textField: { flexGrow: 2 },
	box: { display: 'flex', alignItems: 'center', p: 3 },
}

const NumberFormatCustom = React.forwardRef<NumberFormat<string>, CustomProps>(
	function NumberFormatCustom(props, ref) {
		const { onChange, ...other } = props

		return (
			<NumberFormat
				{...other}
				getInputRef={ref}
				onValueChange={values => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					})
				}}
				thousandSeparator
				isNumericString
				suffix=' ₸'
			/>
		)
	}
)

const PaymentStatus: FC = () => {
	let { id } = useParams<any>()

	const [values, setValues] = React.useState({
		textmask: '(100) 000-0000',
		numberformat: '1320',
	})

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValues({
			...values,
			[event.target.name]: event.target.value,
		})
	}

	return (
		<Paper sx={{ background: '#F4F6F8', pb: 5}} elevation={3}>
			{/* <PageTitle breadcrumbs={[]}>Статус платежа</PageTitle> */}
			{/* <Box sx={{width: '200px'}}><KTSVG path='/media/logos/PaydalaPay.svg'/></Box> */}
			<Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
				<img
					src={toAbsoluteUrl('/media/logos/PayDalaPay.png')}
					className='h-50px'
					alt='Paydala'
				/>
			</Box>
			<Divider variant='middle' sx={{ mx: 10 }} />
			<Box sx={{ p: 3, display: 'flex', alignItems: 'center' }}>
				<Box
					sx={{
						mr: 1,
						p: 1,
						background: '#ffffff',
						borderRadius: '100%',
						alignItems: 'center',
					}}
				>
					<img src={toAbsoluteUrl('/media/logos/Beeline.png')} alt='' />
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}
				>
					<Box sx={{ pb: 1, fontWeight: '400', fontSize: '18px' }}>Beeline</Box>
					<Box>+ (776) 006-04-90</Box>
				</Box>
			</Box>
			<Box sx={{ p: 2,pl: 5, background: '#C84F4F' }}>
				<Typography sx={styles.errorMessage}>Платеж не проведен</Typography>
			</Box>
			<Box sx={{ p: 3, display: 'flex' }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						flexGrow: 1,
						flexBasis: 1,
					}}
				>
					<Box sx={styles.fieldName}>Сумма платежа</Box>
					<Box sx={{ ...styles.paymentSum, mt: 2 }}>30 000 ₸</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						flexGrow: 1,
						flexBasis: 1,
					}}
				>
					<Box sx={styles.fieldName}>Комиссия</Box>
					<Box sx={{ ...styles.com, mt: 2}}>500 ₸</Box>
				</Box>
			</Box>

			<Divider sx={{ mb: 1, mx: 10 }} variant='middle' textAlign='left' />

			<Box sx={styles.box}>
				<Box sx={styles.fieldWrapper}>
					<Typography sx={styles.fieldName}>Номер чека</Typography>
				</Box>
				<Box sx={styles.fieldWrapper}>
					<Typography sx={styles.fieldValue}>P 4230</Typography>
				</Box>
			</Box>
			<Box sx={styles.box}>
				<Box sx={styles.fieldWrapper}>
					<Typography sx={styles.fieldName}>Дата</Typography>
				</Box>
				<Box sx={styles.fieldWrapper}>
					<Typography sx={styles.fieldValue}>
						{moment().format('YYYY MM DD hh:mm:s')}
					</Typography>
				</Box>
			</Box>
			<Box sx={styles.box}>
				<Box sx={styles.fieldWrapper}>
					<Typography sx={styles.fieldName}>ФИО</Typography>
				</Box>
				<Box sx={styles.fieldWrapper}>
					<Typography sx={styles.fieldValue}>Петров С. А.</Typography>
				</Box>
			</Box>
			<Box sx={styles.box}>
				<Box sx={styles.fieldWrapper}>
					<Typography sx={styles.fieldName}>Терминал</Typography>
				</Box>
				<Box sx={styles.fieldWrapper}>
					<Typography sx={styles.fieldValue}>ID 117KZ23</Typography>
				</Box>
			</Box>
		</Paper>
	)
}

export default PaymentStatus
