import { useCallback } from 'react'
import { ITasks } from '../../models/tasks/tasksModel'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { Box, Button, Paper, Typography } from '@mui/material'
import Highlighter from '../../components/helpers/Highlighter'
import { Link } from 'react-router-dom'

interface taskProps {
	task: ITasks
	handleUpdateDrawer: (id: number | undefined) => void
	filter: any
}

const PublicTaskItem = ({ task, filter, handleUpdateDrawer }: taskProps) => {

	const intl = useIntl()

	const handleShowDetailsDrawer = useCallback(() => {

	}, [])

	return (
		<>
			<Paper
				elevation={3}
				sx={{ px: 2, display: 'flex', alignItems: 'center' }}
			>
				<Box>#{task.id}</Box>

				{/* //==================================TITLE */}
				<Box sx={{ p: 3, flexGrow: 1 }}>
					<Typography
						variant={'caption'}
						sx={{ fontSize: '14px', fontWeight: 600 }}
					>
						{Highlighter(task.title, filter.request)}
					</Typography>
				</Box>
				<Box sx={{ p: 1 }}>
					<Typography
						variant={'caption'}
						sx={{ fontSize: '14px', fontWeight: 600 }}
					>
						{task.assignee?.fullName || 'Нет исполнителя'}
					</Typography>
				</Box>
				<Box sx={{ p: 1 }}>
					<Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
						{task.start
							? moment(task.start).format('DD MMMM YYYY, hh:mm a')
							: intl.formatMessage({ id: 'TASK.DEFAULT.DATE' })}
					</Typography>
				</Box>

				<Box sx={{ ml: 1 }}>
					<Button variant='outlined' size='small'>
						Epic
					</Button>
				</Box>
				<Box sx={{ p: 1 }}>
					<Link to={`/task/details/${task.uuid}`}>
						<Button
							onClick={handleShowDetailsDrawer}
							color='secondary'
							size='small'
							variant='outlined'
						>
							{intl.formatMessage({ id: 'TASK.DETAILS' })}
						</Button>
					</Link>
				</Box>
			</Paper>

			{/* <TaskDetailsDrawer
				data={task}
				show={showDetailsDrawer}
				setShow={setShowDetailsDrawer}
				handleClose={handleCloseDetailsDrawer}
				user={user}
			/> */}
		</>
	)
}

export default PublicTaskItem
