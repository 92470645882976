import React, { useRef } from 'react'
import { Modal } from 'react-bootstrap'
import './ZReport.scss'
import ReactToPrint from 'react-to-print'

type Props = {
    show: boolean
    close: CallableFunction
}

const ComponentToPrint = React.forwardRef((props: any, ref: any) => {
    const { shift, name } = props
    return (
        <div ref={ref} className='z-report-box'>
            <div className='d-flex align-items-center flex-column'>
                <p>ТОО "{name}"</p>
                <p>ИИН 123456789012</p>
                <p>РНН 123456789012</p>
                <p>Адрес Almaty</p>
            </div>
            <hr />
            <div className='d-flex flex-column align-items-center'>
                <p>Z-отчет</p>
                <p>Смена №{shift}</p>
                <p>ЗНК 56789012</p>
                <p>РНК 901</p>
                <p>Код 2</p>
                <p>Дата отчета 07.06.22</p>
                <p>Кассир Иванов</p>
                <p>Валюта KZT</p>
            </div>

            <div className='d-flex justify-content-between'>
                <div className='d-flex flex-column justify-content-between'>
                    <p style={{ color: 'white' }}>_</p>
                    <p>ПРОДАЖА</p>
                    <p>ПОКУПКА</p>
                    <p>ВОЗВРАТ ПРОДАЖИ</p>
                    <p>ВОЗВРАТ ПОКУПКИ</p>
                </div>
                <div className='d-flex flex-column justify-content-between m-l-10px'>
                    <p>Количество</p>
                    <p>0</p>
                    <p>0</p>
                    <p>0</p>
                    <p>0</p>
                </div>
                <div className='d-flex flex-column justify-content-between m-l-10px'>
                    <p>Сумма</p>
                    <p>0</p>
                    <p>0</p>
                    <p>0</p>
                    <p>0</p>
                </div>
            </div>
            <hr />
            <p>Количество документов сформированных за смену 1</p>
            <p>Конец отчета</p>
        </div>
    );
});

const ZReportModal = ({ show, close }: Props) => {
    const componentRef = useRef(null)
    return (
        <Modal
            centered
            show={show}
            onHide={() => close()}
            // contentClassName='z-report-box'
            size='sm'
        >
            <ComponentToPrint shift='21' name='Company' ref={componentRef} />
            <ReactToPrint
                trigger={() => <button className='cashier-list-dd m-10-px'>Печать</button>}
                content={() => componentRef.current}
            />

        </Modal>
    )
}
export default ZReportModal