import React, { useEffect, useState } from 'react'
import { Card, Col, Modal, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { OFD } from '../../models/cashier/FileModel'
import { RootState } from '../../setup'
import { activateCashierRequest } from '../../store/actions/cashierActions'

type Props = {
    show: boolean
    close: CallableFunction
    kkmId: number
}

type State = {
    kkmId: number
    ofdId: number
    token: number
    ofdKkmId: number
    registrationNumber: string
}

const InitCashierModal = ({ show, close, kkmId }: Props) => {
    const dispatch = useDispatch()
    const ofdList: OFD[] = useSelector<RootState, OFD[]>(state => state.cashier.ofdList)
    const propsForFilter: string = useSelector<RootState, string>(state => state.cashier.propsForFilter)
    const [canSubmit, setCanSubmit] = useState<boolean>(false)
    const [state, setState] = useState<State>({
        kkmId: 0,
        ofdId: 0,
        token: 0,
        ofdKkmId: 0,
        registrationNumber: ''
    })
    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const name = e.target.name
        if (name === 'ofdKkmId' || name === 'token') {
            const numberValue = parseInt(value)
            if (numberValue > 0) setState({ ...state, [name]: numberValue })
        }
        else if (name === 'registrationNumber' && value.length < 13) setState({ ...state, [name]: value })
        else setState({ ...state, [name]: value })
    }
    const selectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value
        const name = e.target.name
        setState({ ...state, [name]: value })
    }

    const closeHandler = () => {
        close()
        setState({
            kkmId: 0,
            ofdId: 0,
            token: 0,
            ofdKkmId: 0,
            registrationNumber: ''
        })
    }
    const activateCashierHandler = () => {
        dispatch(activateCashierRequest(state, propsForFilter))
        closeHandler()
    }
    useEffect(() => {
        if (state.kkmId !== 0 &&
            state.ofdId !== 0 &&
            state.ofdKkmId !== 0 &&
            state.registrationNumber !== '' &&
            state.registrationNumber.length === 12 &&
            state.token !== 0) setCanSubmit(true)
        else setCanSubmit(false)
    }, [state])
    useEffect(() => {
        setState({ ...state, kkmId })
    }, [kkmId])
    return (
        <>
            <Modal
                show={show}
                onHide={() => closeHandler()}
                centered

            >
                <Card className='organization-modal-box'>
                    <div>
                        <h6 className='organization-list-title'>
                            Информация о кассе
                        </h6>
                        <div className='m-b-40-px d-flex space-betwwen'>
                            <div className='cashier-create-column'>
                                <Form.Label>ОФД</Form.Label>
                            </div>
                            <div className='cashier-create-column'>
                                <Form.Select
                                    className='oraganization-input-create'
                                    name='ofdId'
                                    isInvalid={state.ofdId == 0}
                                    isValid={state.ofdId !== 0}
                                    value={state.ofdId}
                                    onChange={selectHandler}
                                >
                                    <option value={0}></option>
                                    {ofdList.map(item => {
                                        return (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )
                                    })}

                                </Form.Select>
                            </div>
                        </div>
                        <div className='m-b-40-px d-flex space-betwwen'>
                            <div className='cashier-create-column'>
                                <Form.Label>ID кассы в ОФД</Form.Label>
                            </div>
                            <div className='cashier-create-column'>
                                <Form.Control
                                    type='number'
                                    className='oraganization-input-create'
                                    name='ofdKkmId'
                                    isInvalid={state.ofdKkmId === 0}
                                    isValid={state.ofdKkmId !== 0}
                                    value={state.ofdKkmId === 0 ? '' : state.ofdKkmId}
                                    onChange={changeHandler}
                                />
                            </div>
                        </div>
                        <div className='m-b-40-px d-flex space-betwwen'>
                            <div className='cashier-create-column'>
                                <Form.Label>Токен кассы</Form.Label>
                            </div>
                            <div className='cashier-create-column'>
                                <Form.Control
                                    type='number'
                                    className='oraganization-input-create'
                                    name='token'
                                    isInvalid={state.token === 0}
                                    isValid={state.token !== 0}
                                    value={state.token === 0 ? '' : state.token}
                                    onChange={changeHandler}
                                />
                            </div>
                        </div>
                        <div className='m-b-40-px d-flex space-betwwen'>
                            <div className='cashier-create-column'>
                                <Form.Label>Регистрационный номер кассы</Form.Label>
                            </div>
                            <div className='cashier-create-column'>
                                <Form.Control
                                    type='text'
                                    placeholder='Регистрационный номер'
                                    className='oraganization-input-create'
                                    isInvalid={state.registrationNumber.length !== 12}
                                    isValid={state.registrationNumber.length === 12}
                                    name='registrationNumber'
                                    value={state.registrationNumber}
                                    onChange={changeHandler}
                                />
                            </div>
                        </div>

                        <Col className='d-flex space-betwwen ' >
                            <button
                                onClick={() => closeHandler()}
                                className='cashier-btn-create'>
                                Отмена
                            </button>
                            <button
                                className='cashier-btn-create'
                                disabled={!canSubmit}
                                onClick={activateCashierHandler}
                            >
                                Сохранить
                            </button>
                        </Col>
                    </div>
                </Card>
            </Modal>
        </>
    )
}
export default InitCashierModal