import { AnyAction } from 'redux'
import { IPaginationModelTasks } from '../../models/personal/paginationCustom'
import { ITaskComment, ITasks } from '../../models/tasks/tasksModel'
import {
    CREATE_TASK_ERROR,
    CREATE_TASK_REQUEST,
    CREATE_TASK_SUCCESS,
    GET_AVAILABLE_TASK_STATUSES_SUCCESS,
    GET_TASKS,
    GET_TASKS_BY_TYPE,
    GET_TASKS_ERROR,
    GET_TASKS_PAGE_SUCCESS,
    GET_TASKS_SUCCESS,
    GET_TASK_BY_ID,
    GET_TASK_BY_ID_SUCCESS,
    GET_TASK_COMMENT,
    GET_TASK_COMMENT_SUCCESS,
} from '../calendarTasksTypes';

interface tasksStateModel {
	tasks: ITasks[]
	tasksPage: IPaginationModelTasks
	loading: boolean
	taskError: boolean
	taskById: ITasks
	taskByIdStatuses: any[]
	taskComment:ITaskComment[]|any
	createTaskLoading: boolean
	createTaskError: null
	tasksCommentLoading:boolean
}

const initialState: tasksStateModel = {
	tasks: [],
	loading: false,
	taskError: false,
	tasksPage: {} as IPaginationModelTasks,
	taskById: {} as ITasks,
	taskByIdStatuses: [],
	taskComment:[],
	createTaskLoading: false,
	createTaskError: null,
	tasksCommentLoading:false
}

const calendarTasksReducer = (
	state = initialState,
	action: AnyAction
): tasksStateModel => {
	switch (action.type) {
		case GET_TASKS:
			return { ...state, loading: true }
		case GET_TASKS_SUCCESS:
			return { ...state, tasks: action.tasks, loading: false, createTaskLoading: false }
		case GET_TASKS_PAGE_SUCCESS:
			return { ...state, tasksPage: action.tasks, loading: false }
		case GET_TASKS_ERROR:
			return { ...state, taskError: true , loading: false, createTaskLoading: false}
		case GET_TASKS_BY_TYPE:
			return { ...state,  loading: true }
		case GET_TASK_BY_ID:
			return { ...state, taskById: {} as ITasks, loading: true }
		case GET_TASK_BY_ID_SUCCESS:
			return { ...state, taskById: action.task, loading: false }
		case GET_AVAILABLE_TASK_STATUSES_SUCCESS:
			return { ...state, taskByIdStatuses: action.payload }

		case GET_TASK_COMMENT:
			return { ...state, tasksCommentLoading:true }
		case GET_TASK_COMMENT_SUCCESS:
			
			return { ...state, taskComment: action.taskComment, tasksCommentLoading:false }
		//==============================CREATE_TASK
		case CREATE_TASK_REQUEST:
			return { ...state, createTaskLoading: true, createTaskError: null }
		case CREATE_TASK_SUCCESS:
			return {
				...state,
				tasksPage: action.payload,
				createTaskLoading: false,
				createTaskError: null,
			}
		case CREATE_TASK_ERROR:
			return { ...state, createTaskError: null, createTaskLoading: false }
		default:
			return state
	}
}

export default calendarTasksReducer
