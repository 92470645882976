import { ICorrespondenceFilter } from '../../pages/chat/Chat'
import { IMessageFilter } from '../../pages/chat/ChatReply/ChatReply'
import {
    CREATE_CORRESPONDENCE,
    CREATE_CORRESPONDENCE_ERROR,
    CREATE_CORRESPONDENCE_SUCCESS,
    FETCH_CORRESPONDENCE,
    FETCH_CORRESPONDENCE_ERROR,
    FETCH_CORRESPONDENCE_MESSAGES,
    FETCH_CORRESPONDENCE_SUCCESS,
    FETCH_CORRESPONDENCE_MESSAGES_SUCCESS,
    CREATE_CORRESPONDENCE_MESSAGE_ERROR,
    CREATE_CORRESPONDENCE_MESSAGE_SUCCESS,
    FETCH_CORRESPONDENCE_MESSAGES_ERROR,
    TOGGLE_CORRESPONDENCE_MARK,
    TOGGLE_CORRESPONDENCE_IMPORTANT,
    CREATE_CORRESPONDENCE_MESSAGE,
    REQUEST_OPEN_CORRESPONDENCE,
    REQUEST_CORRESPONDENCE_MARK_COUNT,
    REQUEST_CORRESPONDENCE_MARK_COUNT_SUCCESS,
    REQUEST_CORRESPONDENCE_MARK_COUNT_ERROR,
    REQUEST_CORRESPONDENCE_IMPORTANT_COUNT,
    REQUEST_CORRESPONDENCE_IMPORTANT_COUNT_SUCCESS,
    REQUEST_CORRESPONDENCE_IMPORTANT_COUNT_ERROR,
    REQUEST_CORRESPONDENCE_NOT_OPEN_COUNT,
    REQUEST_CORRESPONDENCE_NOT_OPEN_COUNT_SUCCESS,
    REQUEST_CORRESPONDENCE_NOT_OPEN_COUNT_ERROR,
    REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES,
    REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES_SUCCESS,
    REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES_ERROR,
    SEND_PUBLIC_CORRESPONDENCE_MESSAGE_SUCCESS,
    SEND_PUBLIC_CORRESPONDENCE_MESSAGE,
    SEND_PUBLIC_CORRESPONDENCE_MESSAGE_ERROR,
    FETCH_CORRESPONDENCE_INFO,
    FETCH_PUBLIC_CORRESPONDENCE_INFO,
    FETCH_PUBLIC_CORRESPONDENCE_INFO_SUCCESS,
    FETCH_PUBLIC_CORRESPONDENCE_INFO_ERROR,
    FETCH_CORRESPONDENCE_INFO_SUCCESS,
    FETCH_CORRESPONDENCE_INFO_ERROR,
    REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME,
    REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME_SUCCESS,
    REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME_ERROR,
    REPLY_MESSAGE_REQUEST,
    REPLY_MESSAGE_SUCCESS,
    REPLY_MESSAGE_ERROR,
    REPLY_PUBLIC_MESSAGE,
    REPLY_PUBLIC_MESSAGE_SUCCESS,
    REPLY_PUBLIC_MESSAGE_ERROR,
} from '../correspondenceTypes';
import {
	ICorrespondence,
	ICorrespondenceContent,
	ICorrespondenceMessageReply,
	ICorrespondenceMessages,
} from '../reducers/correspondenceReducer'
///////////////////////////////////////////CORRESPONDENCE ACTIONS
export const requestCorrespondenceAction = (
	filter?: ICorrespondenceFilter
) => ({ type: FETCH_CORRESPONDENCE, filter })
export const requestCorrespondenceSuccessAction = (
	payload: ICorrespondence
) => ({ type: FETCH_CORRESPONDENCE_SUCCESS, payload })
export const requestCorrespondenceErrorAction = (payload: any) => ({
	type: FETCH_CORRESPONDENCE_ERROR,
	payload,
})

export const createCorrespondenceAction = (payload: any) => ({
	type: CREATE_CORRESPONDENCE,
	payload,
})
export const createCorrespondenceSuccessAction = (payload: any) => ({
	type: CREATE_CORRESPONDENCE_SUCCESS,
	payload,
})
export const createCorrespondenceErrorAction = (payload: any) => ({
	type: CREATE_CORRESPONDENCE_ERROR,
	payload,
})
///////////////////////////////////////////CORRESPONDENCE MESSAGES ACTIONS

export const requestCorrespondenceMessagesAction = (
	payload: number,
	filter: IMessageFilter
) => ({
	type: FETCH_CORRESPONDENCE_MESSAGES,
	payload,
	filter,
})
export const requestCorrespondenceMessagesSuccessAction = (payload: any) => ({
	type: FETCH_CORRESPONDENCE_MESSAGES_SUCCESS,
	payload,
})
export const requestCorrespondenceMessagesErrorAction = (payload: any) => ({
	type: FETCH_CORRESPONDENCE_MESSAGES_ERROR,
	payload,
})

export const createCorrespondenceMessagesAction = (
	chatId: number,
	payload: any
) => ({
	type: CREATE_CORRESPONDENCE_MESSAGE,
	chatId,
	payload,
})
export const createCorrespondenceMessagesSuccessAction = (payload: any) => ({
	type: CREATE_CORRESPONDENCE_MESSAGE_SUCCESS,
	payload,
})
export const createCorrespondenceMessagesErrorAction = (payload: any) => ({
	type: CREATE_CORRESPONDENCE_MESSAGE_ERROR,
	payload,
})

///////////////////////////////////////////CORRESPONDENCE TOGGLE MARK ACTIONS
export const requestToggleCorrespondenceMarkAction = (
	payload: number,
	filter: ICorrespondenceFilter
) => ({
	type: TOGGLE_CORRESPONDENCE_MARK,
	payload,
	filter,
})
///////////////////////////////////////////CORRESPONDENCE REQUEST MARK ACTIONS

export const requestCorrespondenceMarkCount = () => ({
	type: REQUEST_CORRESPONDENCE_MARK_COUNT,
})
export const requestCorrespondenceMarkCountSuccess = (payload: number) => ({
	type: REQUEST_CORRESPONDENCE_MARK_COUNT_SUCCESS,
	payload,
})
export const requestCorrespondenceMarkCountError = (payload: string) => ({
	type: REQUEST_CORRESPONDENCE_MARK_COUNT_ERROR,
	payload,
})

///////////////////////////////////////////CORRESPONDENCE TOGGLE IMPORTANT ACTIONS
export const requestToggleCorrespondenceImportantAction = (
	payload: number,
	filter: ICorrespondenceFilter
) => ({ type: TOGGLE_CORRESPONDENCE_IMPORTANT, payload, filter })
export const requestCorrespondenceImportantCount = () => ({
	type: REQUEST_CORRESPONDENCE_IMPORTANT_COUNT,
})
export const requestCorrespondenceImportantCountSuccess = (
	payload: number
) => ({ type: REQUEST_CORRESPONDENCE_IMPORTANT_COUNT_SUCCESS, payload })
export const requestCorrespondenceImportantCountError = (payload: string) => ({
	type: REQUEST_CORRESPONDENCE_IMPORTANT_COUNT_ERROR,
	payload,
})
///////////////////////////////////////////CORRESPONDENCE TOGGLE OPEN ACTIONS
export const requestCorrespondenceOpenedAction = (payload: number) => ({
	type: REQUEST_OPEN_CORRESPONDENCE,
	payload,
})
export const requestCorrespondenceNotOpenCountAction = () => ({
	type: REQUEST_CORRESPONDENCE_NOT_OPEN_COUNT,
})
export const requestCorrespondenceNotOpenCountSuccessAction = (
	payload: number
) => ({
	type: REQUEST_CORRESPONDENCE_NOT_OPEN_COUNT_SUCCESS,
	payload,
})
export const requestCorrespondenceNotOpenCountErrorAction = (
	payload: string
) => ({
	type: REQUEST_CORRESPONDENCE_NOT_OPEN_COUNT_ERROR,
	payload,
})

///////////////////////////////////////////GET CORRESPONDENCE PUBLIC MESSAGES
export const reqeustPublicCorrespondenceMessagesAction = (
	payload: any,
	filter: IMessageFilter
) => ({ type: REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES, payload, filter })
export const requestPublicCorrespondenceMessagesSuccessAction = (
	payload: any
) => ({ type: REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES_SUCCESS, payload })
export const reqeustPublicCorrespondenceMessagesErrorAction = (
	payload: any
) => ({ type: REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES_ERROR, payload })

///////////////////////////////////////////SEND CORRESPONDENCE PUBLIC MESSAGES
export const sendPublicCorrespondenceMessageAction = (
	chatId: number,
	chatUuid: string,
	payload: any
) => ({ type: SEND_PUBLIC_CORRESPONDENCE_MESSAGE, chatId, chatUuid, payload })
export const sendPublicCorrespondenceMessageSuccessAction = (
	payload: ICorrespondenceMessages
) => ({ type: SEND_PUBLIC_CORRESPONDENCE_MESSAGE_SUCCESS, payload })
export const sendPublicCorrespondenceMessageErrorAction = (
	payload: string
) => ({ type: SEND_PUBLIC_CORRESPONDENCE_MESSAGE_ERROR, payload })

///////////////////////////////////////////FETCH CORRESPONDENCE INFO
export const requestCorrespondenceInfo = (payload: number) => ({
	type: FETCH_CORRESPONDENCE_INFO,
	payload,
})

export const requestCorrespondenceInfoSuccess = (
	payload: ICorrespondenceContent
) => ({ type: FETCH_CORRESPONDENCE_INFO_SUCCESS, payload })

export const requestCorrespondenceInfoError = (payload: string) => ({
	type: FETCH_CORRESPONDENCE_INFO_ERROR,
	payload,
})

///////////////////////////////////////////FETCH PUBLIC CORRESPONDENCE INFO

export const requestPublicCorrespondenceInfo = (payload: any) => ({
	type: FETCH_PUBLIC_CORRESPONDENCE_INFO,
	payload,
})

export const requestPublicCorrespondenceInfoSuccess = (
	payload: ICorrespondenceContent
) => ({ type: FETCH_PUBLIC_CORRESPONDENCE_INFO_SUCCESS, payload })

export const requestPublicCorrespondenceInfoError = (payload: string) => ({
	type: FETCH_PUBLIC_CORRESPONDENCE_INFO_ERROR,
	payload,
})

///////////////////////////////////////////FETCH PUBLIC CORRESPONDENCE ANONYM NAME
export const requestPublicCorrespondenceAnonymName = (payload: number) => ({
	type: REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME,
	payload,
})

export const requestPublicCorrespondenceAnonymNameSuccess = (
	payload: string
) => ({ type: REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME_SUCCESS, payload })

export const requestPublicCorrespondenceAnonymNameError = (
	payload: string
) => ({ type: REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME_ERROR, payload })

///////////////////////////////////////////REPLY MESSAGE
export const replyMessageAction = (
	chatId: number,
	messageId: number,
	payload: ICorrespondenceMessageReply
) => ({ type: REPLY_MESSAGE_REQUEST, chatId, messageId, payload })

export const replyMessageSuccessAction = (
	payload: ICorrespondenceMessages
) => ({ type: REPLY_MESSAGE_SUCCESS, payload })

export const replyMessageErrorAction = (payload: string) => ({
	type: REPLY_MESSAGE_ERROR,
	payload,
})

///////////////////////////////////////////REPLY PUBLIC MESSAGE

export const replyPublicMessageAction = (
	chatUuid: string,
	messageId: number,
	payload: ICorrespondenceMessageReply
) => ({ type: REPLY_PUBLIC_MESSAGE, chatUuid, messageId, payload })

export const replyPublicMessageSuccessAction = (
	payload: ICorrespondenceMessages
) => ({ type: REPLY_PUBLIC_MESSAGE_SUCCESS, payload })

export const replyPublicMessageErrorAction = (
	payload: string
) => ({ type: REPLY_PUBLIC_MESSAGE_ERROR, payload })


////////////////////////////////////////// FILE PERCENTAGE