import {
	Avatar,
	Box,
	Button,
	IconButton,
	Snackbar,
	Stack,
	Typography,
} from '@mui/material'
import { FC, memo, useEffect, useState } from 'react'
import { ICorrespondenceCreator } from '../../../../store/reducers/correspondenceReducer'
import {
	requestCorrespondenceOpenedAction,
} from '../../../../store/actions/correspondenceAction'
import { useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import CopyToClipboard from 'react-copy-to-clipboard'
import BasicToolTipProps from '../../../../modules/basicToolTip/BasicToolTip'
import LinkIcon from '@mui/icons-material/Link'
import moment from 'moment'
interface ChatInboxItemSmallProps {
	id: number
	title: string
	createdBy?: ICorrespondenceCreator
	opened: boolean
	publicUrl: string
	createdAt: string
	handleGetInCorrespondence: (chatId: number) => void
	lastMessage: any
}

const ChatInboxItemSmall: FC<ChatInboxItemSmallProps> = memo(
	({
		id,
		title,
		createdBy,
		opened,
		publicUrl,
		createdAt,
		handleGetInCorrespondence,
		lastMessage,
	}) => {
		const dispatch = useDispatch()
		const [openSnack, setOpenSnack] = useState(false)

		useEffect(() => {
			//отправляет кучу лишних одинаковых запросов
			// dispatch(requestCorrespondenceNotOpenCountAction()) 
		}, [])

		const handleOpenCorrespondence = () => {
			if (!opened) {
				dispatch(requestCorrespondenceOpenedAction(id))
			}
			handleGetInCorrespondence(id)
		}

		function createMarkup() {
			return { __html: lastMessage?.content }
		}

		const handleCopyPublicLink = (e: any) => {
			e.preventDefault()
			e.stopPropagation()
			setOpenSnack(true)
		}

		const handleClose = (
			event: React.SyntheticEvent | Event,
			reason?: string
		) => {
			event.preventDefault()

			if (reason === 'clickaway') {
				return
			}

			setOpenSnack(false)
		}

		const action = (
			<>
				<Button color='secondary' size='small' onClick={handleClose}>
					Отменить
				</Button>
				<IconButton
					size='small'
					aria-label='close'
					color='inherit'
					onClick={handleClose}
				>
					<CloseIcon fontSize='small' />
				</IconButton>
			</>
		)

		return (
			<>
				<Box
					onClick={handleOpenCorrespondence}
					sx={{
						display: 'flex',
						alignItems: 'center',
						bgcolor: !opened ? 'rgba(128,128,128,0.1)' : '',
						px: 5,
						py: 3,
						'&:hover': {
							bgcolor: 'rgba(0, 158, 247, 0.1)',
							cursor: 'pointer',
							transition: '1s easy',
						},
					}}
				>
					<Box
						sx={{
							mr: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							width: '100px',
						}}
					>
						<Avatar
							src={
								createdBy?.imagePath
									? createdBy?.imagePath
									: '/media/avatars/blank.jpg'
							}
							variant='rounded'
						/>
						<Typography textAlign={'center'}>
							{createdBy?.fullName ? createdBy?.fullName : 'Аноним'}
						</Typography>
					</Box>
					<Box sx={{ flexGrow: 1, flexShrink: 0, flexBasis: '50%' }}>
						<Box sx={{ overflow: 'hidden' }}>
							{/* <Stack  alignContent='center'> */}
							<Typography
								sx={{
									padding: '4px 10px',
									borderRadius: 2,
									bgcolor: 'rgba(255, 0, 0, 0.2)',
									fontSize: '13px',
								}}
								variant='caption'
							>
								Заголовок: {title}
							</Typography>
							{/* <Typography></Typography> */}
							{/* </Stack> */}
							{/* <Stack alignItems=''> */}
							<Typography
								variant='body1'
								noWrap={true}
								sx={{
									// overflow: 'hidden',
									mt: '3px',
									width: '500px',
									height: '50px',
									fontSize: '12px',
									padding: '4px 10px',
									borderRadius: 2,
									bgcolor: 'rgba(0, 0, 255, 0.1)',
								}}
							>
								Последнее сообщение:{' '}
								<div dangerouslySetInnerHTML={createMarkup()}></div>
							</Typography>
							{/* <Typography variant='body1'>
										
									</Typography> */}
							{/* </Stack> */}
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
							<BasicToolTipProps
								onClick={handleCopyPublicLink}
								title='Публичная ссылка'
							>
								<CopyToClipboard text={publicUrl || ''}>
									<LinkIcon color='primary' />
								</CopyToClipboard>
							</BasicToolTipProps>
							<Stack direction='row'>
								<Typography mr={1} variant='subtitle2'>
									{moment(createdAt).format('YYYY MM DD')}
								</Typography>
								<Typography mr={1} variant='subtitle2'>
									{moment(createdAt).format('HH:mm')}
								</Typography>
								<Typography variant='subtitle2'>
									{moment(createdAt).format('a')}
								</Typography>
							</Stack>
						</Box>
					</Box>
					{/* <Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							flexShrink: 0,
						}}
					>
						<Typography variant='subtitle2'>
							{moment(createdAt).format('YYYY MM DD')}
						</Typography>
						<Typography variant='subtitle2'>
							{moment(createdAt).format('HH:mm')}
						</Typography>
						<Typography variant='subtitle2'>
							{moment(createdAt).format('a')}
						</Typography>
					</Box> */}
				</Box>

				<Snackbar
					open={openSnack}
					autoHideDuration={6000}
					onClose={handleClose}
					message='Скопировано в буфер'
					action={action}
				/>
			</>
		)
	}
)

export default ChatInboxItemSmall
