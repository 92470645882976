import React, { Suspense, lazy, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../components/partials'
import PersonalCabinet from '../pages/cabinet/PersonalCabinet'
import CameraTest from '../pages/cameraTest'
import ShiftTable from '../pages/cashier/ShiftTable'
import AboutUs from '../pages/about-us/AboutUs'
import CashierCreateForm from '../pages/cashier/CashierCreateForm'
import CashierTable from '../pages/cashier/CashierTable'
import ContactUs from '../pages/contact-us/ContactUs'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import TaskHelp from '../pages/help/TaskHelp'
import KsmrPage from '../pages/KSMR/KsmrPage'
import TaskListWrapper from '../pages/layout-builder/TaskListWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import OrganizationCreateForm from '../pages/oraganization/OrganizationCreateForm'
import OrganizationTable from '../pages/oraganization/OrganizationTable'
import PaymentStatus from '../pages/payment-status/PaymentStatus'
import Report from '../pages/report/Report'
import PublicTaskDetailsPage from '../pages/TasksRegister/PublicTaskDetailsPage'
import PublicTasks from '../pages/TasksRegister/PublicTasks'
import { requestMenuAction } from '../store/actions/menuAction'

export function PrivateRoutes() {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(requestMenuAction())
	}, [])

	const Employess = lazy(
		() => import('../pages/layout-builder/EmployeesWrapper')
	)
	const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
	const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
	const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
	const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
	const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
	const InstructionPage = lazy(() => import('../pages/instruction/Instruction'))
	const StoragePage = lazy(() => import('../pages/storage/Storage'))
	const CalendarPage = lazy(() => import('../pages/calendar/Calendar'))
	const TaskStatusList = lazy(
		() => import('../pages/TaskStatus/TaskStatusList')
	)
	const AdminPage = lazy(() => import('../pages/page/AdminPage'))
	const TimeTracker = lazy(() => import('../pages/timeTracker/TimeTracker'))
	const Chat = lazy(() => import('../pages/chat/Chat'))

	return (
		<Suspense fallback={<FallbackView />}>
			<Switch>
				<Route path='/dashboard' component={DashboardWrapper} />
				<Route path='/crafted/pages/employees' component={Employess} />
				<Route path='/tasks/calendar' component={CalendarPage} />
				<Route path='/storage' component={StoragePage} />
				<Route path='/tasks/list' component={TaskListWrapper} />
				<Route path='/tasks/status' component={TaskStatusList} />
				<Route path='/reports/pages' component={AdminPage} />
				<Route path='/reports/documents' component={Report} />
				<Route path='/crafted/pages/profile' component={ProfilePage} />
				<Route path='/crafted/pages/wizards' component={WizardsPage} />
				<Route path='/crafted/widgets' component={WidgetsPage} />
				<Route path='/crafted/account' component={AccountPage} />
				<Route path='/apps/chat' component={ChatPage} />
				<Route path='/menu-test' component={MenuTestPage} />
				<Route path='/instruction' component={InstructionPage} />
				<Route path='/help/botHelp' component={TaskHelp} />
				<Route path='/reports/tracker' component={TimeTracker} />
				<Route exact path='/task/secret/:id' component={PublicTasks} />
				<Route exact path='/payment-status/:id' component={PaymentStatus} />
				<Route
					exact
					path='/task/details/:id'
					component={PublicTaskDetailsPage}
				/>
				<Route path='/chat' component={Chat} />
				<Route exact path='/fiscalization/cashiers' component={CashierTable} />
				<Route exact path='/fiscalization/cashiers/create' component={CashierCreateForm} />
				<Route exact path='/fiscalization/cashiers/shift/:id/:factoryNumber' component={ShiftTable} />
				<Route exact path='/fiscalization/organizations' component={OrganizationTable} />
				<Route exact path='/fiscalization/organizations/create' component={OrganizationCreateForm} />
				<Route exact path='/fiscalization/cabinet' component={PersonalCabinet} />
				<Route exact path='/task/secret/:id' component={PublicTasks} />
				<Route exact path='/ksmr' component={KsmrPage} />
				<Route exact path='/camera' component={CameraTest} />
				<Route path='/about' component={AboutUs} />
				<Route path='/contacts' component={ContactUs} />
				<Redirect from='/auth' to='/dashboard' />
				<Redirect exact from='/' to='/dashboard' />
				<Redirect to='error/404' />
			</Switch>
		</Suspense>
	)
}
