import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form, FormikValues } from 'formik'
import { StepperComponent } from '../../../components/assets'
import {
	ICreateAccount,
	createAccountSchemas,
	inits,
	dataUrlToFile,
	toBase64,
} from './stepperHelpers'
import { IdentifyStep1 } from './IdentifyStep1'
import { KTSVG } from '../../../components/helpers/KTSVG'
import { IdentifyStep2 } from './IdentifyStep2'
import { useGlobalContext } from '../cameraContext'
import axios from 'axios'
import Swal from 'sweetalert2'
import KcmrInfo from '../KcmrInfo'

interface StepperProps {
	face: any
	passportBack: any
	passportFront: any
	setFace: any
	setPassportFront: any
	setPassportBack: any
}
const BASE_URL = process.env.REACT_APP_BASE_URL
const StepperIdentify = ({
	setFace,
	setPassportFront,
	setPassportBack,
	face,
	passportBack,
	passportFront,
}: StepperProps) => {
	const stepperRef = useRef<HTMLDivElement | null>(null)
	const stepper = useRef<StepperComponent | null>(null)
	const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
	const [loading, setLoading] = useState(false)
	const [initValues] = useState<ICreateAccount>(inits)
	const [kcmrData, setKcmrData] = useState<any>(null)

	const { step, camera, setOcrData, ocrData } = useGlobalContext()

	const handleSelfie = async (e: any) => {
		if (e.target.files[0] instanceof File) {
			const data = await toBase64(e.target.files[0])
			setFace(data)
		}
	}
	const handleFront = async (e: any, helper: any) => {
		if (e.target.files[0] instanceof File) {
			const data = await toBase64(e.target.files[0])
			setPassportFront(data)
			helper.setValue(data)
		}
	}

	const handleBack = async (e: any, helper: any) => {
		if (e.target.files[0] instanceof File) {
			const data = await toBase64(e.target.files[0])
			setPassportBack(data)
			helper.setValue(data)
		}
	}

	// useEffect(() => {

	// }, [passportFront, passportBack])

	const loadStepper = () => {
		stepper.current = StepperComponent.createInsance(
			stepperRef.current as HTMLDivElement
		)
	}

	const prevStep = () => {
		if (!stepper.current) {
			return
		}

		stepper.current.goPrev()
		setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
	}

	const checkPassport = async (values: any) => {
		let res: any = null
		const passportBack = await dataUrlToFile(
			values?.passportBack,
			'passportFront'
		)
		const passportFront = await dataUrlToFile(
			values?.passportFront,
			'passportFront'
		)

		const formData = new FormData()
		// setLoading(true)
		formData.append('backImage', passportBack)
		formData.append('frontImage', passportFront)
		// formData.append('docType', values.docType.toString())
		formData.append('lang', 'ru')

		res = await axios({
			method: 'post',
			url: `${BASE_URL}/api/kisc/identify/ocr`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		return await res.data
	}
	const checkFace = async (values: any) => {
		let res: any = null
		const photoFace = await dataUrlToFile(face, 'face')

		const formData = new FormData()
		setLoading(true)
		formData.append('selfie', photoFace)
		formData.append('iin', ocrData?.data?.iin)
		formData.append('lang', 'ru')

		res = await axios({
			method: 'post',
			url: `${BASE_URL}/api/kisc/api/kisc/identity`,
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		return await res.data
	}

	const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
		if (!stepper.current) {
			return
		}

		setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

		if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
			if (stepper.current.currentStepIndex === 1) {
				setLoading(true)
				const ocr = await checkPassport(values)
				setOcrData(ocr)
				setLoading(false)
				if (!ocr) {
					Swal.fire({
						position: 'center',
						icon: 'error',
						title: 'Возможно ошибка с сервера',
						showConfirmButton: false,
						timer: 2500,
					})
					return
				}
				if (!ocr.successful) {
					Swal.fire({
						position: 'center',
						icon: 'error',
						title: ocr?.errorMessage || 'Возможно ошибка с сервера',
						showConfirmButton: false,
						timer: 2500,
					})
					return
				} else {
					stepper.current.goNext()
					setLoading(false)
					return
				}
			}
			if (stepper.current.currentStepIndex === 2) {
				// setLoading(true)
				const face = await checkFace(values)
				setLoading(false)
				if (!face) {
					Swal.fire({
						position: 'center',
						icon: 'error',
						title: 'Возможно ошибка с сервера',
						showConfirmButton: false,
						timer: 2500,
					})
					return
				}
				if (!face.successful) {
					Swal.fire({
						position: 'center',
						icon: 'error',
						title: face?.errorMessage || 'Возможно ошибка с сервера',
						showConfirmButton: false,
						timer: 2500,
					})
					setLoading(false)
				} else {
					setKcmrData(face)
					setLoading(false)
					stepper.current.goNext()
				}
			}
		} else {
			stepper.current.goFirst()
			stepper.current.destroy()
			actions.resetForm()
			setFace(null)
			setPassportFront(null)
			setPassportBack(null)
		}
	}
	useEffect(() => {
		if (!stepperRef.current) {
			return
		}

		loadStepper()
	}, [stepperRef])

	useEffect(() => {
		if (step == 'step2') {
			stepper.current?.goto(2)
		}
		if (step == 'step1') {
			stepper.current?.goto(1)
		}
	}, [step])

	return (
		<div
			ref={stepperRef}
			className='stepper stepper-pills stepper-vertical  d-flex flex-column pt-15'
			id='kt_create_account_stepper'
		>
			<div className='d-flex justify-content-center bg-white rounded justify-content-md-center flex-row-auto me-9'>
				<div className='px-6 px-lg-10 px-xxl-15 py-5'>
					<div className='stepper-nav'>
						<div
							className='stepper-item current flex-column flex-sm-row flex-md-row flex-lg-row'
							data-kt-stepper-element='nav'
						>
							<div className='stepper-line w-40px'></div>

							<div className='stepper-icon w-40px h-40px m-0'>
								<i className='stepper-check fas fa-check'></i>
								<span className='stepper-number'>1</span>
							</div>

							<div className='stepper-label  '>
								<h3 className='stepper-title fs-6 text-center mt-3'>Паспорт</h3>

								{/* <div className='stepper-desc fw-bold'>
									Сфотографируйте ваш паспорт
								</div> */}
							</div>
							{/* <div className='stepper-label   d-sm-none d-md-none d-lg-none d-flex  align-self-start mt-2'>
								<h3 className='stepper-title fs-8'>Паспорт</h3>
							</div> */}
						</div>

						<div
							className='stepper-item flex-column flex-sm-row flex-md-row flex-lg-row mx-5'
							data-kt-stepper-element='nav'
						>
							<div className='stepper-line w-40px'></div>

							<div className='stepper-icon w-40px h-40px m-0'>
								<i className='stepper-check fas fa-check'></i>
								<span className='stepper-number'>2</span>
							</div>

							<div className='stepper-label  '>
								<h3 className='stepper-title fs-6 text-center mt-3'>Селфи</h3>
								{/* <div className='stepper-desc fw-bold'>
									Сфотографируйте ваше лицо
								</div> */}
							</div>
							{/* <div className='stepper-label   d-sm-none d-md-none d-lg-none d-flex  align-self-start mt-2'>
								<h3 className='stepper-title fs-8'>Селфи</h3>
							</div> */}
						</div>

						<div
							className='stepper-item flex-column flex-sm-row flex-md-row flex-lg-row'
							data-kt-stepper-element='nav'
						>
							<div className='stepper-line w-40px'></div>

							<div className='stepper-icon w-40px h-40px m-0'>
								<i className='stepper-check fas fa-check'></i>
								<span className='stepper-number'>3</span>
							</div>

							<div className='stepper-label '>
								<h3 className='stepper-title fs-6 text-center mt-3'>Все</h3>
								{/* <div className='stepper-desc fw-bold'>Woah, we are here</div> */}
							</div>
							{/* <div className='stepper-label  '>
								<h3 className='stepper-title fs-8 text-center'>Все</h3>
							</div> */}
						</div>
					</div>
				</div>
			</div>

			<div className='d-flex flex-row-fluid flex-center bg-white rounded'>
				<Formik
					// validationSchema={currentSchema}
					initialValues={initValues}
					onSubmit={submitStep}
				>
					{() => (
						<Form
							className=' w-100 w-xl-700px px-9'
							noValidate
							id='kt_create_account_form'
						>
							<div className='current' data-kt-stepper-element='content'>
								<IdentifyStep1
									passportBack={passportBack}
									passportFront={passportFront}
									handleFront={handleFront}
									handleBack={handleBack}
								/>
							</div>

							<div data-kt-stepper-element='content'>
								<IdentifyStep2
									face={face}
									handleSelfie={handleSelfie}
									ocrData={ocrData?.data}
								/>
							</div>
							<div data-kt-stepper-element='content'>
								{/* <IdentifyStep3 /> */}
								{kcmrData && <KcmrInfo info={kcmrData.data} />}
							</div>

							{/* <div data-kt-stepper-element='content'>
								<Step3 />
							</div>

							<div data-kt-stepper-element='content'>
								<Step4 />
							</div>

							<div data-kt-stepper-element='content'>
								<Step5 />
							</div> */}

							<div className='d-flex flex-stack pt-10'>
								<div className='mr-2'>
									{stepper.current?.currentStepIndex !==
										stepper.current?.totatStepsNumber! && (
										<button
											onClick={prevStep}
											type='button'
											className='btn btn-lg btn-light-primary me-3'
											data-kt-stepper-action='previous'
										>
											<KTSVG
												path='/media/icons/duotune/arrows/arr063.svg'
												className='svg-icon-4 me-1'
											/>
											Назад
										</button>
									)}
								</div>

								<div>
									<button
										type='submit'
										disabled={loading}
										className='btn btn-lg btn-primary me-3'
									>
										<span className='indicator-label'>
											{stepper.current?.currentStepIndex !==
												stepper.current?.totatStepsNumber! && 'Продолжить'}
											{stepper.current?.currentStepIndex ===
												stepper.current?.totatStepsNumber! && 'Повторить'}

											{loading && (
												<span
													className='indicator-progress'
													style={{ display: 'inline-block' }}
												>
													<span className='spinner-border spinner-border-sm align-middle ms-2 me-2'></span>
												</span>
											)}
											<KTSVG
												path='/media/icons/duotune/arrows/arr064.svg'
												className='svg-icon-3 ms-2 me-0'
											/>
										</span>
									</button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	)
}

export { StepperIdentify }
