// INSTRUCTION ACTION TYPES
export const STATUSES_REQUEST: string = 'STATUSES_REQUEST'
export const STATUSES_SUCCESS: string = 'STATUSES_SUCCESS'
export const STATUSES_ERROR: string = 'STATUSES_ERROR'

export const INSTRUCTIONS_REQUEST: string = 'INSTRUCTIONS_REQUEST'
export const INSTRUCTIONS_SUCCESS: string = 'INSTRUCTIONS_SUCCESS'
export const INSTRUCTIONS_ERROR: string = 'INSTRUCTIONS_ERROR'

// AUTH ACTION TYPES
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR'
export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'

//STORAGE ACTION TYPES
export const STORAGE_ERROR = 'STORAGE_ERROR'
export const REQUEST_FILES_AND_FOLDERS = 'REQUEST_FILES_AND_FOLDERS'
export const GET_FILES_AND_FOLDERS = 'GET_FILES_AND_FOLDERS'
export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const CREATE_FOLDER_REQUEST = 'CREATE_FOLDER_REQUEST'
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS'
export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST'
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS'
export const DELETE_FOLDER_REQUEST = 'DELETE_FOLDER_REQUEST'
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS'
export const PREVIEW_IMAGE_REQUEST = 'PREVIEW_IMAGE_REQUEST'
export const PREVIEW_IMAGE_SUCCESS = 'PREVIEW_IMAGE_SUCCESS'
export const MOVE_TO_FOLDER_REQUEST = 'MOVE_TO_FOLDER_REQUEST'
export const MOVE_TO_FOLDER_SUCCESS = 'MOVE_TO_FOLDER_SUCCESS'
export const UPDATE_CURRENT_FOLDER = 'UPDATE_CURRENT_FOLDER'
export const ENABLE_PUBLIC_LINK_REQUEST = 'ENABLE_PUBLIC_LINK_REQUEST'
export const ENABLE_PUBLIC_LINK_SUCCESS = 'ENABLE_PUBLIC_LINK_SUCCESS'
export const DISABLE_PUBLIC_LINK_REQUEST = 'DISABLE_PUBLIC_LINK_REQUEST'
export const UPDATE_PDF_PREVIEW_LINK = 'UPDATE_PDF_PREVIEW_LINK'
export const FILE_VERSION_LIST_REQUEST = 'FILE_VERSION_LIST_REQUEST'
export const FILE_VERSION_LIST_SUCCESS = 'FILE_VERSION_LIST_SUCCESS'
export const FILE_VERSION_PREVIEW_REQUSET = 'FILE_VERSION_PREVIEW_REQUSET'
export const GET_SHARED_FILES_REQUEST = 'GET_SHARED_FILES_REQUEST'
export const GET_SHARED_FILES_SUCCESS = 'GET_SHARED_FILES_SUCCESS'
export const GET_USERS_OF_SHARED_FILE_REQUEST = 'GET_USERS_OF_SHARED_FILE_REQUEST'
export const GET_USERS_OF_SHARED_FILE_SUCCESS = 'GET_USERS_OF_SHARED_FILE_SUCCESS'
export const DELETE_USER_ACCESS_TO_SHARED_FILE_REQUSET = 'DELETE_USER_ACCESS_TO_SHARED_FILE_REQUSET'
export const VALIDATE_USERNAME_REQUEST = 'VALIDATE_USERNAME_REQUEST'
export const VALIDATE_USERNAME_SUCCESS = 'VALIDATE_USERNAME_SUCCESS'
export const SHARE_FILE_TO_USER_REQUEST = 'SHARE_FILE_TO_USER_REQUEST'
export const UPDATE_SHARE_FILE_REQUEST = 'UPDATE_SHARE_FILE_REQUEST'

//CASHIER ACTION TYPES
export const CASHIER_ERROR = 'CASHIER_ERROR'
export const GET_CASHIER_LIST_REQUEST = 'GET_CASHIER_LIST_REQUEST'
export const GET_CASHIER_LIST_SUCCESS = 'GET_CASHIER_LIST_SUCCESS'
export const CREATE_CASHIER_REQUEST = 'CREATE_CASHIER_REQUEST'
export const SET_ORG_NAME_FOR_FILTER = 'SET_ORG_NAME_FOR_FILTER'
export const GET_LIST_OFD_REQUEST = 'GET_LIST_OFD_REQUEST'
export const GET_LIST_OFD_SUCCESS = 'GET_LIST_OFD_SUCCESS'
export const INIT_CASHIER_REQUEST = 'INIT_CASHIER_REQUEST'
export const UNBLOCK_CASHIER_REQUEST = 'UNBLOCK_CASHIER_REQUEST'
export const SET_PROPS_TO_FILTER_CASHIER = 'SET_PROPS_TO_FILTER_CASHIER'
export const GET_SHIFT_LIST_REQUSET = 'GET_SHIFT_LIST_REQUSET'
export const GET_SHIFT_LIST_SUCCESS = 'GET_SHIFT_LIST_SUCCESS'

//ORGANIZATION ACTION TYPES
export const ORGANIZATION_ERROR = 'ORGANIZATION_ERROR'
export const GET_ORGANIZATION_LIST_REQUEST = 'GET_ORGANIZATION_LIST_REQUEST'
export const GET_ORGANIZATION_LIST_SUCCESS = 'GET_ORGANIZATION_LIST_SUCCESS'
export const GET_ACTIVITY_CLASSIFIER_REQUEST = 'GET_ACTIVITY_CLASSIFIER_REQUEST'
export const GET_ACTIVITY_CLASSIFIER_SUCCESS = 'GET_ACTIVITY_CLASSIFIER_SUCCESS'
export const GET_TAXATION_TYPE_REQUEST = 'GET_TAXATION_TYPE_REQUEST'
export const GET_TAXATION_TYPE_SUCCESS = 'GET_TAXATION_TYPE_SUCCESS'
export const CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST'
export const GET_ONE_ORGANIZATION_REQUEST = 'GET_ONE_ORGANIZATION_REQUEST'
export const GET_ONE_ORGANIZATION_SUCCESS = 'GET_ONE_ORGANIZATION_SUCCESS'
export const CONFIRM_USER_FOR_ORGANIZATION = 'CONFIRM_USER_FOR_ORGANIZATION'
export const CREATE_NEW_LINK_FOR_CONFIRMATION = 'CREATE_NEW_LINK_FOR_CONFIRMATION'
export const CONFIRM_USER_FOR_ORGANIZATION_MESSAGE = 'CONFIRM_USER_FOR_ORGANIZATION_MESSAGE'
export const EDIT_ORGANIZATION_REQUEST = 'EDIT_ORGANIZATION_REQUEST'
export const EDIT_ORGANIZATION_SUCCESS = 'EDIT_ORGANIZATION_SUCCESS'
export const SET_PROPS_TO_FILTER_ORGANIZATION = 'SET_PROPS_TO_FILTER_ORGANIZATION'

//COMMON OFD ACTIONS TYPES
export const GET_USER_PERMISSIONS_REQUEST = 'GET_USER_PERMISSIONS_REQUEST'
export const GET_USER_PERMISSIONS_SUCCESS = 'GET_USER_PERMISSIONS_SUCCESS'
export const COMMON_OFD_ERROR = 'COMMON_OFD_ERROR'
export const SET_OFD_LOADING = 'SET_OFD_LOADING'
export const GET_OFD_USERS_REQUEST = 'GET_OFD_USERS_REQUEST'
export const GET_OFD_USERS_SUCCESS = 'GET_OFD_USERS_SUCCESS'
export const CREATE_OFD_USER_REQUEST = 'CREATE_OFD_USER_REQUEST'
export const SET_PROPS_TO_FILTER_OFD_USERS = 'SET_PROPS_TO_FILTER_OFD_USERS'