import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import './CashierTable.scss'
import ReactTable from '../../components/partials/react-table/ReactTable'
import ReactPaginate from 'react-paginate'
import { RootState } from '../../setup'
import '../oraganization/OrganizationTable.scss'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru'
import { Permission } from '../../models/coomonOFD/FileModel'
import { getUserPermissionsRequest } from '../../store/actions/commonOFDAction'
import { Shift } from '../../models/cashier/FileModel'
import { getShiftListRequest } from '../../store/actions/cashierActions'
import ShiftInfoModal from './ShiftInfoModal'
import ZReportModal from './ZReportModal'

type State = {
    currentPage: number
    size: string
    search: {
        shiftNumber: string
    }
    filter: {
        statusChangedTime: {
            start: Date | null
            end: Date | null
        }
        shiftClosed: boolean
        defaultShiftClosed: boolean
    }
    sort: {
        name: string
        method: string
    }
    fetchNeed: boolean
}
type ZReportState = {
    modalShow: boolean
    shiftId: number
}


const ShiftTable = () => {
    const shiftList = [{
        kkmId: 5,
        shiftId: 1,
        shiftNumber: 12351251,
        openTime: '2022-06-03T04:53:35.525Z',
        closeTime: '2022-06-03T04:53:35.525Z',
        isClosed: true,
        operationsBuyCount: 1,
        operationsBuyReturnCount: 2,
        operationsDepositCount: 3,
        operationsSellCount: 4,
        operationsSellReturnCount: 5,
        operationsWithdrawalCount: 6
    }, {
        kkmId: 5,
        shiftId: 2,
        shiftNumber: 224125121,
        openTime: '2022-06-03T04:53:35.525Z',
        closeTime: '2022-06-03T04:53:35.525Z',
        isClosed: true,
        operationsBuyCount: 2,
        operationsBuyReturnCount: 1,
        operationsDepositCount: 3,
        operationsSellCount: 0,
        operationsSellReturnCount: 0,
        operationsWithdrawalCount: 1
    }, {
        kkmId: 5,
        shiftId: 3,
        shiftNumber: 3251236351,
        openTime: '2022-06-03T04:53:35.525Z',
        closeTime: '2022-06-03T04:53:35.525Z',
        isClosed: false,
        operationsBuyCount: 1,
        operationsBuyReturnCount: 0,
        operationsDepositCount: 2,
        operationsSellCount: 4,
        operationsSellReturnCount: 1,
        operationsWithdrawalCount: 6
    }]
    const history = useHistory()
    const dispatch = useDispatch()
    const { id, factoryNumber } = useParams<{ id: string, factoryNumber: string }>()
    const permissions = useSelector<RootState, Permission[]>(state => state.commonOFD.permissions)
    // const shiftList = useSelector<RootState, Shift[]>(state => state.cashier.shift.list)
    const shiftTotalPages = useSelector<RootState, number>(state => state.cashier.shift.totalPages)
    const [infoModal, setInfoModal] = useState<boolean>(false)
    const [zReportModal, setZReportModal] = useState<ZReportState>({ modalShow: false, shiftId: 0 })
    const [state, setState] = useState<State>({
        currentPage: 1,
        size: '20',
        search: {
            shiftNumber: '',
        },
        filter: {
            statusChangedTime: {
                start: new Date(),
                end: new Date()
            },
            shiftClosed: false,
            defaultShiftClosed: true
        },
        sort: {
            name: '',
            method: 'default'
        },
        fetchNeed: true
    })
    const [oneShift, setOneShift] = useState<Shift>({
        kkmId: 0,
        shiftId: 0,
        shiftNumber: 0,
        openTime: '',
        closeTime: '',
        isClosed: false,
        operationsBuyCount: 0,
        operationsBuyReturnCount: 0,
        operationsDepositCount: 0,
        operationsSellCount: 0,
        operationsSellReturnCount: 0,
        operationsWithdrawalCount: 0
    })
    const setFiltersToDefault = () => {
        setState({
            currentPage: 1,
            size: '20',
            search: {
                shiftNumber: '',
            },
            filter: {
                statusChangedTime: {
                    start: new Date(),
                    end: new Date()
                },
                shiftClosed: false,
                defaultShiftClosed: true
            },
            sort: {
                name: '',
                method: 'default'
            },
            fetchNeed: true
        })
    }
    const makePropsForCashierListHandler = () => {
        let props = ''
        const filter = { ...state.filter }
        const search = { ...state.search }
        const sort = { ...state.sort }
        props += `&page=${state.currentPage}`
        if (state.size !== '20') {
            props += `&size=${state.size}`
        }
        if (!filter.defaultShiftClosed) {
            props += `&filter=isClosed=${filter.shiftClosed}`
        }
        if (filter.statusChangedTime.end !== null && filter.statusChangedTime.start !== null) {
            props += `&filter=openTime>${filter.statusChangedTime.start.toISOString().slice(0, -1)}&filter=closeTime<${filter.statusChangedTime.end.toISOString().slice(0, -1)}`
        }
        if (search.shiftNumber.length > 2) {
            props += `&search=shiftNumber=${search.shiftNumber}`
        }
        if (sort.method !== 'default' && sort.name !== '') {
            props += `&sort=${sort.name}=${sort.method}`
        }

        if (state.fetchNeed) {
            dispatch(getShiftListRequest(parseInt(id), props))
        }
    }
    useEffect(() => {
        if (permissions.length === 0) dispatch(getUserPermissionsRequest())
    }, [])
    useEffect(() => {
        makePropsForCashierListHandler()
    }, [state.filter, state.currentPage, state.search, state.size, state.sort])
    const openZReportHandler = (id: number) => {
        setZReportModal({ shiftId: id, modalShow: true })
    }
    const closeZReportHandler = () => {
        setZReportModal({ shiftId: 0, modalShow: false })
    }
    const openOneShiftDataModal = (shiftNumber: number) => {
        const findShift = shiftList.find(item => item.shiftNumber === shiftNumber)
        if (findShift !== undefined) {
            setOneShift(findShift)
            setInfoModal(true)
        }
    }
    const closeOneShiftDataModal = () => {
        setInfoModal(false)
        setOneShift({
            kkmId: 0,
            shiftId: 0,
            shiftNumber: 0,
            openTime: '',
            closeTime: '',
            isClosed: false,
            operationsBuyCount: 0,
            operationsBuyReturnCount: 0,
            operationsDepositCount: 0,
            operationsSellCount: 0,
            operationsSellReturnCount: 0,
            operationsWithdrawalCount: 0
        })
    }
    const changeDateHandler = (date: any, name: string) => {
        if (name === 'openTime') {
            setState({ ...state, fetchNeed: true, filter: { ...state.filter, statusChangedTime: { ...state.filter.statusChangedTime, start: date[0] } } })
        }
        else {
            setState({ ...state, fetchNeed: true, filter: { ...state.filter, statusChangedTime: { ...state.filter.statusChangedTime, end: date[0] } } })
        }
    }
    const handlePageClick = (e: any) => {
        setState({ ...state, currentPage: e.selected + 1, fetchNeed: true })
    }
    const changeFilterHandler = (value: string, name: string) => {
        if (name === 'shiftNumber') {
            if (value.length > 2) {
                setState({ ...state, search: { ...state.search, [name]: value }, fetchNeed: true })
            }
            else {
                setState({ ...state, search: { ...state.search, [name]: value }, fetchNeed: false })
            }
        }
        else if (name === 'size') {
            setState({ ...state, size: value, fetchNeed: true })
        }
        else if (name === 'isClosed') {
            if (value === '0') {
                setState({ ...state, filter: { ...state.filter, defaultShiftClosed: true, shiftClosed: false }, fetchNeed: true })
            }
            else if (value === '1') {
                setState({ ...state, filter: { ...state.filter, defaultShiftClosed: false, shiftClosed: false }, fetchNeed: true })
            }
            else if (value === '2') {
                setState({ ...state, filter: { ...state.filter, defaultShiftClosed: false, shiftClosed: true }, fetchNeed: true })
            }
        }
    }
    const sortHandler = (name: string) => {
        if (state.sort.name !== name) {
            setState({ ...state, sort: { name, method: 'asc' }, fetchNeed: true })
        }
        else if (state.sort.name === name && state.sort.method === 'asc') {
            setState({ ...state, sort: { name, method: 'desc' }, fetchNeed: true })
        }
        else if (state.sort.name === name && state.sort.method === 'desc') {
            setState({ ...state, sort: { name: '', method: 'default' }, fetchNeed: true })
        }
    }
    const sortableColumnHanlder = (column: any) => {
        return (
            <span
                className={
                    state.sort.name === column.id ?
                        state.sort.method === 'asc' ?
                            'cashier-th-sort-asc cashier-th-sort'
                            :
                            state.sort.method === 'desc' ?
                                'cashier-th-sort-desc cashier-th-sort'
                                :
                                'cashier-th-sort'
                        :
                        'cashier-th-sort'}
                onClick={() => sortHandler(column.id)}
            >{column.render('Header')}</span>
        )
    }
    const columnFilterRender = (column: any) => {
        if (column.Header === 'Действие' || column.Header === 'Количество операций') {
            return (
                <>
                    <span className='cashier-th-sort'>{column.render('Header')}</span>
                    <input className='cashier-th-filter-input' disabled />
                </>
            )
        }
        else if (column.Header === 'Дата открытия смены' || column.Header === 'Дата закрытия смены') {
            return (
                <>
                    {sortableColumnHanlder(column)}
                    <DatePicker
                        className='cashier-th-filter-input'
                        // selected={column.Header === 'Дата открытия смены' ? state.filter.statusChangedTime.start : state.filter.statusChangedTime.end}
                        selected={new Date()}
                        onChange={(date) => changeDateHandler(date, column.id)}
                        dateFormat="dd-MM-yyyy"
                        locale={ru}
                        startDate={state.filter.statusChangedTime.start}
                        endDate={state.filter.statusChangedTime.end}
                        selectsRange
                    // placeholderText={`${day}-${month}-${year}`}
                    />
                </>
            )
        }
        else if (column.Header === 'Статус смены') {
            return (
                <>
                    {sortableColumnHanlder(column)}
                    <select
                        className='cashier-th-filter-input'
                        onChange={(e) => changeFilterHandler(e.target.value, column.id)}
                        value={state.filter.defaultShiftClosed ? 0 : state.filter.shiftClosed ? 2 : 1}
                    >
                        <option value={0}>Все</option>
                        <option value={1}>Открыта</option>
                        <option value={2}>Закрыта</option>
                    </select>
                </>
            )
        }
        else {
            const searchCopy: any = { ...state.search }
            let inputValue = ''
            Object.keys(searchCopy).map((item: string) => {
                if (item === column.id) inputValue = searchCopy[item]
            })
            return (
                <>
                    {sortableColumnHanlder(column)}
                    <input
                        value={inputValue}
                        className='cashier-th-filter-input'
                        onChange={(e) => changeFilterHandler(e.target.value, column.id)}
                        placeholder={`Введите 3 символа`}
                    />
                </>
            )
        }
    }
    const columns: any[] = useMemo(() => [
        {
            Header: 'Номер смены',
            accessor: 'shiftNumber',
            Cell: (cell: any) => {
                return (
                    <a href='#' onClick={() => openOneShiftDataModal(cell?.row?.original?.shiftNumber)}>{cell?.row?.original?.shiftNumber}</a>
                )
            }
        },
        {
            Header: 'Дата открытия смены',
            accessor: 'openTime',
            Cell: (cell: any) => {
                if (cell?.row?.original?.openTime === null) return (<>-</>)
                const fullDate = new Date(cell?.row?.original?.openTime)
                const day = fullDate.getDate()
                const month = fullDate.getMonth() + 1
                const year = fullDate.getFullYear()
                const hour = fullDate.getHours()
                const minute = fullDate.getMinutes()
                const exactMinutes = minute.toString().length === 1 ? '0' + minute.toString() : minute
                return (
                    <>
                        <div>
                            {day + '-' + month + '-' + year}
                        </div>
                        <div>
                            {hour + ':' + exactMinutes}
                        </div>
                    </>
                )
            }
        },
        {
            Header: 'Дата закрытия смены',
            accessor: 'closeTime',
            Cell: (cell: any) => {
                if (cell?.row?.original?.closeTime === null) return (<>-</>)
                const fullDate = new Date(cell?.row?.original?.closeTime)
                const day = fullDate.getDate()
                const month = fullDate.getMonth() + 1
                const year = fullDate.getFullYear()
                const hour = fullDate.getHours()
                const minute = fullDate.getMinutes()
                const exactMinutes = minute.toString().length === 1 ? '0' + minute.toString() : minute
                return (
                    <>
                        <div>
                            {day + '-' + month + '-' + year}
                        </div>
                        <div>
                            {hour + ':' + exactMinutes}
                        </div>
                    </>
                )
            }
        },
        {
            Header: 'Статус смены',
            accessor: 'isClosed',
            Cell: (cell: any) => {
                if (cell?.row?.original?.isClosed === true) {
                    return 'Закрыта'
                }
                else return 'Открыта'
            }
        },
        {
            Header: 'Количество операций',
            accessor: 'amountOperations',
            Cell: (cell: any) => {
                return (
                    <span>
                        {cell?.row?.original?.operationsBuyCount
                            + cell?.row?.original?.operationsSellCount
                            + cell?.row?.original?.operationsBuyReturnCount
                            + cell?.row?.original?.operationsSellReturnCount
                            + cell?.row?.original?.operationsDepositCount
                            + cell?.row?.original?.operationsWithdrawalCount}
                    </span>
                )
            }
        },
        {
            Header: 'Действие',
            accessor: 'action',
            Cell: (cell: any) => {
                if (cell?.row?.original?.isClosed === true) {
                    return (
                        <button className='cashier-btn-create cashier-list-btn'
                            onClick={() => openZReportHandler(cell?.row?.original?.id)}>
                            Просмотр Z-отчета
                        </button>
                    )
                }
                else return null
            }
        },
    ], [permissions])


    return (
        <>
            <ZReportModal show={zReportModal.modalShow} close={closeZReportHandler} />
            <ShiftInfoModal   //инфо модалка
                oneShift={oneShift}
                factoryNumber={factoryNumber}
                show={infoModal}
                close={closeOneShiftDataModal} />
            <Row className='d-flex space-betwwen ' >
                <Col className='m-b-20-px'>
                    <h6 className='cashier-list-title'>
                        Смены по кассе с заводским номером {factoryNumber}
                    </h6>
                </Col>
                <Col className='d-flex justify-content-end m-b-20-px align-items-center'>
                    <select
                        className='cashier-list-dd'
                        onChange={(e) => changeFilterHandler(e.target.value, 'size')}>
                        <option value={20}>20 записей</option>
                        <option value={50}>50 записей</option>
                    </select>
                </Col>
                <Col className='d-flex justify-content-end m-b-20-px align-items-center '>
                    <button
                        className='cashier-btn-create cashier-list-btn cashier-list-header-btn'
                        onClick={setFiltersToDefault}
                    >
                        Сбросить фильтры
                    </button>
                </Col>
            </Row>
            <Card>
                <ReactTable
                    // data={cashierListMemorized}
                    data={shiftList}
                    columns={columns}
                    tableWrapperClassName='cashier-table-wrapper'
                    tableClassName='cashier-table'
                    thClassName='cashier-table-td cashier-table-th'
                    thClassNameRight='cashier-table-td-right'
                    tdClassName='cashier-table-td'
                    tdClassNameRight='cashier-table-td-right'
                    trClassNameHover='cashier-table-td-hover'
                    columnFilterRender={columnFilterRender}
                />
            </Card>
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                pageCount={shiftTotalPages}  //количество страниц
                onPageChange={handlePageClick}
                breakLabel='...'
                pageRangeDisplayed={2}
                containerClassName='cashier-pagination-box'
                previousLinkClassName='cashier-pagination-prevlink cashier-pagination-mainlink'
                nextLinkClassName='cashier-pagination-nextlink cashier-pagination-mainlink'
                pageLinkClassName='cashier-pagination-mainlink'
                activeLinkClassName='cashier-pagination-activelink'
                breakLinkClassName='cashier-pagination-breaklink'
            // marginPagesDisplayed={2}
            // forcePage={period.page}
            />
        </>
    )
}
export default ShiftTable