/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect, useRef, useState, MutableRefObject } from 'react'
import { Redirect, Switch, Route, useLocation } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { MasterLayout } from '../components/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout, AuthPage } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { RootState } from '../setup'
import ConfirmUserForOrganization from '../pages/oraganization/ConfirmUserForOrganization'

const Routes: FC = () => {
	const token: string = localStorage.token
	const isAuthorized = useSelector<RootState>(
		({ auth }) => auth.user,
		shallowEqual
	)
	const [identityPage, setIdentityPage] = useState(false)
	const [secretPage, setSecretPage] = useState(false)
	const [secretPageDetails, setsSecretPageDetails] = useState(false)
	const [secretId, setSecretId] = useState<string | undefined>('')
	const [paymentInfoPage, setPaymentInfoPage] = useState(false)
	const [publicChat, setPublicChat] = useState(false)
	const location = useLocation()
	const [about, setAbout] = useState(false)
	const [contacts, setContacts] = useState(false)
	const secretPath: MutableRefObject<string[]> = useRef([])
	useEffect(() => {
		secretPath.current = location.pathname.split('/')
		let idui: string | undefined = secretPath.current.pop()
		if (
			secretPath.current[1] === 'identity' &&
			secretPath.current[2] === 'kcmr'
		) {
			setIdentityPage(true)
			return
		}
		if (
			secretPath.current[1] === 'task' &&
			secretPath.current[2] === 'secret'
		) {
			setSecretId(idui)
			setSecretPage(true)
		}
		if (
			secretPath.current[1] === 'task' &&
			secretPath.current[2] === 'details'
		) {
			setSecretId(idui)
			setSecretPage(false)
			setsSecretPageDetails(true)
		}
		if (secretPath.current[1] === 'payment-status') {
			setPaymentInfoPage(true)
			setSecretId(idui)
		}
		if (secretPath.current[1] === 'mail') {
			setPublicChat(true)
			setSecretId(idui)
		}
		if (location.pathname === '/about/us') {
			setAbout(true)
		}
		if (location.pathname === '/contacts/us') {
			setContacts(true)
		}

		return ()=>{
			
		}
	}, [location])

	return (
		<Switch>
			{!isAuthorized ? (
				/*Render auth page when user at `/auth` and not authorized.*/
				secretPage ? (
					<Redirect to={`/task/secret/${secretId}`} />
				) : secretPageDetails ? (
					<Redirect to={`/task/details/${secretId}`} />
				) : identityPage ? (
					<Redirect to={`/identity/kcmr/`} />
				) : paymentInfoPage ? (
					<Redirect to={`/payment-status/${secretId}`} />
				) : publicChat ? (
					<Redirect to={`/mail/${secretId}`} />
				) : location.pathname === '/organization/user/confirmation' ? ( //страница подтверждения пользователя, который указан при создании организации
					<Route
						exact
						path='/organization/user/confirmation'
						component={ConfirmUserForOrganization}
					/>
				)  : (
					<Route>
						<AuthPage />	
					</Route>
				)
			) : (
				/*Otherwise redirect to root page (`/`)*/
				<Redirect from='/auth' to='/' />
			)}
			<Route path='/error' component={ErrorsPage} />
			<Route path='/logout' component={Logout} />
			{token === 'null'||token === null? (
				/*Redirect to `/auth` when user is not authorized*/
				<Redirect to='/auth/login' />
			) : (
				<MasterLayout>
					<PrivateRoutes />
				</MasterLayout>
			)}
		</Switch>
	)
}

export { Routes }
