import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../components/helpers/KTSVG'
import {useIntl} from 'react-intl'
import {IReportContent, Signature} from '../../models/report/reportModel'
import Tooltip from '@material-ui/core/Tooltip'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import moment from 'moment'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {annulateReportRequestAction, getReportsAction} from '../../store/actions/reportsActions'
import {Box, TextField, Typography} from '@mui/material'
import Button from '@mui/material/Button'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import BasicToolTipProps from '../../modules/basicToolTip/BasicToolTip'
import {RootState} from '../../setup'
import axios from '../../setup/axios/axios-config'
import {getUserAction} from '../../store/actions/authActions'
import Swal from 'sweetalert2'
import DownloadIcon from '@mui/icons-material/Download'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {Controller, useForm} from 'react-hook-form'

export interface logsModel {
    response: string
    status: string
    actionName: string
    createdAt: string
    id: number
}

export interface oldDocsModel {
    id: number
    createdAt: string
    status: string
    files: any
}

export type Props = {
    openSnack: () => void
    show: boolean
    handleClose: () => void
    data: IReportContent
    logs: logsModel[]
    filter: {}
}

const schema = yup.object().shape({
    id: yup.number().required(),
    email: yup
        .string()
        .email('Введите правильный email!')
        .required('email не может быть пустым'),
})

const ReportPreviewModal: React.FC<Props> = ({
                                                 openSnack,
                                                 show,
                                                 handleClose,
                                                 data,
                                                 logs,
                                                 filter,
                                             }) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [signers, setSigners] = useState([])
    const [files, setFiles] = useState([])
    const user: any = useSelector<RootState, any>(
        state => state.auth.user,
        shallowEqual
    )

    const {annulateReportLoading, annulateReportError}: any = useSelector<
        RootState,
        any
    >(state => state.reports, shallowEqual)

    const {control, setValue, handleSubmit, getValues, resetField, formState} =
        useForm({
            defaultValues: {id: 0, email: ''},
            resolver: yupResolver(schema),
            mode: 'onChange',
        })

    const [oldDocs, setOldDocs] = useState([])

    useEffect(() => {
        dispatch(getUserAction())
    }, [])

    useEffect(() => {
        const getSignatures = (id: any) =>
            axios.get(`/api/documents/${id}/signers`).then(data => {
                setSigners(data.data)
            })

        const getFiles = (id: any) =>
            axios.get(`/api/documents/${id}/files`).then(data => {
                setFiles(data.data)
            })

        const getOldDocs = (id: any) =>
            axios.get(`/api/documents/${id}/old-documents`).then(data => {
                setOldDocs(data.data)
            })

        if (data !== undefined) {
            getSignatures(data.id)
            getFiles(data.id)
            setValue('id', data.id)
            getOldDocs(data.id)
        }
    }, [data])

    useEffect(() => {
        if (
            !annulateReportLoading &&
            Object.keys(annulateReportError).length === 0 &&
            show
        ) {
            handleClose()
            Swal.fire({
                icon: 'success',
                title: 'Аннулировано',
                text: `Документ на ${data?.month} на ${data?.subAgent} аннулирован.`,
                showConfirmButton: false,
                timer: 3_000
            })
        }
    }, [annulateReportLoading])

    useEffect(() => {
        if (Object.keys(annulateReportError).length > 0) {
            handleClose()
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: annulateReportError?.response?.data?.message || 'Ошибка !',
                timer: 5000,
            })
        }
    }, [annulateReportError])

    const handleAnnulate = () => {
        if (data) {
            dispatch(annulateReportRequestAction(data.id, filter))
        }
    }

    const handleInform = () => {
        if (!formState.errors.email) {
            informPersonal(getValues())
        }
    }

    function deleteDocument(data: any) {
        axios.post(`/api/documents/${data?.id}/delete`).then(value => {
            Swal.fire({
                title: 'Документ удален',
                icon: 'success',
                timer: 1500,
            })
            handleClose()
            dispatch(getReportsAction(filter))
        }).catch(reason => {
            Swal.fire({
                title: 'Ошибка сервера',
                text: reason?.response?.data?.message || '',
                icon: 'error',
                timer: 1500,
            })
        })
    }

    const handleDelete = () => {
        Swal.fire({
            title: `${intl.formatMessage({id: 'WARNING.DELETE.TITLE'})}`,
            text: `${intl.formatMessage({id: 'WARNING.DELETE.TEXT'})}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `${intl.formatMessage({
                id: 'WARNING.DELETE.SUBMIT',
            })}`,
            cancelButtonText: `${intl.formatMessage({
                id: 'WARNING.DELETE.CANCEL',
            })}`,
        }).then(result => {
            if (result.isConfirmed) {
                deleteDocument(data);
            }
        })
    }

    function resetDocument(data: any) {
        axios.post(`/api/documents/${data?.id}/reset`).then(value => {
            Swal.fire({
                title: 'Документ обновлен',
                icon: 'success',
                timer: 1500,
            })
            handleClose()
            dispatch(getReportsAction(filter))
        }).catch(reason => {
            Swal.fire({
                title: 'Ошибка сервера',
                text: reason?.response?.data?.message || '',
                icon: 'error',
                timer: 1500,
            })
        })
    }

    const handleReset = () => {
        Swal.fire({
            title: `${intl.formatMessage({id: 'WARNING.DELETE.TITLE'})}`,
            text: `${intl.formatMessage({id: 'WARNING.DELETE.TEXT'})}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `${intl.formatMessage({
                id: 'WARNING.DELETE.SUBMIT',
            })}`,
            cancelButtonText: `${intl.formatMessage({
                id: 'WARNING.DELETE.CANCEL',
            })}`,
        }).then(result => {
            if (result.isConfirmed) {
                resetDocument(data);
            }
        })
    }

    const truncate = (str: string | undefined, n: number): string => {
        if (!str) {
            return ''
        }
        return str.length > n ? str.substr(0, n - 1) + '...' : str
    }

    async function informPersonal(payload: any) {
        try {
            const response = await axios.get(`/api/documents/${data?.id}/notify`, {
                params: {id: payload?.id, email: payload?.email},
            })
            Swal.fire({
                title: 'Пользователь уведомлен',
                icon: 'success',
                timer: 1500,
            })
            resetField('email')
        } catch (error: any) {
            Swal.fire({
                title: 'Ошибка сервера',
                text: error?.response?.data?.message || '',
                icon: 'error',
                timer: 1500,
            })
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => handleClose()}
            size='lg'
            dialogClassName='modal-dialog-centered mw-1000px h-auto'
        >
            <div className='modal-content'>
                <div className='modal-header'>
                    <h2>{intl.formatMessage({id: 'TASK.DETAILS'})}</h2>
                    <div
                        className='btn btn-sm btn-icon btn-active-color-primary'
                        onClick={() => handleClose()}
                    >
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr061.svg'
                            className='svg-icon-1'
                        />
                    </div>
                </div>

                <div className='modal-body py-lg-10 px-lg-10'>
                    <div className='card-body '>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                Имя
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <div style={{padding: '1rem'}}>
                                    {data?.subAgent ||
                                        intl.formatMessage({id: 'TASK.DEFAULT.TEXT'})}
                                </div>
                            </div>
                        </div>

                        {data?.creationType !== 'AUTO' && (
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    Автор
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <div style={{padding: '1rem'}}>
                                        {data?.createdBy ||
                                            intl.formatMessage({id: 'TASK.DEFAULT.TEXT'})}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                {intl.formatMessage({id: 'TASK.MODAL.DESCRIPTION'})}
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <div style={{padding: '1rem'}}>
                                    {data?.description ||
                                        intl.formatMessage({id: 'TASK.DEFAULT.TEXT'})}
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                Статус
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <div style={{padding: '1rem'}}>
                                    {data?.status?.title ||
                                        intl.formatMessage({id: 'TASK.STATUS'})}
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6 align-items-center'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                Файлы
                            </label>
                            {data?.files.length === 0 ? (
                                <div className='col-lg-8 fv-row'>
                                    {' '}
                                    <div style={{padding: '1rem', display: 'flex'}}>
                                        {' '}
                                        Нет файла
                                    </div>
                                </div>
                            ) : (
                                <div className='col-lg-8 fv-row'>
                                    <div style={{padding: '1rem', display: 'flex'}}>
                                        <table className='table'>
                                            <thead className='table-primary p-3'>
                                            <tr>
                                                <th className='ps-3'>Имя</th>
                                                <th className='ps-3'>Файл</th>
                                            </tr>
                                            </thead>
                                            <tbody className='table-light '>
                                            {data?.files.map((element: any, i) => {
                                                return (
                                                    <tr key={i} className='table-responsive'>
                                                        <Tooltip
                                                            title={element.name}
                                                            placement='top-start'
                                                        >
                                                            <td className='ps-3'>
                                                                {element.name.length > 60
                                                                    ? element.name
                                                                    .split('')
                                                                    .map((item: any, i: any) =>
                                                                        i < 60 ? item : null
                                                                    )
                                                                    .join('') + ' . . .'
                                                                    : element.name}
                                                            </td>
                                                        </Tooltip>
                                                        <Tooltip
                                                            title={element.downloadLink}
                                                            placement='top-start'
                                                        >
                                                            <td className='ps-3'>
                                                                <BasicToolTipProps title='Скачать'>
                                                                    <div
                                                                        style={{
                                                                            width: '24px',
                                                                            height: '24px',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        <a
                                                                            href={element.downloadLink}
                                                                            target='_blank'
                                                                        >
                                                                            <DownloadIcon
                                                                                style={{
                                                                                    width: '24px',
                                                                                    height: '24px',
                                                                                }}
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                </BasicToolTipProps>
                                                            </td>
                                                        </Tooltip>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}

                        </div>
                        <div className='row mb-6 align-items-center'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                Доступ
                            </label>
                            <a
                                target={'_blank'}
                                href={data?.publicUrl}
                                className='col-lg-4 fv-row'
                            >
                                <div style={{padding: '1rem'}}>
                                    {truncate(data?.publicUrl, 35) ||
                                        intl.formatMessage({id: 'TASK.DEFAULT.ASSIGNEE'})}
                                </div>
                            </a>
                            {data?.publicUrl && (
                                <div className='col-lg-4 fv-row'>
                                    <BasicToolTipProps title='Скопировать'>
                                        <CopyToClipboard text={data?.publicUrl || ''}>
                                            <ContentCopyIcon onClick={() => openSnack()}/>
                                        </CopyToClipboard>
                                    </BasicToolTipProps>
                                </div>
                            )}
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                Отчетный период
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <div style={{padding: '1rem'}}>
                                    {data?.month + '-' + data?.year || 'нет даты'}
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                Подпись
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <div style={{padding: '1rem', display: 'flex'}}>
                                    {signers.length === 0 ? (
                                        'Не подписан'
                                    ) : (
                                        <table className='table'>
                                            <thead className='table-primary p-3'>
                                            <tr>
                                                <th className='ps-3'>Имя</th>
                                                <th className='ps-3'>Email</th>
                                                <th className='ps-3'>Статус</th>
                                                <th className='ps-3'>Дата подписания</th>
                                            </tr>
                                            </thead>
                                            <tbody className='table-light '>
                                            {signers.map((element: Signature, i) => {
                                                return (
                                                    <tr key={i} className='table-responsive'>
                                                        <Tooltip
                                                            title={element.accountName}
                                                            placement='top-start'
                                                        >
                                                            <td className='ps-3'>
                                                                {element.accountName.length > 27
                                                                    ? element.accountName
                                                                    .split('')
                                                                    .map((item, i) =>
                                                                        i < 27 ? item : null
                                                                    )
                                                                    .join('') + ' . . .'
                                                                    : element.accountName}
                                                            </td>
                                                        </Tooltip>

                                                        <td className='ps-3'>{element.email}</td>

                                                        <td className='ps-3'>{element.status}</td>

                                                        <td className='ps-3'>
                                                            {element.performedAt
                                                                ? moment(element.performedAt).format(
                                                                    'DD.MM.YYYY HH:mm'
                                                                )
                                                                : 'нет даты'}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                История
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <div style={{padding: '1rem', display: 'flex'}}>
                                    {logs?.length === 0 ? (
                                        'Нет изменений'
                                    ) : (
                                        <table className='table'>
                                            <thead className='table-primary '>
                                            <tr>
                                                <th className='ps-3'>Названия изменений</th>
                                                <th className='ps-3'>Дата</th>
                                                <th className='ps-3'>Статус | Причина</th>
                                            </tr>
                                            </thead>
                                            <tbody className='table-light '>
                                            {logs.map((element: logsModel) => {
                                                return (
                                                    <tr key={element.id} className='table-responsive'>
                                                        <td className='ps-3'>{element.actionName}</td>

                                                        <td className='ps-3'>
                                                            {moment(element.createdAt).format(
                                                                'DD.MM.YYYY HH:mm'
                                                            )}
                                                        </td>
                                                        <td className='ps-3'>
                                                            {element.status}{' '}
                                                            {element.response == null
                                                                ? ''
                                                                : '-' + element.response}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                Предыдущие документы
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <div style={{padding: '1rem', display: 'flex'}}>
                                    {oldDocs?.length === 0 ? (
                                        'Пусто'
                                    ) : (
                                        <table className='table'>
                                            <thead className='table-primary '>
                                            <tr>
                                                <th className='ps-3'>Дата создания</th>
                                                <th className='ps-3'>Статус</th>
                                                <th className='ps-3'>Ссылка</th>
                                            </tr>
                                            </thead>
                                            <tbody className='table-light '>
                                            {oldDocs.map((element: oldDocsModel) => {
                                                return (
                                                    <tr key={element.id} className='table-responsive'>
                                                        <td className='ps-3'>
                                                            {moment(element.createdAt).format(
                                                                'DD.MM.YYYY HH:mm'
                                                            )}
                                                        </td>
                                                        <td className='ps-3'>{element.status}</td>
                                                        <td className='ps-3'>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                }}
                                                            >
                                                                {element?.files?.map((file: any) => {
                                                                    return (
                                                                        <a
                                                                            key={file?.id}
                                                                            href={file?.downloadLink}
                                                                        >
                                                                            {file?.name}
                                                                        </a>
                                                                    )
                                                                })}
                                                            </Box>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                Уведомлен
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <div style={{padding: '1rem'}}>
                                    {data?.notified ? data?.subAgentEmail : 'Нет'}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='modal-footer d-flex justify-content-between'>
                    {user.roles?.find((e: any) => e.name === 'ROLE_DOCUMENT_EDITOR')
                        && (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                {data?.status.code === 'IN_PROGRESS' && (
                                    <Button
                                        disabled={annulateReportLoading}
                                        type='button'
                                        color='error'
                                        sx={{mr: 2}}
                                        variant='contained'
                                        onClick={() => handleAnnulate()}
                                    >
                                        {annulateReportLoading ? (
                                            <>
											<span
                                                className='spinner-border spinner-border-sm'
                                                role='status'
                                                aria-hidden='true'
                                            ></span>
                                                <span className='sr-only'>Loading...</span>
                                            </>
                                        ) : (
                                            intl.formatMessage({id: 'TEXT.ANNULATE'})
                                        )}
                                    </Button>
                                )}
                                {(data?.status.code === 'FAILED' || data?.status.code === 'EXPIRED' || data?.status.code === 'ANNULLED' || data?.status.code === 'REJECTED') && (
                                    <Button variant='contained'
                                            color='secondary' sx={{mr: 1}}
                                            onClick={() => handleDelete()}>Удалить</Button>
                                )}
                                {data?.status.code === 'EXPIRED' && (
                                    <Button variant='contained' sx={{mr: 1}}
                                            onClick={() => handleReset()}>Обновить</Button>
                                )}
                                {(data?.status.code === 'IN_PROGRESS' || data?.status.code === 'EXPIRED') && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            position: 'relative',
                                        }}
                                    >
                                        <Box component='form' sx={{display: 'flex'}}>
                                            <Controller
                                                name='email'
                                                control={control}
                                                render={({field: {onChange, value}}) => (
                                                    <TextField
                                                        sx={{mr: 1}}
                                                        size='small'
                                                        variant='outlined'
                                                        label='Email контрагента'
                                                        value={value}
                                                        placeholder='Email'
                                                        onChange={onChange}
                                                        error={!!formState.errors.email}
                                                    />
                                                )}
                                            />

                                            <Button
                                                variant='contained'
                                                type='button'
                                                color='secondary'
                                                onClick={handleInform}
                                            >
                                                Уведомить
                                            </Button>
                                        </Box>

                                        {formState.errors.email && (
                                            <Typography
                                                sx={{
                                                    color: 'darkred',
                                                    position: 'absolute',
                                                    top: '95%',
                                                    left: '0',
                                                }}
                                            >
                                                {formState.errors.email.message}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        )}

                    <Button variant='contained' type='button' sx={{marginLeft: 'auto'}}
                            onClick={() => handleClose()}>
                        {intl.formatMessage({id: 'TEXT.CLOSE'})}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export {ReportPreviewModal}
