import { call, put, takeLatest } from '@redux-saga/core/effects'
import { AnyAction } from 'redux'
import { IPaginationModelTasks } from '../../models/personal/paginationCustom'
import { ITaskComment, ITasks } from '../../models/tasks/tasksModel'
import axios from '../../setup/axios/axios-config'
import axiosNative from 'axios'
import qs from 'qs'
import {
	checkAvailableTaskStatusesSuccess,
	createTaskErrorAction,
	createTaskSuccessAction,
	getTaskByIdSuccess,
	getTaskCommentSuccess,
	getTaskErrorAction,
	getTaskSuccessAction,
	getTaskSuccessPageAction,
} from '../actions/calendarTaskAction'
import {
	CHANGE_TASK_STATUS,
	CREATE_TASK_COMMENT,
	CREATE_TASK_REQUEST,
	DELETE_TASKS,
	GET_AVAILABLE_TASK_STATUSES,
	GET_PUBLIC_TASKS,
	GET_PUBLIC_TASKS_DETAILS,
	GET_TASKS,
	GET_TASKS_BY_TYPE,
	GET_TASK_BY_ID,
	GET_TASK_COMMENT,
	UPDATE_TASKS,
	DELETE_TASK_COMMIT,
	UPDATE_TASK_COMMENT,
} from '../calendarTasksTypes'
import _ from 'lodash'
import Swal from 'sweetalert2'

const BASE_URL = process.env.REACT_APP_BASE_URL

const axiosNota = axiosNative.create({
	baseURL: BASE_URL,
})

// API------------------------------

const getTasksApi = async (filters: any) => {
	const tasks = await axios.get(
		`/api/task/calendar?year=${filters.current.getFullYear()}&month=${
			filters.current.getMonth() + 1
		}&assignees=${filters.assignees}&statuses=${filters.statuses}&request=${
			filters.request
		}`
	)

	return await tasks.data
}

const getTasksByTypeApi = (perType?: any) => {
	return axios
		.get(`/api/task/search`, {
			params: {
				page: perType?.page,
				size: 10,
				periodType: perType?.name,
				date: perType?.date,
				assignees: perType?.assignees,
				statuses: perType?.statuses,
				creators: perType?.creators,
				request: perType?.request,
				tagId: perType?.tagId,
			},
			paramsSerializer: params => {
				return qs.stringify(params)
			},
		})
		.then(res => {
			return res.data
		})
}

const getPublicTaskApi = async (id: {
	id: string | undefined
	page: number
}) => {
	const data = await axiosNota.get(
		`/api/task/secret/creator/${id.id}?page=${id.page}`
	)
	return await data.data
}

const getPublicTaskDetailsApi = async (id: string | number) => {
	const data = await axiosNota.get('/api/task/secret/' + id)
	return await data.data
}

// API------------------------------END

function* getTasks(action: AnyAction) {
	try {
		const tasks: ITasks[] = yield call(getTasksApi, action.filters)
		yield put(getTaskSuccessAction(tasks))
	} catch (e) {
		yield put(getTaskErrorAction(true))
	}
}

//=======================================CREATE TASK

const createTasksApi = async (tasks: any) => axios.post('/api/task', tasks)

function* createTasks(action: AnyAction): any {
	const formData = new FormData()
	const tasks = action.payload.newTask
	const filter = action.payload.meta

	tasks.title && formData.append('title', tasks.title)
	tasks.description && formData.append('description', tasks.description)
	for (let i = 0; i < tasks.attachments.length; i++) {
		formData.append('attachments', tasks.attachments[i])
	}
	tasks.start && formData.append('start', tasks.start)
	tasks.end && formData.append('end', tasks.end)
	tasks.assignee && formData.append('assigneeId', tasks.assignee)
	tasks.watchersId.forEach((element: any) => {
		formData.append('watchersId', element.userId)
	})
	tasks.tagIds.forEach((element: any) => {
		formData.append('tagIds', element.id)
	})

	try {
		yield call(createTasksApi, formData)
		//---------------- IF FILTER EXIST SO WE REFETCH CALENDAR TASKS
		if (filter) {
			const response: any = yield call(getTasksApi, filter)
			yield put(getTaskSuccessAction(response))
			return
		}
		//---------------- IF NO FILTER WE FETCH TASK LIST
		const perType = {
			name: 'ALL',
			page: 0,
		}
		const response: any = yield call(getTasksByTypeApi, perType)
		yield put(createTaskSuccessAction(response))
	} catch (e: any) {
		console.log(e, 'ERROR IN SAGA CREATE TASK')
		yield put(createTaskErrorAction(e.response.data.message || 'Нет интернета'))
	}
}

//======================================= GET TASK BY TYPE

function* getTasksByType(action: any) {
	try {
		const tasks: IPaginationModelTasks = yield call(
			getTasksByTypeApi,
			action.filters
		)
		yield put(getTaskSuccessPageAction(tasks))
	} catch (e) {
		yield put(getTaskErrorAction(true))
	}
}

//======================================= DELETE TASK

const deleteTaskApi = (id: string | number) =>
	axios.delete('/api/task/' + id).then(res => res.data)

function* deleteTask(action: AnyAction) {
	try {
		yield call(deleteTaskApi, action.id)
		yield call(getTasks, action?.month)
	} catch (e) {
		console.log(e)
	}
}

//=============================================== UPDATE TASK

const updateTaskApi = async (obj: ITasks) => {
	const data = await axios.put(`/api/task/${obj.id}`, _.omit(obj, 'id'))
	return data
}

function* updateTask(action: AnyAction): any {
	try {
		let task = action.tasks.updatedTask

		const updated = {
			...action.tasks.updatedTask,
			assigneeId: task.assignee
				? task.assignee.userId
					? task.assignee.userId
					: task.assignee.id
				: null,
		}
		if (updated.watchersId.length < 1 || updated.watchersId[0] === undefined) {
			delete updated.watchersId
		}

		if (updated.tagIds.length < 1 || updated.tagIds[0] === undefined) {
			delete updated.tagIds
		}

		delete updated.assignee
		yield call(updateTaskApi, updated)

		if (action.status.new !== action.status.current) {
			yield call(changeTaskStatusApi, updated.id, {
				transitionCode: action.status.new,
			})
		}

		const response: any = yield call(getTasksByTypeApi, action.filter)
		yield put(getTaskSuccessPageAction(response))
	} catch (e) {
		console.log(e)
	}
}

//=============================================== GET TASK BY ID

const getTaskByIdApi = (id: string | number) =>
	axios.get('/api/task/' + id).then(res => res.data)

function* getTaskById(action: AnyAction) {
	try {
		const task: ITasks = yield call(getTaskByIdApi, action.id)
		yield put(getTaskByIdSuccess(task))
	} catch (e) {
		console.log(e)
	}
}

//=============================================== GET PUBLIC TASK

function* getPublicTasks(action: AnyAction) {
	console.log(action.tasks, 'sagaTasksUpdate')
	try {
		const tasks: IPaginationModelTasks = yield call(getPublicTaskApi, action.id)
		yield put(getTaskSuccessPageAction(tasks))
	} catch (e) {
		console.log(e)
	}
}

//=============================================== GET PUBLIC TASK BY DETAILS

function* getPublicTasksDetails(action: AnyAction) {
	console.log(action.tasks, 'sagaTasksUpdate')
	try {
		const tasks: ITasks = yield call(getPublicTaskDetailsApi, action.id)
		yield put(getTaskByIdSuccess(tasks))
	} catch (e) {
		console.log(e)
	}
}

//=============================================== CHECK AVAILABLE STATUS

const checkAvailableTaskStatusesApi = async (id: number) =>
	await axios.get(`/api/task/${id}/transitions`).then(res => res.data)

function* checkAvailableTaskStatuses(action: AnyAction): any {
	try {
		const statuses: any = yield call(checkAvailableTaskStatusesApi, action.id)
		yield put(checkAvailableTaskStatusesSuccess(statuses))
	} catch (e) {
		console.log(e)
	}
}

//=============================================== CHANGE TASK STATUS

const changeTaskStatusApi = async (
	id: number,
	code: { transitionCode: string }
) => await axios.post(`/api/task/${id}/perform`, code).then(res => res.data)

function* changeTaskStatus(action: AnyAction): any {
	try {
		yield call(changeTaskStatusApi, action.taskId, {
			transitionCode: action.taskStatus,
		})

		const response: any = yield call(getTasksByTypeApi, action.period)

		yield put(getTaskSuccessPageAction(response))
	} catch (e) {
		console.log(e)
	}
}

//=============================================== GET TASK COMMENT
// const taskCommentApi = (id: { taskId: any; userId: any, page:any }) => {
// 	console.log(id)
// 	return axiosNota
// 		.get(`/api/task-notes?taskId=${id.taskId}&page=${id.page}`)
// 		.then(data => data.data)
// }
const taskCommentApi = (id: { taskId: any; userId: any; page: any }) => {
	console.log(id)
	return axiosNota
		.get(`/api/task-notes`, {
			params: {
				page: id?.page,
				taskId: id?.taskId,
				// userId: id?.userId
			},
		})
		.then(data => data.data)
}
function* taskComment(action: AnyAction) {
	console.log(action)
	try {
		const taskCom: ITaskComment[] = yield call(taskCommentApi, action.id)
		console.log(taskCom, '########################################')

		yield put(getTaskCommentSuccess(taskCom))
	} catch (e) {
		console.log(e)
	}
}
//=============================================== POST TASK COMMENT

const createTaskCommentApi = (id: { taskId: any; text: any; userId?: any }) => {
	if (id.userId) {
		return axios.post(`/api/task-notes`, id).then(data => data.data)
	}

	return axiosNota.post(`/api/task-notes/anonymous`, id).then(data => data.data)
}
function* createTaskComment(action: AnyAction) {
	console.log(action)
	try {
		yield call(createTaskCommentApi, action.id)
		const taskCom: ITaskComment[] = yield call(taskCommentApi, action.id)
		yield put(getTaskCommentSuccess(taskCom))
	} catch (e) {
		console.log(e)
	}
}
//=============================================== DELETE TASK COMMENT

const deleteTaskCommentApi = (id: {
	taskId: any
	text: any
	commentId: any
}) => {
	return axios.delete(`/api/task-notes/${id.commentId}`).then(data => data.data)
}
function* deleteTaskComment(action: AnyAction) {
	console.log(action)
	try {
		yield call(deleteTaskCommentApi, action.id)
		const taskCom: ITaskComment[] = yield call(taskCommentApi, action.id)
		yield put(getTaskCommentSuccess(taskCom))
	} catch (e: any) {
		console.log(e.response?.data?.message)
		Swal.fire({
			icon: 'error',
			title: 'Ошибка',
			text: e.response?.data?.message,
		})
	}
}
//=============================================== UPDATE TASK COMMENT

const updateTaskCommentApi = (id: {
	taskId: any
	text: any
	commentId: any
}) => {
	return axios
		.put(`/api/task-notes/${id.commentId}`, { text: id.text })
		.then(data => data.data)
}
function* updateTaskComment(action: AnyAction) {
	try {
		yield call(updateTaskCommentApi, action.id)
		const taskCom: ITaskComment[] = yield call(taskCommentApi, action.id)
		yield put(getTaskCommentSuccess(taskCom))
	} catch (e: any) {
		if (e.response.data.errors) {
			Swal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: e.response.data.errors,
			})
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: e.response?.data?.message,
			})
		}
	}
}

export function* watchTasksSaga() {
	yield takeLatest(GET_TASKS, getTasks)
	yield takeLatest(GET_TASKS_BY_TYPE, getTasksByType)
	yield takeLatest(CREATE_TASK_REQUEST, createTasks)
	yield takeLatest(DELETE_TASKS, deleteTask)
	yield takeLatest(UPDATE_TASKS, updateTask)
	yield takeLatest(GET_TASK_BY_ID, getTaskById)
	yield takeLatest(GET_PUBLIC_TASKS, getPublicTasks)
	yield takeLatest(GET_PUBLIC_TASKS_DETAILS, getPublicTasksDetails)
	yield takeLatest(GET_AVAILABLE_TASK_STATUSES, checkAvailableTaskStatuses)
	yield takeLatest(CHANGE_TASK_STATUS, changeTaskStatus)
	yield takeLatest(GET_TASK_COMMENT, taskComment)
	yield takeLatest(CREATE_TASK_COMMENT, createTaskComment)
	yield takeLatest(DELETE_TASK_COMMIT, deleteTaskComment)
	yield takeLatest(UPDATE_TASK_COMMENT, updateTaskComment)
}
