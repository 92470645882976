import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from '../../setup/axios/axios-config'
import { requestStatisticSuccessAction } from '../actions/statisticAction'
import { IStatistic } from '../reducers/statisticReducer'
import { GET_STATISTIC } from '../statisticTypes'

export interface IRequestStatisticProps {
	start: string,
	end: string,
}

const requestStatisticApi = (payload: IRequestStatisticProps) =>
	axios
		.get('/api/statistic/main', {
			params: {  end: payload.end, start: payload.start},
		})
		.then(res => res?.data)

function* requestStatisticSaga(action: AnyAction) {
	try {
		
		const result: IStatistic = yield call(requestStatisticApi, action.payload)
		yield put(requestStatisticSuccessAction(result))
	} catch (e: any) {
		if (e?.response?.status === 500) {
			Swal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: 'Произошла ошибка на сервере',
			})
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: e?.response?.data?.message,
				timer: 10000,
			})
		}
	}
}

export function* watchStatistics() {
	yield takeLatest(GET_STATISTIC, requestStatisticSaga)
}
