import { useEffect, useState } from 'react'
import './schudle.scss'
import { useIntl } from 'react-intl'

interface scheduleProps {
	setSchedule: React.Dispatch<React.SetStateAction<any[]>>
	schedule?: any[]
}

const Schudle = ({ setSchedule, schedule }: scheduleProps) => {
	const intl = useIntl()

	const [state, setState] = useState<any>({})
	const [time, setTime] = useState<any>({
		MONDAY: { dayOfWeek: 'MONDAY', end: '19:00', start: '08:00' },
		TUESDAY: { dayOfWeek: 'TUESDAY', end: '19:00', start: '08:00' },
		WEDNESDAY: { dayOfWeek: 'WEDNESDAY', end: '19:00', start: '08:00' },
		THURSDAY: { dayOfWeek: 'THURSDAY', end: '19:00', start: '08:00' },
		FRIDAY: { dayOfWeek: 'FRIDAY', end: '19:00', start: '08:00' },
		SATURDAY: { dayOfWeek: 'SATURDAY', end: '19:00', start: '08:00' },
		SUNDAY: { dayOfWeek: 'SUNDAY', end: '19:00', start: '08:00' },
	})
	const [timeStart, setTimeStart] = useState()
	const [timeEnd, setTimeEnd] = useState()

	const handleChange = (e: any) => {
		setState({ ...state, [e.target.name]: e.target?.checked })
	}

	const dayEffect = (weekDay: string, sched?: any[]) => {
		if (!sched || sched.length === 0) {
			return { dayOfWeek: weekDay, end: '19:00', start: '08:00' }
		} else {
			const newWeekDay = sched.find(item => weekDay == item.dayOfWeek)
			if (!newWeekDay) {
				return { dayOfWeek: weekDay, end: '19:00', start: '08:00' }
			}
			return newWeekDay
		}
	}

	useEffect(() => {
		const MONDAY = dayEffect('MONDAY', schedule)
		const TUESDAY = dayEffect('TUESDAY', schedule)
		const WEDNESDAY = dayEffect('WEDNESDAY', schedule)
		const THURSDAY = dayEffect('THURSDAY', schedule)
		const FRIDAY = dayEffect('FRIDAY', schedule)
		const SATURDAY = dayEffect('SATURDAY', schedule)
		const SUNDAY = dayEffect('SUNDAY', schedule)
		setTime({
			MONDAY: { ...MONDAY },
			TUESDAY: { ...TUESDAY },
			WEDNESDAY: { ...WEDNESDAY },
			THURSDAY: { ...THURSDAY },
			FRIDAY: { ...FRIDAY },
			SATURDAY: { ...SATURDAY },
			SUNDAY: { ...SUNDAY },
		})

		const trueWeeks = schedule?.map(item => {
			return { [item.dayOfWeek]: true }
		})
		const checkedDays = trueWeeks?.reduce((acc, rec) => {
			return { ...acc, ...rec }
		}, {})
		setState(
			checkedDays || {
				MONDAY: false,
				TUESDAY: false,
				WEDNESDAY: false,
				THURSDAY: false,
				FRIDAY: false,
				SATURDAY: false,
				SUNDAY: false,
			}
		)
	}, [schedule])

	useEffect(() => {
		let obj = []
		for (let key in state) {
			if (state[key] === true) {
				obj.push(time[key])
			}
		}
		setSchedule(obj)
	}, [state, time, timeStart, timeEnd])

	const handleTime = (e: any) => {
		let start
		let end
		if (e.target.ariaLabel === 'start') {
			setTimeStart(e.target?.value)
		} else {
			setTimeEnd(e.target?.value)
		}
		start = e.target.ariaLabel === 'start' ? e.target?.value : timeStart
		end = e.target.ariaLabel === 'end' ? e.target?.value : timeEnd
		setTime({
			...time,
			[e.target.name]: {
				dayOfWeek: e.target.name,
				end: e.target.ariaLabel === 'end' ? e.target.value : end,
				start: e.target.ariaLabel === 'start' ? e.target.value : start,
			},
		})
	}
	return (
		<div className='row mb-6 timePicker'>
			<label className='col-lg-4 col-form-label fw-bold fs-6'>
				{intl.formatMessage({ id: 'PERSONAL.SCHEDULE' })}
			</label>
			<div className='col-lg-8 fv-row'>
				<div className='d-flex align-items-start mt-3 flex-wrap'>
					<div className='d-flex flex-column mb-8 me-3 '>
						{/* ======================== DAY */}
						<label className='form-check form-check-inline form-check-solid'>
							<input
								onChange={handleChange}
								className='form-check-input'
								name='MONDAY'
								type='checkbox'
								checked={state?.MONDAY || false}
							/>
							<span className='fw-bold ps-2 fs-6'>
								{intl.formatMessage({ id: 'WEEK.MONDAY' })}
							</span>
						</label>
						<div
							className={` ${
								state.MONDAY ? 'd-flex' : 'd-none'
							} flex-column  mb-2`}
						>
							{/* ======================= START */}
							<label className='d-flex align-items-center justify-content-between mb-2'>
								<p style={{ margin: 0 }} className='fw-bold  fs-6'>
									{intl.formatMessage({ id: 'TASK.MODAL.START' })}
								</p>
								<input
									onChange={handleTime}
									className='form-date-input'
									name='MONDAY'
									aria-label='start'
									type='time'
									value={time.MONDAY.start}
								/>
							</label>
							{/* ======================= END */}
							<label className='d-flex align-items-center justify-content-between '>
								<p style={{ margin: 0 }} className='fw-bold  fs-6'>
									{intl.formatMessage({ id: 'TASK.MODAL.END' })}
								</p>
								<input
									className='form-input'
									aria-label='end'
									onChange={handleTime}
									name='MONDAY'
									type='time'
									value={time.MONDAY.end}
								/>
							</label>
						</div>
					</div>

					<div className='d-flex flex-column mb-8 me-3 '>
						{/* ======================= DAY */}
						<label className='form-check form-check-inline form-check-solid '>
							<input
								onChange={handleChange}
								className='form-check-input'
								name='TUESDAY'
								type='checkbox'
								checked={state?.TUESDAY || false}
							/>
							<span className='fw-bold ps-2 fs-6'>
								{intl.formatMessage({ id: 'WEEK.TUESDAY' })}
							</span>
						</label>
						<div
							className={` ${
								state.TUESDAY ? 'd-flex' : 'd-none'
							} flex-column mb-2`}
						>
							{/* ======================= START */}
							<label className='d-flex align-items-center justify-content-between mb-2'>
								<p style={{ margin: 0 }} className='fw-bold  fs-6'>
									{intl.formatMessage({ id: 'TASK.MODAL.START' })}
								</p>
								<input
									onChange={handleTime}
									className='form-date-input'
									name='TUESDAY'
									aria-label='start'
									type='time'
									value={time.TUESDAY.start}
								/>
							</label>
							{/* ======================= END */}
							<label className='d-flex align-items-center justify-content-between'>
								<p style={{ margin: 0 }} className='fw-bold  fs-6'>
									{intl.formatMessage({ id: 'TASK.MODAL.END' })}
								</p>
								<input
									onChange={handleTime}
									className='form-input'
									aria-label='end'
									name='TUESDAY'
									type='time'
									value={time.TUESDAY.end}
								/>
							</label>
						</div>
					</div>

					<div className='d-flex flex-column mb-8 me-3 '>
						{/* ======================= DAY */}
						<label className='form-check form-check-inline form-check-solid '>
							<input
								onChange={handleChange}
								className='form-check-input'
								name='WEDNESDAY'
								type='checkbox'
								checked={state?.WEDNESDAY || false}
							/>
							<span className='fw-bold ps-2 fs-6'>
								{intl.formatMessage({ id: 'WEEK.WEDNESDAY' })}
							</span>
						</label>
						<div
							className={` ${
								state.WEDNESDAY ? 'd-flex' : 'd-none'
							} flex-column mb-2`}
						>
							{/* ======================= START */}
							<label className='d-flex align-items-center justify-content-between mb-2'>
								<p style={{ margin: 0 }} className='fw-bold  fs-6'>
									{intl.formatMessage({ id: 'TASK.MODAL.START' })}
								</p>
								<input
									value={time.WEDNESDAY.start}
									onChange={handleTime}
									className='form-date-input'
									name='WEDNESDAY'
									aria-label='start'
									type='time'
								/>
							</label>
							{/* ======================= END */}
							<label className='d-flex align-items-center justify-content-between'>
								<p style={{ margin: 0 }} className='fw-bold  fs-6'>
									{intl.formatMessage({ id: 'TASK.MODAL.END' })}
								</p>
								<input
									value={time.WEDNESDAY.end}
									className='form-input'
									onChange={handleTime}
									aria-label='end'
									name='WEDNESDAY'
									type='time'
								/>
							</label>
						</div>
					</div>

					<div className='d-flex flex-column mb-8 me-3 '>
						<label className='form-check form-check-inline form-check-solid '>
							<input
								onChange={handleChange}
								className='form-check-input'
								name='THURSDAY'
								type='checkbox'
								checked={state?.THURSDAY || false}
							/>
							<span className='fw-bold ps-2 fs-6'>
								{intl.formatMessage({ id: 'WEEK.THURSDAY' })}
							</span>
						</label>
						<div
							className={` ${
								state.THURSDAY ? 'd-flex' : 'd-none'
							} flex-column mb-2`}
						>
							<label className='d-flex align-items-center justify-content-between mb-2'>
								<p style={{ margin: 0 }} className='fw-bold  fs-6'>
									{intl.formatMessage({ id: 'TASK.MODAL.START' })}
								</p>
								<input
									value={time.THURSDAY.start}
									onChange={handleTime}
									className='form-date-input'
									name='THURSDAY'
									aria-label='start'
									type='time'
								/>
							</label>
							<label className='d-flex align-items-center justify-content-between'>
								<p style={{ margin: 0 }} className='fw-bold  fs-6'>
									{intl.formatMessage({ id: 'TASK.MODAL.END' })}
								</p>
								<input
									className='form-input'
									value={time.THURSDAY.end}
									onChange={handleTime}
									aria-label='end'
									name='THURSDAY'
									type='time'
								/>
							</label>
						</div>
					</div>

					<div className='d-flex flex-column mb-8 me-3 '>
						<label className='form-check form-check-inline form-check-solid '>
							<input
								onChange={handleChange}
								className='form-check-input'
								name='FRIDAY'
								type='checkbox'
								checked={state?.FRIDAY || false}
							/>
							<span className='fw-bold ps-2 fs-6'>
								{intl.formatMessage({ id: 'WEEK.FRIDAY' })}
							</span>
						</label>
						<div
							className={` ${
								state.FRIDAY ? 'd-flex' : 'd-none'
							} flex-column mb-2`}
						>
							<label className='d-flex align-items-center justify-content-between mb-2'>
								<p style={{ margin: 0 }} className='fw-bold  fs-6'>
									{intl.formatMessage({ id: 'TASK.MODAL.START' })}
								</p>
								<input
									className='form-date-input'
									value={time.FRIDAY.start}
									onChange={handleTime}
									aria-label='start'
									name='FRIDAY'
									type='time'
								/>
							</label>
							<label className='d-flex align-items-center justify-content-between'>
								<p style={{ margin: 0 }} className='fw-bold  fs-6'>
									{intl.formatMessage({ id: 'TASK.MODAL.END' })}
								</p>
								<input
									className='form-input'
									onChange={handleTime}
									aria-label='end'
									value={time.FRIDAY.end}
									name='FRIDAY'
									type='time'
								/>
							</label>
						</div>
					</div>

					<div className='d-flex flex-column mb-8 me-3 '>
						<label className='form-check form-check-inline form-check-solid '>
							<input
								onChange={handleChange}
								className='form-check-input'
								name='SATURDAY'
								type='checkbox'
								checked={state?.SATURDAY || false}
							/>
							<span className='fw-bold ps-2 fs-6'>
								{intl.formatMessage({ id: 'WEEK.SATURDAY' })}
							</span>
						</label>
						<div
							className={` ${
								state.SATURDAY ? 'd-flex' : 'd-none'
							} flex-column mb-2`}
						>
							<label className='d-flex align-items-center justify-content-between mb-2'>
								<p style={{ margin: 0 }} className='fw-bold  fs-6'>
									{intl.formatMessage({ id: 'TASK.MODAL.START' })}
								</p>
								<input
									value={time.SATURDAY.start}
									className='form-date-input'
									onChange={handleTime}
									aria-label='start'
									name='SATURDAY'
									type='time'
								/>
							</label>
							<label className='d-flex align-items-center justify-content-between'>
								<p style={{ margin: 0 }} className='fw-bold  fs-6'>
									{intl.formatMessage({ id: 'TASK.MODAL.END' })}
								</p>
								<input
									className='form-input'
									onChange={handleTime}
									aria-label='end'
									value={time.SATURDAY.end}
									name='SATURDAY'
									type='time'
								/>
							</label>
						</div>
					</div>

					<div className='d-flex flex-column mb-8 me-3 '>
						<label className='form-check form-check-inline form-check-solid '>
							<input
								onChange={handleChange}
								className='form-check-input'
								name='SUNDAY'
								type='checkbox'
								checked={state?.SUNDAY || false}
							/>
							<span className='fw-bold ps-2 fs-6'>
								{intl.formatMessage({ id: 'WEEK.SUNDAY' })}
							</span>
						</label>
						<div
							className={` ${
								state.SUNDAY ? 'd-flex' : 'd-none'
							} flex-column mb-2`}
						>
							<label className='d-flex align-items-center justify-content-between mb-2'>
								<p style={{ margin: 0 }} className='fw-bold  fs-6'>
									{intl.formatMessage({ id: 'TASK.MODAL.START' })}
								</p>
								<input
									value={time.SUNDAY.start}
									className='form-date-input'
									onChange={handleTime}
									aria-label='start'
									name='SUNDAY'
									type='time'
								/>
							</label>
							<label className='d-flex align-items-center justify-content-between'>
								<p style={{ margin: 0 }} className='fw-bold  fs-6 '>
									{intl.formatMessage({ id: 'TASK.MODAL.END' })}
								</p>
								<input
									value={time.SUNDAY.end}
									className='form-input'
									onChange={handleTime}
									aria-label='end'
									name='SUNDAY'
									type='time'
								/>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Schudle
