import React, { useEffect, useState } from 'react'
import { Card, Form, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import './CashierTable.scss'
import { RootState } from '../../setup'
import { Organization } from '../../models/organization/FileModel'
import { getOrganizationListRequest, getOrganizationListSuccess } from '../../store/actions/organizationAction'
import { createCashierRequest } from '../../store/actions/cashierActions'
import { findPermission } from '../../helpers/findPermissionHelper'
import { Permission } from '../../models/coomonOFD/FileModel'
import { getUserPermissionsRequest } from '../../store/actions/commonOFDAction'

type newCashier = {
    factionalAdress: string
    organisationName: string
    organizationId: number
    factoryNumber: string
    showFactoryList: boolean
}

const CashierCreateForm = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const permissions = useSelector<RootState, Permission[]>(state => state.commonOFD.permissions)
    const organizationList: Organization[] = useSelector<RootState, Organization[]>(state => state.organization.organizationList)
    const [state, setState] = useState<newCashier>({
        factionalAdress: '',
        organisationName: '',
        organizationId: 0,
        factoryNumber: '',
        showFactoryList: false
    })
    useEffect(() => {
        if (permissions.length === 0) dispatch(getUserPermissionsRequest())
        dispatch(getOrganizationListSuccess({ list: [], totalItems: 0, totalPages: 0 }))
    }, [])
    const changeStateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const name = e.target.name
        setState({ ...state, [name]: value })
        if (name === 'organisationName' && value.length > 2 && findPermission('LIST_ORGANIZATIONS', permissions)) {
            if (isNaN(Number(value))) dispatch(getOrganizationListRequest(`?search=name=${value}`))
            else dispatch(getOrganizationListRequest(`?search=xin=${value}`))
        }
    }
    const setOrgIdHandler = (id: number, name: string) => {
        setState({ ...state, organizationId: id, showFactoryList: false, organisationName: name })
    }
    const openOrganisationListHandler = () => {
        setState({ ...state, showFactoryList: true })
    }
    const closeOrganisationListHandler = () => {
        setState({ ...state, organisationName: '', showFactoryList: false })
    }
    const cancelCreation = () => {
        history.push('/fiscalization/cashiers')
    }
    const createCashier = () => {
        if (findPermission('CREATE_KKM', permissions)) dispatch(createCashierRequest(state.organizationId, state.factoryNumber, state.factionalAdress))
        cancelCreation()
    }

    return (
        <Card className='b-r-15-px'>
            <div className='cashier-head-create b-r-15-px'>
                <h6 >
                    Создать кассу
                </h6>
            </div>
            <Form.Group className='cashier-main-create '>
                <div className='m-b-40-px d-flex space-betwwen'>
                    <div className='cashier-create-column'>
                        <Form.Label>Заводской номер кассы</Form.Label>
                    </div>
                    <div className='cashier-create-column'>
                        <Form.Control
                            type='text'
                            placeholder='Номер'
                            className='cashier-input-create'
                            name='factoryNumber'
                            value={state.factoryNumber}
                            onChange={changeStateHandler}
                        />
                    </div>
                </div>
                <div className='m-b-40-px d-flex space-betwwen'>
                    <div className='cashier-create-column'>
                        <Form.Label>Адрес торговой точки</Form.Label>
                    </div>
                    <div className='cashier-create-column' >
                        <Form.Control
                            type='text'
                            placeholder='Адрес'
                            className='cashier-input-create'
                            name='factionalAdress'
                            value={state.factionalAdress}
                            onChange={changeStateHandler}
                        />
                    </div>
                </div>
                <div className='cashier-create-org-select-box d-flex flex-wrap align-items-start' >
                    <div className='cashier-create-column'>
                        <Form.Label>Выберите организацию </Form.Label>
                    </div>
                    <div className='cashier-create-column'>
                        <Form.Control
                            type='text'
                            placeholder='Организация'
                            className='cashier-input-create'
                            name='organisationName'
                            value={state.organisationName}
                            onChange={changeStateHandler}
                            onSelect={openOrganisationListHandler}
                            isInvalid={state.organizationId === 0}
                            isValid={state.organizationId !== 0}
                        />
                        {state.showFactoryList &&
                            <div className='cashier-create-org-list-box'  >
                                <button
                                    className='cashier-create-close-btn cashier-btn-create'
                                    onClick={closeOrganisationListHandler}
                                >
                                    X
                                </button>
                                {state.organisationName.length > 2 ?
                                    <>
                                        {organizationList.length > 0 ?

                                            <>
                                                {organizationList.map((item, index) => {

                                                    if (index === organizationList.length - 1) {
                                                        return (
                                                            <div
                                                                className='cashier-create-org-list-item'
                                                                key={item.id}
                                                                onClick={() => setOrgIdHandler(item.id, item.name)}>
                                                                {item.name}
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <div key={item.id}>
                                                                <div
                                                                    className='cashier-create-org-list-item'
                                                                    onClick={() => setOrgIdHandler(item.id, item.name)}>
                                                                    {item.name}
                                                                </div>
                                                                <hr />
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </>
                                            :
                                            <div>
                                                <p>Совпадений не найдено</p>
                                            </div>
                                        }
                                    </>
                                    :
                                    <div>
                                        <p>Введите первых 3 символа названия или иин организации</p>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <Col className='d-flex space-betwwen ' >
                    <button
                        onClick={cancelCreation}
                        className='cashier-btn-create'>
                        Отмена
                    </button>
                    <button
                        className='cashier-btn-create'
                        disabled={state.factionalAdress === '' || state.organizationId === 0 || state.factoryNumber === ''}
                        onClick={createCashier}>
                        Создать
                    </button>
                </Col>
            </Form.Group>
        </Card>
    )
}
export default CashierCreateForm