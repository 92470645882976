import { AnyAction } from 'redux'
import {
	CREATE_STATUS_SUCCESS,
	GET_STATUSES,
	GET_STATUSES_ERROR,
	GET_STATUSES_SUCCESS,
	GET_STATUS_BY_ID_SUCCESS,
} from '../statusTypes'

export interface AuthState {
	statuses: any
	noteError: any
	status: any
}
const initialState: AuthState = {
	statuses: [],
	noteError: '',
	status: null,
}

const statusReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case GET_STATUSES:
			return { ...state, statuses: [] }
		case GET_STATUSES_SUCCESS:
			return { ...state, statuses: action.statuses }
		case GET_STATUS_BY_ID_SUCCESS:
			return { ...state, status: action.status}
			case CREATE_STATUS_SUCCESS:
				return {...state, statuses: [action.status, ...state.statuses]}
		case GET_STATUSES_ERROR:
			return { ...state, statuses: action.error }

		default:
			return state
	}
}

export default statusReducer
