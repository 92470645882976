import { AnyAction } from 'redux';
import { UserModel } from '../../modules/auth/models/auth/UserModel';
import {
	AUTH_ERROR, GET_USER_PROFILE_ERROR,
	GET_USER_PROFILE_SUCCESS,
	LOGIN_USER,
	LOGIN_USER_SUCCESS,
	LOGOUT_USER_SUCCESS
} from '../actionTypes';

export interface AuthState {
	user: UserModel | null
	authError: any
	userProfileError: any
}
const initialState: AuthState = {
	user: null,
	authError: null,
	userProfileError: null
}

const reducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case LOGIN_USER:
			return { ...state, user: null, authError: null }
		case LOGIN_USER_SUCCESS: {
			// const user: UserModel = {
			// 	username: action.auth.userTitle,
			// 	email: action.auth.userLogin,
			// 	role: action.auth.role,
			// 	imagePath: ''
			// }
			return { ...state, user: action.auth, userProfileError: null, authError: null }
		}
		case GET_USER_PROFILE_SUCCESS:
			return { ...state, user: action.user, authError: null, userProfileError: null }
		case LOGOUT_USER_SUCCESS:
			localStorage.setItem('token', 'null')
			localStorage.setItem('ofdToken', 'null')
			window.location.reload()
			return { ...state, user: null, authError: null, userProfileError: null }
		case AUTH_ERROR:
			return { ...state, authError: action.error, user: null, userProfileError: null }
		case GET_USER_PROFILE_ERROR:
			return { ...state, user: null, authError: null, userProfileError: action.error }
		default:
			return state
	}
}

export default reducer