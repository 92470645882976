import axios from '../../setup/axios/axios-config'
import { takeLatest, put, call  } from 'redux-saga/effects'
import { getInstructionsAction, getStatusAction, getStatusErrorAction } from '../actions/instructionActions'
import { INSTRUCTIONS_REQUEST, STATUSES_REQUEST } from '../actionTypes'
import { InstructionModel, StatusModel } from '../../models/instruction/InstructionModel'

const fetchStatuses = () => axios.get('/api/status/all').then(res => res.data)
const fetchInstructions = () => axios.get('/api/instruction/all').then(res => res.data)

function* getAllStatuses () {
	try {
		const statuses:StatusModel[] = yield call(fetchStatuses)
		yield put(getStatusAction(statuses))
	} catch (e) {
		yield put(getStatusErrorAction(e))
	}
}

function* getAllInstructions () {
	try {
		const instructions:InstructionModel[] = yield call(fetchInstructions)
		yield put(getInstructionsAction(instructions))
	} catch (e) {
		yield put(getStatusErrorAction(e))
	}
}

export function* watchInstruction () {
	yield takeLatest(STATUSES_REQUEST, getAllStatuses)
	yield takeLatest(INSTRUCTIONS_REQUEST, getAllInstructions)
}