import { AnyAction } from 'redux'
import {
	GET_STATISTIC,
	GET_STATISTIC_ERROR,
	GET_STATISTIC_SUCCESS,
} from '../statisticTypes'

export interface IStatistics {
	statistics: IStatistic
	statisticsLoading: boolean
	statisticsError: string
}

export interface IStatistic {
	reportAutoCount: 0
	reportManuallyCount: 0
	taskCount: 0
	userCount: 0
}

const initinalState: IStatistics = {
	statistics: {} as IStatistic,
	statisticsLoading: false,
	statisticsError: '',
}

const statisticReducer = (
	state = initinalState,
	action: AnyAction
): IStatistics => {
	switch (action.type) {
		case GET_STATISTIC:
			return { ...state, statisticsLoading: true, statisticsError: '' }
		case GET_STATISTIC_SUCCESS:
			return { ...state, statisticsLoading: false, statistics: action.payload }
		case GET_STATISTIC_ERROR:
			return {
				...state,
				statisticsLoading: false,
				statisticsError: action.payload,
			}
		default:
			return state
	}
}

export default statisticReducer;
