import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from '@mui/material'
import { memo } from 'react'
import { IHelpContent } from '../../../models/tasks/tasksModel'
import TaskHelpList from '../../../pages/help/TaskHelpList'
import Tree from './Tree'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface IhelpListProps {
	help: IHelpContent
	setParentId: (parentId: any) => void
	page: any
	setHelpModalUpdate: React.Dispatch<React.SetStateAction<boolean>>
	setHelpModalAdd: React.Dispatch<React.SetStateAction<boolean>>
	setData: React.Dispatch<React.SetStateAction<{}>>
}

const TreeNode = memo(
	({ help, page, setHelpModalUpdate, setHelpModalAdd, setData, setParentId}: IhelpListProps) => {

		const hasChild = help.children.length > 0 ? true : false

		return (
			<Accordion TransitionProps={{ unmountOnExit: true }}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<TaskHelpList
						setParentId={setParentId}
						setData={setData}
						setHelpModalUpdate={setHelpModalUpdate}
						setHelpModalAdd={setHelpModalAdd}
						page={page}
						key={help.id}
						help={help}
					/>
				</AccordionSummary>
				{hasChild && (
					<AccordionDetails>
						<Tree
							data={help.children}
							setParentId={setParentId}
							page={page}
							setHelpModalUpdate={setHelpModalUpdate}
							setHelpModalAdd={setHelpModalAdd}
							setData={setData}
						/>
					</AccordionDetails>
				)}
			</Accordion>
		)
	}
)

export default TreeNode
