import React from 'react'
import {
    useTable,
    useFlexLayout,
    useResizeColumns,
    useFilters,
    useSortBy
} from 'react-table'
import './ReactTable.scss'

type columnsType = {
    Header: string
    accessor: string
    Cell: () => void
    width: number
    filterable: boolean
}
type reactTableProps = {
    columns: columnsType[]
    data: any[]
    tableWrapperClassName: string
    tableClassName: string
    thClassName: string
    thClassNameRight: string
    tdClassName: string
    tdClassNameRight: string
    trClassNameHover: string
    columnFilterRender: CallableFunction
}

const ReactTable = ({
    columns,
    data,
    tableWrapperClassName,
    tableClassName,
    thClassName,
    thClassNameRight,
    tdClassName,
    tdClassNameRight,
    trClassNameHover,
    columnFilterRender,

}: reactTableProps) => {

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 400,
        }), [])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy, filters }
    } = useTable({
        columns,
        data,
        defaultColumn,
        manualSortBy: true

    },
        useFilters,
        useSortBy,
        useFlexLayout,
        useResizeColumns,
    )

    return (
        <>
            <div className={tableWrapperClassName}>
                <table className={tableClassName} {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, iThCol) => {
                                    return (
                                        <th
                                            {...column.getHeaderProps()}
                                            className={iThCol === columns.length - 1 ? `${thClassName} ${thClassNameRight}` : thClassName}
                                        >
                                            {
                                                columnFilterRender(column)
                                            }
                                            {column.canResize && (
                                                <div
                                                    {...column.getResizerProps()}
                                                    className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                                                />
                                            )}
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr  {...row.getRowProps()} className={trClassNameHover} key={row.id}>
                                    {row.cells.map((cell, iTbCol) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className={iTbCol === columns.length - 1 ? `${tdClassName} ${tdClassNameRight}` : tdClassName}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default ReactTable