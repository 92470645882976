import { IPaginationModelTasks } from '../../models/personal/paginationCustom'
import { ITaskComment, ITasks } from '../../models/tasks/tasksModel'
import {
	CREATE_TASKS,
	GET_TASKS,
	GET_TASKS_ERROR,
	GET_TASKS_BY_TYPE,
	GET_TASKS_SUCCESS,
	DELETE_TASKS,
	UPDATE_TASKS,
	GET_TASKS_PAGE_SUCCESS,
	GET_TASK_BY_ID,
	GET_TASK_BY_ID_SUCCESS,
	GET_PUBLIC_TASKS,
	GET_PUBLIC_TASKS_DETAILS,
	GET_AVAILABLE_TASK_STATUSES,
	GET_AVAILABLE_TASK_STATUSES_SUCCESS,
	CHANGE_TASK_STATUS,
	GET_TASK_COMMENT,
	CREATE_TASK_COMMENT,
	GET_TASK_COMMENT_SUCCESS,
	CREATE_TASK_REQUEST,
	CREATE_TASK_SUCCESS,
	CREATE_TASK_ERROR,
	DELETE_TASK_COMMIT,
	UPDATE_TASK_COMMENT,
} from '../calendarTasksTypes'

export const getTaskAction = (filters?: any) => ({ type: GET_TASKS, filters })

export const getTasksByType = (filters?: any) => ({
	type: GET_TASKS_BY_TYPE,
	filters,
})

export const getTaskSuccessAction = (tasks: ITasks[]) => ({
	type: GET_TASKS_SUCCESS,
	tasks,
})

export const getTaskSuccessPageAction = (tasks: IPaginationModelTasks) => ({
	type: GET_TASKS_PAGE_SUCCESS,
	tasks,
})

export const getTaskErrorAction = (err: any) => ({ type: GET_TASKS_ERROR, err })

export const createTaskAction = (tasks: any) => ({ type: CREATE_TASKS, tasks })

export const getTaskById = (id: any) => ({ type: GET_TASK_BY_ID, id })
export const getTaskByIdSuccess = (task: any) => ({
	type: GET_TASK_BY_ID_SUCCESS,
	task,
})

export const updateTaskAction = (tasks: any, filter?: any, status?: any) => ({
	type: UPDATE_TASKS,
	tasks,
	filter,
	status,
})

export const checkAvailableTaskStatuses = (id: number) => ({
	type: GET_AVAILABLE_TASK_STATUSES,
	id,
})

export const checkAvailableTaskStatusesSuccess = (payload: any) => ({
	type: GET_AVAILABLE_TASK_STATUSES_SUCCESS,
	payload,
})

export const changeTaskStatusAction = (
	taskId: number,
	taskStatus: string | undefined,
	period: any
) => ({
	type: CHANGE_TASK_STATUS,
	taskId,
	taskStatus,
	period,
})

export const deleteTaskAction = (id: string | number) => ({
	type: DELETE_TASKS,
	id,
})

export const getPublicTasks = (id: {
	id: string | undefined
	page: number
}) => ({ type: GET_PUBLIC_TASKS, id })
export const getPublicTasksDetails = (id: string | number) => ({
	type: GET_PUBLIC_TASKS_DETAILS,
	id,
})

// ============================== TASK COMMENT

export const getTaskComment = (id: { taskId: any; userId?: any, page:any }) => ({
	type: GET_TASK_COMMENT,
	id,
})

export const getTaskCommentSuccess = (taskComment: ITaskComment[]) => ({
	type: GET_TASK_COMMENT_SUCCESS,
	taskComment,
})

export const createTaskComment = (id: {
	taskId: any
	text: any
	userId?: any
}) => ({ type: CREATE_TASK_COMMENT, id })

export const deleteTaskComment = (id: {
	taskId: any
	userId?: any
	commentId: any
}) => ({ type: DELETE_TASK_COMMIT, id })
export const updateTaskComment = (id: {
	taskId: any
	userId?: any
	commentId: any
	text: any
}) => ({ type: UPDATE_TASK_COMMENT, id })

//=============================================CREATE TASK ACTION

export const createTaskRequestAction = (payload: any) => ({
	type: CREATE_TASK_REQUEST,
	payload,
})

export const createTaskSuccessAction = (payload: any) => ({
	type: CREATE_TASK_SUCCESS,
	payload,
})

export const createTaskErrorAction = (payload: any) => ({
	type: CREATE_TASK_ERROR,
	payload,
})

//=============================================
