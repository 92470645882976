import React, {memo} from 'react'
import {useDispatch} from 'react-redux'
import Swal from 'sweetalert2'
import {deleteTaskHelpAction} from '../../store/actions/taskhelpAction'
import {useIntl} from 'react-intl'
import {IReportContent} from '../../models/report/reportModel'
import ErrorIcon from '@mui/icons-material/Error'
import BasicToolTipProps from '../../../ts/modules/basicToolTip/BasicToolTip'
import WarningIcon from '@mui/icons-material/Warning'
import CancelIcon from '@mui/icons-material/Cancel'
import DoneIcon from '@mui/icons-material/Done'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import GppMaybeIcon from '@mui/icons-material/GppMaybe'
import {IconButton, Popover, Typography} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'

interface IhelpListProps {
    report: IReportContent
    page: any
    setHelpModalUpdate: React.Dispatch<React.SetStateAction<boolean>>
    setData: React.Dispatch<React.SetStateAction<IReportContent | undefined>>
    reportModalHandle: (report: IReportContent) => void
}

const ENUM = {
    IN_PROGRESS: 'IN_PROGRESS',
    ACCEPTED: 'ACCEPTED',
    ANNULLED: 'ANNULLED',
    FAILED: 'FAILED',
    EXPIRED: 'EXPIRED',
    INITIATED: 'INITIATED',
    REJECTED: 'REJECTED',
}

const ReportList = memo(
    ({
         report,
         page,
         setHelpModalUpdate,
         setData,
         reportModalHandle,
     }: IhelpListProps) => {
        const dispatch = useDispatch()
        const intl = useIntl()

        const handleDelete = (id: any) => {
            Swal.fire({
                title: 'Вы действительно хотите удалить?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Нет',
                confirmButtonText: 'Да!',
            }).then(result => {
                if (result.isConfirmed) {
                    dispatch(deleteTaskHelpAction({id: id, meta: page}))
                    Swal.fire('Удалено!', 'Успешно удалено.', 'success')
                }
            })
        }
        const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

        const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget)
        }

        const handlePopoverClose = () => {
            setAnchorEl(null)
        }

        const open = Boolean(anchorEl)

        const handleModal = (help: any) => {
            setData(help)
            setHelpModalUpdate(true)
        }

        const handleDownloadFile = () => {
            report?.files?.forEach(file => {
                if (file.signed) {
                    var link = document.createElement('a');
                    link.href = file.downloadLink;
                    link.download = file.name;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })

        }

        const displayDocumentStatusIcon = (
            status: string | undefined
        ): JSX.Element | null => {
            let statusIcon = null

            switch (status) {
                case ENUM.FAILED:
                    statusIcon = (
                        <BasicToolTipProps
                            title={intl.formatMessage({
                                id: 'REPORT_STATUS_ICON_TITLE_FAILED',
                            })}
                        >
                            <ErrorIcon color='error'/>
                        </BasicToolTipProps>
                    )
                    break
                case ENUM.IN_PROGRESS:
                    statusIcon = (
                        <BasicToolTipProps
                            title={intl.formatMessage({
                                id: 'REPORT_STATUS_ICON_TITLE_IN_PROGRESS',
                            })}
                        >
                            <AccessTimeIcon color='info'/>
                        </BasicToolTipProps>
                    )
                    break
                case ENUM.ANNULLED:
                    statusIcon = (
                        <BasicToolTipProps
                            title={intl.formatMessage({
                                id: 'REPORT_STATUS_ICON_TITLE_ANNULLED',
                            })}
                        >
                            <CancelIcon color='warning'/>
                        </BasicToolTipProps>
                    )
                    break
                case ENUM.ACCEPTED:
                    statusIcon = (
                        <BasicToolTipProps
                            title={intl.formatMessage({
                                id: 'REPORT_STATUS_ICON_TITLE_ACCEPTED',
                            })}
                        >
                            <DoneIcon color='success'/>
                        </BasicToolTipProps>
                    )
                    break
                case ENUM.EXPIRED:
                    statusIcon = (
                        <BasicToolTipProps
                            title={intl.formatMessage({
                                id: 'REPORT_STATUS_ICON_TITLE_EXPIRED',
                            })}
                        >
                            <WarningIcon color='warning'/>
                        </BasicToolTipProps>
                    )
                    break
                case ENUM.INITIATED:
                    statusIcon = (
                        <BasicToolTipProps
                            title={intl.formatMessage({
                                id: 'REPORT_STATUS_ICON_TITLE_INIT',
                            })}
                        >
                            <HourglassTopIcon color='info'/>
                        </BasicToolTipProps>
                    )
                    break
                case ENUM.REJECTED:
                    statusIcon = (
                        <BasicToolTipProps
                            title={intl.formatMessage({
                                id: 'REPORT_STATUS_ICON_TITLE_REJECTED',
                            })}
                        >
                            <GppMaybeIcon color='error'/>
                        </BasicToolTipProps>
                    )
                    break
                default:
                    statusIcon = null
                    break
            }
            return statusIcon
        }

        return (
            <tr>
                <td className='w-25'>
                    <div className='d-flex align-items-center '>
                        <div className='symbol symbol-45px '></div>
                        <div className='d-flex justify-content-start '>
                            {report?.id ||
                                intl.formatMessage({id: 'TASK.DEFAULT.TEXT'})}
                        </div>
                    </div>
                </td>

                <td className='w-25'>
                    <div className='d-flex align-items-center '>
                        <div className='symbol symbol-45px '></div>
                        <div
                            onClick={() => reportModalHandle(report)}
                            className='d-flex justify-content-start '
                        >
                            <a
                                className='text-dark fw-bolder text-hover-primary fs-6'
                                style={{cursor: 'pointer'}}
                            >
                                {report?.subAgent ||
                                    intl.formatMessage({id: 'TASK.DEFAULT.TEXT'})}
                            </a>
                        </div>
                    </div>
                </td>

                <td className='w-50'>
                    <Typography
                        className='text-muted fw-bold text-muted d-block fs-7'
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup='true'
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                        component={'div'}
                        variant={'body2'}
                    >

                        {report?.files?.length === 0
                            ? 'Нет файлов'
                            : report?.files?.map((files, i, arr) => {
                                if (i > 1) {
                                    return <span key={i}>...</span>
                                }
                                return <div key={files.id}>{files.name}</div>
                            })}
                        <Popover
                            id='mouse-over-popover'
                            sx={{
                                pointerEvents: 'none',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <Typography component={'div'} variant={'body2'} sx={{p: 1}}>
                                {report?.files?.length === 0
                                    ? 'Нет файлов'
                                    : report?.files?.map(files => (
                                        <div key={files.id}>{files.name}</div>
                                    ))}
                            </Typography>
                        </Popover>
                    </Typography>
                </td>

                <td className='w-25'>
					<span className='text-muted fw-bold text-muted d-block fs-7'>
						{report?.creationType === 'AUTO'
                            ? 'Автоматически'
                            : 'Вручную' || intl.formatMessage({id: 'TASK.DEFAULT.TEXT'})}
					</span>
                </td>

                <td>
                    <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
							<span className='text-muted fw-bold text-muted d-block fs-7'>
								{report?.month + '   ' + report?.year ||
                                    intl.formatMessage({id: 'TASK.DEFAULT.TEXT'})}
							</span>
                        </div>
                    </div>
                </td>
                <td className='w-50'>
                    <div className='d-flex flex-column w-100 me-2'>
						<span className='text-muted fw-bold text-muted d-block fs-7'>
							{report?.createdAt ||
                                intl.formatMessage({id: 'TASK.DEFAULT.TEXT'})}
						</span>
                    </div>
                </td>
                <td className=''>
                    <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-column flex-stack mb-2 justify-content-center align-items-center'>
                            {displayDocumentStatusIcon(report?.status.code)}
                            {report?.files?.find(file => file.signed) && (
                                <IconButton
                                    onClick={handleDownloadFile}
                                    color='secondary'
                                    disableRipple={true}
                                >
                                    <BasicToolTipProps title='Выгрузить файлы'>
                                        <DownloadIcon color='info'/>
                                    </BasicToolTipProps>
                                </IconButton>
                            )}
                        </div>
                    </div>
                </td>
            </tr>
        )
    }
)

export default ReportList
