import {
	Box,
	Card,
	IconButton,
	Link,
	Typography,
	useMediaQuery,
} from '@mui/material'
import moment from 'moment'
import { FC } from 'react'
import { formatBytes } from '../../helpers/herpers'
import DownloadIcon from '@mui/icons-material/Download'
import { useTheme } from '@mui/material/styles'

export type props = {
	img: string
	name: string
	size: number
	date: string
}

const TaskImageSumb: FC<props> = ({ img, name, size, date }) => {
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.up('sm'))

	const checkFileType = (name: string) => {
		const extenction = name.split('.').pop()
		switch (extenction) {
			case 'rtf':
				return '/media/svg/files/rtf.svg'
			case 'tif':
				return '/media/svg/files/tif.svg'
			case 'pdf':
				return '/media/svg/files/pdf.svg'
			case 'doc':
				return '/media/svg/files/doc.svg'
			case 'docx':
				return '/media/svg/files/docx.svg'
			case 'xlsx':
				return '/media/svg/files/xlsx.svg'
			case 'xls':
				return '/media/svg/files/xls.svg'
			default:
				return img
		}
	}

	return (
		<Card
			sx={{
				p: 1,
				alignSelf: 'baseline',
				display: 'flex',
				m: 1,
				// width: '45%',
				alignItems: 'center',
			}}
		>
			<Box
				style={{
					backgroundColor: '#ffffff',
					minWidth: '15px',
					minHeight: '20px',
					display: 'inline-block',
					backgroundSize: 'cover',
					backgroundPosition: 'center center',
					backgroundRepeat: 'no-repeat',
					backgroundImage: 'url(' + checkFileType(name) + ')',
				}}
			></Box>

			{matches ? <Box
				sx={{
					width: '100%',
					pl: 1,
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Typography
					component='div'
					sx={{
						width: 5 / 12,
						pl: 2,
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
					}}
				>
					{name}
				</Typography>
				<Typography sx={{ width: 1 / 12 }}>{formatBytes(size)}</Typography>
				<Typography sx={{ width: 3 / 12 }}>
					{moment(date).format('DD MMM YYYY hh:mm a')}
				</Typography>
				<Box sx={{ width: 1 / 12, display: 'flex' }}>
					<Link
						onClick={(e: any) => e.stopPropagation()}
						underline='none'
						href={img}
						sx={{ ml: 'auto' }}
					>
						<IconButton color='primary'>
							<DownloadIcon />
						</IconButton>
					</Link>
				</Box>
			</Box> : <Box
				sx={{
					width: '100%',
					pl: 1,
					display: 'flex',
					flexDirection: 'column',
					// justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Typography
					component='div'
					sx={{
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
					}}
				>
					{name}
				</Typography>
				<Typography>{formatBytes(size)}</Typography>
				<Typography>
					{moment(date).format('DD MMM YYYY hh:mm a')}
				</Typography>
				<Box>
					<Link
						onClick={(e: any) => e.stopPropagation()}
						underline='none'
						href={img}
						sx={{ ml: 'auto' }}
					>
						<IconButton color='primary'>
							<DownloadIcon />
						</IconButton>
					</Link>
				</Box>
			</Box>}

			

			{/* <Box sx={{pl: 1}}>
				
			</Box> */}
		</Card>
	)
}

export { TaskImageSumb }
