import { call, put, takeLatest } from '@redux-saga/core/effects'
import { AnyAction } from 'redux'
import Swal from 'sweetalert2'
import {
	IReport,
	IReportGroup,
} from '../../models/report/reportModel'
import axios from '../../setup/axios/axios-config'
import {
	annulateReportErrorAction,
	annulateReportSuccessAction,
	createReportErrorAction,
	createReportSuccessAction,
	getLegalsParentError,
	getLegalsParentSuccess,
	getReportsContractorsErrorAction,
	getReportsContractorsSuccessAction,
	getReportsGroupSuccess,
	getReportsSuccessAction,
	getReportsTypesError,
	getReportsTypesSuccess,
	reportsLoading,
	resendReportRequestAction,
} from '../actions/reportsActions'
import {
	ANNULATE_REPORT_REQUEST,
	CREATE_REPORT_REQUEST,
	CREATE_MULTIPLE_REPORTS,
	GET_LEGALS_PARENT,
	GET_REPORTS,
	GET_REPORTS_CONTRACTORS,
	GET_REPORT_TYPES,
	RESEND_REPORT,
	GET_REPORTS_GROUP,
} from '../reportTypes'

/////////////////////////////////GET
// const getReportApi = (filters: {
// 	page: any
// 	name: string
// 	startTime: string
// 	endTime: string
// 	contractor: string
// 	status: string
// 	groupId?: string
// 	month?: string
// 	year?: string
// 	creationType?: string
// }) =>
// 	axios
// 		.get(
// 			`/api/documents/search?page=${filters.page || 0}&name=${
// 				filters.name || ''
// 			}&startTime=${filters.startTime || ''}&endTime=${filters.endTime || ''}${
// 				filters.contractor ? '&legal=' + filters.contractor : ''
// 			}&status=${filters.status || ''}&groupId=${
// 				filters?.groupId || ''
// 			}&month=${filters?.month || ''}&year=${
// 				filters?.year || ''
// 			}&creationType=${filters?.creationType || ''}`
// 		)
// 		.then(res => res.data)


		const getReportApi = (filters: {
			page: any
			name: string
			startTime: string
			endTime: string
			legal: string
			status: string
			groupId?: string
			month?: string
			year?: string
			creationType?: string
			parentLegalPin?: any
		}) =>
			axios
				.get(`/api/documents/search`, {params: {
					page: filters?.page,
					name: filters?.name,
					startTime: filters?.startTime,
					endTime: filters?.endTime,
					legal: filters?.legal,
					status: filters?.status,
					groupId: filters?.groupId,
					month: filters?.month,
					year: filters?.year,
					creationType: filters?.creationType,
					parentLegalPin: filters?.parentLegalPin?.inn
				}}).then(res => res.data)

function* getReports({ filters }: AnyAction) {
	try {
		const report: IReport = yield call(getReportApi, filters)
		yield put(getReportsSuccessAction(report))
	} catch (err: any) {
		// yield put(reportsLoading(false))
		if (err.response?.status === 500) {
			Swal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: 'Произошла ошибка на сервере',
			})
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: err.response?.data.message || 'Произошла ошибка с сервером',
			})
		}
	}
}
const getReportGroupApi = (filters: {
	page: any
	startTime: string
	endTime: string
	month: string
	year: string
	creationType?: string
	creatorId?: string
}) =>
	axios
		.get(
			`/api/documents/groups?page=${filters?.page || 0}&startTime=${
				filters?.startTime || ''
			}&endTime=${filters?.endTime || ''}&month=${filters?.month || ''}&year=${
				filters?.year || ''
			}&creationType=${filters?.creationType || ''}&creatorId=${
				filters?.creatorId || ''
			}`
		)
		.then(res => {
			return res.data
		})

function* getReportsGroup({ groupfilters }: AnyAction) {
	console.log(groupfilters)
	try {
		const report: IReportGroup = yield call(getReportGroupApi, groupfilters)
		console.log(report)
		yield put(getReportsGroupSuccess(report))
	} catch (err: any) {
		yield put(reportsLoading(false))
		if (err.response?.status === 500) {
			Swal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: 'Произошла ошибка на сервере',
			})
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: err.response?.data.message || 'Произошла ошибка с сервером',
			})
		}
	}
}

const getReportsContrractorsApi = () =>
	axios.get(`/api/legals`).then(res => res.data)

function* getReportsContractors(): any {
	try {
		const contractors: any = yield call(getReportsContrractorsApi)

		yield put(getReportsContractorsSuccessAction(contractors))
	} catch (e) {
		yield put(getReportsContractorsErrorAction(e))
	}
}

const resendReportApi = (payload: any) => {
	console.log(payload, 'IN API!!!!')
	return axios
		.post(`/api/reports/${payload.get('id')}/restart`, payload)
		.then(data => {
			if (data.data.status === 'FAILED') {
				Swal.fire({
					icon: 'error',
					title: 'Произошла ошибка',
					text: `Неверный отчет`,
				})
			} else {
				Swal.fire({
					icon: 'success',
					title: 'Повторно отправлен',
					text: `Успешно отправлен ${data.data?.subAgent || ''}`,
				})
			}
		})
}

function* resendReprot({ payload, successText, errorText }: AnyAction): any {
	const formData = new FormData()
	payload.file.forEach((files: any) => {
		formData.append('files', files)
	})

	payload.reportTypes.forEach((types: any) => {
		formData.append('reportTypes', types.id)
	})

	payload.id && formData.append('id', payload.id)
	payload.legalEmail && formData.append('legalEmail', payload.legalEmail)
	payload.parentLegalEmail &&
		formData.append('parentLegalEmail', payload.parentLegalEmail)

	try {
		yield put(resendReportRequestAction(true))
		yield put(reportsLoading(true))
		yield call(resendReportApi, formData)
		yield put(resendReportRequestAction(false))
		yield put(reportsLoading(false))

		const filter = {
			page: 0,
			name: '',
			startTime: '',
			endTime: '',
			legal: '',
			status: '',
		}
		const report: IReport = yield call(getReportApi, filter)
		yield put(getReportsSuccessAction(report))
	} catch (error: any) {
		yield put(resendReportRequestAction(false))
		yield put(reportsLoading(false))
		if (error.response.status === 500) {
			Swal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: 'Произошла ошибка на сервере',
			})
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: error.response.data.message || errorText,
				timer: 10000,
			})
		}
	}
}

const annulateReportApi = (reportId: number) =>
	axios.post(`/api/documents/${reportId}/cancel`)

function* annulateReport(action: AnyAction): any {
	try {
		yield call(annulateReportApi, action.reportId)

		const reports: IReport = yield call(getReportApi, action.filter)
		yield put(annulateReportSuccessAction(reports))
	} catch (error: any) {
		if (error.response.status === 500) {
			Swal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: 'Произошла ошибка на сервере',
			})
		} else {
			yield put(annulateReportErrorAction(error))
		}
	}
}

const getLegalsParentApi = (legalId: number) =>
	axios.get(`/api/legals/${legalId}/parent`).then(res => res.data)

function* getLegalsParent(action: AnyAction): any {
	try {
		const legalsResponse: any[] = yield call(getLegalsParentApi, action.legalId)

		yield put(getLegalsParentSuccess(legalsResponse))
	} catch (e) {
		yield put(getLegalsParentError(e))
	}
}

const createReportApi = async (report: any) => {
	return axios.post('/api/reports', report).then(res => res.data)
}

function* createReport(action: AnyAction): any {
	const report = action.report
	const formData = new FormData()
	report.legalEmail.forEach(
		(item: { email: string; fullName: string; id: any }, i: any) => {
			formData.append(`legals[${i}].legalEmail`, item.email)
			formData.append(`legals[${i}].legalId`, item.id)
		}
	)
	report.reportTypeId.forEach((types: any) => {
		formData.append('reportTypes', types.id)
	})
	report.file?.forEach((files: any) => {
		formData.append('files', files)
	})
	report.description && formData.append('description', report.description)
	report.month && formData.append('month', report.month)
	report.year && formData.append('year', report.year)
	report.groupId && formData.append('groupId ', report.groupId)
	report.parentLegalId && formData.append('parentLegalId', report.parentLegalId)

	try {
		yield put(reportsLoading(true))
		const response = yield call(createReportApi, formData)

		if (response[0]) {
			yield put(reportsLoading(false))
			const filter = {
				page: 0,
				name: '',
				startTime: '',
				endTime: '',
				legal: '',
				status: '',
			}
			const reports: IReport = yield call(getReportApi, filter)
			yield put(createReportSuccessAction(reports))
		} else {
			yield put(reportsLoading(false))
			yield put(
				createReportErrorAction({ year: report.year, month: report.month })
			)
		}
	} catch (e: any) {
		yield put(createReportErrorAction(e))
		yield put(reportsLoading(false))
		if (e.response.data.errors) {
			Swal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: e.response.data.errors,
			})
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: e?.response?.data?.message || 'Произошла ошибка с сервером',
			})
		}
	}
}

const getReportsTypes = () =>
	axios.get('/api/reports/types').then(res => res.data)

function* getReportTypes(action: AnyAction) {
	try {
		const reportTypesResponse: [] = yield call(getReportsTypes)
		yield put(getReportsTypesSuccess(reportTypesResponse))
	} catch (e) {
		yield put(getReportsTypesError(e))
	}
}

const createReportsMultipleApi = async (formData: any) => {
	const res = await axios.post('/api/reports/multiple', formData)
	return res.data
}

function* createMultipleReport(action: AnyAction) {
	const formData = new FormData()
	action.report.files.forEach((file: any, i: any) => {
		formData.append('files', file.file)
	})
	action.report.filesInfo.forEach((item: any, i: any) => {
		formData.append(`reports[${i}].legalEmail`, item?.email || '')
		formData.append(`reports[${i}].filename`, item.fileName)
		formData.append(`reports[${i}].legalId`, item.id)
	})

	formData.append('month', action.report.month)
	formData.append('year', action.report.year)
	formData.append('reportTypeId', action.report.reportTypeId)
	formData.append('groupId', action.report.groupId)
	try {
		yield put(reportsLoading(true))
		yield call(createReportsMultipleApi, formData)
		yield put(reportsLoading(false))

		const filter = {
			page: 0,
			name: '',
			startTime: '',
			endTime: '',
			legal: '',
			status: '',
		}

		const report: IReport = yield call(getReportApi, filter)
		console.log(report)
		yield put(getReportsSuccessAction(report))

		Swal.fire({
			position: 'center',
			icon: 'success',
			title: 'Успешно созданы',
			showConfirmButton: false,
			timer: 1500,
		})
	} catch (e: any) {
		yield put(reportsLoading(false))
		if (e.response.data.errors) {
			Swal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: e.response.data.errors,
			})
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: e?.response?.data?.message || 'Произошла ошибка с сервером',
			})
		}

		console.log(e)
	}
}

export function* watchReports() {
	yield takeLatest(GET_REPORTS_CONTRACTORS, getReportsContractors)
	yield takeLatest(GET_REPORTS, getReports)
	yield takeLatest(RESEND_REPORT, resendReprot)
	yield takeLatest(ANNULATE_REPORT_REQUEST, annulateReport)
	yield takeLatest(GET_LEGALS_PARENT, getLegalsParent)
	yield takeLatest(GET_REPORT_TYPES, getReportTypes)
	yield takeLatest(CREATE_REPORT_REQUEST, createReport)
	yield takeLatest(CREATE_MULTIPLE_REPORTS, createMultipleReport)
	yield takeLatest(GET_REPORTS_GROUP, getReportsGroup)
}
