import axios from '../../setup/axios/axios-config-ofd'
import { AnyAction } from "redux"
import { takeLatest, put, call } from 'redux-saga/effects'
import {
    CONFIRM_USER_FOR_ORGANIZATION,
    CREATE_NEW_LINK_FOR_CONFIRMATION,
    CREATE_ORGANIZATION_REQUEST,
    EDIT_ORGANIZATION_REQUEST,
    GET_ACTIVITY_CLASSIFIER_REQUEST,
    GET_ONE_ORGANIZATION_REQUEST,
    GET_ORGANIZATION_LIST_REQUEST,
    GET_TAXATION_TYPE_REQUEST
} from '../actionTypes'
import {
    ActivityClassifier,
    GetOrganizationList,
    TaxationType,
    NewOrganization,
    EditOrganization,
    ConfirmUserForOrg,
    ConfirmUserForOrgResponce,
    EditOrganizationReqest
} from '../../models/organization/FileModel'
import {
    confirmUserForOrganizationSuccess,
    createNewLinkForConfirmation,
    errorOrganization,
    getActivityClassifierSuccess,
    getOneOrganizationSuccess,
    getOrganizationListRequest,
    getOrganizationListSuccess,
    getTaxationTypeSuccess
} from '../actions/organizationAction'
import { setOfdLoadingAction } from '../actions/commonOFDAction'


const fetchOrganizationList = (props: string) => axios.get(`/organization${props}`).then(res => res.data)
const fetchTaxationTypeList = () => axios.get(`/organization/taxation_type`).then(res => res.data)
const fetchActivityClassifier = () => axios.get(`/organization/activity_classifier`).then(res => res.data)
const createNewOrg = (data: NewOrganization) => axios.post(`/organization`, data).then(res => res.data)
const fetchOrganizationById = (id: number) => axios.get(`/organization/${id}`).then(res => res.data)
const confirmUserForOrg = (data: ConfirmUserForOrg) => axios.put(`/user/confirmation`, data).then(res => res)
const createNewLinkForConfirm = (login: string) => axios.put(`/user/confirmation/update/${login}`).then(res => res)
const editOrgFetch = (data: EditOrganizationReqest) => axios.put(`/organization`, data).then(res => res)

function* getOrganizationListSaga(action: AnyAction) {
    try {
        yield put(setOfdLoadingAction(true))
        const data: GetOrganizationList = yield call(fetchOrganizationList, action.props)
        yield put(getOrganizationListSuccess(data))
        yield put(setOfdLoadingAction(false))
    } catch (e) {
        yield put(errorOrganization(e))
    }
}

function* getTaxationTypeListSaga(action: AnyAction) {
    try {
        yield put(setOfdLoadingAction(true))
        const data: TaxationType[] = yield call(fetchTaxationTypeList)
        yield put(getTaxationTypeSuccess(data))
        yield put(setOfdLoadingAction(false))
    } catch (e) {
        yield put(errorOrganization(e))
    }
}
function* getActivityClassifierListSaga(action: AnyAction) {
    try {
        yield put(setOfdLoadingAction(true))
        const data: ActivityClassifier[] = yield call(fetchActivityClassifier)
        yield put(getActivityClassifierSuccess(data))
        yield put(setOfdLoadingAction(false))
    } catch (e) {
        yield put(errorOrganization(e))
    }
}
function* createNewOrgSaga(action: AnyAction) {
    try {
        yield put(setOfdLoadingAction(true))
        yield call(createNewOrg, action.data)
        yield put(getOrganizationListRequest(''))
        yield put(setOfdLoadingAction(false))
    } catch (e) {
        yield put(errorOrganization(e))
    }
}
function* getOrganizationById(action: AnyAction) {
    try {
        const data: EditOrganization = yield call(fetchOrganizationById, action.id)
        yield put(getOneOrganizationSuccess(data))
    } catch (e) {
        yield put(errorOrganization(e))
    }
}
function* confirmUserForOrgSaga(action: AnyAction) {
    try {
        const res: ConfirmUserForOrgResponce = yield call(confirmUserForOrg, action.data)
        if (res.status === 200) {
            yield put(confirmUserForOrganizationSuccess('success'))
        }
    } catch (e) {
        yield put(createNewLinkForConfirmation(action.data.login))
        yield put(errorOrganization(e))
    }
}
function* createNewLinkForConfirmationSaga(action: AnyAction) {
    try {
        yield call(createNewLinkForConfirm, action.login)
        yield put(confirmUserForOrganizationSuccess('newLink'))
    } catch (e) {
        yield put(confirmUserForOrganizationSuccess('error'))
        yield put(errorOrganization(e))
    }
}
function* editOrganizationSaga(action: AnyAction) {
    try {
        yield put(setOfdLoadingAction(true))
        const newData = action.data
        delete newData.blocked
        delete newData.registrationDate
        yield call(editOrgFetch, newData)
        yield put(getOrganizationListRequest(action.props))
        yield put(setOfdLoadingAction(false))
    } catch (e) {
        yield put(errorOrganization(e))
    }
}
export function* watchOrganization() {
    yield takeLatest(GET_ORGANIZATION_LIST_REQUEST, getOrganizationListSaga)
    yield takeLatest(GET_TAXATION_TYPE_REQUEST, getTaxationTypeListSaga)
    yield takeLatest(GET_ACTIVITY_CLASSIFIER_REQUEST, getActivityClassifierListSaga)
    yield takeLatest(CREATE_ORGANIZATION_REQUEST, createNewOrgSaga)
    yield takeLatest(GET_ONE_ORGANIZATION_REQUEST, getOrganizationById)
    yield takeLatest(CONFIRM_USER_FOR_ORGANIZATION, confirmUserForOrgSaga)
    yield takeLatest(CREATE_NEW_LINK_FOR_CONFIRMATION, createNewLinkForConfirmationSaga)
    yield takeLatest(EDIT_ORGANIZATION_REQUEST, editOrganizationSaga)
}