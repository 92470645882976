import { FileOrFolderModel, FileVersionModel, UsersOfSharedFile, SharedFileModel } from '../../models/storage/FileModel'
import { AnyAction } from 'redux'
import {
    CREATE_FOLDER_SUCCESS,
    GET_FILES_AND_FOLDERS,
    PREVIEW_IMAGE_SUCCESS,
    REQUEST_FILES_AND_FOLDERS,
    STORAGE_ERROR,
    UPDATE_CURRENT_FOLDER,
    UPLOAD_FILE_SUCCESS,
    ENABLE_PUBLIC_LINK_SUCCESS,
    UPDATE_PDF_PREVIEW_LINK,
    FILE_VERSION_LIST_SUCCESS,
    GET_SHARED_FILES_SUCCESS,
    GET_USERS_OF_SHARED_FILE_SUCCESS,
    VALIDATE_USERNAME_SUCCESS
} from "../actionTypes";



export interface IStorageState {
    filesOrFolders: FileOrFolderModel[],
    sharedFiles: SharedFileModel[],
    usersOfSharedFile: UsersOfSharedFile[],
    userWhomWillShare: {
        username: string,
        userId: number,
        exists: boolean
    },
    imageForPreview: {
        url: string,
        name: string,
        show: boolean,
        blob: Blob
    },
    pdfForPreview: string,
    currentFolder: {
        parentIdArr: number[],
        nameArr: string[],
        currFolderId: number
    },
    filePublicLink: string,
    fileVersionList: FileVersionModel[],
    error: any
}

const initialState: IStorageState = {
    filesOrFolders: [],
    sharedFiles: [],
    usersOfSharedFile: [],
    userWhomWillShare: {
        userId: 0,
        username: '',
        exists: false
    },
    imageForPreview: {
        url: '',
        name: '',
        show: false,
        blob: new Blob()
    },
    pdfForPreview: '',
    currentFolder: {
        parentIdArr: [-1],
        nameArr: ['Main'],
        currFolderId: -1
    },
    filePublicLink: '',
    fileVersionList: [],
    error: null
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case REQUEST_FILES_AND_FOLDERS:
            return { ...state, filesOrFolders: [] }
        case GET_FILES_AND_FOLDERS:
            return { ...state, filesOrFolders: action.filesAndFolders }
        case CREATE_FOLDER_SUCCESS:
            return { ...state, filesOrFolders: [...state.filesOrFolders, action.folder] }
        case UPLOAD_FILE_SUCCESS:
            return { ...state, filesOrFolders: [...state.filesOrFolders, action.file] }
        case STORAGE_ERROR:
            return { ...state, error: action.error }
        case PREVIEW_IMAGE_SUCCESS:
            return { ...state, imageForPreview: action.image }
        case UPDATE_CURRENT_FOLDER:
            return { ...state, currentFolder: action.folder }
        case ENABLE_PUBLIC_LINK_SUCCESS:
            return { ...state, filePublicLink: action.link }
        case UPDATE_PDF_PREVIEW_LINK:
            return { ...state, pdfForPreview: action.link }
        case FILE_VERSION_LIST_SUCCESS:
            return { ...state, fileVersionList: action.list }
        case GET_SHARED_FILES_SUCCESS:
            return { ...state, sharedFiles: action.list }
        case GET_USERS_OF_SHARED_FILE_SUCCESS:
            return { ...state, usersOfSharedFile: action.list }
        case VALIDATE_USERNAME_SUCCESS:
            return { ...state, userWhomWillShare: action.user }

        default:
            return state
    }
}

export default reducer