import { AnyAction } from 'redux'
import { ActivityClassifier, EditOrganization, Organization, TaxationType } from '../../models/organization/FileModel'
import { CONFIRM_USER_FOR_ORGANIZATION_MESSAGE, EDIT_ORGANIZATION_SUCCESS, GET_ACTIVITY_CLASSIFIER_SUCCESS, GET_ONE_ORGANIZATION_SUCCESS, GET_ORGANIZATION_LIST_SUCCESS, GET_TAXATION_TYPE_SUCCESS, ORGANIZATION_ERROR, SET_PROPS_TO_FILTER_ORGANIZATION } from '../actionTypes'

export interface OrganizationState {
    organizationList: Organization[]
    totalPages: number
    totalItems: number
    taxationType: TaxationType[]
    activityClassifier: ActivityClassifier[]
    editOrganization: EditOrganization
    confirmUserMess: string
    propsForFilter: string
    error: any
}
const initialState: OrganizationState = {
    organizationList: [],
    totalItems: 0,
    totalPages: 0,
    taxationType: [],
    activityClassifier: [],
    editOrganization: {
        id: 0,
        name: '',
        xin: '',
        juridicalAddress: '',
        taxationTypeCode: '',
        activityClassifierCode: '',
        blocked: false,
        registrationDate: ''
    },
    confirmUserMess: 'default',
    propsForFilter: '',
    error: null
}
const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case ORGANIZATION_ERROR:
            return { ...state, error: action.error }
        case GET_ORGANIZATION_LIST_SUCCESS:
            return { ...state, organizationList: action.data.list, totalItems: action.data.totalItems, totalPages: action.data.totalPages }
        case GET_ACTIVITY_CLASSIFIER_SUCCESS:
            return { ...state, activityClassifier: action.list }
        case GET_TAXATION_TYPE_SUCCESS:
            return { ...state, taxationType: action.list }
        case GET_ONE_ORGANIZATION_SUCCESS:
            return { ...state, editOrganization: action.data }
        case CONFIRM_USER_FOR_ORGANIZATION_MESSAGE:
            return { ...state, confirmUserMess: action.mess }
        case EDIT_ORGANIZATION_SUCCESS:
            return {
                ...state, organizationList: state.organizationList.map((item) => {
                    if (item.id === action.data.id) return action.data
                    return item
                })
            }
        case SET_PROPS_TO_FILTER_ORGANIZATION:
            return { ...state, propsForFilter: action.props }

        default:
            return state
    }
}
export default reducer