import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import { Camera, CameraType } from 'react-camera-pro'

const Wrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 1;
	top: 0;
	left: 0;
`
const InnerQuad = styled.div`
	width: 100%;
	height: 100%;
	border: 2px solid green;
	border-radius: 8px;
`
const CenterQuad = styled.div`
	position: fixed;
	display: flex;
	right: 0;
	width: 20%;
	min-width: 130px;
	min-height: 130px;
	height: 100%;
	// background: rgba(0, 0, 0, 0.8);
	z-index: 9;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 50px;
	box-sizing: border-box;
	flex-direction: column-reverse;
	@media (max-aspect-ratio: 1/1) {
		flex-direction: row;
		bottom: 40%;
		width: 100%;
		height: 40%;
	}
	@media (max-width: 400px) {
		padding: 30px;
	}
`
const CenterQuadFace = styled.div`
	position: fixed;
	display: flex;
	right: 0;
	width: 20%;
	min-width: 130px;
	min-height: 130px;
	height: 100%;
	// background: rgba(0, 0, 0, 0.8);
	z-index: 9;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 50px;
	box-sizing: border-box;
	flex-direction: column-reverse;
	@media (max-aspect-ratio: 1/1) {
		flex-direction: row;
		bottom: 30%;
		width: 100%;
		height: 60%;
	}
	@media (max-width: 400px) {
		padding: 30px;
	}
`
const CloseButtonWrapper = styled.div`
	position: fixed;
	display: flex;
	right: 0;
	width: 20%;
	min-width: 130px;
	min-height: 20px;
	height: 20%;
	// background: rgba(0, 0, 0, 0.8);
	z-index: 10000000;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px;
	box-sizing: border-box;
	flex-direction: column-reverse;
	@media (max-aspect-ratio: 1/1) {
		flex-direction: row;
		top: 8%;
		width: 100%;
		height: 3%;
	}
	@media (max-width: 400px) {
		padding: 30px;
	}
`
const InnerQuadFace = styled.div`
	width: 100%;
	height: 100%;
	border: 2px solid green;
	border-radius: 50%;
`

const Control = styled.div`
	position: fixed;
	display: flex;
	right: 0;
	width: 20%;
	min-width: 130px;
	min-height: 130px;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 50px;
	box-sizing: border-box;
	flex-direction: column-reverse;
	@media (max-aspect-ratio: 1/1) {
		flex-direction: row;
		bottom: 0;
		width: 100%;
		height: 20%;
	}
	@media (max-width: 400px) {
		padding: 10px;
	}
`

const Button = styled.button`
	outline: none;
	color: white;
	opacity: 1;
	background: transparent;
	background-color: transparent;
	background-position-x: 0%;
	background-position-y: 0%;
	background-repeat: repeat;
	background-image: none;
	padding: 0;
	text-shadow: 0px 0px 4px black;
	background-position: center center;
	background-repeat: no-repeat;
	pointer-events: auto;
	cursor: pointer;
	z-index: 2;
	filter: invert(100%);
	border: none;
	&:hover {
		opacity: 0.7;
	}
`
const FullPageButtons = styled.div`
	position: absolute;
	display: flex;
	width: 100%;
	height: 50px;
	top: 40px;
	left: 0;
	// background: red;
	z-index: 200000;
	align-items: center;
	justify-content: space-between;
`
const Back = styled.button`
	border: none;
	outline: none;
	color: white;
	margin-left: 15px;
	font-size: 14px;
	width: 80px;
	height: 40px;
	// border: solid 4px black;
	border-radius: 8px;
	background: red;
	&:hover {
		background-color: rgba(0, 0, 0, 0.3);
	}
`
const BackCameraButton = styled.button`
	border: none;
	outline: none;
	color: white;

	margin-left: 12px;
	font-size: 14px;
	width: 80px;
	height: 40px;
	// border: solid 4px black;
	border-radius: 8px;
	background: red;
	&:hover {
		background-color: rgba(0, 0, 0, 0.3);
	}
`
const Send = styled.button`
	border: none;
	outline: none;
	color: white;
	margin-right: 15px;
	font-size: 14px;
	width: 100px;
	height: 40px;
	// border: solid 4px black;
	border-radius: 8px;
	background: green;
	&:hover {
		background-color: rgba(0, 0, 0, 0.3);
	}
`
const FullPageWrapper = styled.div`
	// position: relative;
`

const TakePhotoButton = styled(Button)`
	background: url('https://img.icons8.com/ios/50/000000/compact-camera.png');
	background-position: center;
	background-size: 50px;
	background-repeat: no-repeat;
	width: 80px;
	height: 80px;
	border: solid 4px black;
	border-radius: 50%;
	&:hover {
		background-color: rgba(0, 0, 0, 0.3);
	}
`

const ChangeFacingCameraButton = styled(Button)`
	background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
	background-position: center;
	background-size: 40px;
	background-repeat: no-repeat;
	width: 40px;
	height: 40px;
	padding: 40px;
	&:disabled {
		opacity: 0;
		cursor: default;
		padding: 60px;
	}
	@media (max-width: 400px) {
		padding: 40px 5px;
		&:disabled {
			padding: 40px 25px;
		}
	}
`

const ImagePreview = styled.div<{ image: string | null }>`
	width: 120px;
	height: 120px;
	${({ image }) => (image ? `background-image:  url(${image});` : '')}
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	@media (max-width: 400px) {
		width: 50px;
		height: 120px;
	}
`

const FullScreenImagePreview = styled.div<{ image: string | null }>`
	width: 100%;
	height: 100%;
	z-index: 100000;
	position: absolute;
	background-color: black;
	${({ image }) => (image ? `background-image:  url(${image});` : '')}
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
`
interface CameraProps {
	setCamera: () => void
	setPhoto: any
	mode?: string
	setCameraErr?: any
}
const CameraComponent = ({
	setCamera,
	setPhoto,
	mode,
	setCameraErr,
}: CameraProps) => {
	const [numberOfCameras, setNumberOfCameras] = useState(0)
	const [image, setImage] = useState<string | null>(null)
	const [showImage, setShowImage] = useState<boolean>(false)
	const camera = useRef<CameraType>(null)

	return (
		<Wrapper>
			{showImage ? (
				<FullPageWrapper>
					<FullPageButtons>
						<Back onClick={() => setShowImage(false)}>Назад</Back>
						<Send
							onClick={() => {
								setPhoto(image)
								setCamera()
							}}
						>
							Отправить
						</Send>
					</FullPageButtons>
					<FullScreenImagePreview
						image={image}
						onClick={() => {
							setShowImage(!showImage)
						}}
					/>
				</FullPageWrapper>
			) : (
				<Camera
					ref={camera}
					aspectRatio='cover'
					numberOfCamerasCallback={setNumberOfCameras}
					errorMessages={{
						noCameraAccessible: (function () {
							// setCameraErr(true)

							return 'У вашего девайса нет камеры'
						})(),
						permissionDenied:
							'Permission denied. Please refresh and give camera permission.',
						switchCamera:
							'It is not possible to switch camera to different one because there is only one video device accessible.',
						canvas: 'Canvas is not supported.',
					}}
				/>
			)}
			<Control>
				<CloseButtonWrapper>
					<BackCameraButton
						onClick={() => {
							setCamera()
						}}
					>
						Назад
					</BackCameraButton>
				</CloseButtonWrapper>

				{mode === 'face' ? (
					<CenterQuadFace>
						<InnerQuadFace />
					</CenterQuadFace>
				) : (
					<CenterQuad>
						<InnerQuad />
					</CenterQuad>
				)}

				<ImagePreview
					image={image}
					onClick={() => {
						setShowImage(!showImage)
					}}
				/>
				<TakePhotoButton
					onClick={() => {
						if (camera.current) {
							try {
								const photo = camera.current.takePhoto()
								setImage(photo)
							} catch (e) {
								// setErr(e)
							}
						}
					}}
				/>
				<ChangeFacingCameraButton
					disabled={numberOfCameras <= 1}
					onClick={() => {

					}}
				/>
			</Control>
			{/* <p style={{ color: '#fff' }}>{err}</p> */}
		</Wrapper>
	)
}

export default CameraComponent
