import { AnyAction } from 'redux';
import { notesModel } from '../../models/notes/notesModel';

import { GET_NOTES, GET_NOTES_ERROR, GET_NOTES_SUCCESS } from '../notesTypes';

export interface AuthState {
  note: notesModel[] | null
  noteError: any
}
const initialState: AuthState = {
  note: [],
  noteError: ""
}

const noteReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_NOTES:

      return { ...state, note: [] }
    case GET_NOTES_SUCCESS:

      return { ...state, note: action.note }
    case GET_NOTES_ERROR:
      return { ...state, noteError: action.error }

    default:
      return state
  }
}

export default noteReducer