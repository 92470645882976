import { activationData, GetCashierListResponce, OFD, ShiftResData } from "../../models/cashier/FileModel";
import {
    CASHIER_ERROR,
    CREATE_CASHIER_REQUEST,
    GET_CASHIER_LIST_REQUEST,
    GET_CASHIER_LIST_SUCCESS,
    GET_LIST_OFD_REQUEST,
    GET_LIST_OFD_SUCCESS,
    INIT_CASHIER_REQUEST,
    SET_ORG_NAME_FOR_FILTER,
    UNBLOCK_CASHIER_REQUEST,
    SET_PROPS_TO_FILTER_CASHIER,
    GET_SHIFT_LIST_REQUSET,
    GET_SHIFT_LIST_SUCCESS
} from "../actionTypes";

export const errorCashier = (error: any) => ({ type: CASHIER_ERROR, error })

export const getCashierListRequest = (props: string) => ({ type: GET_CASHIER_LIST_REQUEST, props })
export const getCashierListSuccess = (data: GetCashierListResponce) => ({ type: GET_CASHIER_LIST_SUCCESS, data })

export const createCashierRequest = (organizationId: number, factoryNumber: string, factionalAddress: string) => ({ type: CREATE_CASHIER_REQUEST, organizationId, factionalAddress, factoryNumber })

export const setOrgNameForFilter = (name: string) => ({ type: SET_ORG_NAME_FOR_FILTER, name })

export const getOfdListRequest = () => ({ type: GET_LIST_OFD_REQUEST })
export const getOfdListSuccess = (list: OFD[]) => ({ type: GET_LIST_OFD_SUCCESS, list })

export const activateCashierRequest = (data: activationData, props: string) => ({ type: INIT_CASHIER_REQUEST, data, props })

export const unblockCashierRequest = (id: number, props: string) => ({ type: UNBLOCK_CASHIER_REQUEST, id, props })

export const setPropsToFilterCashier = (props: string) => ({ type: SET_PROPS_TO_FILTER_CASHIER, props })

export const getShiftListRequest = (id: number, props: string) => ({ type: GET_SHIFT_LIST_REQUSET, id, props })
export const getShiftListSuccess = (data: ShiftResData) => ({ type: GET_SHIFT_LIST_SUCCESS, data })