//================================= CORRESPONDENCE INFO
export const FETCH_CORRESPONDENCE_INFO: string = 'FETCH_CORRESPONDENCE_INFO'
export const FETCH_CORRESPONDENCE_INFO_SUCCESS: string =
	'FETCH_CORRESPONDENCE_INFO_SUCCESS'
export const FETCH_CORRESPONDENCE_INFO_ERROR: string =
	'FETCH_CORRESPONDENCE_INFO_ERROR'
//=================================PUBLIC CORRESPONDENCE INFO
export const FETCH_PUBLIC_CORRESPONDENCE_INFO: string =
	'FETCH_PUBLIC_CORRESPONDENCE_INFO'
export const FETCH_PUBLIC_CORRESPONDENCE_INFO_SUCCESS: string =
	'FETCH_PUBLIC_CORRESPONDENCE_INFO_SUCCESS'
export const FETCH_PUBLIC_CORRESPONDENCE_INFO_ERROR: string =
	'FETCH_PUBLIC_CORRESPONDENCE_INFO_ERROR'
//================================= CORRESPONDENCE
export const FETCH_CORRESPONDENCE: string = 'FETCH_CORRESPONDENCE'
export const FETCH_CORRESPONDENCE_SUCCESS: string =
	'FETCH_CORRESPONDENCE_SUCCESS'
export const FETCH_CORRESPONDENCE_ERROR: string = 'FETCH_CORRESPONDENCE_ERROR'

export const CREATE_CORRESPONDENCE: string = 'CREATE_CORRESPONDENCE'
export const CREATE_CORRESPONDENCE_SUCCESS: string =
	'CREATE_CORRESPONDENCE_SUCCESS'
export const CREATE_CORRESPONDENCE_ERROR: string = 'CREATE_CORRESPONDENCE_ERROR'

//================================= CORRESPONDENCE MESSAGES
export const FETCH_CORRESPONDENCE_MESSAGES: string =
	'FETCH_CORRESPONDENCE_MESSAGES'
export const FETCH_CORRESPONDENCE_MESSAGES_SUCCESS: string =
	'FETCH_CORRESPONDENCE_MESSAGES_SUCCESS'
export const FETCH_CORRESPONDENCE_MESSAGES_ERROR: string =
	'FETCH_CORRESPONDENCE_MESSAGES_ERROR'

export const CREATE_CORRESPONDENCE_MESSAGE: string =
	'CREATE_CORRESPONDENCE_MESSAGES'
export const CREATE_CORRESPONDENCE_MESSAGE_SUCCESS: string =
	'CREATE_CORRESPONDENCE_MESSAGE_SUCCESS'
export const CREATE_CORRESPONDENCE_MESSAGE_ERROR: string =
	'CREATE_CORRESPONDENCE_MESSAGE_ERROR'
//================================= CORRESPONDENCE MARK
export const TOGGLE_CORRESPONDENCE_MARK: string = 'TOGGLE_CORRESPONDENCE_MARK'
export const REQUEST_CORRESPONDENCE_MARK_COUNT: string =
	'REQUEST_CORRESPONDENCE_MARK_COUNT'
export const REQUEST_CORRESPONDENCE_MARK_COUNT_SUCCESS: string =
	'REQUEST_CORRESPONDENCE_MARK_COUNT_SUCCESS'
export const REQUEST_CORRESPONDENCE_MARK_COUNT_ERROR: string =
	'REQUEST_CORRESPONDENCE_MARK_COUNT_ERROR'
//================================= CORRESPONDENCE IMPORTANT
export const TOGGLE_CORRESPONDENCE_IMPORTANT: string =
	'TOGGLE_CORRESPONDENCE_IMPORTANT'
export const REQUEST_CORRESPONDENCE_IMPORTANT_COUNT: string =
	'REQUEST_CORRESPONDENCE_IMPORTANT_COUNT'
export const REQUEST_CORRESPONDENCE_IMPORTANT_COUNT_SUCCESS: string =
	'REQUEST_CORRESPONDENCE_IMPORTANT_COUNT_SUCCESS'
export const REQUEST_CORRESPONDENCE_IMPORTANT_COUNT_ERROR: string =
	'REQUEST_CORRESPONDENCE_IMPORTANT_COUNT_ERROR'
//================================= OPEN CORRESPONDENCE

export const REQUEST_OPEN_CORRESPONDENCE: string = 'REQUEST_OPEN_CORRESPONDENCE'
export const REQUEST_CORRESPONDENCE_NOT_OPEN_COUNT: string =
	'REQUEST_CORRESPONDENCE_NOT_OPEN_COUNT'
export const REQUEST_CORRESPONDENCE_NOT_OPEN_COUNT_SUCCESS: string =
	'REQUEST_CORRESPONDENCE_OPEN_COUNT_SUCCESS'
export const REQUEST_CORRESPONDENCE_NOT_OPEN_COUNT_ERROR: string =
	'REQUEST_CORRESPONDENCE_NOT_OPEN_COUNT_SUCCESS'

//================================= GET PUBLIC CORRESPONDENCE MESSAGES

export const REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES: string =
	'REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES'
export const REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES_SUCCESS: string =
	'REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES_SUCCESS'
export const REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES_ERROR: string =
	'REQUEST_PUBLIC_CORRESPONDENCE_ERROR'

export const SEND_PUBLIC_CORRESPONDENCE_MESSAGE: string =
	'SEND_PUBLIC_CORRESPONDENCE_MESSAGE'
export const SEND_PUBLIC_CORRESPONDENCE_MESSAGE_SUCCESS: string =
	'SEND_PUBLIC_CORRESPONDENCE_MESSAGE_SUCCESS'
export const SEND_PUBLIC_CORRESPONDENCE_MESSAGE_ERROR: string =
	'SEND_PUBLIC_CORRESPONDENCE_MESSAGE_ERROR'
//================================= GET PUBLIC CORRESPONDENCE ANONYMOUSE_NAME

export const REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME: string =
	'REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME'

export const REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME_SUCCESS: string =
	'REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME_SUCCESS'

export const REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME_ERROR: string =
	'REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME_ERROR'

//================================= REPLY MESSAGE

export const REPLY_MESSAGE_REQUEST = 'REPLY_MESSAGE_REQUEST'
export const REPLY_MESSAGE_SUCCESS = 'REPLY_MESSAGE_SUCCESS'
export const REPLY_MESSAGE_ERROR = 'REPLY_MESSAGE_ERROR'

export const REPLY_PUBLIC_MESSAGE = 'REPLY_PUBLIC_MESSAGE_'
export const REPLY_PUBLIC_MESSAGE_SUCCESS = 'REPLY_PUBLIC_MESSAGE_SUCCESS'
export const REPLY_PUBLIC_MESSAGE_ERROR = 'REPLY_PUBLIC_MESSAGE_ERROR'
