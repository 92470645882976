import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from 'react-bootstrap'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { loginAction } from '../../../store/actions/authActions'
import { RootState } from '../../../setup'
import { AppDispatch } from '../../../setup/redux/Store'
import { useIntl } from 'react-intl'

const loginSchema = Yup.object().shape({
	email: Yup.string(),
	password: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Password is required'),
})

const initialValues = {
	email: '',
	password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
	const intl = useIntl()

	const [loading, setLoading] = useState(false)
	const [isPasswordShown, setIsPasswordShown] = useState(false)
	const authError: string = useSelector<RootState, string>(
		state => state.auth.authError
	)
	const dispatch: AppDispatch = useDispatch()
	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: values => {
			setLoading(true)
			setTimeout(() => {
				let data = { email: values.email, password: values.password }
				dispatch(loginAction(data))
			}, 1000)
		},
	})

	useEffect(() => {
		setLoading(false)
	}, [authError])
	return (
		<form
			className='form w-100'
			onSubmit={formik.handleSubmit}
			noValidate
			id='kt_login_signin_form'
		>
			{/* begin::Heading */}
			<div className='text-center mb-10'>
				<h1 className='text-dark mb-3'>
					{intl.formatMessage({ id: 'AUTH.SIGIN' })}
				</h1>
				{authError && <Alert variant='danger'>{authError}</Alert>}
			</div>
			{/* begin::Heading */}

			{/* begin::Form group */}
			<div className='fv-row mb-10'>
				<label className='form-label fs-6 fw-bolder text-dark'>Email</label>
				<input
					placeholder='Email'
					{...formik.getFieldProps('email')}
					className={clsx(
						'form-control form-control-lg form-control-solid',
						{ 'is-invalid': formik.touched.email && formik.errors.email },
						{
							'is-valid': formik.touched.email && !formik.errors.email,
						}
					)}
					type='email'
					name='email'
				/>
				{formik.touched.email && formik.errors.email && (
					<div className='fv-plugins-message-container'>
						<span role='alert'>{formik.errors.email}</span>
					</div>
				)}
			</div>
			{/* end::Form group */}

			{/* begin::Form group */}
			<div className='fv-row mb-10'>
				<div className='d-flex justify-content-between mt-n5'>
					<div className='d-flex flex-stack mb-2'>
						{/* begin::Label */}
						<label className='form-label fw-bolder text-dark fs-6 mb-0'>
							{intl.formatMessage({ id: 'AUTH.PASSWORD' })}
						</label>
						{/* end::Label */}
						{/* begin::Link */}
						<Link
							to='/auth/forgot-password'
							className='link-primary fs-6 fw-bolder'
							style={{ marginLeft: '5px' }}
						>
							{intl.formatMessage({ id: 'AUTH.FORGOT' })}
						</Link>
						{/* end::Link */}
					</div>
				</div>
				<div className='passwordField'>
					<input
						type={isPasswordShown ? 'text' : 'password'}
						{...formik.getFieldProps('password')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{
								'is-invalid': formik.touched.password && formik.errors.password,
							},
							{
								'is-valid': formik.touched.password && !formik.errors.password,
							}
						)}
					/>
					<i
						onClick={() => setIsPasswordShown(!isPasswordShown)}
						className={
							!isPasswordShown ? 'icon icon-eye' : 'icon icon-eye-slash'
						}
					/>
				</div>
				{formik.touched.password && formik.errors.password && (
					<div className='fv-plugins-message-container'>
						<div className='fv-help-block'>
							<span role='alert'>{formik.errors.password}</span>
						</div>
					</div>
				)}
			</div>
			{/* end::Form group */}

			{/* begin::Action */}
			<div className='text-center'>
				<button
					type='submit'
					id='kt_sign_in_submit'
					className='btn btn-lg btn-primary w-100 mb-5'
					disabled={loading || !formik.isValid}
				>
					{!loading && (
						<span className='indicator-label'>
							{intl.formatMessage({ id: 'AUTH.CONTINUE' })}
						</span>
					)}
					{loading && (
						<span className='indicator-progress' style={{ display: 'block' }}>
							{intl.formatMessage({ id: 'TEXT.LOADING' })}...
							<span className='spinner-border spinner-border-sm align-middle ms-2' />
						</span>
					)}
				</button>
			</div>
			{/* end::Action */}
		</form>
	)
}
