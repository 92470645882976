import React, { useState, useEffect } from 'react'
import { Card, Col, Row, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ActivityClassifier, TaxationType } from '../../models/organization/FileModel'
import { RootState } from '../../setup'
import { createOrganizationRequest, getActivityClassifierRequest, getTaxationTypeRequest } from '../../store/actions/organizationAction'
import '../cashier/CashierTable.scss'
import './OrganizationTable.scss'
import { findPermission } from '../../helpers/findPermissionHelper'
import { Permission } from '../../models/coomonOFD/FileModel'
import { getUserPermissionsRequest } from '../../store/actions/commonOFDAction'


type createFormOrganization = {
    xin: string
    name: string
    juridicalAddress: string
    activityClassifierCode: string
    taxationTypeCode: string


}
type createFormUser = {
    fullName: string
    email: string
    login: string
    code: number
}

const OrganizationCreateForm = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const permissions = useSelector<RootState, Permission[]>(state => state.commonOFD.permissions)
    const activityClassifierList = useSelector<RootState, ActivityClassifier[]>(state => state.organization.activityClassifier)
    const taxationTypeList = useSelector<RootState, TaxationType[]>(state => state.organization.taxationType)
    const [canSubmit, setCansubmit] = useState<boolean>(false)
    const [organization, setOrganization] = useState<createFormOrganization>({
        xin: '',
        name: '',
        juridicalAddress: '',
        activityClassifierCode: '',
        taxationTypeCode: ''
    })
    const [firstUser, setFirstUser] = useState<createFormUser>({
        fullName: '',
        email: '',
        login: '',
        code: 0
    })
    useEffect(() => {
        if (permissions.length === 0) dispatch(getUserPermissionsRequest())
    }, [])
    useEffect(() => {
        if (findPermission('LIST_ACTIVITY_CLASSIFIERS', permissions)) dispatch(getActivityClassifierRequest())
        if (findPermission('LIST_TAXATION_TYPES', permissions)) dispatch(getTaxationTypeRequest())
    }, [permissions])
    const emailValidation = (email: string) => {
        if (email.match(/\@/)) {
            if (email.match(/\./)) return true
            else return false
        }
        else return false
    }
    useEffect(() => {
        if (organization.xin === '' ||
            organization.name === '' ||
            organization.activityClassifierCode === '' ||
            organization.juridicalAddress === '' ||
            organization.taxationTypeCode === '' ||
            firstUser.fullName === '' ||
            firstUser.email === '' ||
            !emailValidation(firstUser.email) ||
            firstUser.login === '' ||
            firstUser.code === 0) setCansubmit(false)
        else setCansubmit(true)
    }, [organization, firstUser])
    const changeOrganizationHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const name = e.target.name
        setOrganization({ ...organization, [name]: value })
    }
    const selectOrganizationHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value
        const name = e.target.name
        setOrganization({ ...organization, [name]: value })
    }
    const changeUserHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const name = e.target.name
        if (name === 'code' && parseInt(value) > 0) setFirstUser({ ...firstUser, [name]: parseInt(value) })
        else if (name !== 'code') setFirstUser({ ...firstUser, [name]: value })
    }
    const createOrganizationHandler = () => {
        if (findPermission('CREATE_ORGANIZATION', permissions)) dispatch(createOrganizationRequest({ organization, firstUser }))
        cancelCreation()
    }
    const cancelCreation = () => {
        history.push('/fiscalization/organizations')
    }

    return (
        <>
            <Row className='d-flex space-betwwen m-b-20-px'>
                <h6 className='cashier-list-title'>
                    Регистрация организации
                </h6>
            </Row>
            <Card className='b-r-15-px '>
                <Form.Group className='cashier-main-create '>

                    <Row className='organization-create-wrapper m-b-100-px'>
                        <Col>
                            <h6 className='organization-list-title'>
                                Информация об организации
                            </h6>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <Form.Label>ИИН/БИН организации</Form.Label>
                                </div>
                                <div className='cashier-create-column'>
                                    <Form.Control
                                        type='text'
                                        placeholder='ИИН/БИН'
                                        className='oraganization-input-create'
                                        name='xin'
                                        value={organization.xin}
                                        onChange={changeOrganizationHandler}
                                    />
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <Form.Label>Название организации</Form.Label>
                                </div>
                                <div className='cashier-create-column'>
                                    <Form.Control
                                        type='text'
                                        placeholder='Название'
                                        className='oraganization-input-create'
                                        name='name'
                                        value={organization.name}
                                        onChange={changeOrganizationHandler}
                                    />
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <Form.Label>Юридический адрес организации</Form.Label>
                                </div>
                                <div className='cashier-create-column'>
                                    <Form.Control
                                        type='text'
                                        placeholder='Юридический адрес'
                                        className='oraganization-input-create'
                                        name='juridicalAddress'
                                        value={organization.juridicalAddress}
                                        onChange={changeOrganizationHandler}
                                    />
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <Form.Label>ОКЭД</Form.Label>
                                </div>
                                <div className='cashier-create-column'>
                                    <Form.Select
                                        placeholder='ОКЭД'
                                        className='oraganization-input-create'
                                        name='activityClassifierCode'
                                        isInvalid={organization.activityClassifierCode === ''}
                                        isValid={organization.activityClassifierCode !== ''}
                                        value={organization.activityClassifierCode}
                                        onChange={selectOrganizationHandler}
                                    >
                                        <option value={''}></option>
                                        {activityClassifierList.map(item => {
                                            return (
                                                <option key={item.code} value={item.code}>{item.name}</option>
                                            )
                                        })}

                                    </Form.Select>
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <Form.Label>Тип налогооблажения</Form.Label>
                                </div>
                                <div className='cashier-create-column'>
                                    <Form.Select
                                        placeholder='Тип налогооблажения'
                                        className='oraganization-input-create'
                                        name='taxationTypeCode'
                                        isInvalid={organization.taxationTypeCode === ''}
                                        isValid={organization.taxationTypeCode !== ''}
                                        value={organization.taxationTypeCode}
                                        onChange={selectOrganizationHandler}
                                    >
                                        <option value={''}></option>
                                        {taxationTypeList.map(item => {
                                            return (
                                                <option key={item.code} value={item.code}>{item.name + ' | ' + item.description}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </div>
                            </div>



                        </Col>
                        <Col>
                            <h6 className='organization-list-title'>
                                Информация о контактном лице
                            </h6>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <Form.Label>ФИО контактного лица</Form.Label>
                                </div>
                                <div className='cashier-create-column'>
                                    <Form.Control
                                        type='text'
                                        placeholder='ФИО'
                                        className='oraganization-input-create'
                                        name='fullName'
                                        value={firstUser.fullName}
                                        onChange={changeUserHandler}
                                    />
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <Form.Label>Email контактного лица</Form.Label>
                                </div>
                                <div className='cashier-create-column'>
                                    <Form.Control
                                        type='text'
                                        placeholder='Email'
                                        className='oraganization-input-create'
                                        isValid={emailValidation(firstUser.email)}
                                        isInvalid={!emailValidation(firstUser.email)}
                                        name='email'
                                        value={firstUser.email}
                                        onChange={changeUserHandler}
                                    />
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <Form.Label>Login контактного лица</Form.Label>
                                </div>
                                <div className='cashier-create-column'>
                                    <Form.Control
                                        type='text'
                                        placeholder='Login'
                                        className='oraganization-input-create'
                                        name='login'
                                        value={firstUser.login}
                                        onChange={changeUserHandler}
                                    />
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <Form.Label>Код в системе ОФД</Form.Label>
                                </div>
                                <div className='cashier-create-column'>
                                    <Form.Control
                                        type='number'
                                        placeholder='Код'
                                        className='oraganization-input-create'
                                        isInvalid={firstUser.code === 0}
                                        isValid={firstUser.code !== 0}
                                        name='code'
                                        value={firstUser.code}
                                        onChange={changeUserHandler}
                                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Col className='d-flex space-betwwen ' >
                        <button
                            onClick={cancelCreation}
                            className='cashier-btn-create'>
                            Отмена
                        </button>
                        <button
                            className='cashier-btn-create'
                            disabled={!canSubmit}
                            onClick={createOrganizationHandler}
                        >
                            Создать
                        </button>
                    </Col>
                </Form.Group>


            </Card>
        </>
    )
}
export default OrganizationCreateForm