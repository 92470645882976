export const GET_ORGANIZATIONS_TIMES: string = "GET_ORGANIZATIONS_TIMES"
export const GET_ORGANIZATIONS_TIMES_SUCCESS: string = "GET_ORGANIZATIONS_TIMES_SUCCESS"
export const GET_ORGANIZATIONS_TIMES_ERROR: string = "GET_ORGANIZATIONS_TIMES_ERROR"
export const GET_TIMES_TRACKER_INFO: string = "GET_TIMES_TRACKER_INFO"
export const GET_TIMES_TRACKER_INFO_SUCCESS: string = "GET_TIMES_TRACKER_INFO_SUCCESS"
export const GET_TIMES_TRACKER_INFO_ERROR: string = "GET_TIMES_TRACKER_INFO_ERROR"




// export interface IgetTimesAction {
//     type: TimeTrackerTypes.GET_ORGANIZATIONS_TIMES,
//     filters: { page: any, user: string, organization: string, date: string }
// }

// export interface IgetTimesActionSuccess {
//     type: TimeTrackerTypes.GET_ORGANIZATIONS_TIMES_SUCCESS,
//     times: ITimeTracker
// }

// export interface IgetTimesActionError {
//     type: TimeTrackerTypes.GET_ORGANIZATIONS_TIMES_ERROR,
//     error: any
// }

// export type TimesAction =
//     | IgetTimesAction
//     | IgetTimesActionSuccess
//     | IgetTimesActionError;