import { call, put, takeLatest } from "@redux-saga/core/effects";
import { notesModel } from "../../models/notes/notesModel";
import axios from "../../setup/axios/axios-config";
import { getNotesActionSuccess } from "../actions/noteActions";
import { GET_NOTES } from "../notesTypes";


const getNoteApi = () => axios.get('/api/personal/note/all').then(res => res.data)


function* getNotes() {
  const note: notesModel = yield call(getNoteApi)
  yield put(getNotesActionSuccess(note))
}


export function* watchNotes() {
  yield takeLatest(GET_NOTES, getNotes)
}