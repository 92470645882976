import { AnyAction } from "redux"
import { IAdminPage, IAdminPageContent } from "../../models/adminPages/adminPages"
import { CHECK_ADMIN_PAGE_NAME, GET_ADMIN_PAGE, GET_ADMIN_PAGE_BY_ID, GET_ADMIN_PAGE_BY_ID_SUCCESS, GET_ADMIN_PAGE_ERROR, GET_ADMIN_PAGE_SUCCESS } from "../pageTypes"

interface IadminPageState {
  pages: IAdminPage,
  loading: boolean,
  pageError: boolean,
  pageContent: IAdminPageContent,
  pageName:boolean
}


const initialState: IadminPageState = {
  pages: {} as IAdminPage,
  loading: false,
  pageError: false,
  pageContent: {} as IAdminPageContent,
  pageName:true

}

const adminPagesReducer = (state = initialState, action: AnyAction): IadminPageState => {
  switch (action.type) {
    case GET_ADMIN_PAGE:
      return { ...state, pages: {} as IAdminPage, loading: true, }
    case GET_ADMIN_PAGE_SUCCESS:
      return { ...state, pages: action.pages, loading: false }
    case GET_ADMIN_PAGE_ERROR:
      return { ...state, pageError: true }
    case GET_ADMIN_PAGE_BY_ID:
      return { ...state, pageContent: {} as IAdminPageContent }
    case GET_ADMIN_PAGE_BY_ID_SUCCESS:
      return { ...state, pageContent: action.page }
    case CHECK_ADMIN_PAGE_NAME:
      return { ...state, pageName: action.pageName }
    default:
      return state
  }

}

export default adminPagesReducer