export const GET_REPORTS: string = 'GET_REPORTS'
export const GET_REPORTS_SUCCESS: string = 'GET_REPORTS_SUCCESS'
export const GET_REPORTS_ERROR: string = 'GET_REPORTS_ERROR'
export const GET_REPORT_BY_ID: string = 'GET_REPORT_BY_ID'

export const CREATE_REPORTS: string = 'CREATE_REPORTS'
export const UPDATE_REPORTS: string = 'UPDATE_REPORTS'

export const DELETE_REPORTS: string = 'DELETE_REPORTS'
// =========================================================================

export const GET_REPORTS_CONTRACTORS: string = 'GET_REPORTS_CONTRACTORS'
export const GET_REPORTS_CONTRACTORS_SUCCESS: string = 'GET_REPORTS_CONTRACTORS_SUCCESS'
export const GET_REPORTS_CONTRACTORS_ERROR: string = 'GET_REPORTS_CONTRACTORS_ERROR'

export const RESEND_REPORT: string = 'RESEND_REPORT';
export const RESEND_REPORT_REQUEST: string = 'RESEND_REPORT_REQUEST'

// /====================================CREATE
export const CREATE_REPORT_REQUEST: string = 'CREATE_REPORT_REQUEST';
export const CREATE_REPORT_SUCCESS: string = 'CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_ERROR: string = 'CREATE_REPORT_ERROR';
// /====================================ANNULATE
export const ANNULATE_REPORT_REQUEST: string = 'ANNULATE_REPORT_REQUEST';
export const ANNULATE_REPORT_SUCCESS: string = 'ANNULATE_REPORT_SUCCESS';
export const ANNULATE_REPORT_ERROR: string = 'ANNULATE_REPORT_ERROR';

/////////////REPORT TYPES
export const GET_REPORT_TYPES: string = 'GET_REPORT_TYPES';
export const GET_REPORT_TYPES_SUCCESS: string = 'GET_REPORT_TYPES_SUCCESS';
export const GET_REPORT_TYPES_ERROR: string = 'GET_REPORT_TYPES_ERROR';

//============================================LEGALS (Conteractors is old name)

/////////LEGALS BY PARENT ID
export const GET_LEGALS_PARENT: string = 'GET_LEGALS_PARENT';
export const GET_LEGALS_PARENT_SUCCESS: string = 'GET_LEGALS_PARENT_SUCCESS'
export const GET_LEGALS_PARENT_ERROR: string = 'GET_LEGALS_PARENT_ERROR'

/////////LEGALS BY ID
export const GET_LEGAL_BY_ID: string = 'GET_LEGAL_BY_ID';
export const GET_LEGAL_BY_ID_SUCCESS: string = 'GET_LEGAL_BY_ID_SUCCESS';
export const GET_LEGAL_BY_ID_ERROR: string = 'GET_LEGAL_BY_ID_ERROR';

/////////LEGALS BY ID
export const CREATE_MULTIPLE_REPORTS: string = 'CREATE_MULTIPLE_REPORTS';

export const REPORTS_LOADING: string = 'REPORTS_LOADING:';


/////////REPORTS GROUP BY ID
export const GET_REPORTS_GROUP: string = 'GET_REPORTS_GROUP';
export const GET_REPORTS_GROUP_BY_ID: string = 'GET_REPORTS_GROUP_BY_ID';
export const GET_REPORTS_GROUP_SUCCESS: string = 'GET_REPORTS_GROUP_SUCCESS';
export const GET_REPORTS_GROUP_BY_ID_SUCCESS: string = 'GET_REPORTS_GROUP_BY_ID_SUCCESS';

