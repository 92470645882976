import React, { FC } from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

interface BasicToolTipProps {
	title: string
	onClick?: (e: any) => void
}

const BasicToolTip: FC<BasicToolTipProps> = ({ title, children, onClick }) => {
	return (
		<Tooltip
			componentsProps={{
				tooltip: {
					sx: {
						fontSize: '16px',
					},
				},
			}}
			arrow
			title={title}
		>
			<IconButton
				onClick={(e: any) => {
					if (onClick) {
						onClick(e)
					}
				}}
			>
				{children}
			</IconButton>
		</Tooltip>
	)
}

export default BasicToolTip
