import { AnyAction } from 'redux'
import { OfdUsersList, Permission } from '../../models/coomonOFD/FileModel'
import { COMMON_OFD_ERROR, GET_OFD_USERS_SUCCESS, GET_USER_PERMISSIONS_SUCCESS, SET_OFD_LOADING, SET_PROPS_TO_FILTER_OFD_USERS } from '../actionTypes'

type commonState = {
    users: {
        list: OfdUsersList[]
        totalItems: number
        totalPages: number
    }
    propsForUsersFilter: string
    permissions: Permission[]
    loading: boolean
    error: any
}

const initialState: commonState = {
    users: {
        list: [],
        totalItems: 0,
        totalPages: 0
    },
    propsForUsersFilter: '',
    permissions: [],
    loading: false,
    error: null
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case GET_USER_PERMISSIONS_SUCCESS:
            return { ...state, permissions: action.list }
        case COMMON_OFD_ERROR:
            return { ...state, error: action.error }
        case SET_OFD_LOADING:
            return { ...state, loading: action.data }
        case GET_OFD_USERS_SUCCESS:
            return { ...state, users: { list: action.data.list, totalItems: action.data.totalItems, totalPages: action.data.totalPages } }
        case SET_PROPS_TO_FILTER_OFD_USERS:
            return { ...state, propsForUsersFilter: action.props }

        default:
            return state
    }
}
export default reducer