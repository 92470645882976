export const GET_ADMIN_PAGE: string = 'GET_ADMIN_PAGE'
export const GET_ADMIN_PAGE_SUCCESS: string = 'GET_PERSONALS_SUCCESS'
export const GET_ADMIN_PAGE_ERROR: string = 'GET_ADMIN_PAGE_ERROR'

export const CREATE_ADMIN_PAGE: string = 'CREATE_ADMIN_PAGE'
export const UPDATE_ADMIN_PAGE: string = 'UPDATE_ADMIN_PAGE'

export const DELETE_ADMIN_PAGE: string = 'DELETE_ADMIN_PAGE'

export const GET_ADMIN_PAGE_BY_ID: string = 'GET_ADMIN_PAGE_BY_ID'

export const GET_ADMIN_PAGE_BY_ID_SUCCESS: string = 'GET_ADMIN_PAGE_BY_ID_SUCCESS'
export const VALIDATE_ADMIN_PAGE: string = 'VALIDATE_ADMIN_PAGE'
export const CHECK_ADMIN_PAGE_NAME: string = 'CHECK_ADMIN_PAGE_NAME'




