import { Card } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { PersonData } from '../../models/ksmr/ksmrModels'

interface KcmrInfoProps {
	info: PersonData
}

const KcmrInfo = ({ info }: KcmrInfoProps) => {
	return (
		<div className='card mt-5'>
			{/* begin::Header */}
			<div className='card-header p-0 border-0 pt-5'>
				<h3 className='card-title align-items-start flex-column'>
					<span className='card-label fw-bolder fs-3 mb-1'>
						Инормация по идентификации
						{/* {intl.formatMessage({ id: 'TASK.DETAILS' })} */}
					</span>
					{/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span> */}
				</h3>
			</div>
			{/* end::Header
      {/* begin::Body */}
			{/* <div className='card-body'> */}
			{/* begin::Table container */}
			<div className='card-body p-0'>
				{/* =================================Title */}
				<div className='row mb-6'>
					<label className='col-lg-3 col-form-label fw-bold fs-6'>
						{/* {intl.formatMessage({ id: 'TASK.DETAILS.TITLE' })} */}
						ФИО
					</label>
					<div className='col-lg-9 fv-row'>
						<Card variant='outlined'>
							<div style={{ padding: '1rem' }}>
								{/* <span className='align-middle'> */}
								{/* {info.name + ' ' + info.surname 
                      intl.formatMessage({ id: 'TASK.DETAILS.TITLE_DEFAULT' })} */}
								{`${info?.surname} ${info?.name} ${info?.patronymic}`}
								{/* </span> */}
							</div>
						</Card>
					</div>
				</div>

				{/* ===========================Description */}
				<div className='row mb-6'>
					<label className='col-lg-3 col-form-label fw-bold fs-6'>
						{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
						Дата рождения и место рождения
					</label>
					<div className='col-lg-9 fv-row'>
						<Card variant='outlined'>
							<div style={{ padding: '1rem' }}>
								<div className='row mb-6'>
									<label className='col-lg-3 col-form-label fw-bold fs-6'>
										{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
										Год рождения
									</label>
									<div className='col-lg-9 fv-row'>
										<Card variant='outlined'>
											<div style={{ padding: '1rem' }}>
												<p>{moment(info?.birthDate).format('D.MM.yyyy')}г.</p>
											</div>
										</Card>
									</div>
								</div>
								<div className='row mb-6'>
									<label className='col-lg-3 col-form-label fw-bold fs-6'>
										{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
										Страна
									</label>
									<div className='col-lg-9 fv-row'>
										<Card variant='outlined'>
											<div style={{ padding: '1rem' }}>
												{info?.birthPlace?.country?.nameRu}
											</div>
										</Card>
									</div>
								</div>
								<div className='row mb-6'>
									<label className='col-lg-3 col-form-label fw-bold fs-6'>
										{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
										Регион
									</label>
									<div className='col-lg-9 fv-row'>
										<Card variant='outlined'>
											<div style={{ padding: '1rem' }}>
												{info?.birthPlace?.region?.nameRu}
											</div>
										</Card>
									</div>
								</div>
								<div className='row mb-6'>
									<label className='col-lg-3 col-form-label fw-bold fs-6'>
										{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
										Район
									</label>
									<div className='col-lg-9 fv-row'>
										<Card variant='outlined'>
											<div style={{ padding: '1rem' }}>
												{info?.birthPlace?.district?.nameRu}
											</div>
										</Card>
									</div>
								</div>
								<div className='row mb-6'>
									<label className='col-lg-3 col-form-label fw-bold fs-6'>
										{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
										Город
									</label>
									<div className='col-lg-9 fv-row'>
										<Card variant='outlined'>
											<div style={{ padding: '1rem' }}>
												{info?.birthPlace?.city}
											</div>
										</Card>
									</div>
								</div>
							</div>
						</Card>
					</div>
				</div>
				<div className='row mb-6'>
					<label className='col-lg-3 col-form-label fw-bold fs-6'>
						{/* {intl.formatMessage({ id: 'TASK.DETAILS.TITLE' })} */}
						Пол
					</label>
					<div className='col-lg-9 fv-row'>
						<Card variant='outlined'>
							<div style={{ padding: '1rem' }}>
								{/* <span className='align-middle'> */}
								{/* {info.name + ' ' + info.surname 
                      intl.formatMessage({ id: 'TASK.DETAILS.TITLE_DEFAULT' })} */}
								{`${info?.gender?.nameRu}`}
								{/* </span> */}
							</div>
						</Card>
					</div>
				</div>
				<div className='row mb-6'>
					<label className='col-lg-3 col-form-label fw-bold fs-6'>
						{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
						Национальность
					</label>
					<div className='col-lg-9 fv-row'>
						<Card variant='outlined'>
							<div style={{ padding: '1rem' }}>
								{`${info?.nationality?.nameRu}`}
							</div>
						</Card>
					</div>
				</div>
				<div className='row mb-6'>
					<label className='col-lg-3 col-form-label fw-bold fs-6'>
						{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
						Жизненный статус
					</label>
					<div className='col-lg-9 fv-row'>
						<Card variant='outlined'>
							<div style={{ padding: '1rem' }}>
								{`${info?.lifeStatus?.nameRu}`}
							</div>
						</Card>
					</div>
				</div>
				<div className='row mb-6'>
					<label className='col-lg-3 col-form-label fw-bold fs-6'>
						{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
						Адрес регистрации
					</label>
					<div className='col-lg-9 fv-row'>
						<Card variant='outlined'>
							<div style={{ padding: '1rem' }}>
								<div className='row mb-6'>
									<label className='col-lg-3 col-form-label fw-bold fs-6'>
										{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
										Страна
									</label>
									<div className='col-lg-9 fv-row'>
										<Card variant='outlined'>
											<div style={{ padding: '1rem' }}>
												{info?.regAddress?.country?.nameRu}
											</div>
										</Card>
									</div>
								</div>
								<div className='row mb-6'>
									<label className='col-lg-3 col-form-label fw-bold fs-6'>
										{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
										Регион
									</label>
									<div className='col-lg-9 fv-row'>
										<Card variant='outlined'>
											<div style={{ padding: '1rem' }}>
												{info?.regAddress?.region?.nameRu}
											</div>
										</Card>
									</div>
								</div>
								<div className='row mb-6'>
									<label className='col-lg-3 col-form-label fw-bold fs-6'>
										{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
										Район
									</label>
									<div className='col-lg-9 fv-row'>
										<Card variant='outlined'>
											<div style={{ padding: '1rem' }}>
												{info?.regAddress?.district?.nameRu}
											</div>
										</Card>
									</div>
								</div>
								<div className='row mb-6'>
									<label className='col-lg-3 col-form-label fw-bold fs-6'>
										{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
										Улица
									</label>
									<div className='col-lg-9 fv-row'>
										<Card variant='outlined'>
											<div style={{ padding: '1rem' }}>
												ул.{info?.regAddress?.street}, кв.
												{info?.regAddress?.flat}, {info?.regAddress?.building}
											</div>
										</Card>
									</div>
								</div>
							</div>
						</Card>
					</div>
				</div>
				<div className='row mb-6'>
					<label className='col-lg-3 col-form-label fw-bold fs-6'>
						{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
						Документ
					</label>
					<div className='col-lg-9 fv-row'>
						<Card variant='outlined'>
							<div style={{ padding: '1rem' }}>
								{info?.documents?.map(data => {
									return (
										<div key={data.name}>
											<div className='row mb-6'>
												<label className='col-lg-3 col-form-label fw-bold fs-6'>
													{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
													Номер
												</label>
												<div className='col-lg-9 fv-row'>
													<Card variant='outlined'>
														<div style={{ padding: '1rem' }}>
															{data?.number}
														</div>
													</Card>
												</div>
											</div>
											<div className='row mb-6'>
												<label className='col-lg-3 col-form-label fw-bold fs-6'>
													{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
													Дата выдачи
												</label>
												<div className='col-lg-9 fv-row'>
													<Card variant='outlined'>
														<div style={{ padding: '1rem' }}>
															{moment(data?.beginDate).format('D.MM.yyyy')}
														</div>
													</Card>
												</div>
											</div>
											<div className='row mb-6'>
												<label className='col-lg-3 col-form-label fw-bold fs-6'>
													{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
													Срок действия
												</label>
												<div className='col-lg-9 fv-row'>
													<Card variant='outlined'>
														<div style={{ padding: '1rem' }}>
															{moment(data?.endDate).format('D.MM.yyyy')}
														</div>
													</Card>
												</div>
											</div>
											<div className='row mb-6'>
												<label className='col-lg-3 col-form-label fw-bold fs-6'>
													{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
													Тип паспорта
												</label>
												<div className='col-lg-9 fv-row'>
													<Card variant='outlined'>
														<div style={{ padding: '1rem' }}>
															{data?.type?.nameRu}
														</div>
													</Card>
												</div>
											</div>
											<div className='row mb-6'>
												<label className='col-lg-3 col-form-label fw-bold fs-6'>
													{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
													Орган выдачи
												</label>
												<div className='col-lg-9 fv-row'>
													<Card variant='outlined'>
														<div style={{ padding: '1rem' }}>
															{data?.issueOrganization?.nameRu}
														</div>
													</Card>
												</div>
											</div>
											<div className='row mb-6'>
												<label className='col-lg-3 col-form-label fw-bold fs-6'>
													{/* {intl.formatMessage({ id: 'TASK.DETAILS.DESCRIPTION' })} */}
													Статус
												</label>
												<div className='col-lg-9 fv-row'>
													<Card variant='outlined'>
														<div style={{ padding: '1rem' }}>
															{data?.status?.nameRu}
														</div>
													</Card>
												</div>
											</div>
										</div>
									)
								})}
							</div>
						</Card>
					</div>
				</div>
			</div>
			{/* </div> */}
		</div>
	)
}

export default KcmrInfo
