import { FC, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { KTSVG } from '../../components/helpers/KTSVG'
import { RootState } from '../../setup'
import {
	createMultipleReport,
	getLegalsParent,
	getReportsTypes,
} from '../../store/actions/reportsActions'

import axios from '../../setup/axios/axios-config'
import moment from 'moment'
import AdapterDateFns from '@mui/lab/AdapterMoment'
import DeleteIcon from '@mui/icons-material/Delete'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import {
	Form,
	Formik,
	FormikProps,
	FormikValues,
} from 'formik'
import { array, object, string, number } from 'yup'
import { MultipleFileUploadField } from './uploaderFile/MultipleFileUploadField'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { IconButton, TextField, MenuItem } from '@mui/material'
import 'moment/locale/ru'
import 'moment/locale/kk'
import { useLang } from '../../components/i18n/Metronici18n'

interface ReportModalProps {
	show: boolean
	handleClose: () => void
}

const ReportModalListlUpload: FC<ReportModalProps> = ({
	show,
	handleClose,
}) => {
	// =================================HOOOKS
	const intl = useIntl()
	const dispatch = useDispatch()
	const locale = useLang()
	const { contractors, legalByParentId, reportTypes }: any = useSelector<
		RootState,
		any
	>(state => state.reports, shallowEqual)
	// =================================STATES
	const [report, setReport] = useState<any>({
		legalEmail: '',
		parentLegalEmail: '',
		legalId: 0,
		reportTypeId: 0,
		startDate: '',
		// endDate: '',
		file: [],
	})

	const [legalParentId, setLegalParentId] = useState<number>()
	const [legalId, setLegalId] = useState<number>()
	const [dateError, setDateError] = useState<boolean>()
	const [openPicker, setOpenPicker] = useState<boolean>(false)
	const [fileErr, setFileErr] = useState<boolean>(false)
	const [groupId, setGroupId] = useState<any>('')

	//==================================EFFECTS
	useEffect(() => {
		if (show) {
			axios.get('/api/reports/groupId').then(data => {
				setGroupId(data.data)
			})
		}
	}, [show])
	useEffect(() => {
		dispatch(getReportsTypes())
	}, [])

	useEffect(() => {
		if (legalParentId) {
			dispatch(getLegalsParent(legalParentId))
		}
	}, [legalParentId])

	useEffect(() => {
		if (legalByParentId) {
			setReport((prev: any) => ({
				...prev,
				parentLegalEmail: legalByParentId?.email,
			}))
		}
	}, [legalByParentId])

	const handleCloseCreateModal = () => {
		handleClose()
		setLegalId(undefined)
		setLegalParentId(undefined)
		setReport({
			legalEmail: '',
			parentLegalEmail: '',
			legalId: 0,
			reportTypeId: 0,
			startDate: '',
			endDate: '',
			file: [],
		})
	}

	return (
		<Modal
			show={show}
			onHide={handleCloseCreateModal}
			size='lg'
			enforceFocus={false}
			dialogClassName='modal-dialog-centered mw-1000px h-auto'
		>
			<div className='modal-content'>
				<Formik
					enableReinitialize={true}
					initialValues={{
						files: [],
						startDate: '',
						endDate: '',
						reportTypeId: 0,
					}}
					validationSchema={object({
						files: array(),
						startDate: string().required(),
						// endDate: string(),
						reportTypeId: number().min(1).required(),
						email: string().email('Invalid email'),
					})}
					onSubmit={values => {
						let legalInfo
						legalInfo = values.files.map((item: any) => {
							return {
								...item.url.legal,
								fileName: item.file.name,
								email: item.url?.legal?.email,
							}
						})

						const reportResendData = {
							reportTypeId: values.reportTypeId,
							year: moment(values.startDate).get('year'),
							month: moment(values.startDate).get('month') + 1,
							filesInfo: legalInfo,
							files: values.files,
							groupId,
						}

						dispatch(createMultipleReport(reportResendData))
						handleClose()
					}}
				>
					{({
						values,
						errors,
						isValid,
						isSubmitting,
						touched,
						setFieldValue,
						handleChange,
					}: FormikProps<FormikValues>) => (
						<Form>
							<div className='modal-header'>
								<h2>{intl.formatMessage({ id: 'TASK.DETAILS' })}</h2>
								<div
									className='btn btn-sm btn-icon btn-active-color-primary'
									onClick={handleCloseCreateModal}
								>
									<KTSVG
										path='/media/icons/duotune/arrows/arr061.svg'
										className='svg-icon-1'
									/>
								</div>
							</div>

							<div className='modal-body py-lg-10 px-lg-10'>
								<div className='card-body '>
									{/* <Box component='form' autoComplete='off' onSubmit={handleSubmit}> */}
									<div className='row mb-6 align-items-center'>
										<label className='col-lg-4 col-form-label fw-bold fs-6'>
											{/* {intl.formatMessage({ id: 'REPORT_CREATE_TYPES' })}{' '}
											 */}
											{/* <i className='text-danger'>*</i> */}
											Номер
										</label>
										<div className='col-lg-8 fv-row'>
											<div style={{ padding: '1rem' }}>{groupId}</div>
										</div>
									</div>
									<div className='row mb-6 align-items-center'>
										<label className='col-lg-4 col-form-label fw-bold fs-6'>
											{intl.formatMessage({ id: 'REPORT_CREATE_TYPES' })}{' '}
											<i className='text-danger'>*</i>
										</label>
										<div className='col-lg-8 fv-row'>
											<div style={{ padding: '1rem' }}>
												<TextField
													fullWidth
													select
													InputLabelProps={{
														shrink: Boolean(values.reportTypeId) && true,
													}}
													name='reportTypeId'
													label={intl.formatMessage({
														id: 'REPORT_CREATE_TYPES',
													})}
													value={values.reportTypeId}
													onChange={handleChange}
													error={!!errors.reportTypeId}
												>
													<MenuItem value=''>
														<em>Оставить пустым</em>
													</MenuItem>
													{reportTypes?.map((type: any) => {
														return (
															<MenuItem key={type.id} value={type.id}>
																{type.title}
															</MenuItem>
														)
													})}
												</TextField>
											</div>
										</div>
									</div>

									<div className='row mb-6 align-items-center'>
										<label className='col-lg-4 col-form-label fw-bold fs-6'>
											{intl.formatMessage({ id: 'REPORT_CREATE_DATE' })}
										</label>
										<div className='col-lg-8 fv-row'>
											<div style={{ padding: '1rem' }}>
												<LocalizationProvider
													dateAdapter={AdapterDateFns}
													locale={locale === 'kz' ? 'kk' : locale}
												>
													<DatePicker
														views={['month', 'year']}
														label={intl.formatMessage({
															id: 'REPORT_CREATE_DATE',
														})}
														open={openPicker}
														onOpen={() => setOpenPicker(true)}
														onClose={() => setOpenPicker(false)}
														inputFormat='YYYY-MM-DD'
														// minDate={moment(
														// 	moment().format('YYYY'),
														// 	'YYYY-MM-DD'
														// )}
														maxDate={moment(
															moment().format('YYYY'),
															'YYYY-MM-DD'
														)
															.add(1, 'y')
															.subtract(1, 'M')}
														value={values.startDate ? values.startDate : null}
														onChange={newValue => {
															setFieldValue(
																'startDate',
																newValue
															)
														}}
														renderInput={params => (
															<TextField
																{...params}
																error={!!errors.startDate}
																InputLabelProps={{
																	shrink: Boolean(values.startDate) && true,
																}}
																inputProps={{
																	value: values.startDate
																		? moment(values.startDate).format(
																				'MMMM - YYYY'
																		  )
																		: '',
																}}
																InputProps={{
																	endAdornment: (
																		<>
																			<IconButton
																				onClick={() =>
																					setFieldValue('startDate', '')
																				}
																			>
																				<DeleteIcon />
																			</IconButton>
																			<IconButton
																				onClick={() => setOpenPicker(true)}
																			>
																				<CalendarTodayIcon />
																			</IconButton>
																		</>
																	),
																}}
																fullWidth
																helperText={null}
															/>
														)}
													/>
												</LocalizationProvider>
											</div>
										</div>
									</div>

									<div className='row mb-6 align-items-start'>
										<label className='col-lg-4 col-form-label fw-bold fs-6'>
											Файлы:
										</label>

										<div className='col-lg-8 fv-row'>
											<div style={{ padding: '1rem' }}>
												<MultipleFileUploadField
													setFileErr={setFileErr}
													name='files'
												/>
											</div>

											{/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
										</div>
									</div>
									<div className='row mb-6 align-items-center'>
										{/* <button type='submit' className='btn btn-success' disabled={!isValid || isSubmitting}>
											Подтвердить
										</button> */}
										<Button
											variant='success'
											color='primary'
											disabled={!fileErr || !isValid}
											type='submit'
										>
											Подтвердить
										</Button>
									</div>
									{/* </Box> */}
								</div>
							</div>
						</Form>
					)}
				</Formik>
				<div className='modal-footer d-flex'>
					<button
						type='submit'
						className='ml-auto btn btn-secondary ms-auto'
						onClick={handleCloseCreateModal}
					>
						{intl.formatMessage({ id: 'TEXT.CLOSE' })}
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default ReportModalListlUpload
