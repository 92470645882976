import axios from '../../setup/axios/axios-config'
import { takeLatest, put, call } from 'redux-saga/effects';
import { getTaskHelpErrorAction, getTaskHelpSuccessAction } from '../actions/taskhelpAction';
import { IHelp } from '../../models/tasks/tasksModel';
import { DELETE_TASK_HELP, GET_TASK_HELP, GREATE_TASK_HELP, UPDATE_TASK_HELP } from '../taskHelpTypes';
import { AnyAction } from 'redux';
import { remove } from 'lodash';
import Swal from 'sweetalert2';


const taskHelpApi = (page: any) => axios.get(`/api/help/search?page=${page.page}`).then(res => {
  return res.data
})
const createHelpApi = (help: any) => axios.post('/api/help', help).then(res => res.data)



const updateHelpApi = (help: any) => axios.put('/api/help/' + (+help.id), { ...help, id: remove }).then(res => res.data)

const deleteHelpApi = (id: any) => axios.delete('/api/help/' + (+id)).then(res => res.data)

function* tasHelp(action: AnyAction) {

  try {
    const help: IHelp = yield call(taskHelpApi, action.helpPage)
    yield put(getTaskHelpSuccessAction(help))
  } catch (e) {
    yield put(getTaskHelpErrorAction(e))
  }
}

function* createHelp(action: AnyAction) {

  try {
    yield call(createHelpApi, action.help.help)
    const help: IHelp = yield call(taskHelpApi, action.help.meta)
    // Swal.fire({
    //   position: 'center',
    //   icon: 'success',
    //   title: 'New member created',
    //   showConfirmButton: false,
    //   timer: 1500
    // })
    yield put(getTaskHelpSuccessAction(help))
  } catch (e) {
    yield put(getTaskHelpErrorAction(true))
    // Swal.fire({
    //   icon: 'error',
    //   title: 'Oops...',
    //   text: 'Something went wrong!',
    // })
  }
}

function* deleteHelp(action: AnyAction) {

  try {
    yield call(deleteHelpApi, action.help.id)
    const help: IHelp = yield call(taskHelpApi, action.help.meta)
    yield put(getTaskHelpSuccessAction(help))
  } catch (e) {
    yield put(getTaskHelpErrorAction(e))
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
    })
  }
}
function* updateHelp(action: AnyAction) {

  try {
    if (action.help.help.parentId === 0) delete action.help.help.parentId
    yield call(updateHelpApi, action.help.help)
    const help: IHelp = yield call(taskHelpApi, action.help.meta)
    yield put(getTaskHelpSuccessAction(help))
    // Swal.fire({
    //   position: 'center',
    //   icon: 'success',
    //   title: 'Updated',
    //   showConfirmButton: false,
    //   timer: 1500
    // })
  } catch (e) {
    yield put(getTaskHelpErrorAction(e))
    // Swal.fire({
    //   icon: 'error',
    //   title: 'Oops...',
    //   text: 'Something went wrong!',
    // })
  }
}


function* taskHelpWatcher() {
  yield takeLatest(GET_TASK_HELP, tasHelp)
  yield takeLatest(GREATE_TASK_HELP, createHelp)
  yield takeLatest(DELETE_TASK_HELP, deleteHelp)
  yield takeLatest(UPDATE_TASK_HELP, updateHelp)
}
export default taskHelpWatcher