export const GET_STATUSES: string = 'GET_STATUSES'
export const GET_STATUSES_SUCCESS: string = 'GET_STATUSES_SUCCESS'
export const GET_STATUSES_ERROR: string = 'GET_STATUSES_ERROR'

//////////////////////

export const CREATE_STATUS = 'CREATE_STATUS'
export const CREATE_STATUS_SUCCESS = 'CREATE_STATUS_SUCCESS';
//////////////////////

export const DELETE_STATUS = 'DELETE_STATUS'

//////////////////////
export const UPDATE_STATUS = 'UPDATE_STATUS'


//////////////////////
export const GET_STATUS_BY_ID_REQUEST = 'GET_STATUS_BY_ID_REQUEST'
export const GET_STATUS_BY_ID_SUCCESS = 'GET_STATUS_BY_ID_SUCCESS'
