import { FC } from 'react'
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { calculatePage } from '../../../components/helpers/herpers'
import { IMessageFilter } from './ChatReply'
import { keyframes } from '@mui/system'
import { useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { useHistory } from 'react-router-dom'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

interface IChatReplyToolbarProps {
	totalElements: number
	numberOfElements: number
	page: number
	totalPages: number
	setMessageFilter: (e: any) => void
	messageFilter: IMessageFilter
}

const ChatReplyToolbar: FC<IChatReplyToolbarProps> = ({
	totalElements,
	numberOfElements,
	page,
	totalPages,
	setMessageFilter,
	messageFilter,
}) => {
	const correspondenceMessagesLoading: boolean = useSelector<
		RootState,
		boolean
	>(state => state.correspondence.correspondenceMessagesLoading)
	const history = useHistory()

	const handleReturn = () => {
		history.goBack()
	}

	const handleRefreshPage = () => {
		setMessageFilter({ ...messageFilter, page: 0 })
	}

	const handlePageLeft = () => {
		if (messageFilter.page > 0) {
			setMessageFilter((prev: IMessageFilter) => ({
				...messageFilter,
				page: prev.page - 1,
			}))
		}
	}

	const handlePageRight = () => {
		if (messageFilter.page < totalPages - 1) {
			setMessageFilter((prev: IMessageFilter) => ({
				...messageFilter,
				page: prev.page + 1,
			}))
		}
	}

	return (
		<Box sx={{ px: 5, py: 3 }}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				{/* //=============================== LEFT TOOL BAR */}
				<Stack direction='row' spacing={1} sx={{ mt: 2 }}>
					{/* <IconButton aria-label='down'>
						<KeyboardArrowDownOutlinedIcon />
					</IconButton> */}

					<IconButton onClick={handleReturn} color='primary'>
						<KeyboardBackspaceIcon />
					</IconButton>
					<IconButton
						onClick={handleRefreshPage}
						sx={{
							animation: correspondenceMessagesLoading
								? `${spin} 1s infinite ease`
								: '',
						}}
						color='primary'
					>
						<AutorenewOutlinedIcon />
					</IconButton>
					{/* <IconButton color='secondary' aria-label='add an alarm'>
						<ArchiveOutlinedIcon />
					</IconButton>
					<IconButton color='primary' aria-label='add to shopping cart'>
						<DeleteIcon />
					</IconButton> */}
					{/* <IconButton color='primary' aria-label='add to shopping cart'>
						<MarkChatReadOutlinedIcon />
					</IconButton>
					<IconButton color='primary' aria-label='add to shopping cart'>
						<DriveFileMoveOutlinedIcon />
					</IconButton>  */}
				</Stack>

				{/* //=============================== RIGHT TOOL BAR */}
				<Stack direction='row' alignItems='center' spacing={1} sx={{ mt: 2 }}>
					<Typography component='span' align='center'>
						{
							calculatePage(totalElements, numberOfElements, page, totalPages)
								.pageStart
						}{' '}
						-
						{
							calculatePage(totalElements, numberOfElements, page, totalPages)
								.pageEnd
						}{' '}
						из {totalElements}
					</Typography>
					<IconButton
						onClick={handlePageLeft}
						color='primary'
						aria-label='add to shopping cart'
					>
						<ChevronLeftOutlinedIcon />
					</IconButton>
					<IconButton
						onClick={handlePageRight}
						color='primary'
						aria-label='add to shopping cart'
					>
						<ChevronRightOutlinedIcon />
					</IconButton>
					{/* <IconButton color='primary' aria-label='add to shopping cart'>
						<SettingsOutlinedIcon />
					</IconButton> */}
				</Stack>
			</Box>
		</Box>
	)
}

export default ChatReplyToolbar
