import { useCallback } from 'react'
import { ITasks } from '../../models/tasks/tasksModel'
import moment from 'moment'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Button, Paper, Typography } from '@mui/material'
import TaskDetailsDrawer from './TaskDetailsDrawer'
import Highlighter from '../../components/helpers/Highlighter'
import { getUserAction } from '../../store/actions/authActions'
import { IUserModel } from '../../models/personal/paginationCustom'
import { RootState } from '../../setup'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

interface taskProps {
	task: ITasks
	handleUpdateDrawer: (id: number | undefined) => void
	filter: any
}

const Task = ({ task, filter, handleUpdateDrawer }: taskProps) => {
	//==========================================HOOKS

	const intl = useIntl()
	const dispatch = useDispatch()
	const test = 'we'
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.up('sm'))
	//==========================================STATES

	const [showDetailsDrawer, setShowDetailsDrawer] = useState(false)

	const user: IUserModel = useSelector<RootState, IUserModel>(
		state => state.auth.user,
		shallowEqual
	)
	//==========================================EFFECTS

	useEffect(() => {
		if (showDetailsDrawer) {
			dispatch(getUserAction())
		}
	}, [showDetailsDrawer])
	//==========================================FUNCTIONS

	const handleShowDetailsDrawer = useCallback(() => {
		setShowDetailsDrawer(true)
	}, [])

	const handleCloseDetailsDrawer = useCallback(() => {
		setShowDetailsDrawer(false)
	}, [])

	const showDateTime = (start: any, end: any) => {
		if (start && end) {
			if (
				moment(task.start).format('HH:mm') === moment(task.end).format('HH:mm')
			) {
				return (
					<span className='d-block'>{moment(task.start).format('HH:mm')}</span>
				)
			} else {
				return (
					<span className='d-block'>
						{moment(task.start).format('HH:mm') +
							' - ' +
							moment(task.end).format('HH:mm')}
					</span>
				)
			}
		} else if (!end) {
			return (
				<span className='d-block'>
					{moment(task.start).format('HH:mm') +
						' - ' +
						intl.formatMessage({ id: 'TASK.DEFAULT.DATE' })}
				</span>
			)
		}
		return
	}
	return (
		<>
			{/* ===============================DAY */}
			{matches ? (
				<Paper
					elevation={3}
					sx={{
						px: 2,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Box>#{task.id}</Box>

					{/* //==================================TITLE */}
					<Box sx={{ p: 3, flexGrow: 1 }}>
						<Typography
							variant={'caption'}
							sx={{ fontSize: '14px', fontWeight: 600 }}
						>
							{Highlighter(task.title, filter.request)}
						</Typography>
					</Box>
					<Box sx={{ p: 1 }}>
						<Typography
							variant={'caption'}
							sx={{ fontSize: '14px', fontWeight: 600 }}
						>
							{task.assignee?.fullName ? (
								<span>
									<i>Исполнитель</i> <span>{task.assignee?.fullName}</span>{' '}
								</span>
							) : (
								'Нет исполнителя'
							)}
							{/* {<i>Исполнитель</i> <span>{task.assignee?.fullName}</span>  || } */}
						</Typography>
					</Box>
					<Box sx={{ p: 1 }}>
						<Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
							{task.start
								? moment(task.start).format('DD MMMM YYYY, hh:mm a')
								: intl.formatMessage({ id: 'TASK.DEFAULT.DATE' })}
						</Typography>
					</Box>
					{/* //===================================EPIC */}

					{task?.tags?.map(tag => (
						<Box key={tag.id} sx={{ ml: 1 }}>
							<Button variant='outlined' size='small'>
								{tag.name}
							</Button>
						</Box>
					))}

					{/* //==================================EDIT */}

					{task?.editable ? (
						<Box sx={{ p: 1 }}>
							<Button
								onClick={() => handleUpdateDrawer(task.id)}
								variant='outlined'
								size='small'
							>
								{intl.formatMessage({ id: 'TASK.EDIT' })}
							</Button>
						</Box>
					) : null}
					{/* //==================================DETAILS */}
					<Box sx={{ p: 1 }}>
						<Button
							onClick={handleShowDetailsDrawer}
							color='secondary'
							size='small'
							variant='outlined'
						>
							{intl.formatMessage({ id: 'TASK.DETAILS' })}
						</Button>
					</Box>
				</Paper>
			) : (
				<Paper
					elevation={3}
					sx={{
						px: 2,
						py: 3,
						display: 'flex',
						flexDirection: 'column',
						// justifyContent: 'space-around',
						gap: '5px',
						alignItems: 'center',
					}}
				>
					<Box>#{task.id}</Box>

					{/* //==================================TITLE */}
					<Typography
						variant={'caption'}
						sx={{ fontSize: '14px', fontWeight: 600, margin: 0 }}
					>
						{Highlighter(task.title, filter.request)}
					</Typography>
					<Typography
						variant={'caption'}
						sx={{ fontSize: '14px', fontWeight: 600 }}
					>
						{task.assignee?.fullName ? (
							<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
								<i>Исполнитель</i> <span>{task.assignee?.fullName}</span>{' '}
							</Box>
						) : (
							'Нет исполнителя'
						)}
						{/* {<i>Исполнитель</i> <span>{task.assignee?.fullName}</span>  || } */}
					</Typography>
					<Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
						{task.start
							? moment(task.start).format('DD MMMM YYYY, hh:mm a')
							: intl.formatMessage({ id: 'TASK.DEFAULT.DATE' })}
					</Typography>
					{/* //===================================EPIC */}

					{task?.tags?.map(tag => (
						<Box key={tag.id}>
							<Button variant='outlined' size='small'>
								{tag.name}
							</Button>
						</Box>
					))}

					{/* //==================================EDIT */}

					{task?.editable ? (
						<Button
							onClick={() => handleUpdateDrawer(task.id)}
							variant='outlined'
							size='small'
						>
							{intl.formatMessage({ id: 'TASK.EDIT' })}
						</Button>
					) : null}
					{/* //==================================DETAILS */}
					<Button
						onClick={handleShowDetailsDrawer}
						color='secondary'
						size='small'
						variant='outlined'
					>
						{intl.formatMessage({ id: 'TASK.DETAILS' })}
					</Button>
				</Paper>
			)}

			<TaskDetailsDrawer
				data={task}
				show={showDetailsDrawer}
				setShow={setShowDetailsDrawer}
				handleClose={handleCloseDetailsDrawer}
				user={user}
			/>
		</>
	)
}

export default Task
