import {
    ActivityClassifier,
    ConfirmUserForOrg,
    EditOrganization,
    EditOrganizationReqest,
    GetOrganizationList,
    NewOrganization,
    TaxationType,
    Organization
} from "../../models/organization/FileModel";
import {
    CONFIRM_USER_FOR_ORGANIZATION,
    CONFIRM_USER_FOR_ORGANIZATION_MESSAGE,
    CREATE_NEW_LINK_FOR_CONFIRMATION,
    CREATE_ORGANIZATION_REQUEST,
    EDIT_ORGANIZATION_REQUEST,
    EDIT_ORGANIZATION_SUCCESS,
    GET_ACTIVITY_CLASSIFIER_REQUEST,
    GET_ACTIVITY_CLASSIFIER_SUCCESS,
    GET_ONE_ORGANIZATION_REQUEST,
    GET_ONE_ORGANIZATION_SUCCESS,
    GET_ORGANIZATION_LIST_REQUEST,
    GET_ORGANIZATION_LIST_SUCCESS,
    GET_TAXATION_TYPE_REQUEST,
    GET_TAXATION_TYPE_SUCCESS,
    ORGANIZATION_ERROR,
    SET_PROPS_TO_FILTER_ORGANIZATION
} from "../actionTypes";

export const errorOrganization = (error: any) => ({ type: ORGANIZATION_ERROR, error })
export const getOrganizationListRequest = (props: string) => ({ type: GET_ORGANIZATION_LIST_REQUEST, props })
export const getOrganizationListSuccess = (data: GetOrganizationList) => ({ type: GET_ORGANIZATION_LIST_SUCCESS, data })

export const getActivityClassifierRequest = () => ({ type: GET_ACTIVITY_CLASSIFIER_REQUEST })
export const getActivityClassifierSuccess = (list: ActivityClassifier[]) => ({ type: GET_ACTIVITY_CLASSIFIER_SUCCESS, list })

export const getTaxationTypeRequest = () => ({ type: GET_TAXATION_TYPE_REQUEST })
export const getTaxationTypeSuccess = (list: TaxationType[]) => ({ type: GET_TAXATION_TYPE_SUCCESS, list })

export const createOrganizationRequest = (data: NewOrganization) => ({ type: CREATE_ORGANIZATION_REQUEST, data })

export const getOneOrganizationRequest = (id: number) => ({ type: GET_ONE_ORGANIZATION_REQUEST, id })
export const getOneOrganizationSuccess = (data: EditOrganization) => ({ type: GET_ONE_ORGANIZATION_SUCCESS, data })

export const confirmUserForOrganizationRequest = (data: ConfirmUserForOrg) => ({ type: CONFIRM_USER_FOR_ORGANIZATION, data })
export const confirmUserForOrganizationSuccess = (mess: string) => ({ type: CONFIRM_USER_FOR_ORGANIZATION_MESSAGE, mess })
export const createNewLinkForConfirmation = (login: string) => ({ type: CREATE_NEW_LINK_FOR_CONFIRMATION, login })

export const editOrganizationRequest = (data: EditOrganizationReqest, props: string) => ({ type: EDIT_ORGANIZATION_REQUEST, data, props })
export const editOrganizationSuccess = (data: Organization) => ({ type: EDIT_ORGANIZATION_SUCCESS, data })

export const setPropsToFilterOrganization = (props: string) => ({ type: SET_PROPS_TO_FILTER_ORGANIZATION, props })