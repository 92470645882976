import { FC, useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../components/helpers/KTSVG'
import { createTaskHelpAction } from '../../store/actions/taskhelpAction'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'

const SignupSchema = Yup.object().shape({
	code: Yup.string().required('code is required'),
	description: Yup.string().required('description required'),
	title: Yup.mixed().required('title required'),
	response: Yup.mixed().required('response required'),
})

export type Props = {
	show: boolean
	handleClose: () => void
	page: any
	parentId: number | null
}

const HelpModalAdd: FC<Props> = ({ show, handleClose, page, parentId }) => {
	const intl = useIntl()

	const inits = {
		code: '',
		description: '',
		parentId: parentId,
		response: '',
		title: '',
	}

	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)

	const handleSubmit = (values: any) => {
		const newTask = {
			code: values.code,
			title: values.title,
			description: values.description,
			parentId: values.parentId,
			response: values.response,
		}
		try {
			dispatch(createTaskHelpAction({ help: newTask, meta: page }))
			Swal.fire({
				position: 'center',
				icon: 'success',
				title: intl.formatMessage({
					id: 'BOT_HELP_ADD_SUCCESS',
				}),
				showConfirmButton: false,
				timer: 1500,
			})
		} catch (e) {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: intl.formatMessage({
					id: 'BOT_HELP_ADD_ERROR',
				}),
			})
		}

		handleClose()
	}

	return (
		<Modal
			aria-hidden='true'
			tabIndex='-1'
			show={show}
			onHide={handleClose}
			size='lg'
			centered
		>
			{/* ========================================================FORM */}
			<Formik
				enableReinitialize={true}
				initialValues={inits}
				onSubmit={handleSubmit}
			>
				{props => (
					<Form>
						<div className='modal-content'>
							<div className='modal-header'>
								<h2>{intl.formatMessage({ id: 'HELP.BOT.NEW' })}</h2>
								<div
									className='btn btn-sm btn-icon btn-active-color-primary'
									onClick={handleClose}
								>
									<KTSVG
										path='/media/icons/duotune/arrows/arr061.svg'
										className='svg-icon-1'
									/>
								</div>
							</div>

							<div className='modal-body py-lg-10 px-lg-10'>
								<div className='card-body p-9'>
									{/* =================================CODE */}
									{/* <div className='row mb-6'>
										<label className='col-lg-4 col-form-label fw-bold fs-6'>
											{intl.formatMessage({ id: 'TEXT.CODE' })}
										</label>
										<div className='col-lg-8 fv-row'>
											<input
												name='code'
												type='text'
												className='form-control form-control-lg form-control-solid'
												placeholder={intl.formatMessage({
													id: 'HELP.BOT.UNIQUE',
												})}
												onChange={props.handleChange}
												value={props.values.code}
											/>
											{props.touched.code && props.errors.code && (
												<div className='fv-plugins-message-container danger'>
													<div className='fv-help-block'>
														{props.errors.code}
													</div>
												</div>
											)}
										</div>
									</div> */}
									{/* ===========================TITLE */}
									<div className='row mb-6'>
										<label className='col-lg-4 col-form-label fw-bold fs-6'>
											{intl.formatMessage({ id: 'TASK.MODAL.TITLE' })}
										</label>
										<div className='col-lg-8 fv-row'>
											<input
												name='title'
												type='text'
												className='form-control form-control-lg form-control-solid'
												placeholder={intl.formatMessage({
													id: 'TASK.MODAL.TITLE',
												})}
												onChange={props.handleChange}
												value={props.values.title}
											/>
											{props.touched.title && props.errors.title && (
												<div className='fv-plugins-message-container danger'>
													<div className='fv-help-block'>
														{props.errors.title}
													</div>
												</div>
											)}
										</div>
									</div>
									{/* ===========================DESCRIPTION */}
									<div className='row mb-6'>
										<label className='col-lg-4 col-form-label fw-bold fs-6'>
											{intl.formatMessage({ id: 'TASK.MODAL.DESCRIPTION' })}
										</label>
										<div className='col-lg-8 fv-row'>
											<input
												name='description'
												type='text'
												className='form-control form-control-lg form-control-solid'
												placeholder={intl.formatMessage({
													id: 'TASK.MODAL.DESCRIPTION',
												})}
												onChange={props.handleChange}
												value={props.values.description}
											/>
											{props.touched.description && props.errors.description && (
												<div className='fv-plugins-message-container danger'>
													<div className='fv-help-block'>
														{props.errors.description}
													</div>
												</div>
											)}
										</div>
									</div>
									{/* ===========================RESPONSE */}
									<div className='row mb-6'>
										<label className='col-lg-4 col-form-label fw-bold fs-6'>
											{intl.formatMessage({ id: 'TEXT.RESPONSE' })}
										</label>
										<div className='col-lg-8 fv-row'>
											<input
												name='response'
												type='text'
												className='form-control form-control-lg form-control-solid'
												placeholder={intl.formatMessage({
													id: 'TEXT.RESPONSE',
												})}
												onChange={props.handleChange}
												value={props.values.response}
											/>
											{props.touched.response && props.errors.response && (
												<div className='fv-plugins-message-container danger'>
													<div className='fv-help-block'>
														{props.errors.response}
													</div>
												</div>
											)}
										</div>
									</div>
									{/* =================================Submit */}
								</div>
							</div>
							<div className='modal-footer'>
								<button
									type='submit'
									className='btn btn-success'
									disabled={loading}
									data-bs-dismiss={props.isValid ? 'modal' : null}
								>
									{/* data-bs-dismiss={'modal'} */}
									{!loading && intl.formatMessage({ id: 'TEXT.SAVE' })}
									{loading && (
										<span
											className='indicator-progress'
											style={{ display: 'block' }}
										>
											Please wait...{' '}
											<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
										</span>
									)}
								</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}

export default HelpModalAdd
