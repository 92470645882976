import { IPaginationModel } from "../../models/personal/paginationCustom"
import { IprofileData, IprofileDataPostPut } from "../../modules/accounts/components/Profile"
import {
  DELETE_PERSONAL_FAILED,
  DELETE_PERSONAL_SUCCESS,
  GET_PERSONALS,
  GET_PERSONALS_FAILED,
  GET_PERSONALS_SUCCESS,
  GET_PERSONAL_BY_ID,
  GET_PERSONAL_BY_ID_SAGA,
  CREATE_PERSONAL,
  UPDATE_PERSONAL,
  GET_PAGINATION,
  GET_PAGES_PAGINATION,
  GET_PERSONAL_WITH_FILTER_REQUEST,
  GET_PERSONAL_WITH_FILTER_SUCCESS,
  GET_PERSONAL_WITH_FILTER_ERROR
} from "../personalTypes"

export const createPersonalsAction = (values: IprofileDataPostPut) => ({ type: CREATE_PERSONAL, values })
export const updatePersonalsAction = (values: IprofileDataPostPut, page: number, filter: any) => ({ type: UPDATE_PERSONAL, values, page, filter })

export const getPersonalsSuccessAction = (personals: IprofileData[]) => ({ type: GET_PERSONALS_SUCCESS, personals })
export const getPersonalsErrorAction = (error: any) => ({ type: GET_PERSONALS_FAILED, error })
export const getPersonalsAction = () => ({ type: GET_PERSONALS })

export const getPersonalById = (id: string | number) => ({ type: GET_PERSONAL_BY_ID_SAGA, id })
export const getPersonalByIdAction = (user: IprofileData) => ({ type: GET_PERSONAL_BY_ID, user })

export const deletePersonalAction = (id: number) => ({ type: DELETE_PERSONAL_SUCCESS, id: id })
export const deletePersonalErrorAction = () => ({ type: DELETE_PERSONAL_FAILED })

export const getPagination = (page: number) => ({ type: GET_PAGINATION, page })
export const getPaginationAction = (pages: IPaginationModel) => ({ type: GET_PAGES_PAGINATION, pages })

//===================================PERSONAL FILTER
export const getPersonalWithFilterRequestAction = (page: number, filter?: any) => ({type: GET_PERSONAL_WITH_FILTER_REQUEST, page, filter})
export const getPersonalWithFilterSuccessAction = (payload: any) => ({type: GET_PERSONAL_WITH_FILTER_SUCCESS, payload})
export const getPersonalWithFilterErrorAction = (error: string) => ({type: GET_PERSONAL_WITH_FILTER_ERROR, error})