import _ from "lodash"

const Highlighter = (text: string  = '', highlight: string = '') => {
	if (!highlight.trim()) {
	  return <span>{text}</span>
	}
	const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
	const parts = text.split(regex)

	
	return (
	  <span>
		 {parts.filter(part => part).map((part, i) => (
			regex.test(part) ? <mark className='bg-warning' key={i}>{part}</mark> : <span key={i}>{part}</span>
		 ))}
	 </span>
	)
 }

 export default Highlighter;