import { KTSVG } from '../../../components/helpers/KTSVG'

export interface FileHeaderProps {
	file: File
	onDelete: (file: File) => void
	setTotalFilesUploadStatus: (status: any) => void
}

export function FileHeader({
	file,
	onDelete,
	setTotalFilesUploadStatus,
}: FileHeaderProps) {
	const handleDelete = () => {
		onDelete(file)
		setTotalFilesUploadStatus((prev: any) => ([...prev.slice(0, -1)]))
	}

	return (
		<div className='d-flex justify-content-between align-items-center'>
			<div>{file.name}</div>
			<div>
				<div
					className='btn btn-sm btn-icon btn-active-color-primary'
					onClick={handleDelete}
				>
					<KTSVG
						path='/media/icons/duotune/arrows/arr061.svg'
						className='svg-icon-1'
					/>
				</div>
			</div>
		</div>
	)
}
