import { IStatistic } from '../reducers/statisticReducer'
import { IRequestStatisticProps } from '../sagas/statisticSaga';
import {
	GET_STATISTIC,
	GET_STATISTIC_ERROR,
	GET_STATISTIC_SUCCESS,
} from '../statisticTypes'

export const requestStatisticAction = (payload?: IRequestStatisticProps) => ({
	type: GET_STATISTIC,
	payload,
})
export const requestStatisticSuccessAction = (payload: IStatistic) => ({
	type: GET_STATISTIC_SUCCESS,
	payload,
})
export const requestStatisticErrorAction = (payload: string) => ({
	type: GET_STATISTIC_ERROR,
	payload,
})
