import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import './CashierTable.scss'
import ReactTable from '../../components/partials/react-table/ReactTable'
import ReactPaginate from 'react-paginate'
import { getCashierListRequest, getCashierListSuccess, getOfdListRequest, setOrgNameForFilter, setPropsToFilterCashier, unblockCashierRequest } from '../../store/actions/cashierActions'
import { RootState } from '../../setup'
import { Cashier } from '../../models/cashier/FileModel'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru'
import { findPermission } from '../../helpers/findPermissionHelper'
import { Permission } from '../../models/coomonOFD/FileModel'
import { getUserPermissionsRequest } from '../../store/actions/commonOFDAction'
import InitCashierModal from './InitCashierModal'
import '../oraganization/OrganizationTable.scss'
import LoaderOfd from '../../components/partials/loader/LoaderOfd'

type tableState = {
    filter: {
        statusChangedTime: {
            start: Date | null
            end: Date | null
        }
        shiftClosed: boolean
        status: string
        defaultShiftClosed: boolean
    },
    currentPage: number
    size: string
    search: {
        factoryNumber: string
        organizationXin: string
        organizationName: string
        factionalAddress: string
    }
    sort: {
        name: string
        method: string
    }
    fetchNeed: boolean
}
type initModal = {
    id: number
    show: boolean
}

const CashierTable = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const ofdLoading = useSelector<RootState, boolean>(state => state.commonOFD.loading)
    const permissions = useSelector<RootState, Permission[]>(state => state.commonOFD.permissions)
    const orgNameForFilter: string = useSelector<RootState, string>(state => state.cashier.oragNameForFilter)
    const cashierList: Cashier[] = useSelector<RootState, Cashier[]>(state => state.cashier.cashierList)
    const totalPages: number = useSelector<RootState, number>(state => state.cashier.totalPages)
    const [initModal, setInitModal] = useState<initModal>({ id: 0, show: false })
    const propsFilter = useSelector<RootState, string>(state => state.cashier.propsForFilter)

    const createCashierHandler = () => {
        history.push('/fiscalization/cashiers/create')
    }

    const [state, setState] = useState<tableState>({
        filter: {
            statusChangedTime: {
                start: null,
                end: null
            },
            shiftClosed: false,
            defaultShiftClosed: true,
            status: 'All'
        },
        currentPage: 1,
        size: '20',
        search: {
            factoryNumber: '',
            organizationXin: '',
            organizationName: '',
            factionalAddress: ''
        },
        sort: {
            name: '',
            method: 'default'
        },
        fetchNeed: true
    })

    const setFiltersToDefault = () => {
        setState({
            filter: {
                statusChangedTime: {
                    start: null,
                    end: null
                },
                shiftClosed: false,
                defaultShiftClosed: true,
                status: 'All'
            },
            currentPage: 1,
            size: '20',
            search: {
                factoryNumber: '',
                organizationXin: '',
                organizationName: '',
                factionalAddress: ''
            },
            sort: {
                name: '',
                method: 'default'
            },
            fetchNeed: true
        })
        dispatch(setOrgNameForFilter(''))
    }

    const firstSimbolCheck = (props: string) => {
        if (props.length === 0) return '?'
        return '&'
    }
    const createPropsString = () => {
        let props = ''
        const filter = { ...state.filter }
        const search = { ...state.search }
        const sort = { ...state.sort }
        props += firstSimbolCheck(props)
        props += `page=${state.currentPage}`
        if (state.size !== '20') {
            props += firstSimbolCheck(props)
            props += `size=${state.size}`
        }
        if (!filter.defaultShiftClosed) {
            props += firstSimbolCheck(props)
            props += `filter=shiftClosed=${filter.shiftClosed}`
        }
        if (filter.status !== 'All') {
            props += firstSimbolCheck(props)
            props += `filter=status=${filter.status}`
        }
        if (filter.statusChangedTime.end !== null && filter.statusChangedTime.start !== null) {
            props += firstSimbolCheck(props)
            props += `filter=statusChangedTime>${filter.statusChangedTime.start.toISOString().slice(0, -1)}&filter=statusChangedTime<${filter.statusChangedTime.end.toISOString().slice(0, -1)}`
        }
        if (filter.statusChangedTime.end === null && filter.statusChangedTime.start !== null) {
            props += firstSimbolCheck(props)
            props += `filter=statusChangedTime>${filter.statusChangedTime.start.toISOString().slice(0, -1)}`
        }
        if (search.factionalAddress.length > 2) {
            props += firstSimbolCheck(props)
            props += `search=factionalAddress=${search.factionalAddress}`
        }
        if (search.factoryNumber.length > 2) {
            props += firstSimbolCheck(props)
            props += `search=factoryNumber=${search.factoryNumber}`
        }
        if (search.organizationName.length > 2) {
            props += firstSimbolCheck(props)
            props += `search=organizationName=${search.organizationName}`
        }
        if (search.organizationXin.length > 2) {
            props += firstSimbolCheck(props)
            props += `search=organizationXin=${search.organizationXin}`
        }
        if (sort.method !== 'default' && sort.name !== '') {
            props += firstSimbolCheck(props)
            props += `sort=${sort.name}=${sort.method}`
        }
        dispatch(setPropsToFilterCashier(props))
        return props
    }
    const makePropsForCashierListHandler = () => {
        const props = createPropsString()
        if (state.fetchNeed) {
            dispatch(getCashierListRequest(props))
        }
    }

    useEffect(() => {
        if (orgNameForFilter !== '') setState({ ...state, search: { ...state.search, organizationName: orgNameForFilter } })
        if (permissions.length === 0) dispatch(getUserPermissionsRequest())
        return () => {
            dispatch(setOrgNameForFilter(''))
            dispatch(getCashierListSuccess({ list: [], totalItems: 0, totalPages: 0 }))
        }
    }, [])
    useEffect(() => {
        makePropsForCashierListHandler()
    }, [state.filter, state.currentPage, state.search, state.size, state.sort])
    useEffect(() => {
        if (findPermission('LIST_OFD', permissions)) dispatch(getOfdListRequest())
    }, [permissions])
    const changeDateHandler = (dates: any) => {
        const [start, end] = dates
        setState({ ...state, fetchNeed: true, filter: { ...state.filter, statusChangedTime: { start, end } } })
    }
    const changeFilterHandler = (value: string, name: string) => {
        if (name === 'status') {
            setState({ ...state, filter: { ...state.filter, status: value }, fetchNeed: true })
        }
        else if (name === 'shiftClosed') {
            if (value === '0') {
                setState({ ...state, filter: { ...state.filter, defaultShiftClosed: true, shiftClosed: false }, fetchNeed: true })
            }
            else if (value === '1') {
                setState({ ...state, filter: { ...state.filter, defaultShiftClosed: false, shiftClosed: false }, fetchNeed: true })
            }
            else if (value === '2') {
                setState({ ...state, filter: { ...state.filter, defaultShiftClosed: false, shiftClosed: true }, fetchNeed: true })
            }
        }
        else if (name === 'factoryNumber' || name === 'organizationXin' || name === 'organizationName' || name === 'factionalAddress') {
            if (value.length > 2) {
                setState({ ...state, search: { ...state.search, [name]: value }, fetchNeed: true })
            }
            else {
                setState({ ...state, search: { ...state.search, [name]: value }, fetchNeed: false })
            }
        }
        else if (name === 'size') {
            setState({ ...state, size: value, fetchNeed: true })
        }
    }

    const sortHandler = (name: string) => {
        if (state.sort.name !== name) {
            setState({ ...state, sort: { name, method: 'asc' }, fetchNeed: true })
        }
        else if (state.sort.name === name && state.sort.method === 'asc') {
            setState({ ...state, sort: { name, method: 'desc' }, fetchNeed: true })
        }
        else if (state.sort.name === name && state.sort.method === 'desc') {
            setState({ ...state, sort: { name: '', method: 'default' }, fetchNeed: true })
        }
    }

    const handlePageClick = (e: any) => {
        setState({ ...state, currentPage: e.selected + 1, fetchNeed: true })
    }

    const removeCashierHandler = () => {

    }
    const unblockCashierHandler = (id: number) => {
        dispatch(unblockCashierRequest(id, propsFilter))
    }
    const initCashierHandler = (id: number) => {
        setInitModal({ id, show: true })
    }
    const openShiftPageHandler = (id: number, factoryNumber: string) => {
        history.push(`/fiscalization/cashiers/shift/${id}/${factoryNumber}`)
    }
    const CustomToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
        <button
            ref={ref}
            className='cashier-btn-create cashier-list-btn'
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            &#x25bc;
        </button>
    ));

    const columns: any[] = useMemo(() => [
        {
            Header: 'Заводской номер',
            accessor: 'factoryNumber',
            width: 200
        },
        {
            Header: 'БИН организации',
            accessor: 'organizationXin',
            width: 100
        },
        {
            Header: 'Название организации',
            accessor: 'organizationName',
        },
        {
            Header: 'Адресс кассы',
            accessor: 'factionalAddress',
            width: 200,
        },
        {
            Header: 'Статус кассы',
            accessor: 'status',
            width: 200,
            Cell: (cell: any) => {
                if (cell?.row?.original?.status === 'CREATED') {
                    return (
                        <span>
                            Создана
                        </span>
                    )
                }
                else if (cell?.row?.original?.status === 'ACTIVATED') {
                    return (
                        <span>
                            Готова к работе
                        </span>
                    )
                }
                else if (cell?.row?.original?.status === 'BLOCKED') {
                    return (
                        <span>
                            Заблокирована
                        </span>
                    )
                }
                else if (cell?.row?.original?.status === 'REMOVED_FROM_OFD') {
                    return (
                        <span>
                            Снята с учета
                        </span>
                    )
                }
            }
        },
        {
            Header: 'Дата последней смены статуса',
            accessor: 'statusChangedTime',
            width: 100,
            Cell: (cell: any) => {
                if (cell?.row?.original?.statusChangedTime === null) return (<>-</>)
                const fullDate = new Date(cell?.row?.original?.statusChangedTime)
                const day = fullDate.getDate()
                const month = fullDate.getMonth() + 1
                const year = fullDate.getFullYear()
                const hour = fullDate.getHours()
                const minute = fullDate.getMinutes()
                const exactMinutes = minute.toString().length === 1 ? '0' + minute.toString() : minute
                return (
                    <>
                        <div>
                            {day + '-' + month + '-' + year}
                        </div>
                        <div>
                            {hour + ':' + exactMinutes}
                        </div>
                    </>
                )
            }
        },
        {
            Header: 'Смена',
            accessor: 'shiftClosed', width: 100,
            Cell: (cell: any) => {
                return (
                    <button className='cashier-btn-create cashier-list-btn' onClick={() => openShiftPageHandler(cell?.row?.original?.id, cell?.row?.original?.factoryNumber)}>
                        {cell?.row?.original?.shiftClosed ? 'Закрыта' : 'Открыта'}
                    </button>
                )
            }
        },
        {
            Header: 'Действие',
            accessor: 'action',
            width: 100,
            Cell: (cell: any) => {
                return (
                    <Dropdown>
                        <Dropdown.Toggle
                            id="dropdown-custom-components" as={CustomToggle}
                        >

                        </Dropdown.Toggle>

                        <Dropdown.Menu className='w-250px'>
                            {
                                cell?.row?.original?.status === 'CREATED' && findPermission('ACTIVATE_KKM', permissions) && (
                                    <Dropdown.Item eventKey="1" className='fw-bold' onClick={() => initCashierHandler(cell?.row?.original?.id)}>
                                        Инициализировать кассу
                                    </Dropdown.Item>
                                )
                            }
                            {
                                cell?.row?.original?.status === 'ACTIVATED' && (
                                    <>
                                        {/* <Dropdown.Item eventKey="1" className='fw-bold' onClick={()=>unblockCashierHandler(cell?.row?.original?.id)}>
                                            Разблокировать кассу
                                        </Dropdown.Item>
                                        <Dropdown.Divider /> */}
                                        <Dropdown.Item eventKey="2" className='fw-bold' onClick={removeCashierHandler}>
                                            Снять с учета
                                        </Dropdown.Item>
                                    </>
                                )
                            }
                            {
                                (cell?.row?.original?.status === 'BLOCKED' || cell?.row?.original?.status === 'REMOVED_FROM_OFD') && (
                                    <>
                                        <Dropdown.Item eventKey="1" className='fw-bold' onClick={() => unblockCashierHandler(cell?.row?.original?.id)}>
                                            Разблокировать кассу
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item eventKey="2" className='fw-bold' onClick={removeCashierHandler}>
                                            Снять с учета
                                        </Dropdown.Item>
                                    </>
                                )
                            }

                        </Dropdown.Menu>
                    </Dropdown>
                )
            }
        }
    ], [permissions])

    const sortableColumnHanlder = (column: any) => {
        return (
            <span
                className={
                    state.sort.name === column.id ?
                        state.sort.method === 'asc' ?
                            'cashier-th-sort-asc cashier-th-sort'
                            :
                            state.sort.method === 'desc' ?
                                'cashier-th-sort-desc cashier-th-sort'
                                :
                                'cashier-th-sort'
                        :
                        'cashier-th-sort'}
                onClick={() => sortHandler(column.id)}
            >{column.render('Header')}</span>
        )
    }
    const columnFilterRender = (column: any) => {
        if (column.Header === 'Действие') {
            return (
                <>
                    <span className='cashier-th-sort'>{column.render('Header')}</span>
                    <input className='cashier-th-filter-input' disabled />
                </>
            )
        }
        else if (column.Header === 'Смена') {
            return (
                <>
                    {sortableColumnHanlder(column)}
                    <select
                        className='cashier-th-filter-input'
                        onChange={(e) => changeFilterHandler(e.target.value, column.id)}
                        value={state.filter.defaultShiftClosed ? 0 : state.filter.shiftClosed ? 2 : 1}
                    >
                        <option value={0}>Все</option>
                        <option value={1}>Открыта</option>
                        <option value={2}>Закрыта</option>
                    </select>
                </>
            )
        }
        else if (column.Header === 'Статус кассы') {
            return (
                <>
                    {sortableColumnHanlder(column)}
                    <select
                        className='cashier-th-filter-input'
                        onChange={(e) => changeFilterHandler(e.target.value, column.id)}
                        value={state.filter.status}
                    >
                        <option value={'All'}>Все</option>
                        <option value={'CREATED'}>Создана</option>
                        <option value={'ACTIVATED'}>Готова к работе</option>
                        <option value={'BLOCKED'}>Заблокирована</option>
                        <option value={'REMOVED_FROM_OFD'}>Снята с учета</option>

                    </select>
                </>
            )
        }
        else if (column.Header === 'Дата последней смены статуса') {
            const fullDate = new Date()
            const day = fullDate.getDate()
            const month = fullDate.getMonth() + 1
            const year = fullDate.getFullYear()
            return (
                <>
                    {sortableColumnHanlder(column)}
                    <DatePicker
                        className='cashier-th-filter-input'
                        selected={new Date()}
                        onChange={(dates) => changeDateHandler(dates)}
                        dateFormat="dd-MM-yyyy"
                        locale={ru}
                        startDate={state.filter.statusChangedTime.start}
                        endDate={state.filter.statusChangedTime.end}
                        selectsRange
                        placeholderText={`${day}-${month}-${year}`}
                    />
                </>
            )
        }
        else {
            const searchCopy: any = { ...state.search }
            let inputValue = ''
            Object.keys(searchCopy).map((item: string) => {
                if (item === column.id) inputValue = searchCopy[item]
            })
            return (
                <>
                    {sortableColumnHanlder(column)}
                    <input
                        value={inputValue}
                        className='cashier-th-filter-input'
                        onChange={(e) => changeFilterHandler(e.target.value, column.id)}
                        placeholder={`Введите 3 символа`}
                    />
                </>
            )
        }

    }
    const closeInitModalHandler = () => {
        setInitModal({ id: 0, show: false })
    }
    return (
        <>
            {ofdLoading && <LoaderOfd />}
            <InitCashierModal show={initModal.show} close={closeInitModalHandler} kkmId={initModal.id} />
            <Row className='d-flex space-betwwen ' >
                <Col className='m-b-20-px'>
                    <h6 className='cashier-list-title'>
                        Кассы зарегестрированные в системе
                    </h6>
                </Col>
                <Col className='d-flex justify-content-end m-b-20-px align-items-center'>
                    <select
                        className='cashier-list-dd'
                        onChange={(e) => changeFilterHandler(e.target.value, 'size')}>
                        <option value={20}>20 записей</option>
                        <option value={50}>50 записей</option>
                    </select>
                </Col>
                <Col className='d-flex justify-content-end m-b-20-px align-items-center '>
                    <button
                        className='cashier-btn-create cashier-list-btn cashier-list-header-btn'
                        onClick={setFiltersToDefault}
                    >
                        Сбросить фильтры
                    </button>
                    {findPermission('CREATE_KKM', permissions) &&
                        <button
                            className='cashier-btn-create cashier-list-btn cashier-list-header-btn'
                            onClick={createCashierHandler}
                        >
                            Создать
                        </button>}
                </Col>
            </Row>
            <Card>
                <ReactTable
                    // data={cashierListMemorized}
                    data={cashierList}
                    columns={columns}
                    tableWrapperClassName='cashier-table-wrapper'
                    tableClassName='cashier-table'
                    thClassName='cashier-table-td cashier-table-th'
                    thClassNameRight='cashier-table-td-right'
                    tdClassName='cashier-table-td'
                    tdClassNameRight='cashier-table-td-right'
                    trClassNameHover='cashier-table-td-hover'
                    columnFilterRender={columnFilterRender}
                />
            </Card>
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                pageCount={totalPages}  //количество страниц
                onPageChange={handlePageClick}
                breakLabel='...'
                pageRangeDisplayed={2}
                containerClassName='cashier-pagination-box'
                previousLinkClassName='cashier-pagination-prevlink cashier-pagination-mainlink'
                nextLinkClassName='cashier-pagination-nextlink cashier-pagination-mainlink'
                pageLinkClassName='cashier-pagination-mainlink'
                activeLinkClassName='cashier-pagination-activelink'
                breakLinkClassName='cashier-pagination-breaklink'
            // marginPagesDisplayed={2}
            // forcePage={period.page}
            />
        </>
    )
}
export default CashierTable