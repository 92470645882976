import { FC, useState } from 'react'
import { KTSVG } from '../../../helpers/KTSVG'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { getPersonalsAction } from '../../../../store/actions/personalsActions'
import { Alert } from 'react-bootstrap'
import { Toast } from 'react-bootstrap'
import axios from "../../../../setup/axios/axios-config";


async function createNewMember(values: any) {
  axios.post(`api/personal/info/`, values)
}
const data = {
  address: "",
  email: "",
  fullName: "",
  organization: "",
  phone: ""
}


const profileDetailsSchema = Yup.object().shape({
  fullName: Yup.string().required('Full name is required'),
  organization: Yup.string().required('Organization name is required'),
  email: Yup.string().required('Email is required'),
  address: Yup.string().required('Addres is required'),
  phone: Yup.string().required('Phone is required'),
})
const InviteUsers: FC = () => {
  const dispatch = useDispatch()

  const [visible, setVisible] = useState(true)
  const [loading, setLoading] = useState(false)

  const formik = useFormik({

    initialValues: { ...data },
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        let newData = {
          address: values.address,
          email: values.email,
          fullName: values.fullName,
          imagePath: null,
          organization: values.organization,
          phone: values.phone
        }
        createNewMember(newData)
        dispatch(getPersonalsAction())
        setVisible(true)
        setLoading(false)
      }, 1000)
    },
  })

  return (
    <div className='modal fade' id='kt_modal_invite_friends' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>

            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
                  <div className='col-lg-8'>
                    {/* <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
                    >
                      <div
                        className='image-input-wrapper w-125px h-125px'
                      style={{ backgroundImage: `url(${toAbsoluteUrl(data.avatar)})` }}
                      >
                        <img width={100} height={100} src={img} alt="" />
                      </div>
                    </div> */}
                  </div>
                </div>



                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full name</label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Full name'
                      {...formik.getFieldProps('fullName')}
                    />
                    {formik.touched.fullName && formik.errors.fullName && (
                      <div className='fv-plugins-message-container danger'>
                        <div className='fv-help-block'>{formik.errors.fullName}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Organization</label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Organization'
                      {...formik.getFieldProps('organization')}
                    />
                    {formik.touched.organization && formik.errors.organization && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.organization}</div>
                      </div>
                    )}
                  </div>
                </div>
                {/* new field addres */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Address</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Addres'
                      {...formik.getFieldProps('address')}
                    />
                    {formik.touched.address && formik.errors.address && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.address}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Phone</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='tel'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Phone number'
                      {...formik.getFieldProps('phone')}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.phone}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Email</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='email'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Company website'
                      {...formik.getFieldProps('email')}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.email}</div>
                      </div>
                    )}
                  </div>
                </div>

              </div>
              <Alert color="success" >this is success</Alert>
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' disabled={loading} data-bs-dismiss={formik.isValid ? 'modal' : null}>
                  {/* data-bs-dismiss={'modal'} */}
                  {!loading && 'Save Changes'}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>

          </div>
        </div>
      </div >
      <Toast onClose={() => setVisible(false)} show={visible} delay={3000} autohide>
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
          />
          <strong className="me-auto">Bootstrap</strong>
          <small>11 mins ago</small>
        </Toast.Header>
        <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
      </Toast>
    </div >
  )
}

export { InviteUsers }
