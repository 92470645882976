import React from 'react'

import * as Yup from 'yup'
import { StepperIdentify } from './stepperIdenty/StepperIdentify'

const info = {
	name: 'МУХТАР',
	surname: 'КУСАИНОВ',
	patronymic: 'МАРАТОВИЧ',
	birthDate: 640458000000,
	iin: 900419300938,
	birthPlace: {
		country: {
			nameRu: 'КАЗАХСТАН',
			code: 398,
			nameKz: 'ҚАЗАҚСТАН',
			changeDate: '2008-03-01T13:21:44+06:00',
		},
		city: '-',
		district: {
			nameRu: 'АЛМАТЫ',
			code: 1910,
			nameKz: 'АЛМАТЫ',
			changeDate: '2008-03-01T13:21:45+06:00',
		},
		region: {
			nameRu: 'БОСТАНДЫКСКИЙ',
			code: 1910264,
			nameKz: 'БОСТАНДЫҚ',
			changeDate: '2008-03-01T13:21:45+06:00',
		},
	},
	lifeStatus: {
		nameRu: 'Нормальный',
		code: 0,
		nameKz: 'Қалыпты',
		changeDate: '2008-03-01T13:21:45+06:00',
	},
	gender: {
		nameRu: 'Мужской',
		code: 1,
		nameKz: 'Ер',
		changeDate: '2008-03-01T13:21:45+06:00',
	},
	nationality: {
		nameRu: 'КАЗАХСТАН',
		code: 398,
		nameKz: 'ҚАЗАҚСТАН',
		changeDate: '2008-03-01T13:21:44+06:00',
	},
	documents: [
		{
			number: 36855661,
			beginDate: 1408471200000,
			patronymic: 'МАРАТОВИЧ',
			endDate: 1724004000000,
			surname: 'КУСАИНОВ',
			name: 'МУХТАР',
			type: {
				nameRu: 'УДОСТОВЕРЕНИЕ РК',
				code: 2,
				nameKz: 'ҚР ЖЕКЕ КУӘЛІГІ',
				changeDate: '2008-03-01T13:21:45+06:00',
			},
			birthDate: 640458000000,
			issueOrganization: {
				nameRu: 'МИНИСТЕРСТВО ВНУТРЕННИХ ДЕЛ РК',
				code: 2,
				nameKz: 'ҚР ІШКІ ІСТЕР МИНИСТРЛІГІ',
				changeDate: '2008-03-01T13:21:45+06:00',
			},
			status: {
				nameRu: 'ДОКУМЕНТ ДЕЙСТВИТЕЛЕН',
				code: 0,
				nameKz: 'ҚҰЖАТ ЖАРАМДЫ',
				changeDate: '2008-03-01T13:21:45+06:00',
			},
		},
	],
	citizenship: null,
	regAddress: {
		country: {
			nameRu: 'КАЗАХСТАН',
			code: 398,
			nameKz: 'ҚАЗАҚСТАН',
			changeDate: '2008-03-01T13:21:44+06:00',
		},
		beginDate: 1632506400000,
		street: 'УЛИЦА Сатпаева',
		flat: null,
		district: {
			nameRu: 'АЛМАТЫ',
			code: 1910,
			nameKz: 'АЛМАТЫ',
			changeDate: '2008-03-01T13:21:45+06:00',
		},
		region: {
			nameRu: 'БОСТАНДЫКСКИЙ',
			code: 1910264,
			nameKz: 'БОСТАНДЫҚ',
			changeDate: '2008-03-01T13:21:45+06:00',
		},
		building: 'УЛИЦА Сатпаева',
	},
}

const identifyValidate = Yup.object().shape({
	photoFace: Yup.string().required('Нет фото лица').nullable(),
	passportBack: Yup.string().required('Нет фото пасспорта').nullable(),
	passportFront: Yup.string().required('Нет фото пасспорта').nullable(),
	iin: Yup.string().required('Заполните ИИН поле'),
})

interface KcmrDetailsProps {
	face: any
	passportBack: any
	passportFront: any
	setFace: any
	setPassportFront: any
	setPassportBack: any
}

const KsmrDetails = ({
	face,
	passportBack,
	passportFront,
	setFace,
	setPassportFront,
	setPassportBack,
}: KcmrDetailsProps) => {
	// if (camera === 'face') {
	// 	return (
	// 		<CameraComponent
	// 			setCamera={() => setCamera('')}
	// 			setPhoto={setFace}
	// 			mode='face'
	// 			setCameraErr={setCameraErr}
	// 		/>
	// 	)
	// }
	// if (camera === 'passportFront') {
	// 	return (
	// 		<CameraComponent
	// 			setCamera={() => setCamera('')}
	// 			setPhoto={setPassportFront}
	// 			setCameraErr={setCameraErr}
	// 		/>
	// 	)
	// }
	// if (camera === 'passportBack') {
	// 	return (
	// 		<CameraComponent
	// 			setCamera={() => setCamera('')}
	// 			setPhoto={setPassportBack}
	// 			setCameraErr={setCameraErr}
	// 		/>
	// 	)
	// }

	return (
		<>
			<div className={`card `}>
				<div className='card-body py-3'>
					{/* <Horizontal /> */}
					{/* <Vertical /> */}
					<StepperIdentify
						face={face}
						passportBack={passportBack}
						passportFront={passportFront}
						setFace={setFace}
						setPassportFront={setPassportFront}
						setPassportBack={setPassportBack}
						// handleFront={handleFront}
						// handleSelfie={handleSelfie}
						// handleBack={handleBack}
					/>
					{/* begin::Table container */}
				</div>

				{/* begin::Body */}
			</div>
			{/* {showInfo ? (
				<KcmrInfo info={identityInfo.data.kcmrResult.personData} />
			) : (
				<div className='card d-flex p-5 justify-content-center-center mt-5'>
					<h1>Нет данных</h1>
				</div>
			)} */}
		</>
	)
}

export default KsmrDetails
