import {AnyAction} from 'redux'
import {IReportGroup} from '../../models/report/reportModel'
import {
    ANNULATE_REPORT_ERROR,
    ANNULATE_REPORT_REQUEST,
    ANNULATE_REPORT_SUCCESS,
    CREATE_REPORT_ERROR,
    CREATE_REPORT_REQUEST,
    CREATE_REPORT_SUCCESS,
    GET_LEGALS_PARENT,
    GET_LEGALS_PARENT_ERROR,
    GET_LEGALS_PARENT_SUCCESS,
    GET_LEGAL_BY_ID,
    GET_LEGAL_BY_ID_ERROR,
    GET_LEGAL_BY_ID_SUCCESS,
    GET_REPORTS,
    GET_REPORTS_CONTRACTORS_ERROR,
    GET_REPORTS_CONTRACTORS_SUCCESS,
    GET_REPORTS_GROUP,
    GET_REPORTS_GROUP_SUCCESS,
    GET_REPORTS_SUCCESS,
    GET_REPORT_TYPES,
    GET_REPORT_TYPES_ERROR,
    GET_REPORT_TYPES_SUCCESS,
    REPORTS_LOADING,
    RESEND_REPORT_REQUEST,
} from '../reportTypes'

export interface reportState {
    reports: any
    contractors: any[]
    reportError: any
    report: any
    loading: boolean

    reportTypes: []
    reportTypesLoading: boolean
    reportTypesError: {}

    reportResendLoading: boolean

    legalById: any
    legalByIdLoading: boolean
    legalByIdError: {}
    reportsGroup: IReportGroup

    legalByParentId: any
    legalByParentIdLoading: boolean
    legalByParentIdError: {}

    createReportLoading: boolean
    createReportError: any

    annulateReportLoading: boolean
    annulateReportError: any
    loadingPage: boolean,
}

const initialState: reportState = {
    reports: {},
    contractors: [],
    reportError: '',
    reportsGroup: {} as IReportGroup,
    report: {},
    loading: false,
    loadingPage: false,

    reportTypes: [],
    reportTypesLoading: false,
    reportTypesError: {},

    reportResendLoading: false,

    legalById: {},
    legalByIdLoading: false,
    legalByIdError: {},

    legalByParentId: {},
    legalByParentIdLoading: false,
    legalByParentIdError: {},

    createReportLoading: false,
    createReportError: {},

    annulateReportLoading: false,
    annulateReportError: {},
}

// Action keys

// enum TypeKeys {
//     INCREMENT = "INCREMENT",
//     DECREMENT = "DECREMENT",
//     SET_AMOUNT = "SET_AMOUNT",
// }

// // Action types

// interface IncrementAction {
//     type: TypeKeys.INCREMENT,
//     by: number
// }

// interface DecrementAction {
//     type: TypeKeys.DECREMENT,
//     by: number
// }

// interface SetAmountAction {
//     type: TypeKeys.SET_AMOUNT,
//     to: number
// }

// type Action =
//     | IncrementAction
//     | DecrementAction
//     | SetAmountAction;

const reportsReducer = (state = initialState, action: AnyAction): reportState => {
    switch (action.type) {
        case GET_REPORTS:
            return {...state, loadingPage: true}
        case GET_REPORTS_SUCCESS:
            return {...state, reports: action.report, loadingPage: false}
        case GET_REPORTS_GROUP:
            return {...state, loadingPage: true}
        case GET_REPORTS_GROUP_SUCCESS:
            return {...state, reportsGroup: action.group, loadingPage: false}
        case GET_REPORTS_CONTRACTORS_SUCCESS:
            return {...state, contractors: action.contractors}
        case GET_REPORTS_CONTRACTORS_ERROR:
            return {...state, reportError: action.error}
        // case GET_REPORT_BY_ID:
        // 	return { ...state, status: action.status }
        // case GET_REPORTS_ERROR:
        // 	return { ...state, statuses: action.error }
        case REPORTS_LOADING:
            return {...state, loadingPage: action.loading}

        //====================create report
        case CREATE_REPORT_REQUEST:
            return {...state, createReportLoading: true, createReportError: {}}
        case CREATE_REPORT_SUCCESS:
            return {...state, reports: action.reports, createReportLoading: false}
        case CREATE_REPORT_ERROR:
            return {...state, createReportError: action.message, createReportLoading: false}
        //===================annulate report
        case ANNULATE_REPORT_REQUEST:
            return {...state, annulateReportLoading: true, annulateReportError: {}}
        case ANNULATE_REPORT_SUCCESS:
            return {...state, reports: action.reports, annulateReportLoading: false,}
        case ANNULATE_REPORT_ERROR:
            return {...state, annulateReportError: action.message, annulateReportLoading: false}
        // ===========================
        case RESEND_REPORT_REQUEST:
            return {...state, reportResendLoading: action.payload}
        case GET_REPORT_TYPES:
            return {...state, reportTypesLoading: true, reportTypesError: {}}
        case GET_REPORT_TYPES_SUCCESS:
            return {
                ...state,
                reportTypes: action.reportTypes,
                reportTypesLoading: false,
            }
        case GET_REPORT_TYPES_ERROR:
            return {
                ...state,
                reportTypesLoading: false,
                reportTypesError: action.error,
            }
        //==============================================LEGALS_BY_PARENT_ID
        case GET_LEGALS_PARENT:
            return {
                ...state,
                legalByParentIdLoading: true,
                legalByParentIdError: {},
            }
        case GET_LEGALS_PARENT_SUCCESS:
            return {
                ...state,
                legalByParentId: action.legal,
                legalByParentIdLoading: false,
            }
        case GET_LEGALS_PARENT_ERROR:
            return {
                ...state,
                legalByParentIdLoading: false,
                legalByParentIdError: action.error,
            }
        //==============================================LEGALS_BY__ID
        case GET_LEGAL_BY_ID:
            return {...state, legalByIdLoading: true, legalByIdError: {}}
        case GET_LEGAL_BY_ID_SUCCESS:
            return {...state, legalById: action.legal, legalByIdLoading: false}
        case GET_LEGAL_BY_ID_ERROR:
            return {
                ...state,
                legalByIdError: action.error,
                legalByIdLoading: false,
            }
        default:
            return state
    }
}

export default reportsReducer
