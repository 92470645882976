import {
	Box,
	Button,
	ButtonGroup,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	ToggleButton,
	ToggleButtonGroup,
	useMediaQuery,
} from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import StatusFilter from './StatusFilter'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SearchIcon from '@mui/icons-material/Search'
import moment from 'moment'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import axios from '../../setup/axios/axios-config'
import { IprofileData } from '../../modules/accounts/components/Profile'
import MultiSelectCustom from '../../components/partials/MultiSelectCustom'
import { useRouteMatch } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
interface TaskListToolbarProps {
	handlePrevPeriod: () => void
	handleNextPeriod: () => void

	setPersonal: (ser: any) => void
	person: any

	setStatus: (ser: any) => void
	status: any

	creatorPerson: any
	setCreatorPerson: (ser: any) => void

	handleSeacrh: (ser: any) => void
	setSearch: (text: string) => void
	search: string

	setPeriod: (period: any) => void

	periods: any
	period: any
	setTags: any
	tags: any

	showEpicList: string
	setShowEpicList: (type: string) => void

	epics: any[]
	selectedEpics: any
	setSelectedEpics: (arr: any) => void

	setAssignee: (e: any) => any
	assignee: any
	author: any
	setAuthor: (e: any) => any
}

const TaskListToolbar: FC<TaskListToolbarProps> = ({
	handlePrevPeriod,
	handleNextPeriod,
	setPersonal,
	person,
	setStatus,
	status,
	creatorPerson,
	setCreatorPerson,
	handleSeacrh,
	setSearch,
	search,
	setPeriod,
	periods,
	period,
	setTags,
	tags,
	setShowEpicList,
	showEpicList,
	epics,
	selectedEpics,
	setSelectedEpics,
	setAssignee,
	assignee,
	author,
	setAuthor,
}) => {
	const [,] = useState([])
	const intl = useIntl()
	const { path } = useRouteMatch()
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.up('sm'))

	const handleChange = (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: string
	) => {
		if (newAlignment) {
			setPeriod({ ...period, name: newAlignment })
		} else {
			setPeriod({ ...period })
		}
	}
	const personals: IprofileData[] = useSelector<RootState, IprofileData[]>(
		state => state.personals.personals,
		shallowEqual
	)
	const dispatch = useDispatch()
	const [epic, setEpic] = useState([])

	useEffect(() => {
		const tags = async () => {
			const tag = await axios.get('/api/tags')
			setEpic(await tag.data)
		}
		tags()
	}, [])

	const [tagFilter, setTagFilter] = useState<any>(null)

	useEffect(() => {
		setPersonal(assignee?.map((assign: IprofileData) => assign.userId))
		setCreatorPerson(author?.map((auth: IprofileData) => auth.userId))
	}, [author, assignee])

	const handleClearFilter = () => {
		setStatus([])
		setPersonal([])
		setCreatorPerson([])
		setSearch('')
		setTags('')
		setTagFilter(null)
		setPeriod({ ...period, name: 'ALL' })
		setAuthor([])
		setAssignee([])
		setSelectedEpics([])
	}

	const handleChangeView = (event: any, newValue: any) => {
		if (showEpicList === 'group') {
			setPeriod({ ...period, tagId: undefined })
			handleClearFilter()
		}
		if (event.target.value === 'group') {
			handleClearFilter()
		}
		setShowEpicList(event.target.value)
	}

	const checkIfFilterExist = (period: any): boolean => {
		if (!!period.assignees.length) {
			return false
		}
		if (!!period.statuses.length) {
			return false
		}
		if (!!period.creators.length) {
			return false
		}
		if (!!period.request) {
			return false
		}
		if (!!period.tagId) {
			return false
		}
		return true
	}

	return (
		<>
			{matches ? (
				<Box sx={{ mb: 5, display: 'flex', justifyContent: 'space-between' }}>
					<Box
						sx={{
							display: 'flex',
						}}
					>
						{period.name !== periods.name && showEpicList !== 'group' && (
							<ButtonGroup
								sx={{
									'& .MuiButton-outlined': {
										borderColor: 'rgb(222, 226, 230)',
									},
									'.MuiButtonGroup-grouped:not(:last-of-type):hover': {
										borderRightColor: 'transparent',
									},

									mr: 1,
								}}
								size='small'
								aria-label='small button group'
								color='inherit'
							>
								<Button onClick={() => handlePrevPeriod()}>
									<ChevronLeftIcon />
								</Button>
								<Button
									disabled
									sx={{
										'&.MuiButton-outlined:disabled': {
											color: 'inherit',
										},
									}}
									onClick={() => handleNextPeriod()}
								>
									{moment(period.date).format('DD-MM-YYYY')}
								</Button>

								<Button onClick={() => handleNextPeriod()}>
									<ChevronRightIcon />
								</Button>
							</ButtonGroup>
						)}

						{showEpicList !== 'group' && (
							<>
								<Box sx={{ mr: 1 }}>
									<MultiSelectCustom
										style='outlined'
										items={personals}
										size={{ width: '140px', height: '2.9rem' }}
										label='Автор'
										placeholder='Введите для поиска'
										selectAllLabel='Выбрать все'
										onChange={setAuthor}
										value={author}
									/>
								</Box>
								<Box sx={{ mr: 1 }}>
									<MultiSelectCustom
										style='outlined'
										items={personals}
										size={{ width: '140px', height: '2.9rem' }}
										label='Исполнитель'
										placeholder='Введите для поиска'
										selectAllLabel='Выбрать все'
										onChange={setAssignee}
										value={assignee}
									/>
								</Box>
							</>
						)}

						{showEpicList === 'group' && (
							<MultiSelectCustom
								getOptionLabel={(option: any) => {
									return option.name
								}}
								style='outlined'
								items={epics}
								size={{ width: '140px', height: '2.9rem' }}
								label='Тэги'
								placeholder='Введите для поиска'
								selectAllLabel='Выбрать все'
								onChange={setSelectedEpics}
								sx={{ pr: 1, height: '40px' }}
								value={selectedEpics}
							/>
						)}
						<FormControl className='me-5 w-100px'>
							<InputLabel>Вид</InputLabel>
							<Select
								size='small'
								value={showEpicList}
								label='Вид'
								onChange={handleChangeView}
							>
								<MenuItem value='list'>Список</MenuItem>
								<MenuItem value='group'>Группа</MenuItem>
							</Select>
						</FormControl>

						{showEpicList !== 'group' && (
							<>
								<StatusFilter status={status} setStatus={setStatus} />
								<Box sx={{ position: 'relative', width: '230px' }}>
									<input
										onChange={handleSeacrh}
										placeholder='Введите заголовок для поиска'
										value={search}
										style={{
											width: '100%',
											height: '2.7em',
											padding: '5px 30px 5px 10px',
											borderRadius: '0.25em',
											border: '1px solid #A1A5B7',
											// border: 'transparent',
											outline: 'none',
											// boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
										}}
										type='text'
										className=' me-5'
									/>
									<SearchIcon
										sx={{ position: 'absolute', top: '25%', right: '5%' }}
									/>
								</Box>
								<Button
									disabled={checkIfFilterExist(period)}
									onClick={() => handleClearFilter()}
									sx={{
										ml: 1,
										mr: 1,
										height: '3.35em',
									}}
									size='small'
									variant='outlined'
								>
									сброс
								</Button>
							</>
						)}
					</Box>
					{showEpicList !== 'group' && (
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
							}}
						>
							<ToggleButtonGroup
								color='primary'
								value={period.name}
								exclusive
								onChange={handleChange}
								sx={{
									height: '2.6em',
									flexShrink: 0,
								}}
							>
								<ToggleButton value='MONTH'>
									{intl.formatMessage({ id: 'TASK.MOUNTH' })}
								</ToggleButton>
								<ToggleButton value='WEEK'>
									{intl.formatMessage({ id: 'TASK.WEEK' })}
								</ToggleButton>
								<ToggleButton value='DAY'>
									{intl.formatMessage({ id: 'TASK.DAY' })}
								</ToggleButton>
								<ToggleButton value='ALL'>
									{intl.formatMessage({ id: 'TASK.ALLIST' })}
								</ToggleButton>
							</ToggleButtonGroup>
						</Box>
					)}
				</Box>
			) : (
				<Box sx={{ mb: 5, display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
					<Box
						sx={{
							display: 'flex',
							gap: '10px',
							flexWrap: 'wrap',
							// justifyContent: 'center',
						}}
					>
						{period.name !== periods.name && showEpicList !== 'group' && (
							<ButtonGroup
								sx={{
									'& .MuiButton-outlined': {
										borderColor: 'rgb(222, 226, 230)',
									},
									'.MuiButtonGroup-grouped:not(:last-of-type):hover': {
										borderRightColor: 'transparent',
									},

									mr: 1,
								}}
								size='small'
								aria-label='small button group'
								color='inherit'
							>
								<Button onClick={() => handlePrevPeriod()}>
									<ChevronLeftIcon />
								</Button>
								<Button
									disabled
									sx={{
										'&.MuiButton-outlined:disabled': {
											color: 'inherit',
										},
									}}
									onClick={() => handleNextPeriod()}
								>
									{moment(period.date).format('DD-MM-YYYY')}
								</Button>

								<Button onClick={() => handleNextPeriod()}>
									<ChevronRightIcon />
								</Button>
							</ButtonGroup>
						)}

						{showEpicList !== 'group' && (
							<>
								<Box>
									<MultiSelectCustom
										style='outlined'
										items={personals}
										size={{ width: '140px', height: '2.9rem' }}
										label='Автор'
										placeholder='Введите для поиска'
										selectAllLabel='Выбрать все'
										onChange={setAuthor}
										value={author}
									/>
								</Box>
								<Box>
									<MultiSelectCustom
										style='outlined'
										items={personals}
										size={{ width: '140px', height: '2.9rem' }}
										label='Исполнитель'
										placeholder='Введите для поиска'
										selectAllLabel='Выбрать все'
										onChange={setAssignee}
										value={assignee}
									/>
								</Box>
							</>
						)}

						{showEpicList === 'group' && (
							<MultiSelectCustom
								getOptionLabel={(option: any) => {
									return option.name
								}}
								style='outlined'
								items={epics}
								size={{ width: '140px', height: '2.9rem' }}
								label='Тэги'
								placeholder='Введите для поиска'
								selectAllLabel='Выбрать все'
								onChange={setSelectedEpics}
								sx={{ pr: 1, height: '40px' }}
								value={selectedEpics}
							/>
						)}
						<FormControl className='me-5 w-100px'>
							<InputLabel>Вид</InputLabel>
							<Select
								size='small'
								value={showEpicList}
								label='Вид'
								onChange={handleChangeView}
							>
								<MenuItem value='list'>Список</MenuItem>
								<MenuItem value='group'>Группа</MenuItem>
							</Select>
						</FormControl>

						{showEpicList !== 'group' && (
							<Box
								sx={{
									display: 'flex',
									gap: '10px',
									flexWrap: 'wrap',
									alignItems: 'center',
								}}
							>
								<StatusFilter status={status} setStatus={setStatus} />
								<Box sx={{ position: 'relative', width: '230px' }}>
									<input
										onChange={handleSeacrh}
										placeholder='Введите заголовок для поиска'
										value={search}
										style={{
											width: '100%',
											height: '2.7em',
											padding: '5px 30px 5px 10px',
											borderRadius: '0.25em',
											border: '1px solid #A1A5B7',
											// border: 'transparent',
											outline: 'none',
											// boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
										}}
										type='text'
										className=' me-5'
									/>
									<SearchIcon
										sx={{ position: 'absolute', top: '25%', right: '5%' }}
									/>
								</Box>
								<Button
									disabled={checkIfFilterExist(period)}
									onClick={() => handleClearFilter()}
									sx={{
										height: '3.35em',
									}}
									size='small'
									variant='outlined'
								>
									сброс
								</Button>
							</Box>
						)}
					</Box>
					{showEpicList !== 'group' && (
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
							}}
						>
							<ToggleButtonGroup
								color='primary'
								value={period.name}
								exclusive
								onChange={handleChange}
								sx={{
									height: '2.6em',
									flexShrink: 0,
								}}
							>
								<ToggleButton value='MONTH'>
									{intl.formatMessage({ id: 'TASK.MOUNTH' })}
								</ToggleButton>
								<ToggleButton value='WEEK'>
									{intl.formatMessage({ id: 'TASK.WEEK' })}
								</ToggleButton>
								<ToggleButton value='DAY'>
									{intl.formatMessage({ id: 'TASK.DAY' })}
								</ToggleButton>
								<ToggleButton value='ALL'>
									{intl.formatMessage({ id: 'TASK.ALLIST' })}
								</ToggleButton>
							</ToggleButtonGroup>
						</Box>
					)}
				</Box>
			)}
		</>
	)
}

export default TaskListToolbar
