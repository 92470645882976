import { ITimeTracker, ITimeTrackerContent } from "../../models/timeTracker/timeModel"
import { GET_ORGANIZATIONS_TIMES, GET_ORGANIZATIONS_TIMES_ERROR, GET_ORGANIZATIONS_TIMES_SUCCESS, GET_TIMES_TRACKER_INFO, GET_TIMES_TRACKER_INFO_ERROR, GET_TIMES_TRACKER_INFO_SUCCESS } from "../timeTrackerTypes"

export const getTimeTrackerAction = (filters: { page: number, username: string, companyName: string }) => ({ type: GET_ORGANIZATIONS_TIMES, filters })
export const getTimeTrackerSuccessAction = (times: ITimeTracker) => ({ type: GET_ORGANIZATIONS_TIMES_SUCCESS, times })
export const getTimeTrackeErrorAction = (error: any) => ({ type: GET_ORGANIZATIONS_TIMES_ERROR, error })

export const getTimeTrackerInfo = (filters: { page: any, userId: any, date: string }) => ({ type: GET_TIMES_TRACKER_INFO, filters })
export const getTimeTrackerInfoSuccess = (timesInfo: ITimeTrackerContent) => ({ type: GET_TIMES_TRACKER_INFO_SUCCESS, timesInfo })
export const getTimeTrackeInfoError = (error: any) => ({ type: GET_TIMES_TRACKER_INFO_ERROR, error })
