import { FC, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../components/helpers/KTSVG'
import { updateTaskHelpAction } from '../../store/actions/taskhelpAction'
import { remove } from 'lodash'
import { IHelp } from '../../models/tasks/tasksModel'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'

const SignupSchema = Yup.object().shape({
	code: Yup.string().required('code is required'),
	description: Yup.string().required('description required'),
	title: Yup.mixed().required('title required'),
	response: Yup.mixed().required('response required'),
})

export type Props = {
	show: boolean
	handleClose: () => void
	page: any
	data?: any
	setData: React.Dispatch<React.SetStateAction<{}>>
	taskHelp: IHelp
}

const HelpModalUpdate: FC<Props> = ({
	show,
	handleClose,
	page,
	data,
	taskHelp,
}) => {
	const intl = useIntl()
	const inits = {
		code: '',
		description: '',
		parentId: '',
		response: '',
		title: '',
	}

	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)

	// const handleSubmit = (values: any) => {
	//     const newTask = {
	//         code: values.code,
	//         title: values.title,
	//         description: values.description,
	//         // parentId: 3,
	//         response: values.response,

	//     }
	//     dispatch(createTaskHelpAction({ help: newTask, meta: page }))
	//     handleClose()
	// }

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: data,
		// validationSchema: profileDetailsSchema,
		onSubmit: (values: any) => {
			const newTask = {
				title: values.title,
				description: values.description,
				parentId: values.parentId,
				response: values.response,
				id: values.id,
			}
			try {
				dispatch(
					updateTaskHelpAction({
						help: {
							...newTask,
							parentId: values.parentId === '' ? remove : +(+values.parentId),
						},
						meta: page,
					})
				)
				handleClose()
				Swal.fire({
					position: 'center',
					icon: 'success',
					title: intl.formatMessage({
                        id: 'BOT_HELP_UPDATE_SUCCESS',
                    }),
					showConfirmButton: false,
					timer: 1500,
				})
			} catch (e) {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: intl.formatMessage({
                        id: 'BOT_HELP_UPDATE_ERROR',
                    }),
				})
			}
		},
	})

	useEffect(() => {}, [])

	return (
		<Modal
			aria-hidden='true'
			tabIndex='-1'
			show={show}
			onHide={handleClose}
			size='lg'
			centered
		>
			{/* ========================================================FORM */}
			<form onSubmit={formik.handleSubmit}>
				<div className='modal-content'>
					<div className='modal-header'>
						<h2>Помощь изменить</h2>
						<div
							className='btn btn-sm btn-icon btn-active-color-primary'
							onClick={handleClose}
						>
							<KTSVG
								path='/media/icons/duotune/arrows/arr061.svg'
								className='svg-icon-1'
							/>
						</div>
					</div>

					<div className='modal-body py-lg-10 px-lg-10'>
						<div className='card-body p-9'>
							{/* =================================CODE */}
							{/* <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>{intl.formatMessage({ id: 'TEXT.CODE' })}</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        disabled
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Code'
                                        {...formik.getFieldProps('code')}
                                    />
                                    {formik.touched.code && formik.errors.code && (
                                        <div className='fv-plugins-message-container danger'>
                                            <div className='fv-help-block'>{formik.errors.code}</div>
                                        </div>
                                    )}
                                </div>
                            </div> */}
							{/* ===========================Description */}
							<div className='row mb-6'>
								<label className='col-lg-4 col-form-label fw-bold fs-6'>
									{intl.formatMessage({ id: 'TASK.MODAL.TITLE' })}
								</label>
								<div className='col-lg-8 fv-row'>
									<input
										type='text'
										className='form-control form-control-lg form-control-solid'
										placeholder={intl.formatMessage({ id: 'TASK.MODAL.TITLE' })}
										{...formik.getFieldProps('title')}
									/>
									{formik.touched.title && formik.errors.title && (
										<div className='fv-plugins-message-container danger'>
											<div className='fv-help-block'>{formik.errors.title}</div>
										</div>
									)}
								</div>
							</div>
							{/* ===========================Start */}
							<div className='row mb-6'>
								<label className='col-lg-4 col-form-label fw-bold fs-6'>
									{intl.formatMessage({ id: 'TASK.MODAL.DESCRIPTION' })}
								</label>
								<div className='col-lg-8 fv-row'>
									<input
										type='text'
										className='form-control form-control-lg form-control-solid'
										placeholder={intl.formatMessage({
											id: 'TASK.MODAL.DESCRIPTION',
										})}
										{...formik.getFieldProps('description')}
									/>
									{formik.touched.description && formik.errors.description && (
										<div className='fv-plugins-message-container danger'>
											<div className='fv-help-block'>
												{formik.errors.description}
											</div>
										</div>
									)}
								</div>
							</div>
							{/* ===========================End */}
							<div className='row mb-6'>
								<label className='col-lg-4 col-form-label fw-bold fs-6'>
									{intl.formatMessage({ id: 'TEXT.RESPONSE' })}
								</label>
								<div className='col-lg-8 fv-row'>
									<input
										type='text'
										className='form-control form-control-lg form-control-solid'
										placeholder={intl.formatMessage({ id: 'TEXT.RESPONSE' })}
										{...formik.getFieldProps('response')}
									/>
									{formik.touched.response && formik.errors.response && (
										<div className='fv-plugins-message-container danger'>
											<div className='fv-help-block'>
												{formik.errors.response}
											</div>
										</div>
									)}
								</div>
							</div>
							<div className='row mb-6'>
								<label className='col-lg-4 col-form-label fw-bold fs-6'>
									{intl.formatMessage({ id: 'TEXT.PARENT' })}
								</label>
								<div className='col-lg-8 fv-row'>
									<select
										className='form-control form-control-lg form-control-solid'
										placeholder={intl.formatMessage({ id: 'TEXT.PARENT' })}
										{...formik.getFieldProps('parentId')}
									>
										<option value={''}>
											{intl.formatMessage({ id: 'TEXT.CHOOSEPARENT' })}
										</option>
										{taskHelp?.content?.map(item => {
											return (
												<option key={item.id} value={item.id}>
													{item.title}
												</option>
											)
										})}
									</select>
									{formik.touched.response && formik.errors.response && (
										<div className='fv-plugins-message-container danger'>
											<div className='fv-help-block'>
												{formik.errors.response}
											</div>
										</div>
									)}
								</div>
							</div>
							{/* ===========================Estimate */}
							{/* <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Estimate</label>
                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                type='datetime-local'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Estimate'
                                                {...props.getFieldProps('estimate')}
                                            />
                                            {props.touched.estimate && props.errors.estimate && (
                                                <div className='fv-plugins-message-container danger'>
                                                    <div className='fv-help-block'>{props.errors.estimate}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div> */}
							{/* =================================Submit */}
						</div>
					</div>
					<div className='modal-footer'>
						<button type='submit' className='btn btn-success'>
							{/* data-bs-dismiss={'modal'} */}
							{!loading && intl.formatMessage({ id: 'TEXT.SAVE' })}
							{loading && (
								<span
									className='indicator-progress'
									style={{ display: 'block' }}
								>
									Please wait...{' '}
									<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
								</span>
							)}
						</button>
					</div>
				</div>
			</form>
		</Modal>
	)
}

export { HelpModalUpdate }
