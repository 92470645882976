export const GET_PERSONALS: string = 'GET_PERSONALS'
export const GET_PERSONAL_BY_ID_SAGA: string = 'GET_PERSONAL_BY_ID_SAGA'
export const GET_PERSONAL_BY_ID: string = 'GET_PERSONAL_BY_ID'
export const GET_PERSONALS_SUCCESS: string = 'GET_PERSONALS_SUCCESS'
export const GET_PERSONALS_FAILED: string = 'GET_PERSONALS_FAILED'

export const CREATE_PERSONAL: string = 'CREATE_PERSONAL'
export const UPDATE_PERSONAL: string = 'UPDATE_PERSONAL'

export const DELETE_PERSONAL_SUCCESS: string = 'DELETE_PERSONALS_SUCCESS'
export const DELETE_PERSONAL_FAILED: string = 'DELETE_PERSONALS_FAILED'

export const GET_PAGINATION: string = 'GET_PAGINATION'
export const GET_PAGES_PAGINATION: string = 'GET_PAGES_PAGINATION'


//===========================PERSONAL FILTERED
export const GET_PERSONAL_WITH_FILTER_REQUEST: string = 'GET_PERSONAL_WITH_FILTER_REQUEST'
export const GET_PERSONAL_WITH_FILTER_SUCCESS: string = 'GET_PERSONAL_WITH_FILTER_SUCCESS'
export const GET_PERSONAL_WITH_FILTER_ERROR: string = 'GET_PERSONAL_WITH_FILTER_ERROR'
