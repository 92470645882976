import { FC, useState } from 'react'
import axios from '../../setup/axios/axios-config'
import CameraComponent from './CameraComponent'
import { MyGlobalContext } from './cameraContext'
import KsmrDetails from './KsmrDetails'

const KsmrPage: FC = () => {

	const handleChange = (e: any) => {
		const obj = [
			{
				id: 'sdas',
				email: 'asfasf@jkjkfa',
			},
			{
				id: 'sdas',
				email: 'asfasf@jkjkfa',
			},
			{
				id: 'sdas',
				email: 'asfasf@jkjkfa',
			},
		]
		const formData = new FormData()
		const file = e.target.files[0]
		formData.append('file', file)
		formData.append('email', JSON.stringify(obj))
		// formData.append('id', "adds@mail")

		axios.post('/api/reports/validate').then(data => data.data)
	}

	const dataSend = () => {}
	const [camera, setCamera] = useState('')
	const [step, setStep] = useState('')
	const [ocrData, setOcrData] = useState<any>(null)
	const [face, setFace] = useState<any>(null)
	const [passportFront, setPassportFront] = useState<any>(null)
	const [passportBack, setPassportBack] = useState<any>(null)

	return (
		<MyGlobalContext.Provider
			value={{ camera, setCamera, step, setStep, ocrData, setOcrData }}
		>
			{/* <input type='file' onChange={handleChange} /> */}
			{camera === 'face' && (
				<CameraComponent
					setCamera={() => setCamera('')}
					setPhoto={setFace}
					mode='face'
				/>
			)}
			{camera === 'passportFront' && (
				<CameraComponent
					setCamera={() => setCamera('')}
					setPhoto={setPassportFront}
				/>
			)}
			{camera === 'passportBack' && (
				<CameraComponent
					setCamera={() => setCamera('')}
					setPhoto={setPassportBack}
				/>
			)}
			{camera === '' && (
				<KsmrDetails
					face={face}
					passportFront={passportFront}
					passportBack={passportBack}
					setFace={setFace}
					setPassportFront={setPassportFront}
					setPassportBack={setPassportBack}
				/>
			)}
		</MyGlobalContext.Provider>
	)
}

export default KsmrPage
