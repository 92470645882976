import { Spinner } from 'react-bootstrap'
import './LoaderOfd.scss'

const LoaderOfd = () => {
    return (
        <div className='loaderodf-box'>
            <div className='d-flex justify-content-center align-items-center spinner-wrapper'>
                <Spinner animation='border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </Spinner>
            </div>
        </div>
    )
}
export default LoaderOfd