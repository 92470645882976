import React, { FC, useEffect } from 'react'
import { Field, useField } from 'formik'
import { Typography } from '@mui/material'
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import { useGlobalContext } from '../cameraContext'

interface Stepper1Props {
	passportBack: any
	passportFront: any
	handleFront: (e: any, helper: any) => void
	handleBack: (e: any, helper: any) => void
}

const IdentifyStep1: FC<Stepper1Props> = ({
	passportBack,
	passportFront,
	handleFront,
	handleBack,
}) => {
	const { camera, setCamera, setStep } = useGlobalContext()
	const [fieldBack, metaBack, helpersBack] = useField('passportBack')
	const [fieldFront, metaFront, helpersFront] = useField('passportFront')
	const [docTypeField, docTypeMeta, docTypeHelper] = useField('docType')

	useEffect(() => {
		helpersBack.setValue(passportBack)
		helpersFront.setValue(passportFront)
	}, [passportBack, passportFront])

	return (
		<div className='w-100'>
			<div className='pb-10 pb-lg-15 d-flex flex-column align-items-center'>
				<h2 className='fw-bolder d-flex align-items-center text-dark'>
					Сфотографируйте ваш паспорт с обеих сторон
				</h2>

				<div className='text-gray-400 fw-bold fs-6'>
					Следите чтобы ваш паспорт был в рамке.
				</div>
			</div>

			<div className='fv-row d-flex justify-content-center'>
				<div className='row-12 d-flex justify-content-center'>
					<div className='col-lg-6 '>
						<div className='col-lg-9 fv-row'>
							<div className='d-flex mt-5'>
								<div className='face me-5'>
									Лицевая сторона
									<div
										className='imgWrapper '
										style={{
											width: 130,
											height: 130,
											overflow: 'hidden',
											borderRadius: 6,
											border: '1px solid #E4E6EF',
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<img
											src={passportFront ? passportFront : ''}
											alt=''
											width={150}
										/>
									</div>
									<button
										style={{ width: 130 }}
										onClick={() => {
											setCamera('passportFront')
											setStep('step1')
										}}
										color='primary'
										aria-label='upload picture'
										// component='span'
										className='btn btn-primary my-2 btn-sm'
									>
										<PhotoCamera />
										<Typography className='ms-2' variant='caption'>
											Камера
										</Typography>
									</button>
									<div style={{ width: 130 }}>
										<input
											lang='ru'
											type='file'
											name='passportFront'
											className='form-control form-control-md form-control-solid'
											placeholder='File'
											onChange={(e: any) => handleFront(e, helpersFront)}
										/>
									</div>
									{metaFront.touched && metaFront.error && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block text-danger'>
												{metaFront.error}
											</div>
										</div>
									)}
								</div>

								{docTypeMeta.value !=3&&<div className='face'>
									Задняя сторона
									<div
										className='imgWrapper '
										style={{
											width: 130,
											height: 130,
											overflow: 'hidden',
											borderRadius: 6,
											border: '1px solid #E4E6EF',
											verticalAlign: 'midle',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<img
											src={passportBack ? passportBack : ''}
											alt=''
											width={150}
										/>
									</div>
									<button
										style={{ width: 130 }}
										className='btn btn-primary my-2  btn-sm'
										onClick={() => {
											setCamera('passportBack')
											setStep('step1')
										}}
										color='primary'
										aria-label='upload picture'
										// component='span'
									>
										<PhotoCamera />
										<Typography className='ms-2' variant='caption'>
											Камера
										</Typography>
									</button>
									<div style={{ width: 130 }}>
										<input
											lang='ru'
											type='file'
											name='passportBack'
											className='form-control form-control-md form-control-solid'
											placeholder='File'
											onChange={(e: any) => handleBack(e, helpersBack)}
										/>
									</div>
									{/* <input type='file' onChange={handleBack} /> */}
									{metaBack.touched && metaBack.error && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block text-danger'>
												{metaBack.error}
											</div>
										</div>
									)}
								</div>}
							</div>
						</div>
						{/* </div> */}
						<div className='row mb-6'>
							<label className='col-lg-3 col-form-label fw-bold fs-6'>
								{/* {intl.formatMessage({ id: 'TASK.DETAILS.TITLE' })} */}
								Тип пасспорта
							</label>
							<div className='col-lg-9 fv-row d-flex'>
								<label className='d-flex  cursor-pointer mb-5 me-5'>
									<span className='form-check form-check-custom form-check-solid me-3'>
										{/* <input
											value={1}
											checked={formik.values.docType === 1}
											onChange={() => formik.setFieldValue('docType', 1)}
											name='docType'
											type='radio'
											className='form-check-input'
										/> */}
										<Field
											type='radio'
											className='form-check-input'
											name='docType'
											value={'1'}
										/>
									</span>
									<span className='d-flex flex-column justify-content-center'>
										<span className=' fs-8'>id(новый)</span>
									</span>
								</label>
								<label className='d-flex  cursor-pointer mb-5 me-5'>
									<span className='form-check form-check-custom form-check-solid me-3'>
										{/* <input
											value={2}
											checked={formik.values.docType === 2}
											onChange={() => formik.setFieldValue('docType', 2)}
											name='docType'
											type='radio'
											className='form-check-input'
										/> */}
										<Field
											type='radio'
											className='form-check-input'
											name='docType'
											value={'2'}
										/>
									</span>
									<span className='d-flex flex-column justify-content-center'>
										<span className=' fs-8'>id(старый)</span>
									</span>
								</label>
								<label className='d-flex  cursor-pointer mb-5 me-5'>
									<span className='form-check form-check-custom form-check-solid me-3'>
										{/* <input
											value={3}
											checked={formik.values.docType === 3}
											onChange={() => formik.setFieldValue('docType', 3)}
											name='docType'
											type='radio'
											className='form-check-input'
										/> */}
										<Field
											className='form-check-input'
											type='radio'
											name='docType'
											value={'3'}
										/>
									</span>
									<span className='d-flex flex-column justify-content-center'>
										<span className=' fs-8'>Загран паспорт</span>
									</span>
								</label>
							</div>
						</div>
					</div>

					{/* <div className='fv-plugins-message-container invalid-feedback'>
						<ErrorMessage name='accountType' />
					</div> */}
				</div>
			</div>
		</div>
	)
}

export { IdentifyStep1 }
