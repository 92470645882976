import React from 'react'
import ReactDOM from 'react-dom'
// Redux
// https://github.com/rt2zz/redux-persist
import { Provider } from 'react-redux'
import store from './ts/setup/redux/Store'
// Axios
import axios from './ts/setup/axios/axios-config'
import axiosOFD from './ts/setup/axios/axios-config-ofd'

import { Chart, registerables } from 'chart.js'

// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
// Apps
import { App } from './ts/App'
import { MetronicI18nProvider } from './ts/components/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './sass/style.scss'
import './sass/style.react.scss'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

axios.interceptors.request.use(config => {
	try {
		const token = localStorage.getItem('token')
		if (token && token !== null && token !== 'null' && config.url !== '/authenticate') config.headers['Authorization'] = `Bearer ${token}`
	} catch {
		// do nothing
	}
	return config
})
axiosOFD.interceptors.request.use(config => {
	try {
		const token = localStorage.getItem('ofdToken')
		if (token && token !== null && token !== 'null' && config.url !== '/authenticate') config.headers['Authorization'] = `Bearer ${token}`
	} catch {
		// do nothing
	}
	return config
})

Chart.register(...registerables)

ReactDOM.render(
	<MetronicI18nProvider>
		<Provider store={store}>
			{/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
			<App basename={PUBLIC_URL} />
		</Provider>
	</MetronicI18nProvider>,
	document.getElementById('root')
)
