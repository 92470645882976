import { Avatar, Box, Card, Grid, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { TaskImageSumb } from '../../components/partials/layout/TaskImageSumb'
import { ITaskComment, ITasks } from '../../models/tasks/tasksModel'
import { RootState } from '../../setup'
import {
	createTaskComment,
	deleteTaskComment,
	getPublicTasksDetails,
	getTaskComment,
	updateTaskComment,
} from '../../store/actions/calendarTaskAction'
import classnames from 'classnames/bind'
import { Editor } from '@tinymce/tinymce-react'
import TaskCommentsList from '../Task/TaskCommentsList'
import Swal from 'sweetalert2'
import React from 'react'
import paginationStyles from '../Task/paginationStyles.module.scss'
import ReactPaginate from 'react-paginate'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'


let cnPage = classnames.bind(paginationStyles)

const menubar = false

const plugins =
	'advlist autolink lists image link tinydrive imagetools charmap print preview anchor ' +
	'searchreplace visualblocks code fullscreen ' +
	'insertdatetime media table paste code help wordcount emoticons '

const toolbar =
	'undo redo | formatselect | emoticons | ' +
	'bold italic backcolor | alignleft aligncenter ' +
	'alignright alignjustify |  image link | bullist numlist outdent indent | ' +
	'removeformat | help '

const BASE_URL = process.env.REACT_APP_BASE_URL

const PublicTaskDetailsPage = () => {
	const params: any = useParams()
	const [comment, setComment] = React.useState('')
	const [editorKey, setEditorKey] = React.useState(4)
	const data: ITasks = useSelector<RootState, ITasks>(
		state => state.tasks.taskById,
		shallowEqual
	)
	const loading = useSelector<RootState, any>(
		state => state.tasks.loading,
		shallowEqual
	)

	const taskComment: ITaskComment = useSelector<RootState, ITaskComment>(
		state => state.tasks.taskComment,
		shallowEqual
	)

	const tasksCommentLoading: boolean = useSelector<RootState, boolean>(
		state => state.tasks.tasksCommentLoading,
		shallowEqual
	)

	const dispatch = useDispatch()
	const intl = useIntl()
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.up('sm'))
	useEffect(() => {
		dispatch(getPublicTasksDetails(params.id))
	}, [])
	useEffect(() => {
		if (data.id) {
			dispatch(getTaskComment({ taskId: data.id, page: 0 }))
		}
	}, [data])

	if (loading) {
		return (
			<>
				<span className='spinner-border text-light'></span>{' '}
				<span className='text-light'> Loading...</span>
			</>
		)
	}

	// const editorRef = React.useRef<any>()
	const clearEditor = () => {
		const newKey = editorKey * 43
		setEditorKey(newKey)
	}
	if (loading) {
		return (
			<>
				<span className='spinner-border text-light'></span>{' '}
				<span className='text-light'> Loading...</span>
			</>
		)
	}

	const handleSubmit = (e: any) => {
		e.preventDefault()

		dispatch(createTaskComment({ taskId: data.id, text: comment }))

		setComment('')
		clearEditor()
	}

	const handleChangeComment = (arg: { commentId: any; text: any }) => {
		dispatch(
			updateTaskComment({
				taskId: data.id,
				// userId: user.id,
				commentId: arg.commentId,
				text: arg.text,
			})
		)
	}

	const handleDeleteComment = (comment: any) => {
		Swal.fire({
			title: `${intl.formatMessage({ id: 'WARNING.DELETE.COMMENT' })}`,
			text: ``,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: `${intl.formatMessage({
				id: 'WARNING.DELETE.SUBMIT',
			})}`,
			cancelButtonText: `${intl.formatMessage({
				id: 'WARNING.DELETE.CANCEL',
			})}`,
		}).then(result => {
			if (result.isConfirmed) {
				dispatch(
					deleteTaskComment({
						taskId: data.id,
						// userId: user.id,
						commentId: comment.id,
					})
				)

				Swal.fire({
					title: intl.formatMessage({
						id: 'NOTE.DELETE.COMMENT.SUCCESS',
					}),
					icon: 'success',
					timer: 1500,
				})
			}
		})
	}
	const handleChange = (e: any) => {
		let html = e.target.getContent()
		// setContentText(html)
		setComment(html)
	}

	const handlePageClick = (e: any) => {
		dispatch(getTaskComment({ taskId: data.id, page: e.selected }))
	}

	function example_image_upload_handler(
		blobInfo: any,
		success: any,
		failure: any,
		progress: any
	) {
		var xhr: XMLHttpRequest, formData

		xhr = new XMLHttpRequest()
		// xhr.withCredentials = true
		xhr.open('POST', `${BASE_URL}/minio/file/media`)
		// xhr.setRequestHeader('Authorization', `Bearer ${token}`)

		xhr.upload.onprogress = function (e) {
			progress((e.loaded / e.total) * 100)
		}
		xhr.onload = function () {
			var json

			if (xhr.status === 403) {
				failure('HTTP Error: ' + xhr.status, { remove: true })
				return
			}

			if (xhr.status < 200 || xhr.status >= 300) {
				failure('HTTP Error: ' + xhr.status)
				return
			}

			json = JSON.parse(xhr.responseText)

			if (!json || typeof json.baseFileUrl != 'string') {
				failure('Invalid JSON: ' + xhr.responseText)
				return
			}
			try {
				success(json?.baseFileUrl)
			} catch (e: any) {}
		}

		xhr.onerror = function () {
			failure(
				'Image upload failed due to a XHR Transport error. Code: ' + xhr.status
			)
		}

		formData = new FormData()
		formData.append('file', blobInfo.blob(), blobInfo.filename())
		formData.append('name', blobInfo.filename())

		xhr.send(formData)
	}
	const createMarkup = () => {
		if (data) {
			return { __html: data?.description || '' }
		}
		return { __html: '' }
	}

	return (
		<>
			<div className={`card `}>
				{/* begin::Header */}
				<div className='card-header border-0 pt-5'>
					<h3 className='card-title align-items-start flex-column'>
						<span className='card-label fw-bolder fs-3 mb-1'>
							{intl.formatMessage({ id: 'TASK.DETAILS' })}
						</span>
						{/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span> */}
					</h3>
				</div>
				{/* end::Header
      {/* begin::Body */}
				<div className='card-body py-3'>
					{/* begin::Table container */}
					<Stack spacing={2}>
						<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: matches ? '': 'column'}}>
							<Box sx={{ width: matches ? 3 / 12: 1, padding: 1 }}>
								<Typography fontWeight='medium'>
									{intl.formatMessage({ id: 'TASK.MODAL.TITLE' })}
								</Typography>
							</Box>
							<Box sx={{ width: matches ? 9 / 12: 1, padding: 1 }}>
								<Typography>
									{data.title ||
										intl.formatMessage({ id: 'TASK.DEFAULT.TEXT' })}
								</Typography>
							</Box>
						</Box>

						<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: matches ? '': 'column' }}>
							<Box sx={{ width: matches ? 3 / 12: 1, padding: 1 }}>
								<Typography fontWeight='medium'>
									{/* {intl.formatMessage({ id: 'TASK.MODAL.DESCRIPTION' })} */}
									Описание
								</Typography>
							</Box>
							<Box sx={{ width: matches ? 9 / 12: 1, padding: 1 }}>
								<Typography component={'div'} variant={'body2'}>
									<div dangerouslySetInnerHTML={createMarkup()} />
								</Typography>
							</Box>
						</Box>
						{/* //==============================DATE */}
						<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: matches ? '': 'column' }}>
							<Box sx={{ width: matches ? 3 / 12: 1, padding: 1 }}>
								<Typography fontWeight='medium'>
									{intl.formatMessage({ id: 'TASK.MODAL.START' })}
								</Typography>
							</Box>
							<Box sx={{ width: matches ? 9 / 12: 1, padding: 1 }}>
								<Typography>{data.start || 'No date'}</Typography>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: matches ? '': 'column' }}>
							<Box sx={{ width: matches ? 3 / 12: 1, padding: 1 }}>
								<Typography fontWeight='medium'>
									{intl.formatMessage({ id: 'TASK.MODAL.END' })}
								</Typography>
							</Box>
							<Box sx={{ width: matches ? 9 / 12: 1, padding: 1 }}>
								<Typography>
									{data.end || intl.formatMessage({ id: 'TASK.DEFAULT.DATE' })}
								</Typography>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: matches ? '': 'column' }}>
							<Box sx={{ width: matches ? 3 / 12: 1, padding: 1 }}>
								<Typography fontWeight='medium'>
									{intl.formatMessage({ id: 'TASK.MODAL.STATUS' })}
								</Typography>
							</Box>
							<Box sx={{ width: matches ? 9 / 12: 1, padding: 1 }}>
								<Typography>
									{data.status?.code ||
										intl.formatMessage({ id: 'TASK.DEFAULT.STATUS' })}
								</Typography>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: matches ? '': 'column' }}>
							<Box sx={{ width: matches ? 3 / 12: 1, padding: 1 }}>
								<Typography fontWeight='medium'>
									{intl.formatMessage({ id: 'TASK.MODAL.CREATOR' })}
								</Typography>
							</Box>
							<Box sx={{ width: matches ? 9 / 12: 1, padding: 1 }}>
								<Typography>
									{data.creator?.fullName ||
										intl.formatMessage({ id: 'TASK.DEFAULT.CREATOR' })}
								</Typography>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: matches ? '': 'column' }}>
							<Box sx={{ width: matches ? 3 / 12: 1, padding: 1 }}>
								<Typography fontWeight='medium'>
									{intl.formatMessage({ id: 'TASK.MODAL.ASSIGNEE' })}
								</Typography>
							</Box>
							<Box sx={{ width: matches ? 9 / 12: 1, padding: 1 }}>
								<Typography>
									{data.assignee?.fullName ||
										intl.formatMessage({ id: 'TASK.DEFAULT.ASSIGNEE' })}
								</Typography>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: matches ? '': 'column' }}>
							<Box sx={{ width: matches ? 3 / 12: 1, padding: 1 }}>
								<Typography fontWeight='medium'>Наблюдатели</Typography>
							</Box>
							<Box sx={{ width: matches ? 9 / 12: 1, padding: 1 }}>
								<Typography>
									{data.watchers?.length === 0
										? 'Никого нет'
										: data.watchers?.map((watcher: any) => {
												return (
													<Typography key={watcher?.id}>
														{watcher?.fullName}
													</Typography>
												)
										  })}
								</Typography>
							</Box>
						</Box>

						{/* //==============================ATTACHMENTS */}
						<Box sx={{ display: 'flex', flexDirection: matches ? '': 'column' }}>
							<Typography fontWeight='medium' sx={{ p: 1 }}>
								{intl.formatMessage({ id: 'TASK.MODAL.ATTACHMENTS' })}
							</Typography>
							<Card variant='outlined' sx={{ p: 1, fontWeight: 'regular' }}>
								{data.attachments?.length === 0
									? intl.formatMessage({ id: 'TASK.DEFAULT.ATTACHMENTS' })
									: data.attachments?.map((element: any) => {
											return (
												<TaskImageSumb
													key={element.id}
													img={`${BASE_URL}/minio/file/media/${element.uuid}`}
													name={element.name}
													size={element.size}
													date={element.lastModified}
												/>
											)
									  })}
							</Card>
						</Box>
						<Typography fontWeight='medium' sx={{ pb: 1 }}>
							Комментарии
						</Typography>
						{
							<Grid container wrap='nowrap'>
								<Grid sx={{ pr: 2 }} item>
									<Avatar alt='Remy Sharp' />
								</Grid>

								<Grid
									component='form'
									justifyContent='left'
									item
									xs
									zeroMinWidth
									noValidate
									autoComplete='off'
									onSubmit={handleSubmit}
								>
									<div className='form-group border radius-5'>
										<Editor
											// onInit={(evt, editor) => (editorRef.current = editor)}
											key={editorKey}
											apiKey='x933w4qvw7ckm4dwx6qfe39t1e0qq0dj6wc19z4a2wet0gbg'
											init={{
												plugins,
												toolbar,
												menubar,
												height: 300,
												visual: false,
												powerpaste_allow_local_images: true,
												language: 'ru',
												images_upload_url: `${BASE_URL}/minio/file`,
												images_upload_handler: example_image_upload_handler,
												file_browser_callback_types: 'file image media',
												path_absolute: '/',
												tinydrive_token_provider: `${BASE_URL}/minio/file`,
												file_picker_callback: function (cb, value, meta) {
													var input = document.createElement('input')
													input.setAttribute('type', 'file')
													var xhr: XMLHttpRequest, formData
													xhr = new XMLHttpRequest()
													input.onchange = function (e: any) {
														var file = e.target.files[0]
														xhr.open('POST', `${BASE_URL}/minio/file/media`)
														// xhr.setRequestHeader(
														// 	'Authorization',
														// 	`Bearer ${token}`
														// )
														xhr.onload = function () {
															var json
															if (xhr.status === 403) {
																return
															}
															if (xhr.status < 200 || xhr.status >= 300) {
																return
															}
															json = JSON.parse(xhr.responseText)

															if (
																!json ||
																typeof json.baseFileUrl != 'string'
															) {
																return
															}
															cb(json.baseFileUrl, {
																alt: json.name,
																title: json.name,
																text: json.name,
															})
														}

														xhr.onerror = function () {}

														formData = new FormData()
														formData.append('file', file)
														formData.append('name', file.name)

														xhr.send(formData)
													}

													input.click()
												},
											}}
											onChange={handleChange}
										/>
									</div>

									<button type='submit' className='btn btn-primary my-5 btn-sm'>
										{' '}
										Отправить
									</button>
								</Grid>
							</Grid>
						}
						{taskComment?.content?.map(item => {
							return (
								<TaskCommentsList
									// user={user}
									key={item.id}
									handleChangeComment={handleChangeComment}
									handleDeleteComment={handleDeleteComment}
									item={item}
								/>
							)
						})}

						{tasksCommentLoading && (
							<div className='d-flex justify-content-center'>
								<span className='spinner-border d-flex justify-content-center'></span>
							</div>
						)}
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '20px',
							}}
						>
							<div className='fs-6 fw-bold text-gray-700'>
								Всего комментариев
								{'  ' + taskComment?.totalElements}
							</div>

							<ReactPaginate
								previousLabel={'<'}
								nextLabel={'>'}
								pageCount={taskComment.totalPages}
								onPageChange={handlePageClick}
								containerClassName={cnPage('pagination')}
								previousLinkClassName={cnPage('pagination__link')}
								nextLinkClassName={cnPage('pagination__link')}
								disabledClassName={cnPage('pagination__link--disabled')}
								activeClassName={cnPage('pagination__link--active')}
								pageRangeDisplayed={2}
								marginPagesDisplayed={2}
								forcePage={taskComment.page}
							/>
						</div>
					</Stack>
				</div>
			</div>
		</>
	)
}

export default PublicTaskDetailsPage
