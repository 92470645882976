import {
	CREATE_TASK_STATUS,
	DELETE_TASK_STATUS,
	GET_TASK_STATUS_ERROR,
	GET_TASK_STATUS_REQUEST,
	GET_TASK_STATUS_SUCCESS,
	UPDATE_TASK_STATUS,
} from '../taskStatusTypes'

export const getTaskStatusesAction = (taskStatusPage: any) => ({
	type: GET_TASK_STATUS_REQUEST,
	taskStatusPage,
})
export const getTaskStatusesSuccessAction = (taskStatus: any) => ({
	type: GET_TASK_STATUS_SUCCESS,
	taskStatus,
})
export const getTaskStatusesErrorAction = (error: any) => ({
	type: GET_TASK_STATUS_ERROR,
	error,
})

export const createTaskStatusAction = (taskStatus: any, page: any) => ({ type: CREATE_TASK_STATUS, taskStatus, page})
export const updateTaskStatusAction = (taskStatus: any, taskStatusId: any, page: any) => ({
	type: UPDATE_TASK_STATUS,
	taskStatus,
	taskStatusId,
	page
})
export const deleteTaskStatusAction = (taskStatus: { id: any; meta: any }) => ({
	type: DELETE_TASK_STATUS,
	taskStatus,
})
