import { FileOrFolderModel, FileVersionModel, UsersOfSharedFile, SharedFileModel } from "../../models/storage/FileModel";
import {
    CREATE_FOLDER_REQUEST,
    CREATE_FOLDER_SUCCESS,
    GET_FILES_AND_FOLDERS,
    PREVIEW_IMAGE_REQUEST,
    PREVIEW_IMAGE_SUCCESS,
    REQUEST_FILES_AND_FOLDERS,
    STORAGE_ERROR,
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    MOVE_TO_FOLDER_REQUEST,
    UPDATE_CURRENT_FOLDER,
    ENABLE_PUBLIC_LINK_REQUEST,
    ENABLE_PUBLIC_LINK_SUCCESS,
    DISABLE_PUBLIC_LINK_REQUEST,
    UPDATE_PDF_PREVIEW_LINK,
    DELETE_FILE_REQUEST,
    DELETE_FOLDER_REQUEST,
    FILE_VERSION_LIST_REQUEST,
    FILE_VERSION_LIST_SUCCESS,
    FILE_VERSION_PREVIEW_REQUSET,
    GET_SHARED_FILES_REQUEST,
    GET_SHARED_FILES_SUCCESS,
    GET_USERS_OF_SHARED_FILE_REQUEST,
    GET_USERS_OF_SHARED_FILE_SUCCESS,
    DELETE_USER_ACCESS_TO_SHARED_FILE_REQUSET,
    VALIDATE_USERNAME_REQUEST,
    VALIDATE_USERNAME_SUCCESS,
    SHARE_FILE_TO_USER_REQUEST,
    UPDATE_SHARE_FILE_REQUEST
} from "../actionTypes";


export const getFilesAndFoldersAction = (filesAndFolders: FileOrFolderModel[]) => ({ type: GET_FILES_AND_FOLDERS, filesAndFolders })
export const requestFilesAndFolderAction = (parentId = -1) => ({ type: REQUEST_FILES_AND_FOLDERS, parentId })
export const errorStorage = (error: any) => ({ type: STORAGE_ERROR, error })

export const uploadFileAction = (file: FileOrFolderModel) => ({ type: UPLOAD_FILE_SUCCESS, file })
export const requestUploadFileAction = (file: FormData, parentId: number) => ({ type: UPLOAD_FILE_REQUEST, file, parentId })

export const createFolderAction = (folder: FileOrFolderModel) => ({ type: CREATE_FOLDER_SUCCESS, folder })
export const requestCreateFolderAction = (folderName: string, parentId: number) => ({ type: CREATE_FOLDER_REQUEST, folderName, parentId })

export const requestImagePreviewAction = (id: number, name: string, show: boolean, versionReq = false, download = false) => ({ type: PREVIEW_IMAGE_REQUEST, id, name, show, versionReq, download })
export const imagePreviewAction = (image: { url: string, name: string, show: boolean }) => ({ type: PREVIEW_IMAGE_SUCCESS, image })

export const requestMoveToFolder = (id: number, parentId: number, isFolder: boolean, folderId: number) => ({ type: MOVE_TO_FOLDER_REQUEST, id, parentId, isFolder, folderId })

export const updateCurrentFolder = (folder: { parentIdArr: number[], nameArr: string[], currFolderId: number }) => ({ type: UPDATE_CURRENT_FOLDER, folder })

export const requestEnablePublicLink = (id: number) => ({ type: ENABLE_PUBLIC_LINK_REQUEST, id })
export const enablePublicLinkAction = (link: string) => ({ type: ENABLE_PUBLIC_LINK_SUCCESS, link })

export const requestDisablePublicLink = (id: number) => ({ type: DISABLE_PUBLIC_LINK_REQUEST, id })

export const updatePdfPreviewLink = (link: string) => ({ type: UPDATE_PDF_PREVIEW_LINK, link })

export const deleteFileRequest = (id: number) => ({ type: DELETE_FILE_REQUEST, id })
export const deleteFolderRequest = (id: number) => ({ type: DELETE_FOLDER_REQUEST, id })

export const fileVersionRequest = (id: number) => ({ type: FILE_VERSION_LIST_REQUEST, id })
export const fileVersionSuccess = (list: FileVersionModel[]) => ({ type: FILE_VERSION_LIST_SUCCESS, list })
export const fileVersionPreviewRequest = (id: number, name: string) => ({ type: FILE_VERSION_PREVIEW_REQUSET, id, name })

export const getSharedFilesRequest = () => ({ type: GET_SHARED_FILES_REQUEST })
export const getSharedFilesSuccess = (list: SharedFileModel[]) => ({ type: GET_SHARED_FILES_SUCCESS, list })
export const getUsersOfSharedFileRequest = (fileId: number) => ({ type: GET_USERS_OF_SHARED_FILE_REQUEST, fileId })
export const getUsersOfSharedFileSuccess = (list: UsersOfSharedFile[]) => ({ type: GET_USERS_OF_SHARED_FILE_SUCCESS, list })
export const deleteUserAccessToSharedFile = (userId: number, fileId: number) => ({ type: DELETE_USER_ACCESS_TO_SHARED_FILE_REQUSET, userId, fileId })
export const validateUsernameRequest = (username: string) => ({ type: VALIDATE_USERNAME_REQUEST, username })
export const validateUsernameSuccess = (user: { username: string, userId: number, exists: boolean }) => ({ type: VALIDATE_USERNAME_SUCCESS, user })
export const shareFileToUserRequest = (fileId: number, userId: number, isWriteAccess: boolean) => ({ type: SHARE_FILE_TO_USER_REQUEST, fileId, userId, isWriteAccess })
export const updateShareFileRequest = (file: FormData, id: number) => ({ type: UPDATE_SHARE_FILE_REQUEST, file, id })