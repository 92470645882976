import { AnyAction } from 'redux'
import { ITimeTracker, ITimeTrackerInfo } from '../../models/timeTracker/timeModel'
import { GET_ORGANIZATIONS_TIMES, GET_ORGANIZATIONS_TIMES_ERROR, GET_ORGANIZATIONS_TIMES_SUCCESS, GET_TIMES_TRACKER_INFO, GET_TIMES_TRACKER_INFO_ERROR, GET_TIMES_TRACKER_INFO_SUCCESS } from '../timeTrackerTypes'

export interface trackerState {
	times: ITimeTracker
	noteError: any
	loading: boolean
	timesInfo: ITimeTrackerInfo
}
const initialState: trackerState = {
	times: {} as ITimeTracker,
	noteError: '',
	loading: false,
	timesInfo: {} as ITimeTrackerInfo,
}

const timeTrackerReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case GET_ORGANIZATIONS_TIMES:
			return { ...state, times: {}, loading: true }
		case GET_ORGANIZATIONS_TIMES_SUCCESS:
			return { ...state, times: action.times, loading: false }
		case GET_ORGANIZATIONS_TIMES_ERROR:
			return { ...state, noteError: action.error }

		case GET_TIMES_TRACKER_INFO:
			return { ...state, timesInfo: {} }
		case GET_TIMES_TRACKER_INFO_SUCCESS:
			console.log(action)
			return { ...state, timesInfo: action.timesInfo }
		case GET_TIMES_TRACKER_INFO_ERROR:
			return { ...state, noteError: action.error }
		default:
			return state
	}
}

export default timeTrackerReducer
