import axios from '../../setup/axios/axios-config-ofd'
import { AnyAction } from "redux"
import { takeLatest, put, call } from 'redux-saga/effects'
import { CREATE_CASHIER_REQUEST, GET_CASHIER_LIST_REQUEST, GET_LIST_OFD_REQUEST, GET_SHIFT_LIST_REQUSET, INIT_CASHIER_REQUEST, UNBLOCK_CASHIER_REQUEST } from '../actionTypes'
import { errorCashier, getCashierListRequest, getCashierListSuccess, getOfdListSuccess, getShiftListSuccess } from '../actions/cashierActions'
import { GetCashierListResponce, OFD, activationData, ShiftResData } from '../../models/cashier/FileModel'
import { setOfdLoadingAction } from '../actions/commonOFDAction'


type createCashierData = {
    organizationId: number
    factoryNumber: string
    factionalAddress: string
}


const fetchCashierList = (props: string) => axios.get(`/kkm${props}`).then(res => res.data)
const createCashier = (data: createCashierData) => axios.post(`/kkm`, data).then(res => res.data)
const fetchOfdList = () => axios.get(`/ofd`).then(res => res.data)
const fetchInitCashier = (data: activationData) => axios.put(`/kkm/activation`, data).then(res => res.data)
const fetchUnblockCashier = (id: number) => axios.put(`/kkm/unblocking`, { kkmId: id }).then(res => res)
const fetchShiftList = (kkmId: string, props: string) => axios.get(`/shift?kkmId=${kkmId}${props}`).then(res => res.data)

function* getCashierListSaga(action: AnyAction) {
    try {
        yield put(setOfdLoadingAction(true))
        const data: GetCashierListResponce = yield call(fetchCashierList, action.props)
        yield put(getCashierListSuccess(data))
        yield put(setOfdLoadingAction(false))
    } catch (e) {
        yield put(errorCashier(e))
    }
}
function* createCashierSaga(action: AnyAction) {
    try {
        yield put(setOfdLoadingAction(true))
        const data = {
            organizationId: action.organizationId,
            factoryNumber: action.factoryNumber,
            factionalAddress: action.factionalAddress
        }
        yield call(createCashier, data)
        yield put(getCashierListRequest(''))
        yield put(setOfdLoadingAction(false))
    } catch (e) {
        yield put(errorCashier(e))
    }
}
function* getOFDListSaga(action: AnyAction) {
    try {
        const data: OFD[] = yield call(fetchOfdList)
        yield put(getOfdListSuccess(data))
    } catch (e) {
        yield put(errorCashier(e))
    }
}
function* initCashierSaga(action: AnyAction) {
    try {
        yield put(setOfdLoadingAction(true))
        yield call(fetchInitCashier, action.data)
        yield put(getCashierListRequest(action.props))
        yield put(setOfdLoadingAction(false))
    } catch (e) {
        yield put(errorCashier(e))
    }
}
function* unblockCashierSaga(action: AnyAction) {
    try {
        yield put(setOfdLoadingAction(true))
        yield call(fetchUnblockCashier, action.id)
        yield put(getCashierListRequest(action.props))
        yield put(setOfdLoadingAction(false))
    } catch (e) {
        yield put(errorCashier(e))
    }
}
function* getShiftListSaga(action: AnyAction) {
    try {
        yield put(setOfdLoadingAction(true))
        const data: ShiftResData = yield call(fetchShiftList, action.id, action.props)
        yield put(getShiftListSuccess(data))
        yield put(setOfdLoadingAction(false))
    } catch (e) {
        yield put(errorCashier(e))
    }
}

export function* watchCashier() {
    yield takeLatest(GET_CASHIER_LIST_REQUEST, getCashierListSaga)
    yield takeLatest(CREATE_CASHIER_REQUEST, createCashierSaga)
    yield takeLatest(GET_LIST_OFD_REQUEST, getOFDListSaga)
    yield takeLatest(INIT_CASHIER_REQUEST, initCashierSaga)
    yield takeLatest(UNBLOCK_CASHIER_REQUEST, unblockCashierSaga)
    yield takeLatest(GET_SHIFT_LIST_REQUSET, getShiftListSaga)
}