import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { KTSVG } from '../../components/helpers/KTSVG'
import { IHelpContent } from '../../models/tasks/tasksModel'
import { deleteTaskHelpAction } from '../../store/actions/taskhelpAction'
import { useIntl } from 'react-intl'

interface IhelpListProps {
	help: IHelpContent
	setParentId: (parentId: any) => void
	page: any
	setHelpModalUpdate: React.Dispatch<React.SetStateAction<boolean>>
	setHelpModalAdd: React.Dispatch<React.SetStateAction<boolean>>
	setData: React.Dispatch<React.SetStateAction<{}>>
}

const TaskHelpList = memo(
	({
		help,
		page,
		setParentId,
		setHelpModalUpdate,
		setHelpModalAdd,
		setData,
	}: IhelpListProps) => {
		const dispatch = useDispatch()
		const intl = useIntl()

		const handleDelete = (id: any) => {

			Swal.fire({
				title: intl.formatMessage({ id: 'BOT_HELP_DELETE_NOTE' }),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: intl.formatMessage({ id: 'BOT_HELP_DELETE_NO' }),
				confirmButtonText: intl.formatMessage({ id: 'BOT_HELP_DELETE_YES' }),
			}).then((result) => {
				if (result.isConfirmed) {
					dispatch(deleteTaskHelpAction({ id: id, meta: page }))
					Swal.fire(
						intl.formatMessage({ id: 'NOTE.DELETED' }),
						intl.formatMessage({ id: 'NOTE.DELETED.INFO' }),
						'success'
					)
				}
			})
		}

		const handleModal = (help: any) => {
			setData(help)
			setHelpModalUpdate(true)
		}

		const handleModalAdd = (parentId: any) => {
			setParentId(parentId);
			setHelpModalAdd(true)
		}

		return (
			<div
				className='row w-100'
				key={help.id}
			>
				{/* <div className='col-2'>
					<div className='d-flex align-items-center '>
						<div className='symbol symbol-45px '></div>
						<div className='d-flex justify-content-start flex-column'>
							<span className='text-dark fw-bolder text-hover-primary fs-6'>
								{help?.code || intl.formatMessage({ id: 'TASK.DEFAULT.TEXT' })}
							</span>
						</div>
					</div>
				</div> */}
				<div className='col-2'>
					{/* <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                          
                        </a> */}
					<span className='text-muted fw-bold d-block fs-7'>
						{help?.title || intl.formatMessage({ id: 'TASK.DEFAULT.TEXT' })}
					</span>
				</div>
				<div className='col-2'>
					<div>
						<span className='text-muted fw-bold d-block fs-7'>
							{help?.description ||
								intl.formatMessage({ id: 'TASK.DEFAULT.TEXT' })}
						</span>
					</div>
				</div>
				<div className='col-2'>
					<div>
						<span className='text-muted fw-bold d-block fs-7'>
							{help?.parentId ||
								intl.formatMessage({ id: 'TEXT.DEFAULT.NODATA' })}
						</span>
					</div>
				</div>
				<div className='col-4'>
					<div>
						<span className='text-muted fw-bold d-block fs-7 text-wrap'>
							{help.response ||
								intl.formatMessage({ id: 'TEXT.DEFAULT.NODATA' })}
						</span>
					</div>
				</div>
				{/* <div className='col-2'>
					<div>
						<span className='text-muted fw-bold d-block fs-7'>
							{help.statusCode ||
								intl.formatMessage({ id: 'TEXT.DEFAULT.NODATA' })}
						</span>
					</div>
				</div> */}
				<div className='col-2'>
					<div className='d-flex justify-content-end flex-shrink-0'>
						<span
							onClick={(e) => { e.stopPropagation(); handleModalAdd(help.id) }}
							className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
						>
							<KTSVG
								path='/media/icons/duotune/arrows/arr075.svg'
								className='svg-icon-3'
							/>
						</span>
						<span
							onClick={(e) => { e.stopPropagation(); handleModal(help) }}
							className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
						>
							<KTSVG
								path='/media/icons/duotune/art/art005.svg'
								className='svg-icon-3'
							/>
						</span>
						<a
							onClick={(e) => { e.stopPropagation(); handleDelete(help.id) }}
							href='#'
							className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
						>
							<KTSVG
								path='/media/icons/duotune/general/gen027.svg'
								className='svg-icon-3'
							/>
						</a>
					</div>
				</div>
			</div>
		)
	}
)

export default TaskHelpList
