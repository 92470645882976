import React, { useState } from 'react'
import { Card, Col, Modal } from 'react-bootstrap'
import { Shift } from '../../models/cashier/FileModel'
import ZReportModal from './ZReportModal'

type Props = {
    oneShift: Shift
    factoryNumber: string
    show: boolean
    close: CallableFunction
}
type ZReportState = {
    modalShow: boolean
    shiftId: number
}

const ShiftInfoModal = ({
    oneShift,
    factoryNumber,
    show,
    close
}: Props) => {
    const [zReportModal, setZReportModal] = useState<ZReportState>({ modalShow: false, shiftId: 0 })
    const timeAndDate = (isOpenTime: boolean) => {
        let fullDate = new Date(oneShift.closeTime)
        if (isOpenTime) fullDate = new Date(oneShift.openTime)
        const day = fullDate.getDate()
        const month = fullDate.getMonth() + 1
        const year = fullDate.getFullYear()
        const hour = fullDate.getHours()
        const minute = fullDate.getMinutes()
        const exactMinutes = minute.toString().length === 1 ? '0' + minute.toString() : minute
        return (
            <>
                <div>
                    {day + '-' + month + '-' + year}, {hour + ':' + exactMinutes}
                </div>
            </>
        )
    }
    const openZReportHanlder = () => {
        setZReportModal({ shiftId: oneShift.shiftId, modalShow: true })
    }
    const openCashierRibbonHanlder = () => {

    }
    const closeZReportHandler = () => {
        setZReportModal({ shiftId: 0, modalShow: false })
    }
    return (
        <>
            <ZReportModal show={zReportModal.modalShow} close={closeZReportHandler} />
            <Modal
                show={show}
                centered
                onHide={() => close()}
            >
                <Card className='organization-modal-box'>
                    <div>
                        <h6 className='organization-list-title'>
                            Информация о смене
                        </h6>
                        <div className='organization-w-100 shift-height-50vh'>
                            <div className='m-b-40-px d-flex space-betwwen '>
                                <div className='cashier-create-column'>
                                    <h4>Заводской номер кассы</h4>
                                </div>
                                <div className='cashier-create-column'>
                                    <h4>{factoryNumber}</h4>
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <h4>Номер смены</h4>
                                </div>
                                <div className='cashier-create-column'>
                                    <h4>{oneShift.shiftNumber}</h4>
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <h4>Дата и время открытия смены</h4>
                                </div>
                                <div className='cashier-create-column'>
                                    <h4>{timeAndDate(true)}</h4>
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <h4>Дата и время закрытия смены</h4>
                                </div>
                                <div className='cashier-create-column'>
                                    <h4>{timeAndDate(false)}</h4>
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <h4>Остаток наличности при закрытии</h4>
                                </div>
                                <div className='cashier-create-column'>
                                    <h4>ДОБАВИТЬ ДАННЫЕ</h4>
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <h4>Продажа (кол-во, сумма)</h4>
                                </div>
                                <div className='cashier-create-column'>
                                    <h4>{oneShift.operationsBuyCount}, ДОБАВИТЬ ДАННЫЕ</h4>
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <h4>Покупка (кол-во, сумма)</h4>
                                </div>
                                <div className='cashier-create-column'>
                                    <h4>{oneShift.operationsSellCount}, ДОБАВИТЬ ДАННЫЕ</h4>
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <h4>Возврат продажи (кол-во, сумма)</h4>
                                </div>
                                <div className='cashier-create-column'>
                                    <h4>{oneShift.operationsBuyReturnCount}, ДОБАВИТЬ ДАННЫЕ</h4>
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <h4>Возврат покупки (кол-во, сумма)</h4>
                                </div>
                                <div className='cashier-create-column'>
                                    <h4>{oneShift.operationsSellReturnCount}, ДОБАВИТЬ ДАННЫЕ</h4>
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <h4>Задаток (кол-во, сумма)</h4>
                                </div>
                                <div className='cashier-create-column'>
                                    <h4>{oneShift.operationsDepositCount}, ДОБАВИТЬ ДАННЫЕ</h4>
                                </div>
                            </div>
                            <div className='m-b-40-px d-flex space-betwwen'>
                                <div className='cashier-create-column'>
                                    <h4>Изъятие (кол-во, сумма)</h4>
                                </div>
                                <div className='cashier-create-column'>
                                    <h4>{oneShift.operationsWithdrawalCount}, ДОБАВИТЬ ДАННЫЕ</h4>
                                </div>
                            </div>
                        </div>


                        <Col className='d-flex space-betwwen ' >
                            <button
                                className='cashier-btn-create'
                                disabled={!oneShift.isClosed}
                                onClick={openZReportHanlder}
                            >
                                Z-отчет
                            </button>
                            <button
                                className='cashier-btn-create'
                                // disabled={!oneShift.isClosed}
                                onClick={openCashierRibbonHanlder}
                            >
                                Кассовая лента
                            </button>
                            <button
                                onClick={() => close()}
                                className='cashier-btn-create'>
                                Отмена
                            </button>

                        </Col>
                    </div>
                </Card>
            </Modal>
        </>
    )
}
export default ShiftInfoModal