import { authErrorAction } from '../actions/authActions'
import axios from '../../setup/axios/axios-config'
import { AuthModel } from '../../modules/auth/models/auth/AuthModel'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
	GET_USER_PROFILE,
	GET_USER_PROFILE_ERROR,
	GET_USER_PROFILE_SUCCESS,
	LOGIN_USER,
	LOGIN_USER_SUCCESS,
	LOGOUT_USER,
	LOGOUT_USER_SUCCESS,
} from '../actionTypes'
import { AnyAction } from 'redux'
import { UserModel } from '../../modules/auth/models/auth/UserModel'
import { AuthStatuses } from '../../helpers/AuthStatuses'
import { getUserPermissionsRequest, getUserPermissionSuccess } from '../actions/commonOFDAction'

const fetchLogin = (data: { email: string; password: string }) =>
	axios
		.post('/authenticate', { username: data.email, password: data.password })
		.then(res => res.data)
		.then(data => {
			const { status } = data
			if (status === AuthStatuses.invalidCredentials) {
				throw new Error('Не верный логин или пароль, попробуйте еще раз.')
			} else {
				localStorage.setItem('token', data.jwttoken)
				localStorage.setItem('ofdToken', data.jwtFiscalApiToken)
				return data
			}
		})
const fetchLogout = () => axios.get('/loggedOut').then(res => res.data)

const fetchUserData = () => axios.get('/current-user').then(res => res.data)

function* loginSaga(action: AnyAction) {
	try {
		const auth: AuthModel = yield call(fetchLogin, action.data)
		yield put({ type: LOGIN_USER_SUCCESS, auth })
		yield put(getUserPermissionsRequest()) //запрос на разрешения пользователей ОФД
	} catch (e: any) {
		if (e.response?.status === 500) {
			yield put(authErrorAction('Произошла ошибка на сервере.'))
		} else if (e.message === 'Не верный логин или пароль, попробуйте еще раз.') {
			yield put(authErrorAction(e.message))
		} else if (e.message === 'Network Error') {
			yield put(authErrorAction('Ошибка сервера'))
		} else {
			yield put(authErrorAction('Нет подключения к интернету'))
		}

		// yield put(authErrorAction('Ошибка на сервере '))
	}
}

function* logoutSaga() {
	try {
		yield call(fetchLogout)
		yield put({ type: LOGOUT_USER_SUCCESS })
		yield put(getUserPermissionSuccess([])) //очистка разрешений пользователей ОФД
	} catch (e: any) {
		yield put(authErrorAction(e.message))
	}
}
function* getUserSaga() {
	try {
		const user: UserModel = yield call(fetchUserData)
		yield put({ type: GET_USER_PROFILE_SUCCESS, user })
	} catch (e) {
		localStorage.setItem('token', 'null')
		localStorage.setItem('ofdToken', 'null')
		yield put({ type: GET_USER_PROFILE_ERROR, error: e })
	}
}

export default function* watchAuth() {
	yield takeLatest(LOGIN_USER, loginSaga)
	yield takeLatest(GET_USER_PROFILE, getUserSaga)
	yield takeLatest(LOGOUT_USER, logoutSaga)
}
