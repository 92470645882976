import { IStatus } from '../../models/tasks/tasksModel'
import {
	CREATE_STATUS,
	CREATE_STATUS_SUCCESS,
	DELETE_STATUS,
	GET_STATUSES,
	GET_STATUSES_ERROR,
	GET_STATUSES_SUCCESS,
	GET_STATUS_BY_ID_REQUEST,
	GET_STATUS_BY_ID_SUCCESS,
	UPDATE_STATUS,
} from '../statusTypes'

export const getStatusesAction = () => ({ type: GET_STATUSES })
export const getStatusesSuccessAction = (statuses: IStatus[]) => ({
	type: GET_STATUSES_SUCCESS,
	statuses,
})
export const getStatusesErrorAction = (error: any) => ({
	type: GET_STATUSES_ERROR,
	error,
})
// export const getPersonalsAction = () => ({ type: GET_PERSONALS })

/////////////CREATE
export const createStatusRequest = (status: any) => ({
	type: CREATE_STATUS,
	status,
})

export const createStatusSuccess = (status: any) => ({
	type: CREATE_STATUS_SUCCESS,
	status,
})

/////////////DELETE
export const deleteStatusRequest = (statusId: any) => ({
	type: DELETE_STATUS,
	statusId,
})

/////////////UPDATE
export const updateStatusRequest = (statusId: number, status: any) => ({
	type: UPDATE_STATUS,
	statusId,
	status,
})

/////////////GET_BY_ID
export const getStatusByIdRequest = (statusId: number) => ({
	type: GET_STATUS_BY_ID_REQUEST,
	statusId,
})
export const getStatusByIdSuccess = (status: any) => ({
	type: GET_STATUS_BY_ID_SUCCESS,
	status,
})
