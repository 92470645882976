import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FC, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useLayout } from '../core'

const Footer: FC = () => {
	const { classes } = useLayout()
	const intl = useIntl()
	const [show, setShow] = useState(false)
	const [showContact, setShowContact] = useState(false)
	return (
		<>
			<div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
				{/* begin::Container */}
				<div
					className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
				>
					{/* begin::Copyright */}
					<div className='text-dark order-2 order-md-1'>
						<span className='text-muted fw-bold me-2'>
							{new Date().getFullYear()} &copy;
						</span>
						<a
							href='https://admin.paydala.kz/'
							className='text-gray-800 text-hover-primary'
						>
							Paydala.kz
						</a>
					</div>
					{/* end::Copyright */}

					{/* begin::Nav */}
					<ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
						<li className='menu-item'>
							<Link to={'/about'}>
								<p className='menu-link ps-0 pe-2'>
									{intl.formatMessage({id: 'ABOUT'})}
								</p>
							</Link>

						</li>
						<li className='menu-item'>
							<Link to={'/contacts'}>
								<p className='menu-link pe-0 pe-2'>
									{intl.formatMessage({id: 'CONTACT'})}
								</p>
							</Link>
						</li>
					</ul>
					{/* end::Nav */}
				</div>
				{/* end::Container */}
			</div>
			<Modal
				show={show}
				onHide={() => setShow(false)}
				size='lg'
				dialogClassName='modal-dialog-centered mw-1000px h-auto'
			>
				<Box sx={{ p: 2 }}>
					<Box p={2} display='flex'>
						<Typography>About us.</Typography>
						<Button
							onClick={() => setShow(false)}
							sx={{ marginLeft: 'auto' }}
							variant='contained'
						>
							Закрыть
						</Button>
					</Box>
					<Box>
						About us. Lorem ipsum dolor sit amet consectetur, adipisicing elit.
						Sit, neque, vitae labore ipsam voluptatum tenetur repellendus
						possimus officiis debitis maxime eos fugit, officia id illum amet
						provident alias placeat modi illo! Quis, assumenda asperiores
						voluptas natus ipsum delectus soluta nam?
					</Box>
				</Box>
			</Modal>
			<Modal
				show={showContact}
				onHide={() => setShowContact(false)}
				size='lg'
				dialogClassName='modal-dialog-centered mw-1000px h-auto'
			>
				<Box sx={{ p: 2 }}>
					<Box p={2} display='flex'>
						<Typography>Contacts</Typography>
						<Button
							onClick={() => setShowContact(false)}
							sx={{ marginLeft: 'auto' }}
							variant='contained'
						>
							Закрыть
						</Button>
					</Box>
					<Box>
						Contacts Lorem ipsum dolor sit amet consectetur, adipisicing elit.
						Sit, neque, vitae labore ipsam voluptatum tenetur repellendus
						possimus officiis debitis maxime eos fugit, officia id illum amet
						provident alias placeat modi illo! Quis, assumenda asperiores
						voluptas natus ipsum delectus soluta nam?
					</Box>
				</Box>
			</Modal>
		</>
	)
}

export { Footer }
