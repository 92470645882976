import { AnyAction } from "redux"
import { ITaskStatusPage } from "../../models/tasks/tasksModel"
import { GET_TASK_STATUS_ERROR, GET_TASK_STATUS_REQUEST, GET_TASK_STATUS_SUCCESS } from "../taskStatusTypes"

export interface TaskStatusState {
	taskStatusPage: ITaskStatusPage
	loading: boolean
	taskStatusError: any
 }
 const initialState: TaskStatusState = {
	taskStatusPage: {} as ITaskStatusPage,
	loading: false,
	taskStatusError: false,
 }
 
 const taskStatusReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
	  case GET_TASK_STATUS_REQUEST:
		 return { ...state, taskStatusPage: {}, loading: true, }
	  case GET_TASK_STATUS_SUCCESS:
		 return { ...state, taskStatusPage: action.taskStatus, loading: false }
	  case GET_TASK_STATUS_ERROR:
		 return { ...state, taskStatusError: true, loading: false }
 
	  default:
		 return state
	}
 }
 
 export default taskStatusReducer