import React from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { deleteTaskHelpAction } from '../../store/actions/taskhelpAction'
import { useIntl } from 'react-intl'
import {
	IReportContent,
	IReportGroupContent,
} from '../../models/report/reportModel'
import ErrorIcon from '@mui/icons-material/Error'
import BasicToolTipProps from '../../modules/basicToolTip/BasicToolTip'
import WarningIcon from '@mui/icons-material/Warning'
import CancelIcon from '@mui/icons-material/Cancel'
import DoneIcon from '@mui/icons-material/Done'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import GppMaybeIcon from '@mui/icons-material/GppMaybe'

interface IhelpListProps {
	report: IReportGroupContent
	page: any
	setHelpModalUpdate: React.Dispatch<React.SetStateAction<boolean>>
	setData: React.Dispatch<React.SetStateAction<IReportContent | undefined>>
	// reportModalHandle?: (report: IReportGroupContent) => void
	group: string
	setGroup: any
}

const ENUM = {
	IN_PROGRESS: 'IN_PROGRESS',
	ACCEPTED: 'ACCEPTED',
	ANNULLED: 'ANNULLED',
	FAILED: 'FAILED',
	EXPIRED: 'EXPIRED',
	INITIATED: 'INITIATED',
	REJECTED: 'REJECTED',
}

const ReportGroupList = ({
	report,
	page,
	setHelpModalUpdate,
	setData,
	// reportModalHandle,
	group,
	setGroup,
}: IhelpListProps) => {
	const dispatch = useDispatch()
	const intl = useIntl()

	const handleDelete = (id: any) => {
		Swal.fire({
			title: 'Вы действительно хотите удалить?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: 'Нет',
			confirmButtonText: 'Да!',
		}).then(result => {
			if (result.isConfirmed) {
				dispatch(deleteTaskHelpAction({ id: id, meta: page }))
				Swal.fire('Удалено!', 'Успешно удалено.', 'success')
			}
		})
	}

	const handleModal = (help: any) => {
		setData(help)
		setHelpModalUpdate(true)
	}

	const displayDocumentStatusIcon = (
		status: string | undefined
	): JSX.Element | null => {
		let statusIcon = null

		switch (status) {
			case ENUM.FAILED:
				statusIcon = (
					<BasicToolTipProps
						title={intl.formatMessage({
							id: 'REPORT_STATUS_ICON_TITLE_FAILED',
						})}
					>
						<ErrorIcon color='error' />
					</BasicToolTipProps>
				)
				break
			case ENUM.IN_PROGRESS:
				statusIcon = (
					<BasicToolTipProps
						title={intl.formatMessage({
							id: 'REPORT_STATUS_ICON_TITLE_IN_PROGRESS',
						})}
					>
						<AccessTimeIcon color='info' />
					</BasicToolTipProps>
				)
				break
			case ENUM.ANNULLED:
				statusIcon = (
					<BasicToolTipProps
						title={intl.formatMessage({
							id: 'REPORT_STATUS_ICON_TITLE_ANNULLED',
						})}
					>
						<CancelIcon color='warning' />
					</BasicToolTipProps>
				)
				break
			case ENUM.ACCEPTED:
				statusIcon = (
					<BasicToolTipProps
						title={intl.formatMessage({
							id: 'REPORT_STATUS_ICON_TITLE_ACCEPTED',
						})}
					>
						<DoneIcon color='success' />
					</BasicToolTipProps>
				)
				break
			case ENUM.EXPIRED:
				statusIcon = (
					<BasicToolTipProps
						title={intl.formatMessage({
							id: 'REPORT_STATUS_ICON_TITLE_EXPIRED',
						})}
					>
						<WarningIcon color='warning' />
					</BasicToolTipProps>
				)
				break
			case ENUM.INITIATED:
				statusIcon = (
					<BasicToolTipProps
						title={intl.formatMessage({
							id: 'REPORT_STATUS_ICON_TITLE_INIT',
						})}
					>
						<HourglassTopIcon color='info' />
					</BasicToolTipProps>
				)
				break
			case ENUM.REJECTED:
				statusIcon = (
					<BasicToolTipProps
						title={intl.formatMessage({
							id: 'REPORT_STATUS_ICON_TITLE_REJECTED',
						})}
					>
						<GppMaybeIcon color='error' />
					</BasicToolTipProps>
				)
				break
			default:
				statusIcon = null
				break
		}
		return statusIcon
	}

	return (
		<tr key={report.id}>
			<td className='w-25'>
				<div className='d-flex align-items-center '>
					<div className='symbol symbol-45px '></div>
					<div
						onClick={() => {
							setGroup(report.number)
						}}
						className='d-flex justify-content-start '
					>
						<a
							className='text-dark fw-bolder text-hover-primary fs-6'
							style={{ cursor: 'pointer' }}
						>
							{report?.number ||
								intl.formatMessage({ id: 'TASK.DEFAULT.TEXT' })}
						</a>
					</div>
				</div>
			</td>

			{/* <td className='w-25'>
				<span className='text-muted fw-bold text-muted d-block fs-7'>
					файлы
				</span>
			</td> */}
			<td className='w-25'>
				<span className='text-muted fw-bold text-muted d-block fs-7'>
					{report?.creationType === 'AUTO'
						? 'Автоматически'
						: 'Вручную' || intl.formatMessage({ id: 'TASK.DEFAULT.TEXT' })}
				</span>
			</td>

			<td className='w-25'>
				<span className='text-muted fw-bold text-muted d-block fs-7'>
					{report?.createdBy || intl.formatMessage({ id: 'TASK.DEFAULT.TEXT' })}
				</span>
			</td>

			<td className='w-50'>
				<div className='d-flex flex-column w-100 me-2'>
					<div className='d-flex flex-stack mb-2'>
						<span className='text-muted fw-bold text-muted d-block fs-7'>
							{report?.month || intl.formatMessage({ id: 'TASK.DEFAULT.TEXT' })}
						</span>
					</div>
				</div>
			</td>
			<td className='w-25'>
				<div className='d-flex flex-column w-100 me-2'>
					{/* <div className='d-flex flex-stack mb-2 justify-content-center align-items-center'> */}
					{/* {displayDocumentStatusIcon(report?.status)} */}
					<span className='text-muted fw-bold text-muted d-block fs-7'>
						{report?.createdAt ||
							intl.formatMessage({ id: 'TASK.DEFAULT.TEXT' })}
					</span>
					{/* </div> */}
				</div>
			</td>
		</tr>
	)
}

export default ReportGroupList
