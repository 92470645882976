import { GET_PAGES_PAGINATION, GET_PERSONALS, GET_PERSONALS_FAILED, GET_PERSONALS_SUCCESS, GET_PERSONAL_BY_ID, GET_PERSONAL_WITH_FILTER_ERROR, GET_PERSONAL_WITH_FILTER_REQUEST, GET_PERSONAL_WITH_FILTER_SUCCESS } from '../personalTypes'
import { IprofileData } from '../../modules/accounts/components/Profile';
import { AnyAction } from 'redux';
import { IPaginationModel } from '../../models/personal/paginationCustom';
interface initialTypes {
  personals: IprofileData[],
  personalsError: any
  user: IprofileData,
  paginationInfo: IPaginationModel
  paginationInfoLoading: boolean
  paginationInfoError: string
}


const initialState: initialTypes = {
  personals: [],
  personalsError: "",
  user: {} as IprofileData,
  paginationInfo: {} as IPaginationModel,
  paginationInfoLoading: false,
  paginationInfoError: '',
}





export const personalsReducer = (state = initialState, action: AnyAction): initialTypes => {
  switch (action.type) {
    case GET_PERSONALS:
      return {
        ...state,
        personals: [],
        personalsError: ''
      }
    case GET_PERSONALS_FAILED:
      return {
        ...state,
        personalsError: action.error
      }
    case GET_PERSONALS_SUCCESS:
      return {
        ...state,
        personals: action.personals, personalsError: ''
      }
    case GET_PERSONAL_BY_ID:
      return {
        ...state,
        personals: action.personals,
        user: action.user, personalsError: ''
      }
    case GET_PAGES_PAGINATION:
      return {
        ...state,
        paginationInfo: action.pages
      }

      //=================================PERSONAL FILTER
      case GET_PERSONAL_WITH_FILTER_REQUEST:
      return {
        ...state, paginationInfoLoading: true, paginationInfoError: ''
      }
      case GET_PERSONAL_WITH_FILTER_SUCCESS:
      return {
        ...state,
        paginationInfo: action.payload ,paginationInfoLoading: false, paginationInfoError: ''
      }
      case GET_PERSONAL_WITH_FILTER_ERROR:
      return {
        ...state,
        paginationInfoLoading: false, paginationInfoError: action.error
      }

    default:
      return state
  }
}


export default personalsReducer