export const GET_TASKS = "GET_TASKS"
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS"
export const GET_TASKS_ERROR = "GET_TASKS_ERROR"
export const GET_TASKS_BY_TYPE = "GET_TASKS_BY_TYPE"

export const CREATE_TASKS = "CREATE_TASKS"
export const CREATE_TASKS_SUCCESS = "CREATE_TASKS_SUCCESS"

export const UPDATE_TASKS = "UPDATE_TASKS"

export const DELETE_TASKS = "DELETE_TASKS"

export const GET_TASKS_PAGE_SUCCESS = "GET_TASKS_PAGE_SUCCESS"

export const GET_TASK_BY_ID = "GET_TASK_BY_ID"
export const GET_TASK_BY_ID_SUCCESS = "GET_TASK_BY_ID_SUCCESS"

export const GET_PUBLIC_TASKS = "GET_PUBLIC_TASKS"
export const GET_PUBLIC_TASKS_DETAILS = "GET_PUBLIC_TASKS_DETAILS"

export const GET_AVAILABLE_TASK_STATUSES = "GET_AVAILABLE_STATUSES"
export const GET_AVAILABLE_TASK_STATUSES_SUCCESS = "GET_AVAILABLE_TASK_STATUSES_SUCCESS"

export const CHANGE_TASK_STATUS = "CHANGE_TASK_STATUS"


export const GET_TASK_COMMENT = "GET_TASK_COMMENT"
export const GET_TASK_COMMENT_SUCCESS = "GET_TASK_COMMENT_SUCCESS"
export const GET_TASK_COMMENT_ERROR = "GET_TASK_COMMENT_ERROR"

export const CREATE_TASK_COMMENT = "CREATE_TASK_COMMENT"
export const CREATE_TASK_REQUEST = 'CREATE_TASK_REQUEST'
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS'
export const CREATE_TASK_ERROR = 'CREATE_TASK_ERROR'
export const DELETE_TASK_COMMIT = 'DELETE_TASK_COMMIT'
export const UPDATE_TASK_COMMENT = 'UPDATE_TASK_COMMENT'
