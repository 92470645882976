import { call, put, takeLatest } from '@redux-saga/core/effects'
import { AnyAction } from 'redux'
import { IStatus } from '../../models/tasks/tasksModel'
import axios from '../../setup/axios/axios-config'
import { createStatusSuccess, getStatusByIdSuccess, getStatusesSuccessAction } from '../actions/statusActions'
import { CREATE_STATUS, DELETE_STATUS, GET_STATUSES, GET_STATUS_BY_ID_REQUEST, UPDATE_STATUS } from '../statusTypes'


/////////////////////////////////GET
const getStatusApi = () =>
	axios.get('/api/task/status/all').then(res => res.data)

function* getStatus() {
	try {
		const status: IStatus[] = yield call(getStatusApi)
		yield put(getStatusesSuccessAction(status))
	} catch (err) {
		console.log(err, 'saga status')
	}
}

//////////////////////////////////////CREATE
const createStatusApi = (status: any) => axios.post('/api/task/status', status).then(res => res.data)

function* createStatus({ status }: AnyAction): any {
	try {
		const statusResponse = yield call(createStatusApi, status)
		yield put(createStatusSuccess(statusResponse));
	} catch (err) {
		console.log(err, 'error create in Saga')
	}
}

//////////////////////////////////////UPDATE
const updateStatusApi = (statusId: number, status: any) => axios.put('/api/task/status/' + statusId, status);

function* updateStatus({ statusId, status }: AnyAction) {
	try {
		yield call(updateStatusApi, statusId, status)
	} catch (err) {
		console.log(err, 'delete saga error')
	}
}
//////////////////////////////////////DELETE
const deleteStatusApi = (statusId: any) =>
	axios.delete('/api/status/' + statusId)

function* deleteStatus({ statusId }: AnyAction) {
	try {
		yield call(deleteStatusApi, statusId)
	} catch (err) {
		console.log(err, 'delete saga error')
	}
}

////////////////////////////////////GET BY ID
const getStatusByIdApi = (statusId: number) => axios.get('/api/task/status/' + statusId).then(res => res.data);

function* getStatusById({ statusId }: AnyAction): any {
	try {
		const status = yield call(getStatusByIdApi, statusId);
		yield put(getStatusByIdSuccess(status));
	} catch (err) {
		console.log(err);
	}
}

export function* watchStatus() {
	yield takeLatest(GET_STATUSES, getStatus)
	yield takeLatest(CREATE_STATUS, createStatus)
	yield takeLatest(DELETE_STATUS, deleteStatus)
	yield takeLatest(UPDATE_STATUS, updateStatus)
	yield takeLatest(GET_STATUS_BY_ID_REQUEST, getStatusById)
}
