import { AnyAction } from 'redux';
import { IHelp } from '../../models/tasks/tasksModel';
import { GET_TASK_HELP, GET_TASK_HELP_ERROR, GET_TASK_HELP_SUCCESS } from '../taskHelpTypes';

export interface HelpTaskState {
  help: IHelp
  loading: boolean
  helpError: any
}
const initialState: HelpTaskState = {
  help: {} as IHelp,
  helpError: false,
  loading: false
}

const taskHelp = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_TASK_HELP:
      return { ...state, help: {}, loading: true, }
    case GET_TASK_HELP_SUCCESS:
      return { ...state, help: action.help, loading: false }
    case GET_TASK_HELP_ERROR:
      return { ...state, helpError: true, loading: false }

    default:
      return state
  }
}

export default taskHelp