
export const formatBytes = (bytes: number, decimals = 2) => {
	if (bytes === 0) return '0 Bytes'

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const getPersonalIndex = (id: number, allPersonal: any[]): any => {
	for (let i = 0; i < allPersonal?.length; i++) {
		if (allPersonal[i].id === id) {
			return i
		}
	}
}

export function checkIfFilesAreTooBig(files?: [File]): boolean {
	let valid = true
	if (files) {
		files.map(file => {
			if (file.size > (200 * 1024 * 1024)) {
				valid = false
			}
		})
	}
	return valid
}

export function checkRoleExist(
	roles: any[],
	needRole: string[],
	all?: boolean
) {
	if (needRole?.length === 1) {
	
		return !!roles.find((role: any) => role.name === needRole[0])
	}

	if (needRole?.length > 1 && all) {

		const result = roles.filter(role => {
			for (let i = 0; i < needRole?.length; i++) {
				if (role?.name === needRole[i]) {
					return true;
				}
			}
		})

    return result.length === roles.length ? true : false

	} else if (needRole?.length > 1) {
		
		return !!roles.find(role => {
			for (let i = 0; i < needRole?.length; i++) {
				if (role?.name === needRole[i]) {
					return true
				}
			}
		})
	}
}

export const calculatePage = (
	totalElements: number,
	numberOfElements: number,
	page: number,
	totalPages: number
) => {
	let pageStart = 0
	let pageEnd = 0
	const pageN = page + 1
	if (pageN > 1) {
		pageStart = pageN * 10 - 9
		pageEnd = pageStart + (numberOfElements - 1 )
	} else {
		pageStart = 1
		pageEnd = pageStart - 1 + numberOfElements
	}

	return { pageStart, pageEnd }
}

export const phoneRegExp = /(\+?\d+ ?-?){10,12}/