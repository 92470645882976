import { Stack } from '@mui/material'
import { Box } from '@mui/system'
import React, { FC } from 'react'
import { ICorrespondenceMessagesContent } from '../../../store/reducers/correspondenceReducer'
import ChatReplyItem from '../ChatReply/ChatReplyItem'

interface TreeViewProps {
	formScroll: (e: any, messageId: number, fullName: string) => void
	chatId: number
	anonymouseName: string
	message: ICorrespondenceMessagesContent
	reply: boolean
}
const TreeView: FC<TreeViewProps> = ({
	formScroll,
	chatId,
	anonymouseName,
	message,
	reply,
}) => {
	function createMarkup() {
		return { __html: message?.content }
	}

	return (
		<Box>
			{message.replyTo?.id && (
				<TreeView
					formScroll={formScroll}
					chatId={chatId}
					anonymouseName={anonymouseName}
					message={message.replyTo}
					reply={true}
				/>
			)}
			{reply ? (
				<Stack
			
					sx={{ borderRadius: '10px', bgcolor: 'rgba(0, 142, 202, 0.2)' }}
				>
					<ChatReplyItem
					formScroll={formScroll}
					chatId={chatId}
					anonymouseName={anonymouseName}
					message={message}
					reply={reply}
				/>
					{/* <Avatar
						sx={{ width: 56, height: 56, mr: 3 }}
						src={
							message?.createdBy?.fullName === 'Anonymous'
								? '/media/avatars/blank.png'
								: message?.createdBy?.imagePath
						}
						variant='rounded'
					/>
					<Stack>
						<Stack direction='row' alignItems='center' spacing={1}>
							<Typography>{message.createdBy.fullName}</Typography>
							<Typography>{message.createdAt}</Typography>
						</Stack>
						<Box sx={{ overflow: 'hidden', maxHeight: '50px' }}>
							<div dangerouslySetInnerHTML={createMarkup()} />
						</Box>
					</Stack> */}
				</Stack>
			) : (
				<ChatReplyItem
					formScroll={formScroll}
					chatId={chatId}
					anonymouseName={anonymouseName}
					message={message}
				/>
			)}
		</Box>
	)
}

export default TreeView
