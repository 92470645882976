import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import { Cashier } from '../../models/cashier/FileModel'
import { getCashierListRequest, } from '../../store/actions/cashierActions'
import ReactTable from '../../components/partials/react-table/ReactTable'
import ReactPaginate from 'react-paginate'

type props = {
    orgId: number
    orgName: string
}
type State = {
    currentPage: number
}

const OrgInfoCashierTab = ({ orgId, orgName }: props) => {
    const dispatch = useDispatch()
    const cashierList: Cashier[] = useSelector<RootState, Cashier[]>(state => state.cashier.cashierList)
    const totalPages: number = useSelector<RootState, number>(state => state.cashier.totalPages)
    const [state, setState] = useState<State>({ currentPage: 1 })
    useEffect(() => {
        if (orgName !== '' && state.currentPage === 1) dispatch(getCashierListRequest(`?search=organizationName=${orgName}`))
        else if (orgName !== '' && state.currentPage !== 1) dispatch(getCashierListRequest(`?search=organizationName=${orgName}&page=${state.currentPage}`))
    }, [state.currentPage])
    const columns: any[] = useMemo(() => [
        {
            Header: 'Заводской номер',
            accessor: 'factoryNumber',
            width: 200
        },
        {
            Header: 'Адресс кассы',
            accessor: 'factionalAddress',
            width: 200,
        },
        {
            Header: 'Статус кассы',
            accessor: 'status',
            width: 200,
            Cell: (cell: any) => {
                if (cell?.row?.original?.status === 'CREATED') {
                    return (
                        <span>
                            Создана
                        </span>
                    )
                }
                else if (cell?.row?.original?.status === 'ACTIVATED') {
                    return (
                        <span>
                            Готова к работе
                        </span>
                    )
                }
                else if (cell?.row?.original?.status === 'BLOCKED') {
                    return (
                        <span>
                            Заблокирована
                        </span>
                    )
                }
                else if (cell?.row?.original?.status === 'REMOVED_FROM_OFD') {
                    return (
                        <span>
                            Снята с учета
                        </span>
                    )
                }
            }
        }
    ], [orgName])
    const handlePageClick = (e: any) => {
        setState({ ...state, currentPage: e.selected + 1 })
    }
    return (
        <div className='organization-info-box'>
            <ReactTable
                // data={cashierListMemorized}
                data={cashierList}
                columns={columns}
                tableWrapperClassName='cashier-table-wrapper organization-info-content'
                tableClassName='cashier-table'
                thClassName='cashier-table-td cashier-table-th'
                thClassNameRight='cashier-table-td-right'
                tdClassName='cashier-table-td'
                tdClassNameRight='cashier-table-td-right'
                trClassNameHover='cashier-table-td-hover'
                columnFilterRender={(column: any) => <span className='cashier-th-sort organization-cursor-default'>{column.render('Header')}</span>}
            />
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                pageCount={totalPages}  //количество страниц
                onPageChange={handlePageClick}
                breakLabel='...'
                pageRangeDisplayed={1}
                containerClassName='cashier-pagination-box'
                previousLinkClassName='cashier-pagination-prevlink cashier-pagination-mainlink'
                nextLinkClassName='cashier-pagination-nextlink cashier-pagination-mainlink'
                pageLinkClassName='cashier-pagination-mainlink'
                activeLinkClassName='cashier-pagination-activelink'
                breakLinkClassName='cashier-pagination-breaklink'
                marginPagesDisplayed={1}
            // forcePage={period.page}
            />
        </div>
    )
}
export default OrgInfoCashierTab