import { FC, memo, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'

import {
	Autocomplete,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from '@mui/material'
import { useIntl } from 'react-intl'
import { IprofileData } from '../../modules/accounts/components/Profile'
import { RootState } from '../../setup'
import axios from '../../setup/axios/axios-config'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { ITasks } from '../../models/tasks/tasksModel'
import { getPersonalsAction } from '../../store/actions/personalsActions'
import {
	checkAvailableTaskStatuses,
	getTaskById,
	updateTaskAction,
} from '../../store/actions/calendarTaskAction'
import moment from 'moment'
import { DateTimePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterMoment'
import { Editor } from '@tinymce/tinymce-react'
import MultiSelect from '../../components/partials/MultiSelect'
import MultiSelectTags from '../../components/partials/MultiSelectTags'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
const menubar = false

const plugins =
	'advlist autolink lists image link tinydrive imagetools charmap print preview anchor ' +
	'searchreplace visualblocks code fullscreen ' +
	'insertdatetime media table paste code help wordcount emoticons '

const toolbar =
	'undo redo | formatselect | emoticons | ' +
	'bold italic backcolor | alignleft aligncenter ' +
	'alignright alignjustify |  image link | bullist numlist outdent indent | ' +
	'removeformat | help '

interface TaskUpdateDrawerProps {
	show: boolean
	handleClose: () => void
	data: any
	filter: any
}

const TaskUpdateDrawer: FC<TaskUpdateDrawerProps> = memo(
	({ show, data, handleClose, filter }) => {
		const intl = useIntl()
		const dispatch = useDispatch()
		const [loading, setLoading] = useState(false)
		const [watchers, setWatchers] = useState<any>([])
		const [editText, setEditText] = useState<any>('')
		const token = localStorage.token
		const theme = useTheme();
		const matches = useMediaQuery(theme.breakpoints.up('sm'))
		const handleEditChange = (e: any) => {
			let html = e.target.getContent()
			// setContentText(html)
			setEditText(html)
		}
		const [taskToUpdate, setTaskToUpdate] = useState<any>({
			id: 0,
			title: '',
			description: '',
			assignee: {},
			start: '',
			end: '',
			watchersId: [],
			tagIds: [],
		})

		const taskById: ITasks = useSelector<RootState, ITasks>(
			state => state.tasks.taskById,
			shallowEqual
		)

		const [currentStatus, setCurrentStatus] = useState<string>(
			taskById.status?.code
		)

		const [availableStatuses, setAvailableStatuses] = useState<any>([])

		const personals: IprofileData[] = useSelector<RootState, IprofileData[]>(
			state => state.personals.personals,
			shallowEqual
		)

		const taskByIdStatuses: any[] = useSelector<RootState, any[]>(
			state => state.tasks.taskByIdStatuses,
			shallowEqual
		)
		const [epic, setEpic] = useState([])
		const [tags, setTags] = useState<any>([])
		useEffect(() => {
			const tags = async () => {
				const tag = await axios.get('/api/tags')
				setEpic(await tag.data)
			}
			tags()
		}, [])

		useEffect(() => {
			setTaskToUpdate({
				id: taskById.id,
				title: taskById.title,
				description: taskById.description,
				assignee: taskById.assignee,
				start: taskById.start,
				end: taskById.end,
				// watchersId: watchers,
			})
			setWatchers(taskById.watchers || [])
			setCurrentStatus(taskById.status?.code)

			setEditText(taskById?.description)
			setTags(taskById?.tags)
		}, [taskById])

		useEffect(() => {
			setAvailableStatuses(taskByIdStatuses)
		}, [taskByIdStatuses])

		useEffect(() => {
			if (data) {
				dispatch(getTaskById(data))
				dispatch(checkAvailableTaskStatuses(data))
			}
		}, [data])

		useEffect(() => {
			dispatch(getPersonalsAction())
		}, [])
		useEffect(() => {
			setWatchers(taskById?.watchers || [])
		}, [])
		useEffect(() => {
			setTags(taskById?.tags || [])
		}, [])

		const handleSubmit = (e: any) => {
			e.preventDefault()

			const updatedTask = {
				...taskToUpdate,
				tagIds: tags.map((tag: any) => tag.id),
				assignee: taskToUpdate?.assignee,
				watchersId: watchers.map((watcher: any) => watcher.userId),
			}

			const status = { current: taskById?.status?.code, new: currentStatus }
			dispatch(updateTaskAction({ updatedTask }, filter, status))
			handleClose()
		}

		const handleChange = (e: any) => {
			setTaskToUpdate({ ...taskToUpdate, [e.target.name]: e.target.value })
		}

		const handleChangeStatus = (e: any) => {
			setCurrentStatus(e.target.value)
		}

		// const handleChangeFile = (e: any) => {
		// 	setTaskToUpdate({ ...taskToUpdate, [e.target.name]: e.target.files })
		// }

		const handleChangeStartTime = (newValue: any) => {
			if (newValue?._d === undefined) {
				setTaskToUpdate((prev: any) => ({ ...prev, start: '' }))
			} else {
				setTaskToUpdate({
					...taskToUpdate,
					start: moment(newValue?._d)?.format('YYYY-MM-DD HH:mm'),
				})
			}
		}

		const handleWatcherChange = (e: any) => {
			setWatchers([...new Set(e.target.value)])
		}

		const handleChangeEndTime = (newValue: any) => {
			if (newValue?._d === undefined) {
				setTaskToUpdate((prev: any) => ({ ...prev, end: '' }))
			} else {
				setTaskToUpdate({
					...taskToUpdate,
					end: moment(newValue?._d)?.format('YYYY-MM-DD HH:mm'),
				})
			}
		}

		const BASE_URL = process.env.REACT_APP_BASE_URL
		useEffect(() => {
			setTaskToUpdate({ ...taskToUpdate, description: editText })
		}, [editText])

		function example_image_upload_handler(
			blobInfo: any,
			success: any,
			failure: any,
			progress: any
		) {
			var xhr: XMLHttpRequest, formData

			xhr = new XMLHttpRequest()
			// xhr.withCredentials = true
			xhr.open('POST', `${BASE_URL}/minio/file`)
			xhr.setRequestHeader('Authorization', `Bearer ${token}`)

			xhr.upload.onprogress = function (e) {
				progress((e.loaded / e.total) * 100)
			}
			xhr.onload = function () {
				var json

				if (xhr.status === 403) {
					failure('HTTP Error: ' + xhr.status, { remove: true })
					return
				}

				if (xhr.status < 200 || xhr.status >= 300) {
					failure('HTTP Error: ' + xhr.status)
					return
				}

				json = JSON.parse(xhr.responseText)

				if (!json || typeof json.baseFileUrl != 'string') {
					failure('Invalid JSON: ' + xhr.responseText)
					return
				}
				try {
					success(json?.baseFileUrl)
				} catch (e: any) {
					console.error(e)
				}
			}

			xhr.onerror = function () {
				failure(
					'Image upload failed due to a XHR Transport error. Code: ' +
						xhr.status
				)
			}

			formData = new FormData()
			formData.append('file', blobInfo.blob(), blobInfo.filename())
			formData.append('name', blobInfo.filename())

			xhr.send(formData)
		}

		//==================================================DrawerContent

		const DrawerContent = () => (
			<Box
				sx={{
					padding: 5,
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
				role='presentation'
				component='form'
				noValidate
				autoComplete='off'
				onSubmit={handleSubmit}
			>
				<Box sx={{ flexGrow: 1 }}>
					<Stack spacing={5}>
						<Box>
							<Typography fontWeight='medium' sx={{ pb: 1, mb: 2 }}>
								{intl.formatMessage({ id: 'TASK.MODAL.TITLE' })}
							</Typography>

							<TextField
								name='title'
								fullWidth
								label=''
								value={taskToUpdate.title || ''}
								onChange={handleChange}
								variant='standard'
								InputLabelProps={{
									// shrink: taskToUpdate.title ? true : false,
									shrink: false,
								}}
							/>
						</Box>
						{/* <Box> */}
						<Typography fontWeight='medium' sx={{ pb: 1, mb: 2 }}>
							Описание
						</Typography>

						{/* <TextField
								name='description'
								fullWidth
								label=''
								value={taskToUpdate.description || ''}
								onChange={handleChange}
								variant='outlined'
								InputLabelProps={{
									// shrink: taskToUpdate.description ? true : false,
									shrink: false,
								}}
							/>
						</Box> */}

						<Editor
							// onInit={(evt, editor) => (editorRef.current = editor)}
							// onInit={onInitEditor}
							// key={editorKey}
							apiKey='x933w4qvw7ckm4dwx6qfe39t1e0qq0dj6wc19z4a2wet0gbg'
							initialValue={taskById?.description}
							init={{
								plugins,
								toolbar,
								menubar,
								height: 300,
								visual: false,
								powerpaste_allow_local_images: true,
								language: 'ru',
								images_upload_url: `${BASE_URL}/minio/file`,
								images_upload_handler: example_image_upload_handler,
								file_browser_callback_types: 'file image media',
								path_absolute: '/',
								// file_browser_callback: myFileBrowser,
								tinydrive_token_provider: `${BASE_URL}/minio/file`,
								file_picker_callback: function (cb, value, meta) {
									var input = document.createElement('input')
									input.setAttribute('type', 'file')
									var xhr: XMLHttpRequest, formData
									xhr = new XMLHttpRequest()
									input.onchange = function (e: any) {
										var file = e.target.files[0]
										xhr.open('POST', `${BASE_URL}/minio/file`)
										xhr.setRequestHeader('Authorization', `Bearer ${token}`)
										xhr.onload = function () {
											var json
											if (xhr.status === 403) {
												// failure('HTTP Error: ' + xhr.status, { remove: true })
												return
											}
											if (xhr.status < 200 || xhr.status >= 300) {
												// failure('HTTP Error: ' + xhr.status)
												return
											}
											json = JSON.parse(xhr.responseText)

											if (!json || typeof json.baseFileUrl != 'string') {
												// failure('Invalid JSON: ' + xhr.responseText)
												return
											}
											cb(json.baseFileUrl, {
												alt: json.name,
												title: json.name,
												text: json.name,
											})
										}

										xhr.onerror = function () {
											// failure(
											// 	'Image upload failed due to a XHR Transport error. Code: ' +
											// 		xhr.status
											// )
										}

										formData = new FormData()
										formData.append('file', file)
										formData.append('name', file.name)

										xhr.send(formData)
									}

									input.click()
								},
							}}
							onChange={handleEditChange}
						/>
						{/* //==============================DATE */}

						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Box
								sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
							>
								<Typography fontWeight='medium' sx={{ pr: 1, mb: 2 }}>
									{intl.formatMessage({ id: 'TASK.MODAL.START' })}
								</Typography>

								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DateTimePicker
										label=''
										value={taskToUpdate.start === '' ? '' : taskToUpdate.start}
										onChange={handleChangeStartTime}
										renderInput={(params: JSX.IntrinsicAttributes) => (
											<TextField
												{...params}
												name='start'
												className='me-2'
												variant='standard'
												fullWidth
												InputLabelProps={{ shrink: false }}
											/>
										)}
									/>
								</LocalizationProvider>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center', ml: 3 }}>
							<Box
								sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
							>
								<Typography fontWeight='medium' sx={{ pr: 1, mb: 2 }}>
									{intl.formatMessage({ id: 'TASK.MODAL.END' })}
								</Typography>

								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DateTimePicker
										label=''
										value={taskToUpdate.end === '' ? '' : taskToUpdate.end}
										onChange={handleChangeEndTime}
										renderInput={(params: JSX.IntrinsicAttributes) => (
											<TextField
												{...params}
												name='end'
												variant='standard'
												className='me-2'
												fullWidth
												InputLabelProps={{ shrink: false }}
											/>
										)}
									/>
								</LocalizationProvider>
							</Box>
						</Box>

						{/* <Divider>Actions</Divider> */}
						{/* //==============================STATUS */}
						{/* <Stack direction='row' spacing='20px'> */}
						<Box>
							<Typography fontWeight='medium' sx={{ pb: 1, mb: 2 }}>
								{intl.formatMessage({ id: 'TASK.MODAL.STATUS' })}
							</Typography>

							<TextField
								name='currentStatus'
								fullWidth
								select
								variant='standard'
								value={currentStatus || ''}
								label=''
								onChange={handleChangeStatus}
								disabled={taskByIdStatuses?.length === 0 ? true : false}
								InputLabelProps={{ shrink: false }}
							>
								<MenuItem value={currentStatus} selected={true} disabled hidden>
									{currentStatus}
								</MenuItem>

								{taskByIdStatuses?.map(item => {
									return (
										<MenuItem divider key={item.id} value={item.code}>
											{item.title}
										</MenuItem>
									)
								})}
							</TextField>
							{/* <FormControl fullWidth> */}
							{/* <InputLabel id='demo-simple-select-label'>
										{intl.formatMessage({ id: 'TASK.MODAL.STATUS' })}
									</InputLabel> */}
							{/* </FormControl> */}
						</Box>
						<Box>
							<Typography fontWeight='medium' sx={{ pb: 1, mb: 2 }}>
								{/* {intl.formatMessage({ id: 'TASK.MODAL.ASSIGNEE' })} */}
								Теги
							</Typography>
							{/* <Select
									sx={{ minWidth: '200px' }}
									renderValue={selected => {
										return personals
											.filter((el: any) => {
												return selected.includes(el.userId)
											})
											.map((item: any) => item.fullName)
											.join(', ')
									}}
									name='watchersId'
									multiple
									fullWidth
									// SelectDisplayProps={{
									// 	id: '32',
									// }}
									label={intl.formatMessage({ id: 'TASK.MODAL.ASSIGNEE' })}
									value={watchers}
									onChange={handleWatcherChange}
								>
									{personals?.map((item: any): any => {
										return (
											<MenuItem key={item.userId} value={item.userId}>
												{item.fullName}
											</MenuItem>
										)
									})}
								</Select> */}
							<MultiSelectTags
								// formik={formik}
								style={'standard'}
								items={epic}
								// getOptionDisabled={getOptionDisabled}
								label=''
								placeholder=''
								selectAllLabel='Select all'
								onChange={setTags}
								selectedValues={tags}
							/>
						</Box>
						{/* //==============================ASSIGNEE */}
						<Box>
							<Typography fontWeight='medium' sx={{ pb: 1, mb: 2 }}>
								{intl.formatMessage({ id: 'TASK.MODAL.ASSIGNEE' })}
							</Typography>

							{/* <TextField
									sx={{ minWidth: '200px' }}
									name='assignee'
									select
									value={
										personals?.[
											getPersonalIndex(taskToUpdate.assignee?.id, personals)
										] || ''
									}
									label=''
									onChange={handleChange}
									InputLabelProps={{ shrink: false }}
								>
									{personals?.map((item: any): any => {
										return (
											<MenuItem key={item.userId} value={item}>
												{item.fullName}
											</MenuItem>
										)
									})}
								</TextField> */}

							<Autocomplete
								className='me-5'
								fullWidth
								onChange={(e: any, props: any) => {
									setTaskToUpdate({
										...taskToUpdate,
										assignee: props,
									})
								}}
								getOptionLabel={(option: any) => {
									return option?.fullName || ''
								}}
								value={taskToUpdate.assignee}
								renderOption={(props, option: any) => {
									return (
										<li {...props} key={option.id}>
											{option.fullName}
										</li>
									)
								}}
								disablePortal
								id='combo-box-demo'
								options={personals}
								renderInput={(params: any) => (
									<TextField
										// style={{ width: 200, minWidth: 100 }}
										{...params}
										fullWidth
										variant='standard'
										label=''
									/>
								)}
							/>
							{/* <FormControl fullWidth> */}
							{/* <InputLabel id='demo-simple-select-label'>
										{intl.formatMessage({ id: 'TASK.MODAL.ASSIGNEE' })}
									</InputLabel> */}
							{/* </FormControl> */}
						</Box>
						<Box>
							<Typography fontWeight='medium' sx={{ pb: 1, mb: 2 }}>
								{/* {intl.formatMessage({ id: 'TASK.MODAL.ASSIGNEE' })} */}
								Наблюдатели
							</Typography>
							{/* <Select
									sx={{ minWidth: '200px' }}
									renderValue={selected => {
										return personals
											.filter((el: any) => {
												return selected.includes(el.userId)
											})
											.map((item: any) => item.fullName)
											.join(', ')
									}}
									name='watchersId'
									multiple
									fullWidth
									// SelectDisplayProps={{
									// 	id: '32',
									// }}
									label={intl.formatMessage({ id: 'TASK.MODAL.ASSIGNEE' })}
									value={watchers}
									onChange={handleWatcherChange}
								>
									{personals?.map((item: any): any => {
										return (
											<MenuItem key={item.userId} value={item.userId}>
												{item.fullName}
											</MenuItem>
										)
									})}
								</Select> */}
							<MultiSelect
								// formik={formik}
								style={'standard'}
								items={personals}
								// getOptionDisabled={getOptionDisabled}
								label=''
								placeholder=''
								selectAllLabel='Select all'
								onChange={setWatchers}
								selectedValues={watchers}
							/>
						</Box>
						{/* </Stack> */}

						{/* //==============================ATTACHMENTS */}
						{/* <Box>
						<Typography fontWeight='medium' sx={{ pb: 1 }}>
							Attachments
						</Typography>
						<Card sx={{ p: 1, fontWeight: 'regular', display: 'flex' }}>
							{data.attachments?.length === 0
								? intl.formatMessage({ id: 'TASK.DEFAULT.ATTACHMENTS' })
								: data.attachments?.map((element: any) => {
										return (
											<TaskImageSumb
												key={element.id}
												img={`${BASE_URL}/minio/file/media/${element.uuid}`}
												name={element.name}
												size={element.size}
											/>
										)
								  })}
						</Card>
					</Box> */}
					</Stack>
				</Box>
				<Box sx={{ my: '30px' }}>
					<Stack spacing={2}>
						<Button
							type='submit'
							color='success'
							disabled={loading}
							variant='contained'
							size='small'
						>
							{!loading && intl.formatMessage({ id: 'TEXT.SAVE' })}
						</Button>
						<Button onClick={handleClose} variant='contained' size='small'>
							{intl.formatMessage({ id: 'TEXT.CLOSE' })}
						</Button>
					</Stack>
				</Box>
			</Box>
		)

		return (
			<div>
				<>
					<Drawer sx={{'& .MuiDrawer-paper': {width: matches ? '50%' : '100%'}}} anchor={'right'} open={show} onClose={handleClose}>
						{DrawerContent()}
					</Drawer>
				</>
			</div>
		)
	}
)

export default TaskUpdateDrawer
