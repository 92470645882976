import { call, put, takeLatest } from '@redux-saga/core/effects'
import { AnyAction } from 'redux'
import Swal from 'sweetalert2'
import { IAdminPage } from '../../models/adminPages/adminPages'
import axios from '../../setup/axios/axios-config'
import { checkAdminPageAction, getAdminPageByIdSuccess, getAdminPageSuccessAction } from '../actions/AdminPageAction'
import { CREATE_ADMIN_PAGE, DELETE_ADMIN_PAGE, GET_ADMIN_PAGE, GET_ADMIN_PAGE_BY_ID, UPDATE_ADMIN_PAGE, VALIDATE_ADMIN_PAGE } from '../pageTypes'


/////////////////////////////////GET
const getAdminPagesApi = (pages: any) =>
  axios.post(`/api/page/search/?page=${pages.page}&size=5&`, { lang: pages.lang || "", name: pages.pathName || "", statusId: +pages.statusId||1, content: pages.content }).then(res => res.data)

function* getAdminPage({ pages }: AnyAction) {
  try {
    const adminPages: IAdminPage = yield call(getAdminPagesApi, pages)
    yield put(getAdminPageSuccessAction(adminPages))
  } catch (err) {
    console.log(err, 'saga status')
  }
}

//////////////////////////////////////CREATE
const createAdminPageApi = async (page: any) => {

  try {
    const data = await axios('/api/page',
      {
        method: "POST",
        data: page
      })
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Страница создана',
        showConfirmButton: false,
        timer: 1500
      })

    return await data.data
  } catch (e) {
    console.log(e, "errrrr")
    Swal.fire({
      icon: 'error',
      title: 'Упс...',
      text: 'Произошла ошибка',
      timer: 1500
    })
  }
}


function* createAdminPage({ page }: AnyAction): any {
  try {

    yield call(createAdminPageApi, page.newPage)
    const pages: IAdminPage = yield call(getAdminPagesApi, page.page)

    yield put(getAdminPageSuccessAction(pages))
   
  } catch (err) {
    console.log(err, 'error create in Saga')
    
  }
}

//////////////////////////////////////UPDATE



////////////////////////////////////// VALIDATE


const validateField =  (symbols:string)=> axios.get(`/api/page/exists?name=${symbols}`).then(data=>data.data)


function* validateFieldSaga({symbols}:AnyAction){
  try{
    const vali:boolean = yield call(validateField, symbols)
    yield put(checkAdminPageAction(vali))
    
  }catch(e){
        
  }


}


////////////////////////////////////////////////
// const updateAdminPageApi = (pageValue: any) => axios.put('/api/page/' + pageValue.id, pageValue.pageValue);

const updateAdminPageApi = async (pageValue: any) => {

  try {
    const data = await axios('/api/page/' + pageValue.id,
      {
        method: "PUT",
        data: { ...pageValue.pageValue }
      })

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Страница обновлена',
      showConfirmButton: false,
      timer: 1500
    })
    return await data.data
  } catch (e) {
    console.log(e, "errrrr")
    Swal.fire({
      icon: 'error',
      title: 'Упс...',
      text: 'Произошла ошибка',
      timer: 1500
    })
  }
}


function* updateAdminPage({ page }: AnyAction) {
  try {
    yield call(updateAdminPageApi, { pageValue: page.newPage, id: page.id })
    const pages: IAdminPage = yield call(getAdminPagesApi, page.page)
    yield put(getAdminPageSuccessAction(pages))
  } catch (err) {
    console.log(err, 'delete saga error')
  }
}
//////////////////////////////////////DELETE
const deleteAdminPageApi = (adminPage: any) =>
  axios.delete('/api/page/' + adminPage)

function* deleteAdminPage({ pageId }: AnyAction) {
  try {
    console.log(pageId)
    yield call(deleteAdminPageApi, pageId.adminPageId)
    const pages: IAdminPage = yield call(getAdminPagesApi, pageId.page)
    yield put(getAdminPageSuccessAction(pages))
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Удален',
      showConfirmButton: false,
      timer: 1500
    })
  } catch (err) {
    console.log(err, 'delete saga error')
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Что то пошло не так!',
      timer: 1500
    })
  }
}

////////////////////////////////////GET BY ID
const getAdminPageByIdApi = (id: any) => axios.get('/api/page/' + id).then(res => res.data);

function* getAdminPageId({ id }: AnyAction): any {
  try {
    const page = yield call(getAdminPageByIdApi, id);
    yield put(getAdminPageByIdSuccess(page));
  } catch (err) {
    console.log(err);
  }
}

export function* watchAdminPages() {
  yield takeLatest(GET_ADMIN_PAGE, getAdminPage)
  yield takeLatest(CREATE_ADMIN_PAGE, createAdminPage)
  yield takeLatest(GET_ADMIN_PAGE_BY_ID, getAdminPageId)
  yield takeLatest(UPDATE_ADMIN_PAGE, updateAdminPage)
  yield takeLatest(DELETE_ADMIN_PAGE, deleteAdminPage)
  yield takeLatest(VALIDATE_ADMIN_PAGE, validateFieldSaga)
}
