import React, { memo } from 'react'
import { IHelpContent } from '../../../models/tasks/tasksModel'
import TreeNode from './TreeNode'

interface IhelpListTreeProps {
	data: IHelpContent[]
	setParentId: (parentId: any) => void
	page: any
	setHelpModalUpdate: React.Dispatch<React.SetStateAction<boolean>>
	setHelpModalAdd: React.Dispatch<React.SetStateAction<boolean>>
	setData: React.Dispatch<React.SetStateAction<{}>>
}

const Tree = memo(
	({ data, page, setHelpModalUpdate, setHelpModalAdd, setData, setParentId}: IhelpListTreeProps) => {
		return (
			<div>
				{data?.map((node: any) => {
					return (
						<TreeNode
							setData={setData}
							setParentId={setParentId}
							setHelpModalUpdate={setHelpModalUpdate}
							setHelpModalAdd={setHelpModalAdd}
							page={page}
							key={node.id}
							help={node}
						/>
					)
				})}
			</div>
		)
	}
)

export default Tree
