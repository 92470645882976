import React from 'react'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { MasterInit } from './MasterInit'
import { PageDataProvider, useLayout } from './core'
import {
	DrawerMessenger,
	ActivityDrawer,
	Main,
	InviteUsers,
	UpgradePlan,
	NewMember,
	// ChangePersonalInfoModal
} from '../partials'
import clsx from 'clsx'

const MasterLayout: React.FC = ({ children }) => {
	const { classes } = useLayout()

	return (
		<PageDataProvider>
			<div className='page d-flex flex-row flex-column-fluid'>
				<div
					className='wrapper d-flex flex-column flex-row-fluid'
					id='kt_wrapper'
				>
					<HeaderWrapper />

					<div
						id='kt_content'
						className='content d-flex flex-column flex-column-fluid'
					>
						<Toolbar />
						<div
							className={clsx(
								'd-flex flex-column-fluid align-items-start',
								classes.contentContainer.join(' ')
							)}
							id='kt_post'
						>
							<AsideDefault />
							<Content>{children}</Content>
						</div>
					</div>
					<Footer />
				</div>
			</div>

			{/* begin:: Drawers */}
			<ActivityDrawer />
			<DrawerMessenger />
			{/* end:: Drawers */}

			{/* begin:: Modals */}
			<Main />
			<NewMember />
			{/* <ChangePersonalInfoModal /> */}
			<InviteUsers />
			<UpgradePlan />
			{/* end:: Modals */}

			<MasterInit />
			<ScrollTop />
		</PageDataProvider>
	)
}

export { MasterLayout }
