import clsx from 'clsx'
import React, { FC } from 'react'
import { KTSVG } from '../../../helpers/KTSVG'
import { toAbsoluteUrl } from '../../../../helpers'
import {
	HeaderUserMenu,
} from '../../../partials'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { IUserModel } from '../../../../models/personal/paginationCustom'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
	toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
	toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
	toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
	// const token = localStorage.token
	// const { pathname } = useLocation()
	// const personal = pathname.split('/')[pathname.split('/').length - 1]
	// const dispatch = useDispatch()
	const user: IUserModel = useSelector<RootState, IUserModel>(
		state => state.auth.user,
		shallowEqual
	)
	// useEffect(() => {
	// 	// dispatch(getPersonalById(personal))
	// 	// dispatch(getUserAction())
	// }, [])
	return (
		<div className='d-flex align-items-stretch flex-shrink-0'>
			<div className='topbar d-flex align-items-stretch flex-shrink-0'>
				{/* Search */}
				{/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
				{/* Activities */}
				<div
					className={clsx(
						'd-flex align-items-center ',
						toolbarButtonMarginClass
					)}
				>
					<div
						className={clsx(
							'btn btn-icon btn-active-light-primary position-relative',
							toolbarButtonHeightClass
						)}
						id='kt_activities_toggle'
					>
						<KTSVG
							path='/media/icons/duotune/general/gen032.svg'
							className={toolbarButtonIconSizeClass}
						/>
					</div>
				</div>
				{/* Quick links */}
				{/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen025.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <QuickLinks />
         
        </div> */}

				{/* CHAT */}
				<div
					className={clsx(
						'd-flex align-items-center',
						toolbarButtonMarginClass
					)}
				>
					<div
						className={clsx(
							'btn btn-icon btn-active-light-primary position-relative',
							toolbarButtonHeightClass
						)}
						id='kt_drawer_chat_toggle'
					>
						<KTSVG
							path='/media/icons/duotune/communication/com012.svg'
							className={toolbarButtonIconSizeClass}
						/>

						<span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
					</div>
				</div>

				{/* NOTIFICATIONS */}
				{/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
     
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen022.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <HeaderNotificationsMenu />
         
        </div> */}

				{/* begin::User */}
				<div
					className={clsx(
						'd-flex align-items-center',
						toolbarButtonMarginClass
					)}
					id='kt_header_user_menu_toggle'
				>
					{/* begin::Toggle */}
					<div
						className={clsx(
							'cursor-pointer symbol',
							toolbarUserAvatarHeightClass
						)}
						data-kt-menu-trigger='click'
						data-kt-menu-attach='parent'
						data-kt-menu-placement='bottom-end'
						data-kt-menu-flip='bottom'
					>
						<img
							className='h-25px w-25px rounded'
							src={toAbsoluteUrl(
								user.personalInfo?.imagePath
									? user.personalInfo?.imagePath
									: '/media/avatars/blank.png'
							)}
							alt='profile photo'
						/>
					</div>
					<HeaderUserMenu userInfo={user} />
					{/* end::Toggle */}
				</div>
				{/* end::User */}
			</div>
		</div>
	)
}

export { Topbar }
