import axios from '../../setup/axios/axios-config'
import { AnyAction } from 'redux'
import { takeLatest, put, call } from 'redux-saga/effects'
import {
	createCorrespondenceErrorAction,
	createCorrespondenceMessagesSuccessAction,
	createCorrespondenceSuccessAction,
	replyMessageSuccessAction,
	requestCorrespondenceErrorAction,
	requestCorrespondenceImportantCountSuccess,
	requestCorrespondenceInfoSuccess,
	requestCorrespondenceMarkCountSuccess,
	requestCorrespondenceMessagesSuccessAction,
	requestCorrespondenceNotOpenCountSuccessAction,
	requestCorrespondenceSuccessAction,
	requestPublicCorrespondenceAnonymNameSuccess,
	requestPublicCorrespondenceInfoSuccess,
	requestPublicCorrespondenceMessagesSuccessAction,
	sendPublicCorrespondenceMessageSuccessAction,
} from '../actions/correspondenceAction'
import {
	ICorrespondence,
	ICorrespondenceContent,
	ICorrespondenceMessages,
} from '../reducers/correspondenceReducer'
import {
	CREATE_CORRESPONDENCE,
	CREATE_CORRESPONDENCE_MESSAGE,
	FETCH_CORRESPONDENCE,
	FETCH_CORRESPONDENCE_INFO,
	FETCH_CORRESPONDENCE_MESSAGES,
	FETCH_PUBLIC_CORRESPONDENCE_INFO,
	REPLY_MESSAGE_REQUEST,
	REPLY_PUBLIC_MESSAGE,
	REQUEST_CORRESPONDENCE_IMPORTANT_COUNT,
	REQUEST_CORRESPONDENCE_MARK_COUNT,
	REQUEST_CORRESPONDENCE_NOT_OPEN_COUNT,
	REQUEST_OPEN_CORRESPONDENCE,
	REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME,
	REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES,
	SEND_PUBLIC_CORRESPONDENCE_MESSAGE,
	TOGGLE_CORRESPONDENCE_IMPORTANT,
	TOGGLE_CORRESPONDENCE_MARK,
} from '../correspondenceTypes'
import Swal from 'sweetalert2'
import { ICorrespondenceFilter } from '../../pages/chat/Chat'
import { IMessageFilter } from '../../pages/chat/ChatReply/ChatReply'
import axiosNative from 'axios'
import emitter from '../../setup/redux/emitter'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const axiosNota = axiosNative.create({
	baseURL: BASE_URL,
})

///////////////////////////////////////FETCH CORRESPONDENCE
const requestCorrespondenceApi = (filter?: ICorrespondenceFilter) =>
	axios
		.get('/api/mail', {
			params: {
				page: filter?.page,
				marked: filter?.marked,
				important: filter?.important,
				title: filter?.title,
			},
		})
		.then(res => res.data)

function* requestCorrespondence(action: AnyAction) {
	const filter = action?.filter
	// Object.keys(filter).forEach(k => filter[k] == null && delete filter[k])
	try {
		const response: ICorrespondence = yield call(
			requestCorrespondenceApi,
			filter
		)
		yield put(requestCorrespondenceSuccessAction(response))
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			yield put(requestCorrespondenceErrorAction('Ошибка сервера'))
		} else {
			yield put(requestCorrespondenceErrorAction(e?.response?.data?.message))
		}
	}
}

///////////////////////////////////////CREATE CORRESPONDENCE

const createCorrespondenceApi = (payload: any) =>
	axios
		.post('/api/mail', payload, {
			onUploadProgress: (progressEvent: any) => {
				if (progressEvent.lengthComputable) {
					emitter.emit(
						'FILE_PERCENTAGE',
						Math.floor(100 * (progressEvent.loaded / progressEvent.total))
					)
				}
			},
		})
		.then(res => res.data)

function* createCorrespondence(action: AnyAction) {
	const data = action.payload
	const formData = new FormData()
	formData.append('title', data?.title)
	formData.append('content', data?.content)
	for (let i = 0; i < data?.members.length; i++) {
		formData.append('members', data?.members[i])
	}
	if (!!data.attachments.length) {
		for (let i = 0; i < data?.attachments.length; i++) {
			formData.append('attachments', data?.attachments[i])
		}
	}
	try {
		const newCorrespondence: ICorrespondence = yield call(
			createCorrespondenceApi,
			formData
		)

		yield call(requestCorrespondenceOpenApi, newCorrespondence.id)

		const response: ICorrespondence = yield call(requestCorrespondenceApi)
		yield put(createCorrespondenceSuccessAction(response))
		emitter.emit('FILE_PERCENTAGE', 0)
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			yield put(createCorrespondenceErrorAction('Ошибка сервера'))
		} else {
			yield put(createCorrespondenceErrorAction(e?.response?.data?.message))
		}
	}
}

///////////////////////////////////////TOGGLE CORRESPONDENCE MARK
const toggleCorrespondenceMarkApi = (id: number) =>
	axios.post(`/api/mail/${id}/toggle-mark`)

function* toggleCorrespondenceMark(action: AnyAction) {
	try {
		yield call(toggleCorrespondenceMarkApi, action.payload)
		const response: ICorrespondence = yield call(
			requestCorrespondenceApi,
			action.filter
		)
		yield put(requestCorrespondenceSuccessAction(response))

		const markCount: number = yield call(requestCorrespondenceMarkCountApi)
		yield put(requestCorrespondenceMarkCountSuccess(markCount))
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			Swal.fire({
				title: 'Ошибка сервера',
				icon: 'error',
				timer: 3000,
			})
		} else {
			Swal.fire({
				title: e?.response?.data?.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}
}
///////////////////////////////////////TOGGLE CORRESPONDENCE IMPORTANT

const toggleCorrespondenceImportantApi = (id: number) =>
	axios.post(`/api/mail/${id}/toggle-important`)

function* toggleCorrespondenceImportant(action: AnyAction) {
	try {
		yield call(toggleCorrespondenceImportantApi, action.payload)
		const response: ICorrespondence = yield call(
			requestCorrespondenceApi,
			action.filter
		)
		yield put(requestCorrespondenceSuccessAction(response))

		const importantCount: number = yield call(
			requestCorrespondenceImportantCountApi
		)
		yield put(requestCorrespondenceImportantCountSuccess(importantCount))
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			Swal.fire({
				title: 'Ошибка сервера',
				icon: 'error',
				timer: 3000,
			})
		} else {
			Swal.fire({
				title: e?.response?.data?.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}
}

/////////////////////////////////////// FETCH CORRESPONDENCE MESSAGES

const requestCorrespondenceMessagesApi = (
	chatId: number,
	filter?: IMessageFilter
) =>
	axios
		.get(`/api/mail/${chatId}/messages`, { params: { page: filter?.page } })
		.then(res => res.data)

function* getCorrespondenceMessages(action: AnyAction) {
	try {
		const response: ICorrespondenceMessages = yield call(
			requestCorrespondenceMessagesApi,
			action.payload,
			action.filter
		)
		yield put(requestCorrespondenceMessagesSuccessAction(response))
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			Swal.fire({
				title: 'Ошибка сервера',
				icon: 'error',
				timer: 3000,
			})
		} else {
			Swal.fire({
				title: e?.response?.data?.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}
}

/////////////////////////////////////// CREATE CORRESPONDENCE MESSAGES

const createCorrespondenceMessageApi = (chatId: number, payload: any) =>
	axios
		.post(`/api/mail/${chatId}/messages`, payload, {
			onUploadProgress: (progressEvent: any) => {
				if (progressEvent.lengthComputable) {
					emitter.emit(
						'FILE_PERCENTAGE',
						Math.floor(100 * (progressEvent.loaded / progressEvent.total))
					)
				}
			},
		})
		.then(res => res.data)

function* createCorrespondenceMessage(action: AnyAction) {
	const formData = new FormData()
	formData.append('content', action.payload.content)
	if (action.payload.attachments?.length) {
		for (let i = 0; i < action.payload.attachments?.length; i++) {
			formData.append('attachments', action.payload.attachments[i])
		}
	}

	try {
		yield call(createCorrespondenceMessageApi, action.chatId, formData)
		const response: ICorrespondenceMessages = yield call(
			requestCorrespondenceMessagesApi,
			action.chatId
		)
		yield put(createCorrespondenceMessagesSuccessAction(response))
		emitter.emit('FILE_PERCENTAGE', 0)
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			Swal.fire({
				title: 'Ошибка сервера',
				icon: 'error',
				timer: 3000,
			})
		} else {
			Swal.fire({
				title: e?.response?.data?.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}
}

///////////////////////////////////// OPEN CORRESPONDENCE

const requestCorrespondenceOpenApi = (payload?: number) =>
	axios.post(`/api/mail/${payload}/open`).then(res => res.data)

function* requestCorrespondenceOpen(action: AnyAction) {
	try {
		yield call(requestCorrespondenceOpenApi, action.payload)
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			Swal.fire({
				title: 'Ошибка сервера',
				icon: 'error',
				timer: 3000,
			})
		} else {
			Swal.fire({
				title: e?.response?.data?.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}
}

////////////////////////////////////////////// REQUEST CORRESPONDENCE MARK COUNT
const requestCorrespondenceMarkCountApi = () =>
	axios.get(`/api/mail/marked/count`).then(res => res.data)

function* requestCorrespondenceMarkCount(action: AnyAction) {
	try {
		const response: number = yield call(requestCorrespondenceMarkCountApi)
		yield put(requestCorrespondenceMarkCountSuccess(response))
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			Swal.fire({
				title: 'Ошибка сервера',
				icon: 'error',
				timer: 3000,
			})
		} else {
			Swal.fire({
				title: e?.response?.data?.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}
}

////////////////////////////////////////////// REQUEST CORRESPONDENCE IMPORTANT COUNT

const requestCorrespondenceImportantCountApi = () =>
	axios.get(`/api/mail/important/count`).then(res => res.data)

function* requestCorrespondenceImportantCount(action: AnyAction) {
	try {
		const response: number = yield call(requestCorrespondenceImportantCountApi)

		yield put(requestCorrespondenceImportantCountSuccess(response))
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			Swal.fire({
				title: 'Ошибка сервера',
				icon: 'error',
				timer: 3000,
			})
		} else {
			Swal.fire({
				title: e?.response?.data?.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}
}

////////////////////////////////////////////// REQUEST CORRESPONDENCE NOT OPEN COUNT

const requestCorrespondenceNotOpenCountApi = () =>
	axios.get(`/api/mail/not-opened/count`).then(res => res.data)

function* requestCorrespondenceNotOpenCount(action: AnyAction) {
	try {
		const response: number = yield call(requestCorrespondenceNotOpenCountApi)

		yield put(requestCorrespondenceNotOpenCountSuccessAction(response))
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			Swal.fire({
				title: 'Ошибка сервера',
				icon: 'error',
				timer: 3000,
			})
		} else {
			Swal.fire({
				title: e?.response?.data?.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}
}
/////////////////////////////////////////////////////////GET PUBLIC CORRES MESSAGES

const requestPublicCorrespondenceMessagesApi = (
	payload: string,
	filter?: IMessageFilter
) =>
	axiosNota
		.get(`/api/mail/${payload}/public/messages`, {
			params: { page: filter?.page ? filter?.page : 0 },
		})
		.then(res => res.data)

function* requestPublicCorrespondenceMessages(action: AnyAction) {
	try {
		const response: ICorrespondenceMessages = yield call(
			requestPublicCorrespondenceMessagesApi,
			action.payload,
			action.filter
		)
		yield put(requestPublicCorrespondenceMessagesSuccessAction(response))
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			Swal.fire({
				title: 'Ошибка сервера',
				icon: 'error',
				timer: 3000,
			})
		} else {
			Swal.fire({
				title: e?.response?.data?.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}
}
/////////////////////////////////////////////////////////SEND PUBLIC CORRES MESSAGE

const sendPublicCorrespondenceMessageApi = (chatId: number, payload: any) =>
	axiosNota
		.post(`/api/mail/${chatId}/public/messages`, payload, {
			onUploadProgress: (progressEvent: any) => {
				if (progressEvent.lengthComputable) {
					emitter.emit(
						'FILE_PERCENTAGE',
						Math.floor(100 * (progressEvent.loaded / progressEvent.total))
					)
				}
			},
		})
		.then(res => res.data)

function* sendPublicCorrespondenceMessage(action: AnyAction) {
	const formData = new FormData()
	formData.append('content', action.payload?.content)
	action.payload?.anonymousName &&
		formData.append('anonymousName', action.payload?.anonymousName)
	if (action.payload.attachments?.length) {
		for (let i = 0; i < action.payload.attachments?.length; i++) {
			formData.append('attachments', action.payload.attachments[i])
		}
	}
	try {
		yield call(sendPublicCorrespondenceMessageApi, action.chatId, formData)

		const response: ICorrespondenceMessages = yield call(
			requestPublicCorrespondenceMessagesApi,
			action.chatUuid
		)
		yield put(sendPublicCorrespondenceMessageSuccessAction(response))
		emitter.emit('FILE_PERCENTAGE', 0)
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			Swal.fire({
				title: 'Ошибка сервера',
				icon: 'error',
				timer: 3000,
			})
		} else {
			Swal.fire({
				title: e?.response?.data?.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}
}
//////////////////////////////////////////////////REQUEST CORRESPONDENCE INFO
const requestCorrespondeceInfoApi = (payload: number) =>
	axios.get(`/api/mail/${payload}`).then(res => res.data)

function* requestCorrespondeceInfo(action: AnyAction) {
	try {
		const response: ICorrespondenceContent = yield call(
			requestCorrespondeceInfoApi,
			action.payload
		)

		yield put(requestCorrespondenceInfoSuccess(response))
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			Swal.fire({
				title: 'Ошибка сервера',
				icon: 'error',
				timer: 3000,
			})
		} else {
			Swal.fire({
				title: e?.response?.data?.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}
}
//////////////////////////////////////////////////REQUEST PUBLIC CORRESPONDENCE INFO
const requestPublicCorrespondeceInfoApi = (payload: number) =>
	axiosNota.get(`/api/mail/${payload}/public`).then(res => res.data)

function* requestPublicCorrespondeceInfo(action: AnyAction) {
	try {
		const response: ICorrespondenceContent = yield call(
			requestPublicCorrespondeceInfoApi,
			action.payload
		)

		yield put(requestPublicCorrespondenceInfoSuccess(response))
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			Swal.fire({
				title: 'Ошибка сервера',
				icon: 'error',
				timer: 3000,
			})
		} else {
			Swal.fire({
				title: e?.response?.data?.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}
}

/////////////////////////////////////////////////GET ANONYM NAME

const requestCorrespondenceAnonymNameApi = (payload: number) =>
	axiosNota.get(`/api/mail/${payload}/public/name`).then(res => res.data)

function* requestCorrespondenceAnonymName(action: AnyAction) {
	try {
		const response: string = yield call(
			requestCorrespondenceAnonymNameApi,
			action.payload
		)
		yield put(requestPublicCorrespondenceAnonymNameSuccess(response))
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			Swal.fire({
				title: 'Ошибка сервера',
				icon: 'error',
				timer: 3000,
			})
		} else {
			Swal.fire({
				title: e?.response?.data?.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}
}

/////////////////////////////////////////////////REPLY TO MESSAGE

const replyMessageApi = (messageId: number, payload: FormData) =>
	axios.post(`/api/mail/${messageId}/reply`, payload).then(res => res.data)

function* replyMessageSaga(action: AnyAction) {
	const payload = action.payload

	let formData = new FormData()
	payload.anonymousName &&
		formData.append('anonymousName', payload.anonymousName)
	payload.content && formData.append('content', payload.content)

	if (payload.attachments?.length) {
		for (let i = 0; i < payload.attachments?.length; i++) {
			formData.append('attachments', payload.attachments[i])
		}
	}

	try {
		yield call(replyMessageApi, action.messageId, formData)

		const messages: ICorrespondenceMessages = yield call(
			requestCorrespondenceMessagesApi,
			action.chatId
		)

		yield put(replyMessageSuccessAction(messages))
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			Swal.fire({
				title: 'Ошибка сервера',
				icon: 'error',
				timer: 3000,
			})
		} else {
			Swal.fire({
				title: e?.response?.data?.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}
}

/////////////////////////////////////////////////PUBLIC REPLY TO MESSAGE

const replyPublicMessageApi = (messageId: number, payload: FormData) =>
	axiosNota
		.post(`/api/mail/${messageId}/public/reply`, payload)
		.then(res => res.data)

function* replyPublicMessageSaga(action: AnyAction) {
	const payload = action.payload

	let formData = new FormData()
	payload.anonymousName &&
		formData.append('anonymousName', payload.anonymousName)
	payload.content && formData.append('content', payload.content)
	if (payload.attachments?.length) {
		for (let i = 0; i < payload.attachments?.length; i++) {
			formData.append('attachments', payload.attachments[i])
		}
	}

	try {
		yield call(replyPublicMessageApi, action.messageId, formData)

		const messages: ICorrespondenceMessages = yield call(
			requestPublicCorrespondenceMessagesApi,
			action.chatUuid
		)

		yield put(replyMessageSuccessAction(messages))
	} catch (e: any) {
		if (e?.response?.data?.status === 500) {
			Swal.fire({
				title: 'Ошибка сервера',
				icon: 'error',
				timer: 3000,
			})
		} else {
			Swal.fire({
				title: e?.response?.data?.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}
}

////////////////////////////////////////////////////////////////////////////
function* correspondenceWatcher() {
	yield takeLatest(FETCH_CORRESPONDENCE, requestCorrespondence)
	yield takeLatest(CREATE_CORRESPONDENCE, createCorrespondence)
	yield takeLatest(TOGGLE_CORRESPONDENCE_MARK, toggleCorrespondenceMark)
	yield takeLatest(
		TOGGLE_CORRESPONDENCE_IMPORTANT,
		toggleCorrespondenceImportant
	)
	yield takeLatest(FETCH_CORRESPONDENCE_MESSAGES, getCorrespondenceMessages)
	yield takeLatest(CREATE_CORRESPONDENCE_MESSAGE, createCorrespondenceMessage)
	yield takeLatest(REQUEST_OPEN_CORRESPONDENCE, requestCorrespondenceOpen)
	yield takeLatest(
		REQUEST_CORRESPONDENCE_MARK_COUNT,
		requestCorrespondenceMarkCount
	)
	yield takeLatest(
		REQUEST_CORRESPONDENCE_IMPORTANT_COUNT,
		requestCorrespondenceImportantCount
	)
	yield takeLatest(
		REQUEST_CORRESPONDENCE_NOT_OPEN_COUNT,
		requestCorrespondenceNotOpenCount
	)
	yield takeLatest(
		REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES,
		requestPublicCorrespondenceMessages
	)
	yield takeLatest(
		SEND_PUBLIC_CORRESPONDENCE_MESSAGE,
		sendPublicCorrespondenceMessage
	)
	yield takeLatest(FETCH_CORRESPONDENCE_INFO, requestCorrespondeceInfo)
	yield takeLatest(
		FETCH_PUBLIC_CORRESPONDENCE_INFO,
		requestPublicCorrespondeceInfo
	)
	yield takeLatest(
		REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME,
		requestCorrespondenceAnonymName
	)
	yield takeLatest(REPLY_MESSAGE_REQUEST, replyMessageSaga)
	yield takeLatest(REPLY_PUBLIC_MESSAGE, replyPublicMessageSaga)
}

export default correspondenceWatcher
