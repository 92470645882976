import { LinearProgress } from '@material-ui/core'
import { TextField } from '@mui/material'
import { memo, useCallback, useEffect, useState } from 'react';
import { FileHeader } from './FileHeader'
const BASE_URL = process.env.REACT_APP_BASE_URL

export interface SingleFileUploadWithProgressProps {
	file: File
	onDelete: (file: File) => void
	onUpload: (file: File, url: string) => void
	setTotalFilesUploadStatus: (status: any) => void
}

export const SingleFileUploadWithProgress = memo(
	({
		file,
		onDelete,
		onUpload,
		setTotalFilesUploadStatus,
	}: SingleFileUploadWithProgressProps) => {
		const [progress, setProgress] = useState(0)
		const [text, setText] = useState<any>({})
		const [fileInfo, setFileIfo] = useState<any>()
		useEffect(() => {
			async function upload() {
				try {
					const url = await uploadFile(file, setProgress)
					setFileIfo(url)
				} catch (e) {
					console.error('Ошибка сервера', e)
				}
			}
			upload()
		}, [])

		useEffect(() => {
			if (progress === 100) {
				setTotalFilesUploadStatus((prev: any) => [...prev, true])
			}
		}, [progress])
		useEffect(() => {
			onUpload(file, fileInfo)
		}, [fileInfo])

		const errorText = useCallback(
			text => {
				setText(text)
			},
			[text]
		)

		function uploadFile(file: File, onProgress: (percentage: number) => void) {
			const url = BASE_URL + '/api/reports/validate'
			const key = 'docs_upload_example_us_preset'
			const token = localStorage.token
			return new Promise<string>((res, rej) => {
				const xhr = new XMLHttpRequest()
				xhr.open('POST', url)
				xhr.setRequestHeader('Authorization', `Bearer ${token}`)
				xhr.onload = () => {
					const resp = JSON.parse(xhr.responseText)
					errorText(resp)
					res(resp)
				}
				xhr.onerror = evt => rej(evt)
				xhr.upload.onprogress = event => {
					if (event.lengthComputable) {
						const percentage = (event.loaded / event.total) * 100
						onProgress(Math.round(percentage))
					}
				}

				const formData = new FormData()
				formData.append('file', file)

				xhr.send(formData)
			})
		}

		return (
			<div>
				<FileHeader
					file={file}
					setTotalFilesUploadStatus={setTotalFilesUploadStatus}
					onDelete={onDelete}
				/>
				<LinearProgress variant='determinate' value={progress} />
				<p style={{ color: text?.errors ? 'red' : 'green', margin: 0 }}>
					{(text?.errors?.inn ?? text?.legal?.fullName) || ''}
				</p>

				<div style={{ paddingTop: '1rem' }}>
					<TextField
						lang='ru'
						focused
						type={'email'}
						required
						// id={fileWrapper?.url?.legal?.fullName}
						// name={fileWrapper?.url?.legal?.fullName}
						onChange={e =>
							setFileIfo({
								...fileInfo,
								legal: { ...fileInfo?.legal, email: e.target.value },
							})
						}
						// onChange={handleEmailChange}
						fullWidth
						label={'email'}
						// defaultValue={fileWrapper?.url?.legal?.email}
						value={fileInfo?.legal?.email}
						variant='outlined'
						error={!fileInfo?.legal?.email}
					/>
				</div>
			</div>
		)
	}
)
