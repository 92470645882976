import { FC } from 'react'
import { PageTitle } from '../../components/layout/core'
import TaskList from '../Task/TaskList'
import { useIntl } from 'react-intl'



const TaskListWrapper: FC = () => {
	const intl = useIntl()
	return (
		<>
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'TASK.TASKLIST' })}</PageTitle>
			<TaskList className={''} />
		</>
	)
}

export default TaskListWrapper;