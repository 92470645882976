import axios from '../../setup/axios/axios-config'
import { call, put, takeLatest } from 'redux-saga/effects'
import { REQUEST_MENU } from '../menuTypes'
import { requestMenuActionSuccess } from '../actions/menuAction';

////////////////////////////////
const getMenuListApi = () =>
	axios.get('/api/menu/available').then(res => res.data)

function* getMenuList(): any {
	try {
		const res = yield call(getMenuListApi);
		yield put(requestMenuActionSuccess(res));

	} catch (err) {
		console.log(err, 'error in saga getMenuList')
	}
}

export function* watchMenus() {
	yield takeLatest(REQUEST_MENU, getMenuList)
}
