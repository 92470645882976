import AdapterDate from '@mui/lab/AdapterMoment'
import { DatePicker, DateRange, LocalizationProvider } from '@mui/lab'
import {
	Autocomplete,
	Box,
	Checkbox,
	FormControl,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	TextField,
} from '@mui/material'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { KTSVG } from '../../components/helpers/KTSVG'
import { RootState } from '../../setup'
import axios from '../../setup/axios/axios-config'
import {
	createReportRequestAction,
	getReportsTypes,
} from '../../store/actions/reportsActions'
import 'moment/locale/ru'
import 'moment/locale/kk'

import moment from 'moment'
import DeleteIcon from '@mui/icons-material/Delete'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useLang } from '../../components/i18n/Metronici18n'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import MultiSelect from '../../components/partials/MultiSelect'
import FileUploadButton from '../Task/FileUploadButton'
import ReportGenerateModal from './ReportGenerateModal'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

interface ReportModalProps {
	show: boolean
	handleClose: () => void
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			marginLeft: 2,
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
}
const parents = [
	{ id: 2, fullName: 'Jassefi' },
	{ id: 1, fullName: 'Paydala' },
]
const validationSchema = Yup.object().shape({
	legalEmail: Yup.array().min(1, 'Выберите субагента').nullable(),
	reportTypeId: Yup.array()
		.nullable()
		.when(['file'], {
			is: (sck: any) => {
				return sck.length !== 0
			},
			then: Yup.array().nullable(),
			otherwise: Yup.array().min(1, 'Выберите тип документа').nullable(),
		}),
	description: Yup.string(),
	reportDate: Yup.string()
		.required('Не выбрали дату')
		.test({
			message: 'Дата неправильно заполнена',
			test: arr => {
				return arr !== 'Invalid date'
			},
		})
		.nullable(),
	file: Yup.mixed(),
	parentLegalId: Yup.object().nullable().required('Выберите контрагента'),
})

const ReportModal: FC<ReportModalProps> = ({ show, handleClose }) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const locale = useLang()
	const {
		contractors,
		legalByParentId,
		reportTypes,
		createReportLoading,
		createReportError,
	}: any = useSelector<RootState, any>(state => state.reports, shallowEqual)

	const [legalParentId, setLegalParentId] = useState<number>()
	const [legalId, setLegalId] = useState<number>()
	const [legalEmails, setLegalEmails] = useState<any>([])
	const [openGenerate, setOpenGenerate] = useState<boolean>(false)
	const [rangeDate, setRangeDate] = useState<DateRange<Date>>([null, null])
	const [personName, setPersonName] = React.useState<string[]>([])
	const [groupId, setGroupId] = React.useState<any>('')
	const [openPicker, setOpenPicker] = useState<boolean>(false)

	const formik = useFormik({
		initialValues: {
			name: '',
			legalEmail: legalEmails,
			description: '',
			reportTypeId: [],
			reportDate: '',
			file: [],
			parentLegalId: null,
		},
		validationSchema: validationSchema,
		onSubmit: (values: any, actions) => {
			const reportResendData = {
				groupId,
				legalEmail: values.legalEmail,
				description: values.description,
				reportTypeId: values.reportTypeId,
				year: moment(values.reportDate).get('year'),
				month: moment(values.reportDate).get('month') + 1,
				file: [...values.file],
				parentLegalId: values?.parentLegalId?.id,
			}

			dispatch(createReportRequestAction(reportResendData))
			setLegalEmails([])
			setPersonName([])
			actions.resetForm()
			handleClose()
			setRangeDate([null, null])
		},
	})

	useEffect(() => {
		dispatch(getReportsTypes())
	}, [])

	useEffect(() => {
		if (show) {
			axios.get('/api/reports/groupId').then(data => {
				setGroupId(data.data)
			})
		}
	}, [show])
	const onChangeFile = (e: any) => {
		formik.setFieldValue('file', e)
	}

	const handleReportTypeChange = (
		event: SelectChangeEvent<typeof personName>
	) => {
		const {
			target: { value },
		} = event
		setPersonName(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		)
		formik.setFieldValue(
			'reportTypeId',
			typeof value === 'string' ? value.split(',') : value
		)
	}

	useEffect(() => {
		formik.setFieldValue('startDate', rangeDate[0])
		formik.setFieldValue('endDate', rangeDate[1])
	}, [rangeDate])

	useEffect(() => {
		if (Object.keys(createReportError).length > 0) {
			handleClose()
			if (createReportError?.year) {
				Swal.fire({
					icon: 'error',
					title: 'Пусто',
					text: `Отчет за период ${moment()
						.set({
							year: createReportError.year,
							month:
								createReportError.month > 1
									? createReportError.month - 1
									: createReportError.month,
						})
						.startOf('month')
						.format('YYYY-MM-DD')} - ${moment()
						.set({
							year: createReportError.year,
							month:
								createReportError.month > 1
									? createReportError.month - 1
									: createReportError.month,
						})
						.endOf('month')
						.format('YYYY-MM-DD')} пуст`,
					timer: 5000,
				})
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Ошибка',
					text: createReportError.response?.data?.message || 'Ошибка !',
					timer: 5000,
				})
			}
		}
	}, [createReportError])

	useEffect(() => {
		formik.setFieldValue('legalEmail', legalEmails)
	}, [legalEmails])

	const handleCloseCreateModal = () => {
		handleClose()
		setLegalId(undefined)
		setLegalParentId(undefined)
		setLegalEmails([])
		formik.resetForm()
	}
	const handleDateRange = useCallback(
		(newValue: any) => {
			setRangeDate(newValue)
		},
		[rangeDate]
	)

	return (
		<Modal
			show={show}
			onHide={handleCloseCreateModal}
			size='lg'
			enforceFocus={false}
			dialogClassName='modal-dialog-centered mw-1000px h-auto'
		>
			<div className='modal-content'>
				<div className='modal-header'>
					<h2>
						Добавить документ
					</h2>
					<div
						className='btn btn-sm btn-icon btn-active-color-primary'
						onClick={handleCloseCreateModal}
					>
						<KTSVG
							path='/media/icons/duotune/arrows/arr061.svg'
							className='svg-icon-1'
						/>
					</div>
				</div>
				<div className='modal-body py-lg-10 px-lg-10'>
					<div className='card-body '>
						<Box
							component='form'
							autoComplete='off'
							onSubmit={formik.handleSubmit}
							lang='ru'
						>
							<div className='row mb-6 align-items-center'>
								<label className='col-lg-4 col-form-label fw-bold fs-6'>
									Номер документа
								</label>
								<div className='col-lg-8 fv-row'>
									<div style={{ padding: '1rem' }}>{groupId}</div>
								</div>
							</div>
							{/* //=======================PARENT LEGAL==================== */}
							<div className='row mb-6 align-items-center'>
								<label className='col-lg-4 col-form-label fw-bold fs-6'>
									{intl.formatMessage({ id: 'REPORT_CREATE_LEGALS' })}{' '}
									<i className='text-danger'>*</i>
								</label>

								<div className='col-lg-8 fv-row'>
									<div style={{ padding: '1rem' }}>
										
										<MultiSelect
											formik={formik}
											style='outlined'
											items={contractors}
											label='Выберите контрагента'
											placeholder=''
											selectAllLabel='Select all'
											onChange={setLegalEmails}
										/>
										{formik.touched.legalEmail && formik.errors.legalEmail && (
											<div style={{ color: 'red' }} className='fv-help-block'>
												{formik.errors.legalEmail}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className='row mb-6 align-items-center'>
								<label className='col-lg-4 col-form-label fw-bold fs-6'>
									{intl.formatMessage({ id: 'REPORT_CREATE_TYPES' })}{' '}
									{/* <i className='text-danger'>*</i> */}
								</label>
								<div className='col-lg-8 fv-row'>
									<div style={{ padding: '1rem' }}>
										<FormControl sx={{ width: '100%' }}>
											<InputLabel
												id='demo-multiple-chip-label'
												error={
													Boolean(formik.touched.reportTypeId) &&
													Boolean(formik.errors.reportTypeId)
												}
											>
												Выберите тип документа, чтоб сгенерировать автоматически
											</InputLabel>
											<Select
												fullWidth
												multiple
												name='reportTypeId'
												value={formik.values.reportTypeId}
												onChange={handleReportTypeChange}
												input={
													<OutlinedInput
														label='Выберите тип документа, чтоб сгенерировать автоматически'
														error={
															Boolean(formik.touched.reportTypeId) &&
															Boolean(formik.errors.reportTypeId)
														}
													/>
												}
												renderValue={selected => {
													return selected
														.map((report: any) => {
															return report.title
														})
														.join(', ')
												}}
												MenuProps={MenuProps}
												error={
													Boolean(formik.touched.reportTypeId) &&
													Boolean(formik.errors.reportTypeId)
												}
											>
												{/* <MenuItem value=''>
												<em>None</em>
											</MenuItem> */}
												{/* <div className='d-flex flex-column '> */}
												{reportTypes?.map((type: any) => {
													return (
														// <MenuItem key={type.id} value={type.id}>
														// 	{type.title}
														// </MenuItem>

														<MenuItem
															style={{
																height: '30px',
																paddingLeft: '10px',
																display: 'flex',
																width: '100%',
															}}
															key={type.id}
															value={type}
														>
															<Checkbox
																checked={personName.indexOf(type) > -1}
															/>
															<ListItemText primary={type.title} />
														</MenuItem>
													)
												})}
												{/* </div> */}
											</Select>
										</FormControl>
										{formik.touched.reportTypeId && formik.errors.reportTypeId && (
											<div style={{ color: 'red' }} className='fv-help-block'>
												{formik.errors.reportTypeId}
											</div>
										)}
										{/* </TextField> */}
									</div>
								</div>
							</div>
							{/* ============================== description============= */}
							<div className='row mb-6 align-items-center'>
								<label className='col-lg-4 col-form-label fw-bold fs-6'>
									{/* {intl.formatMessage({ id: 'REPORT_CREATE_LEGAL_PARENT' })}{' '}
									{legalByParentId.fullName} */}
									Комментарий
								</label>
								<div className='col-lg-8 fv-row'>
									<div style={{ padding: '1rem' }}>
										<TextField
											name='description'
											onChange={formik.handleChange}
											fullWidth
											value={formik.values.description}
											InputLabelProps={{
												shrink: Boolean(formik.values.description) && true,
											}}
											label={
												// 	intl.formatMessage({
												// 	id: 'REPORT_CREATE_LEGAL_PARENT_EMAIL',
												// })
												'Комментарий'
											}
											variant='outlined'
											error={
												Boolean(formik.touched.description) &&
												Boolean(formik.errors.description)
											}
										/>
										{formik.touched.description && formik.errors.description && (
											<div style={{ color: 'red' }} className='fv-help-block'>
												{formik.errors.description}
											</div>
										)}
									</div>
								</div>
							</div>

							{/* //================================== YEAR ========================= */}
							<div className='row mb-6 align-items-center'>
								<label className='col-lg-4 col-form-label fw-bold fs-6'>
									Отчетный период
									<i className='text-danger'>*</i>
								</label>
								<div className='col-lg-8 fv-row'>
									<div style={{ padding: '1rem' }}>

										<LocalizationProvider
											dateAdapter={AdapterDate}
											locale={locale === 'kz' ? 'kk' : locale}
										>
											<DatePicker
												views={['month', 'year']}
												label={intl.formatMessage({
													id: 'REPORT_CREATE_DATE',
												})}
												open={openPicker}
												onOpen={() => setOpenPicker(true)}
												onClose={() => setOpenPicker(false)}
												inputFormat='YYYY-MM-DD'
												maxDate={moment(moment().format('YYYY'), 'YYYY-MM-DD')
													.add(1, 'y')
													.subtract(1, 'M')}
												value={
													formik.values.reportDate
														? formik.values.reportDate
														: null
												}
												onChange={newValue => {
													formik.setFieldValue(
														'reportDate',
														newValue
													)
												}}
												renderInput={params => (
													<TextField
														{...params}
														error={
															Boolean(formik.touched.reportDate) &&
															Boolean(formik.errors.reportDate)
														}
														InputLabelProps={{
															shrink: Boolean(formik.values.reportDate) && true,
														}}
														inputProps={{
															value: formik.values.reportDate
																? moment(formik.values.reportDate).format(
																		'MMMM - YYYY'
																  )
																: '',
														}}
														InputProps={{
															endAdornment: (
																<>
																	<IconButton
																		onClick={() =>
																			formik.setFieldValue('reportDate', '')
																		}
																	>
																		<DeleteIcon />
																	</IconButton>
																	<IconButton
																		onClick={() => setOpenPicker(true)}
																	>
																		<CalendarTodayIcon />
																	</IconButton>
																</>
															),
														}}
														helperText={null}
													/>
												)}
											/>
										</LocalizationProvider>
										{formik.touched.reportDate && formik.errors.reportDate && (
											<div style={{ color: 'red' }} className='fv-help-block'>
												{formik.errors.reportDate}
											</div>
										)}
									</div>
								</div>
							</div>
							{/* //================================== Generate ========================= */}
							<div className='row mb-6 align-items-center'>
								<label className='col-lg-4 col-form-label fw-bold fs-6'>
									Генерация отчета
								</label>
								<div className='col-lg-8 fv-row'>
									<div style={{ padding: '1rem' }}>
										<button
											className='btn btn-success'
											onClick={(e: any) => {
												e.preventDefault()
												setOpenGenerate(true)
											}}
										>
											Сгенерировать
										</button>
										<ReportGenerateModal
											formik={formik}
											contractors={contractors}
											reportTypes={reportTypes}
											show={openGenerate}
											handleClose={() => setOpenGenerate(false)}
										/>
									</div>
								</div>
							</div>

							<div className='row mb-6 align-items-center'>
								<label className='col-lg-4 col-form-label fw-bold fs-6'>
									Подписать под
									<i className='text-danger'>*</i>
								</label>
								<div className='col-lg-8 fv-row'>
									<div style={{ padding: '1rem' }}>
										<Autocomplete
											disablePortal
											onChange={(e: any, props: any) => {
												formik.setFieldValue('parentLegalId', props)
											}}
											value={formik.values.parentLegalId}
											getOptionLabel={(option: any) => {
												return option?.fullName || ''
											}}
											renderOption={(props, option: any) => {
												return (
													<li {...props} key={option.id}>
														{option.fullName}
													</li>
												)
											}}
											options={parents}
											sx={{ width: 300 }}
											renderInput={(params: any) => (
												<TextField {...params} label='Агент' />
											)}
										/>
										{formik.touched.parentLegalId &&
											formik.errors.parentLegalId && (
												<div style={{ color: 'red' }} className='fv-help-block'>
													{formik.errors.parentLegalId}
												</div>
											)}
									</div>
								</div>
							</div>

							{/* //================================== FILE ========================= */}
							<div className='row mb-6 align-items-center'>
								<label className='col-lg-4 col-form-label fw-bold fs-6'>
									Прикрепите свои файлы
								</label>
								<div className='col-lg-8 fv-row'>
									<div style={{ padding: '1rem' }}>

										<FileUploadButton onChange={onChangeFile} />
										{formik.touched.file && formik.errors.file && (
											<div style={{ color: 'red' }} className='fv-help-block'>
												{formik.errors.file}
											</div>
										)}
									</div>
								</div>
							</div>

							{legalEmails.map((emails: any) => {
								return (
									<div key={emails.id} className='row mb-6 align-items-center'>
										<label className='col-lg-4 col-form-label fw-bold fs-6'>
											Контрагент:
											{' ' + emails.fullName}
										</label>
										<div className='col-lg-8 fv-row'>
											<div style={{ padding: '1rem' }}>
												<TextField
													// focused
													required
													type={'email'}
													name='parentLegalEmail'
													onChange={(e: any) =>
														setLegalEmails(
															legalEmails?.map((item: any) => {
																if (item.id === emails.id) {
																	return { ...item, email: e.target.value }
																}
																return item
															})
														)
													}
													fullWidth
													value={emails.email}
													label={intl.formatMessage({
														id: 'REPORT_CREATE_LEGAL_PARENT_EMAIL',
													})}
													variant='outlined'
												/>
											</div>
										</div>
									</div>
								)
							})}
							<div className='row mb-6 align-items-center'>
								<button type='submit' className='btn btn-success'>
									{createReportLoading ? (
										<>
											<span
												className='spinner-border spinner-border-sm'
												role='status'
												aria-hidden='true'
											></span>
											<span className='sr-only'>Loading...</span>
										</>
									) : (
										intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })
									)}
								</button>
							</div>
						</Box>
					</div>
				</div>
				<div className='modal-footer d-flex'>
					<button
						disabled={createReportLoading}
						type='submit'
						className='ml-auto btn btn-secondary ms-auto'
						onClick={handleCloseCreateModal}
					>
						{intl.formatMessage({ id: 'TEXT.CLOSE' })}
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default ReportModal
