import React, { useEffect, useState } from 'react'
import { Card, Col, Spinner, Modal, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import { useHistory, useLocation } from 'react-router-dom'
import './OrganizationTable.scss'
import { confirmUserForOrganizationRequest, confirmUserForOrganizationSuccess } from '../../store/actions/organizationAction'

type stateProps = {
    login: any
    uuid: any
    password: string
    confirmPassword: string
    showPassword: boolean
}
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ConfirmUserForOrganization = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const query = useQuery()
    const confirmUserMess = useSelector<RootState, string>(state => state.organization.confirmUserMess)
    const [modal, setModal] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const [showDescription, setShowDescription] = useState(false)
    const [state, setState] = useState<stateProps>({
        login: query.get('login'),
        uuid: query.get('uuid'),
        password: '',
        confirmPassword: '',
        showPassword: false
    })
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const name = e.target.name
        if (name === 'showPassword') setState({ ...state, showPassword: !state.showPassword })
        else setState({ ...state, [name]: value })
        if (name === 'password' || name === 'confirmPassword') setShowDescription(true)
    }
    const checkPassword = () => {
        if ((state.confirmPassword === state.password) &&
            (state.password !== '') &&
            (state.password.match(/\d/) !== null) &&
            state.password.length > 7 &&
            (state.password.match(/\W/) !== null) &&
            (state.password.match(/[A-Z]/) !== null) &&
            (state.password.match(/[a-z]/) !== null)) {
            setCanSubmit(true)
            setShowDescription(false)
        }
        else setCanSubmit(false)
    }
    useEffect(() => {
        checkPassword()
    }, [state.password, state.confirmPassword])
    const confirmUserHanlder = () => {
        dispatch(confirmUserForOrganizationRequest({ login: state.login, uuid: state.uuid, newPassword: state.password }))
        setModal(true)
    }
    const closeModalHandler = () => {
        dispatch(confirmUserForOrganizationSuccess('default'))
        setModal(false)
        history.replace('/auth/login')
    }
    const modalMessage = () => {
        if (confirmUserMess === 'success') {
            return (
                <Card className='organization-modal-box'>
                    <Card.Title>
                        Подтверждение пользователя прошло успешно
                    </Card.Title>
                    <button
                        className='cashier-btn-create'
                        onClick={closeModalHandler}
                    >
                        Подтвердить
                    </button>
                </Card>
            )
        }
        else if (confirmUserMess === 'newLink') {
            return (
                <Card className='organization-modal-box'>
                    <Card.Title>
                        Время активации ссылки истекло. Вам выслана новая ссылка для подтверждения. Проверьте почту
                    </Card.Title>
                    <button
                        className='cashier-btn-create'
                        onClick={closeModalHandler}
                    >
                        Подтвердить
                    </button>
                </Card>
            )
        }
        else if (confirmUserMess === 'error') {
            return (
                <Card className='organization-modal-box'>
                    <Card.Title>
                        Что то пошло не так. Обратитесь в службу поддержки
                    </Card.Title>
                    <button
                        className='cashier-btn-create'
                        onClick={closeModalHandler}
                    >
                        Подтвердить
                    </button>
                </Card>
            )
        }
        else {
            <Card className='organization-modal-box'>
                <div className='d-flex justify-content-center align-items-center spinner-wrapper'>
                    <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                </div>
            </Card>
        }
    }
    return (
        <div className='d-flex flex-column-fluid align-items-start container'>
            <div className='content flex-row-fluid'>
                <Card className='organization-modal-box'>
                    <div>
                        <h6 className='organization-list-title'>
                            Информация о пользователе
                        </h6>
                        <div className='m-b-40-px d-flex space-betwwen'>
                            <div className='cashier-create-column'>
                                <Form.Label>Логин</Form.Label>
                            </div>
                            <div className='cashier-create-column'>
                                <Form.Control
                                    type='text'
                                    placeholder='Логин'
                                    className='oraganization-input-create'
                                    name='login'
                                    value={state.login}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className='m-b-40-px d-flex space-betwwen'>
                            <div className='cashier-create-column'>
                                <Form.Label>Пароль</Form.Label>
                            </div>
                            <div className='cashier-create-column'>
                                <Form.Control
                                    type={state.showPassword ? 'text' : 'password'}
                                    placeholder='Пароль'
                                    className='oraganization-input-create'
                                    name='password'
                                    value={state.password}
                                    onChange={onChangeHandler}
                                    isValid={canSubmit}
                                    isInvalid={!canSubmit}
                                />
                            </div>
                        </div>
                        <div className='m-b-40-px d-flex space-betwwen'>
                            <div className='cashier-create-column'>
                                <Form.Label>Подтвердите пароль</Form.Label>
                            </div>
                            <div className='cashier-create-column min-h-220'>
                                <Form.Control
                                    type={state.showPassword ? 'text' : 'password'}
                                    placeholder='Подтвердите пароль'
                                    className='oraganization-input-create'
                                    name='confirmPassword'
                                    value={state.confirmPassword}
                                    onChange={onChangeHandler}
                                    isValid={canSubmit}
                                    isInvalid={!canSubmit}
                                />
                                {showDescription &&
                                    <div className='organization-confirmation-pass'>
                                        <h6>Требования к паролю:</h6>
                                        <ul>
                                            <li>Поля "Пароль" и "Подтвердите пароль" должны совпадать</li>
                                            <li>Минимум 8 символов</li>
                                            <li>Минимум 1 цифра</li>
                                            <li>Минимум 1 заглавная</li>
                                            <li>Минимум 1 строчная</li>
                                            <li>Минимум 1 спецсимвол</li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='m-b-40-px d-flex space-betwwen'>
                            <div className='cashier-create-column'>
                                <Form.Label htmlFor='organization-user-show-pass'>Показать пароль</Form.Label>
                            </div>
                            <div className='cashier-create-column'>
                                <Form.Check id='organization-user-show-pass'
                                    type='checkbox'
                                    checked={state.showPassword}
                                    name='showPassword'
                                    onChange={onChangeHandler} />
                            </div>
                        </div>
                        <Col className='d-flex space-betwwen ' >
                            <button
                                className='cashier-btn-create'
                                disabled={!canSubmit}
                                onClick={confirmUserHanlder}
                            >
                                Сохранить
                            </button>
                        </Col>
                    </div>
                </Card>
                <Modal
                    show={modal}
                    centered
                >
                    {modalMessage()}
                </Modal>
            </div>
        </div>
    )
}
export default ConfirmUserForOrganization