import React, { memo, useCallback, useEffect, useRef, useState } from 'react'

import classnames from 'classnames/bind'
import paginationStyles from './paginationStyles.module.scss'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import {
	getTasksByType,
	getTaskSuccessAction,
} from '../../store/actions/calendarTaskAction'
import Task from './Task'
import moment from 'moment'
import { IPaginationModelTasks } from '../../models/personal/paginationCustom'
import ReactPaginate from 'react-paginate'
import { Spinner } from 'react-bootstrap'
import useDebounce from '../../components/helpers/useDebounce'
import { useIntl } from 'react-intl'
import 'moment/locale/ru'
import { useLang } from '../../components/i18n/Metronici18n'
import { Box, Button, Card, Stack } from '@mui/material'
import TaskUpdateDrawer from './TaskUpdateDrawer'
import { get } from 'lodash'
import TaskListToolbar from './TaskListToolbar'
import TaskCreateDrawer from '../../components/partials/layout/TaskCreateDrawer'
import axios from '../../setup/axios/axios-config'
let cnPage = classnames.bind(paginationStyles)

type Props = {
	className: string
}

const periods = {
	name: 'ALL',
	page: 0,
	date: moment().format('yyyy-MM-DD'),
	assignees: [],
	statuses: [],
	creators: [],
	request: '',
}

const TaskList: React.FC<Props> = () => {
	const locale = useLang()
	moment.locale(locale)
	const intl = useIntl()
	// const { tasksByEpic } = useParams<any>()

	const dispatch = useDispatch()
	const tasks: IPaginationModelTasks = useSelector<
		RootState,
		IPaginationModelTasks
	>(state => state.tasks.tasksPage, shallowEqual)
	const loading: boolean = useSelector<RootState, boolean>(
		state => state.tasks.loading,
		shallowEqual
	)
	const [modalCreate, setModalCreate] = useState<boolean>(false)
	const [taskToUpdateId, setTaskToUpdateId] = useState<number>()

	const [isOpenTaskUpdateDrawer, setOpenTaskUpdateDrawer] =
		useState<boolean>(false)
	const [isOpenTaskCreateDrawer, setOpenTaskCreateDrawer] =
		useState<boolean>(false)

	const [period, setPeriod] = useState<any>({ ...periods, name: 'ALL' })
	const [curentDate, setCurrentDate] = useState<string>()
	const currentPeriod = useRef<string>()

	const [person, setPersonal] = useState<number[]>([])
	const [status, setStatus] = useState<number[]>([])
	const [creatorPerson, setCreatorPerson] = useState<number[]>([])

	const [search, setSearch] = useState('')
	const [tags, setTags] = useState('')
	const debouncedSearchTerm = useDebounce(search, 500)
	const [showEpicList, setShowEpicList] = useState<string>('group')
	const [epics, setEpics] = useState([])
	const [selectedEpics, setSelectedEpics] = useState([])

	const [assignee, setAssignee] = useState([])
	const [author, setAuthor] = useState([])

	useEffect(() => {
		const tags = async () => {
			const tag = await axios.get('/api/tags')
			setEpics(await tag.data)
		}
		tags()
	}, [])

	const handleBackToTags = () => {
		setShowEpicList('group')
		setStatus([])
		setPersonal([])
		setCreatorPerson([])
		setSearch('')
		setTags('')
		setAssignee([])
		setAuthor([])
		// setTagFilter(null)
	}

	const handleSeacrh = (e: any) => {
		const text = e.target.value
		setSearch(text)
	}
	useEffect(() => {
		setPeriod({
			...period,
			assignees: person,
			creators: creatorPerson,
			statuses: status,
			tagId: tags,
			page: 0,
		})
	}, [person, status, creatorPerson, tags])

	// useEffect(() => {
	// 	setPeriod({
	// 		...period,
	// 		tagId: tags,
	// 	})
	// }, [tasksByEpic])

	useEffect(() => {
		if (currentPeriod.current !== period.name) {
			setCurrentDate(prev => undefined)
		}

		dispatch(getTasksByType(period))
	}, [period])

	useEffect(() => {
		setPeriod({ ...period, page: 0, request: search })
	}, [debouncedSearchTerm])

	const handleSelectEpic = (item: any) => {
		getTaskSuccessAction([])
		// setPeriod({ ...period, page: 0, tagId: item.id })
		setTags(item.id)
		setShowEpicList('list')
	}

	const handleNextPeriod = () => {
		const week = period.name
		const nextWeek = moment(curentDate).add(1, week).format('yyyy-MM-DD')
		setCurrentDate(nextWeek)
		currentPeriod.current = period.name
		setPeriod({ ...period, date: nextWeek, page: 0 })
	}

	const handlePrevPeriod = () => {
		const week = period.name
		const nextWeek = moment(curentDate).subtract(1, week).format('yyyy-MM-DD')
		setCurrentDate(nextWeek)
		currentPeriod.current = period.name
		setPeriod({ ...period, date: nextWeek, page: 0 })
	}

	// const handleToday = () => {
	// 	const periodName = period.name
	// 	setCurrentDate(undefined)
	// 	currentPeriod.current = period.name
	// 	const nextWeek = moment().add(0, periodName).format('yyyy-MM-DD')
	// 	setPeriod({ ...period, date: nextWeek })
	// }

	const handlePageClick = (e: any) => {
		setPeriod({ ...period, page: e.selected })
	}

	// const handleOpenTaskCreateDrawer = useCallback(() => {
	// 	setOpenTaskCreateDrawer(true)
	// }, [])

	const handleCloseTaskCreateDrawer = useCallback(() => {
		setOpenTaskCreateDrawer(false)
	}, [])

	const handleOpenTaskUpdateDrawer = useCallback((id: number | undefined) => {
		setTaskToUpdateId(id)
		setOpenTaskUpdateDrawer(true)
	}, [])

	const handleCloseTaskUpdateDrawer = useCallback(() => {
		setTaskToUpdateId(undefined)
		setOpenTaskUpdateDrawer(false)
	}, [])

	const _tasks = get(tasks, 'content', [])

	return (
		<>
			<Card sx={{ width: '100%' }}>
				<Box
					sx={{
						display: 'flex',
						p: 3,
						justifyContent: 'space-between',
						borderBottom: 1,
						borderColor: 'grey.500',
					}}
				>
					<h2>{intl.formatMessage({ id: 'TASK.TASKLIST' })}</h2>
					<h3>
						{moment(tasks?.periodStart || Date.now()).format('MMMM D, yyyy') ===
						moment(tasks?.periodEnd || Date.now()).format('MMMM D, yyyy')
							? moment(tasks?.periodStart || Date.now()).format('MMMM D, yyyy')
							: `${moment(tasks?.periodStart || Date.now()).format(
									'MMMM D, yyyy'
							  )} - ${moment(tasks?.periodEnd || Date.now()).format(
									'MMMM D, yyyy'
							  )}`}
					</h3>
					<Button
						variant='contained'
						onClick={() => setOpenTaskCreateDrawer(true)}
					>
						{intl.formatMessage({ id: 'TASK.ADDTASK' })}
					</Button>
				</Box>
				<Box sx={{ p: 3 }}>
					<TaskListToolbar
						creatorPerson={creatorPerson}
						handleNextPeriod={handleNextPeriod}
						handlePrevPeriod={handlePrevPeriod}
						handleSeacrh={handleSeacrh}
						period={period}
						periods={periods}
						status={status}
						setStatus={setStatus}
						setPeriod={setPeriod}
						setPersonal={setPersonal}
						setCreatorPerson={setCreatorPerson}
						person={person}
						setSearch={setSearch}
						search={search}
						setTags={setTags}
						tags={tags}
						showEpicList={showEpicList}
						setShowEpicList={setShowEpicList}
						epics={epics}
						selectedEpics={selectedEpics}
						setSelectedEpics={setSelectedEpics}
						setAssignee={setAssignee}
						setAuthor={setAuthor}
						author={author}
						assignee={assignee}
					/>

					{/* ////////////////////////// TASK LIST //////////////////////// */}
					{showEpicList === 'list' && period.tagId && (
						<Button
							variant='contained'
							onClick={handleBackToTags}
							sx={{ mb: 2 }}
						>
							Назад
						</Button>
					)}
					<div className='task_list_container' style={{ position: 'relative' }}>
						{showEpicList === 'list' && (
							<Stack spacing={1}>
								{_tasks.map(task => {
									return (
										<Task
											key={task.id}
											task={task}
											handleUpdateDrawer={handleOpenTaskUpdateDrawer}
											filter={period}
										/>
									)
								})}
								{loading && (
									<div
										style={{
											position: 'absolute',
											top: '30%',
											left: '45%',
											width: '150px',
											height: '70px',
											background: 'white',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: '12px',
											boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
										}}
										className='loader'
									>
										<div
											className='loader-body'
											style={{ display: 'flex', alignItems: 'center' }}
										>
											<Spinner animation='border' role='status'></Spinner>
											<span className='ps-5 fs-4'>Загрузка</span>
										</div>
									</div>
								)}
							</Stack>
						)}
						{showEpicList === 'group' && (
							<Stack spacing={1}>
								{selectedEpics?.length > 0
									? selectedEpics?.map((epic: any) => {
											return (
												<Button
													variant='outlined'
													sx={{
														display: 'flex',
														justifyContent: 'start',
														'&:hover': {
															color: 'red',
														},
													}}
													// elevation={3}
													// sx={{ p: 3, cursor: 'pointer' }}
													onClick={() => handleSelectEpic(epic)}
													key={epic.id}
												>
													{epic.name}
												</Button>
											)
									  })
									: !!epics.length &&
									  epics?.map((item: any) => {
											return (
												<Button
													variant='outlined'
													sx={{
														display: 'flex',
														justifyContent: 'start',
														'&:hover': {
															color: 'red',
														},
													}}
													// elevation={3}
													// sx={{ p: 3, cursor: 'pointer' }}
													onClick={() => handleSelectEpic(item)}
													key={item.id}
												>
													{item.name}
												</Button>
											)
									  })}
							</Stack>
						)}
					</div>

					{showEpicList === 'list' && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '20px',
							}}
						>
							<div className='fs-6 fw-bold text-gray-700'>
								{intl.formatMessage({ id: 'PAGINATION.ENTRIES' })}{' '}
								{tasks?.totalElements}
							</div>
							<ReactPaginate
								previousLabel={'<'}
								nextLabel={'>'}
								pageCount={tasks.totalPages}
								onPageChange={handlePageClick}
								containerClassName={cnPage('pagination')}
								previousLinkClassName={cnPage('pagination__link')}
								nextLinkClassName={cnPage('pagination__link')}
								disabledClassName={cnPage('pagination__link--disabled')}
								activeClassName={cnPage('pagination__link--active')}
								pageRangeDisplayed={2}
								marginPagesDisplayed={2}
								forcePage={period.page}
							/>
						</div>
					)}
				</Box>
			</Card>

			<TaskCreateDrawer
				show={isOpenTaskCreateDrawer}
				handleClose={handleCloseTaskCreateDrawer}
				filter={period}
			/>

			<TaskUpdateDrawer
				data={taskToUpdateId}
				show={isOpenTaskUpdateDrawer}
				handleClose={handleCloseTaskUpdateDrawer}
				filter={period}
			/>
		</>
	)
}

export default memo(TaskList)
