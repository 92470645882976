import { AnyAction } from 'redux'

import {
	CREATE_CORRESPONDENCE,
	CREATE_CORRESPONDENCE_ERROR,
	CREATE_CORRESPONDENCE_MESSAGE,
	CREATE_CORRESPONDENCE_MESSAGE_ERROR,
	CREATE_CORRESPONDENCE_MESSAGE_SUCCESS,
	CREATE_CORRESPONDENCE_SUCCESS,
	FETCH_CORRESPONDENCE,
	FETCH_CORRESPONDENCE_ERROR,
	FETCH_CORRESPONDENCE_INFO_SUCCESS,
	FETCH_CORRESPONDENCE_MESSAGES,
	FETCH_CORRESPONDENCE_MESSAGES_ERROR,
	FETCH_CORRESPONDENCE_MESSAGES_SUCCESS,
	FETCH_CORRESPONDENCE_SUCCESS,
	FETCH_PUBLIC_CORRESPONDENCE_INFO_SUCCESS,
	REPLY_MESSAGE_ERROR,
	REPLY_MESSAGE_REQUEST,
	REPLY_MESSAGE_SUCCESS,
	REQUEST_CORRESPONDENCE_IMPORTANT_COUNT_SUCCESS,
	REQUEST_CORRESPONDENCE_MARK_COUNT_SUCCESS,
	REQUEST_CORRESPONDENCE_NOT_OPEN_COUNT_SUCCESS,
	REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME_SUCCESS,
	REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES,
	REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES_ERROR,
	REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES_SUCCESS,
	SEND_PUBLIC_CORRESPONDENCE_MESSAGE,
	SEND_PUBLIC_CORRESPONDENCE_MESSAGE_ERROR,
	SEND_PUBLIC_CORRESPONDENCE_MESSAGE_SUCCESS,
} from '../correspondenceTypes'

//-----------------------------------Correspondence interfaces
export interface ICorrespondenceCreator {
	id?: number
	fullName: string
	imagePath: string
}

export interface ICorrespondenceMessageReply {
	anonymousName?: string
	attachments: ICorrespondenceMessagesContentAttachment
	content: string
}

export interface ICorrespondenceContent {
	id: number
	title: string
	status: string
	createdBy: ICorrespondenceCreator
	important: boolean
	marked: boolean
	opened: boolean
	publicUrl: string
	createdAt: string
	newMessagesCount: number
	lastMessage: ICorrespondenceMessagesContent
}

export interface ICorrespondence {
	id?: number
	content: ICorrespondenceContent[]
	numberOfElements: number
	page: number
	totalElements: number
	totalPages: number
}

//-----------------------------------Correspondence messages interfaces
export interface ICorrespondenceMessagesContentAttachment {
	baseFileUrl: string
	creatorId: number
	fileType: string
	folder: boolean
	id: number
	lastModified: string
	name: string
	size: number
	uuid: string
}
export interface ICorrespondenceMessagesContent {
	id: number
	anonymousName: string
	content: string
	createdBy: ICorrespondenceCreator
	attachments: ICorrespondenceMessagesContentAttachment[]
	createdAt: string
	replyTo: ICorrespondenceMessagesContent
}
export interface ICorrespondenceMessages {
	content: ICorrespondenceMessagesContent[]
	numberOfElements: number
	page: number
	totalElements: number
	totalPages: number
}

//-----------------------------------Correspondence state interface
export interface ICorrespondenceProps {
	correspondences: ICorrespondence | {}
	correspondencesLoading: boolean
	correspondencesError: string

	createCorrespondenceLoading: boolean
	createCorrespondenceError: string
	//////////////////////////////////////////////////////
	correspondenceMessages: ICorrespondenceMessages | {}
	correspondenceMessagesLoading: boolean
	correspondenceMessagesError: string

	createCorrespondenceMessageLoading: boolean
	createCorrespondenceMessageError: string

	correspondenceMarkCount: number
	correspondenceImportantCount: number
	correspondenceNotOpenCount: number

	createPublicCorrespondenceLoading: boolean
	createPublicCorrespondenceError: string

	correspondenceInfo: ICorrespondenceContent

	anonymName: string

	replyMessageLoading: boolean
	replyMessageError: string
}

const initialState: ICorrespondenceProps = {
	correspondences: {} as ICorrespondence,
	correspondencesLoading: false,
	correspondencesError: '',

	createCorrespondenceLoading: false,
	createCorrespondenceError: '',

	correspondenceMessages: {} as ICorrespondenceMessages,
	correspondenceMessagesLoading: false,
	correspondenceMessagesError: '',

	createCorrespondenceMessageLoading: false,
	createCorrespondenceMessageError: '',

	correspondenceMarkCount: 0,
	correspondenceImportantCount: 0,
	correspondenceNotOpenCount: 0,

	createPublicCorrespondenceLoading: false,
	createPublicCorrespondenceError: '',

	correspondenceInfo: {} as ICorrespondenceContent,

	anonymName: '',

	replyMessageLoading: false,
	replyMessageError: '',
}

const correspondenceReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case FETCH_CORRESPONDENCE:
			return {
				...state,
				correspondencesLoading: true,
				correspondencesError: '',
			}
		case FETCH_CORRESPONDENCE_SUCCESS:
			return {
				...state,
				correspondences: action.payload,
				correspondencesLoading: false,
				correspondencesError: '',
			}
		case FETCH_CORRESPONDENCE_ERROR:
			return {
				...state,
				correspondencesLoading: false,
				correspondencesError: action.payload,
			}

		case CREATE_CORRESPONDENCE:
			return {
				...state,
				createCorrespondenceLoading: true,
				createCorrespondenceError: '',
			}
		case CREATE_CORRESPONDENCE_SUCCESS:
			return {
				...state,
				createCorrespondenceLoading: false,
				correspondences: action.payload,
				createCorrespondenceError: '',
			}
		case CREATE_CORRESPONDENCE_ERROR:
			return {
				...state,
				createCorrespondenceLoading: false,
				createCorrespondenceError: action.payload,
			}

		////////////////////
		case FETCH_CORRESPONDENCE_MESSAGES:
			return {
				...state,
				correspondenceMessagesLoading: true,
				correspondenceMessagesError: '',
			}
		case FETCH_CORRESPONDENCE_MESSAGES_SUCCESS:
			return {
				...state,
				correspondenceMessages: action.payload,
				correspondenceMessagesLoading: false,
				correspondenceMessagesError: '',
			}
		case FETCH_CORRESPONDENCE_MESSAGES_ERROR:
			return {
				...state,
				correspondenceMessagesLoading: false,
				correspondenceMessagesError: action.payload,
			}

		case CREATE_CORRESPONDENCE_MESSAGE:
			return {
				...state,
				createCorrespondenceMessageLoading: true,
				createCorrespondenceMessageError: '',
			}
		case CREATE_CORRESPONDENCE_MESSAGE_SUCCESS:
			return {
				...state,
				correspondenceMessages: action.payload,
				createCorrespondenceMessageLoading: false,
				createCorrespondenceMessageError: '',
			}
		case CREATE_CORRESPONDENCE_MESSAGE_ERROR:
			return {
				...state,
				createCorrespondenceMessageLoading: false,
				createCorrespondenceMessageError: action.payload,
			}
		case REQUEST_CORRESPONDENCE_MARK_COUNT_SUCCESS:
			return { ...state, correspondenceMarkCount: action.payload }
		case REQUEST_CORRESPONDENCE_IMPORTANT_COUNT_SUCCESS:
			return { ...state, correspondenceImportantCount: action.payload }
		case REQUEST_CORRESPONDENCE_NOT_OPEN_COUNT_SUCCESS:
			return { ...state, correspondenceNotOpenCount: action.payload }
		case REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES:
			return { ...state, correspondenceMessagesLoading: true }
		case REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES_SUCCESS:
			return {
				...state,
				correspondenceMessages: action.payload,
				correspondenceMessagesLoading: false,
			}
		case REQUEST_PUBLIC_CORRESPONDENCE_MESSAGES_ERROR:
			return {
				...state,
				correspondenceMessagesError: action.payload,
				correspondenceMessagesLoading: false,
			}
		case SEND_PUBLIC_CORRESPONDENCE_MESSAGE:
			return {
				...state,
				createPublicCorrespondenceLoading: true,
				createPublicCorrespondenceError: '',
			}
		case SEND_PUBLIC_CORRESPONDENCE_MESSAGE_SUCCESS:
			return {
				...state,
				correspondenceMessages: action.payload,
				createPublicCorrespondenceLoading: false,
				createPublicCorrespondenceError: '',
			}
		case SEND_PUBLIC_CORRESPONDENCE_MESSAGE_ERROR:
			return {
				...state,
				createPublicCorrespondenceLoading: false,
				createPublicCorrespondenceError: '',
			}
		case FETCH_PUBLIC_CORRESPONDENCE_INFO_SUCCESS:
			return { ...state, correspondenceInfo: action.payload }
		case FETCH_CORRESPONDENCE_INFO_SUCCESS:
			return { ...state, correspondenceInfo: action.payload }

		// case REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME:
		// return {...state}
		case REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME_SUCCESS:
			return { ...state, anonymName: action.payload }
		// case REQUEST_PUBLIC_CORRESPONDENCE_ANONYMOUSE_NAME_ERROR:
		// return {...state}
		case REPLY_MESSAGE_REQUEST:
			return { ...state, replyMessageLoading: true, replyMessageError: '' }
		case REPLY_MESSAGE_SUCCESS:
			return {
				...state,
				replyMessageLoading: false,
				correspondenceMessages: action.payload,
			}
		case REPLY_MESSAGE_ERROR:
			return {
				...state,
				replyMessageLoading: false,
				replyMessageError: action.payload,
			}
		default:
			return state
	}
}

export default correspondenceReducer
