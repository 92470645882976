import { CreateOfdUser, OfdUsersDataRes, Permission } from "../../models/coomonOFD/FileModel";
import {
    COMMON_OFD_ERROR,
    CREATE_OFD_USER_REQUEST,
    GET_OFD_USERS_REQUEST,
    GET_OFD_USERS_SUCCESS,
    GET_USER_PERMISSIONS_REQUEST,
    GET_USER_PERMISSIONS_SUCCESS,
    SET_OFD_LOADING,
    SET_PROPS_TO_FILTER_OFD_USERS
} from "../actionTypes";

export const commonOFDError = (error: any) => ({ type: COMMON_OFD_ERROR, error })

export const getUserPermissionsRequest = () => ({ type: GET_USER_PERMISSIONS_REQUEST })
export const getUserPermissionSuccess = (list: Permission[]) => ({ type: GET_USER_PERMISSIONS_SUCCESS, list })

export const setOfdLoadingAction = (data: boolean) => ({ type: SET_OFD_LOADING, data })

export const getOfdUsersRequest = (props: string) => ({ type: GET_OFD_USERS_REQUEST, props })
export const getOfdUsersSuccess = (data: OfdUsersDataRes) => ({ type: GET_OFD_USERS_SUCCESS, data })

export const createOfdUserRequest = (data: CreateOfdUser, props: string) => ({ type: CREATE_OFD_USER_REQUEST, data, props })

export const setPropsToFilterUsers = (props: string) => ({ type: SET_PROPS_TO_FILTER_OFD_USERS, props })