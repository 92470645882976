import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { PageTitle } from '../../components/layout/core'

const AboutUs = () => {
	const matches = useMediaQuery('(max-width:768px)')

	return (
		<>
			<PageTitle breadcrumbs={[]}>О нас</PageTitle>
			<Paper sx={{ p: 2 }}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={6}>
						<Box>
							<img
								style={{ width: '100%' }}
								src='https://cdni.iconscout.com/illustration/premium/thumb/e-wallet-4487367-3722728.png'
								alt='Paydala'
							/>
						</Box>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<Box
							sx={{
								gap: 2,
								height: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								flexDirection: 'column',
								textAlign: 'center',
								py: matches ? '6rem' : '',
							}}
						>
							<Typography variant='h4'>Что такое Paydala?</Typography>{' '}
							<Typography sx={{ fontSize: '14px' }} variant='subtitle2'>
								Paydala - платежная организация предоставляющая лаконичные
								решения в сфере платежных услуг.
							</Typography>
							<Typography variant='h4'>Предназначение Paydala</Typography>{' '}
							<Typography sx={{ fontSize: '14px' }} variant='subtitle2'>
								Мы соединяем клиентов, предоставляя комплексные
								финансово-технологические решения для приема платежей и
								проведения расчетов с физическими, юридическими лицами, а также
								предоставляем доступ к банковской платформе для создания
								современных финансовых сервисов
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</>
	)
}

export default AboutUs
