import axios from '../../setup/axios/axios-config-ofd'
import { AnyAction } from "redux"
import { takeLatest, put, call } from 'redux-saga/effects'
import { CREATE_OFD_USER_REQUEST, GET_OFD_USERS_REQUEST, GET_USER_PERMISSIONS_REQUEST } from '../actionTypes'
import { commonOFDError, getOfdUsersRequest, getOfdUsersSuccess, getUserPermissionSuccess, setOfdLoadingAction } from '../actions/commonOFDAction'
import { CreateOfdUser, OfdUsersDataRes, Permission } from '../../models/coomonOFD/FileModel'

const fetchUserPerm = () => axios.get(`/user/permission`).then(res => res.data)
const fetchOfdUsers = (props: string) => axios.get(`/user${props}`).then(res => res.data)
const createOfdUserReq = (data: CreateOfdUser) => axios.post(`/user`, data).then(res => res.data)

function* getUserPermissionSaga(action: AnyAction) {
    try {
        const list: Permission[] = yield call(fetchUserPerm)
        yield put(getUserPermissionSuccess(list))
    } catch (e) {
        yield put(commonOFDError(e))
    }
}
function* getOfdUsersListSaga(action: AnyAction) {
    try {
        const data: OfdUsersDataRes = yield call(fetchOfdUsers, action.props)
        yield put(getOfdUsersSuccess(data))
    } catch (e) {
        yield put(commonOFDError(e))
    }
}
function* createOfdUserSaga(action: AnyAction) {
    try {
        yield put(setOfdLoadingAction(true))
        yield call(createOfdUserReq, action.data)
        yield put(getOfdUsersRequest(action.props))
        yield put(setOfdLoadingAction(false))
    } catch (e) {
        yield put(commonOFDError(e))
    }
}
export function* watchCommonOFD() {
    yield takeLatest(GET_USER_PERMISSIONS_REQUEST, getUserPermissionSaga)
    yield takeLatest(GET_OFD_USERS_REQUEST, getOfdUsersListSaga)
    yield takeLatest(CREATE_OFD_USER_REQUEST, createOfdUserSaga)
}