import AdapterDate from '@mui/lab/AdapterMoment'
import {
	DatePicker,
	LocalizationProvider,
} from '@mui/lab'
import {
	Box,
	Checkbox,
	FormControl,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	TextField,
	Modal,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../components/helpers/KTSVG'
import MultiSelect from '../../components/partials/MultiSelect'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from '../../setup/axios/axios-config'
import QueryString from 'qs'
import Swal from 'sweetalert2'

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 600,
	bgcolor: 'background.paper',
	borderRadius: 2,
	boxShadow: 24,
	p: 4,
	display: 'flex',
	flexDirection: 'column',
}

interface ReportModalProps {
	show: boolean
	handleClose: () => void
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			marginLeft: 2,
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
}

const validationSchema = Yup.object().shape({
	legalEmail: Yup.array().min(1, 'Выберите субагента').nullable(),
	reportTypeId: Yup.array().min(1, 'Выберите тип документа').nullable(),
	reportDate: Yup.string()
		.required('Не выбрали дату')
		.test({
			message: 'Дата неправильно заполнена',
			test: arr => {
				return arr !== 'Invalid date'
			},
		})
		.nullable(),
})

interface ReportGenerateModalProps {
	show: boolean
	handleClose: () => void
	formik: any
	contractors: any[]
	reportTypes: any[]
}
const ReportGenerateModal: FC<ReportGenerateModalProps> = ({
	show,
	formik,
	handleClose,
	contractors,
	reportTypes,
}) => {
	const intl = useIntl()
	const [legals, setLegals] = useState<any>([])
	const [personName, setPersonName] = useState<any[]>([])
	const [datePer, setDatePer] = useState<string>('')
	const [openPicker, setOpenPicker] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	useEffect(() => {
		setLegals(formik.values.legalEmail)
		setPersonName(formik.values.reportTypeId)
		genFormik.setFieldValue('legalEmail', formik.values.legalEmail)
		genFormik.setFieldValue('reportTypeId', formik.values.reportTypeId)
		genFormik.setFieldValue('reportDate', formik.values.reportDate)
	}, [formik])
	const handleReportTypeChange = (
		event: SelectChangeEvent<typeof personName>
	) => {
		const {
			target: { value },
		} = event
		setPersonName(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		)
		genFormik.setFieldValue(
			'reportTypeId',
			typeof value === 'string' ? value.split(',') : value
		)
	}

	useEffect(() => {
		genFormik.setFieldValue('legalEmail', legals)
	}, [legals])

	const genFormik = useFormik({
		initialValues: {
			legalEmail: legals,
			reportTypeId: personName,
			reportDate: datePer,
		},
		validationSchema: validationSchema,
		onSubmit: (values, actions) => {
			const reportResendData = {
				legals: values.legalEmail.map((item: any) => item.id),
				reportTypes: values.reportTypeId.map(item => item.id),
				year: moment(values.reportDate).get('year'),
				month: moment(values.reportDate).get('month') + 1,
			}
			setLoading(true)
			axios
				.get(`/api/reports/download`, {
					params: {
						...reportResendData,
					},
					paramsSerializer: params => {
						return QueryString.stringify(params)
					},
					responseType: 'blob',
				})
				.then(async response => {

					let blob = new Blob([response.data])
					let link = document.createElement('a')
					link.href = window.URL.createObjectURL(blob)
					link.download = 'reports.zip'
					link.click()
					setLoading(false)
				})
			.catch(async (e: any) => {
				// if (e instanceof Error) {
				// }
				Swal.fire({
					position: 'center',
					icon: 'error',
					title: JSON.parse(await (e?.response?.data)?.text())?.message,
					// showConfirmButton: false,
					timer: 3000,
				})
				setLoading(false)
			})
		},
	})

	return (
		<Modal
			open={show}
			onClose={handleClose}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
			sx={{ overflow: 'scroll' }}
		>
			<Box
				sx={{
					position: 'absolute' as 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 600,
					bgcolor: 'background.paper',
					borderRadius: 2,
					boxShadow: 24,
					p: 4,
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div className='modal-header'>
					<h2>Генерация</h2>
					<div
						className='btn btn-sm btn-icon btn-active-color-primary'
						onClick={() => handleClose()}
					>
						<KTSVG
							path='/media/icons/duotune/arrows/arr061.svg'
							className='svg-icon-1'
						/>
					</div>
				</div>
				<div className='modal-body '>
					<Box
						component='form'
						autoComplete='off'
						onSubmit={genFormik.handleSubmit}
						lang='ru'
					>
						<div className='row mb-6 align-items-center'>
							<label className='col-lg-4 col-form-label fw-bold fs-6'>
								{/* {intl.formatMessage({ id: 'REPORT_CREATE_LEGALS' })}{' '}
								 */}{' '}
								Контрагенты
								<i className='text-danger'>*</i>
							</label>

							<div className='col-lg-8 fv-row '>
								<div
									style={{
										padding: '1rem',
										maxHeight: 300,
										overflow: 'scroll',
									}}
								>
									<MultiSelect
										formik={genFormik}
										style='outlined'
										items={contractors}
										label='Субагенты'
										placeholder=''
										selectAllLabel='Select all'
										onChange={setLegals}
										selectedValues={legals}
									/>
									{genFormik.touched.legalEmail && genFormik.errors.legalEmail && (
										<div style={{ color: 'red' }} className='fv-help-block'>
											{genFormik.errors.legalEmail}
										</div>
									)}
								</div>
							</div>
						</div>
						<div className='row mb-6 align-items-center'>
							<label className='col-lg-4 col-form-label fw-bold fs-6'>
								{intl.formatMessage({ id: 'REPORT_CREATE_TYPES' })}{' '}
								<i className='text-danger'>*</i>
							</label>
							<div className='col-lg-8 fv-row'>
								<div style={{ padding: '1rem' }}>
									<FormControl sx={{ width: '100%' }}>
										<InputLabel
											id='demo-multiple-chip-label'
											error={
												Boolean(genFormik.touched.reportTypeId) &&
												Boolean(genFormik.errors.reportTypeId)
											}
										>
											Тип документа
										</InputLabel>
										<Select
											fullWidth
											multiple
											name='reportTypeId'
											value={genFormik.values.reportTypeId}
											onChange={handleReportTypeChange}
											input={
												<OutlinedInput
													label='Тип документа'
													error={
														Boolean(genFormik.touched.reportTypeId) &&
														Boolean(genFormik.errors.reportTypeId)
													}
												/>
											}
											renderValue={selected => {
												return selected
													.map((report: any) => {
														return report.title
													})
													.join(', ')
											}}
											MenuProps={MenuProps}
											error={
												Boolean(genFormik.touched.reportTypeId) &&
												Boolean(genFormik.errors.reportTypeId)
											}
										>
											{/* <MenuItem value=''>
												<em>None</em>
											</MenuItem> */}
											{/* <div className='d-flex flex-column '> */}
											{reportTypes?.map((type: any) => {
												return (
													// <MenuItem key={type.id} value={type.id}>
													// 	{type.title}
													// </MenuItem>

													<MenuItem
														style={{
															height: '30px',
															paddingLeft: '10px',
															display: 'flex',
															width: '100%',
														}}
														key={type.id}
														value={type}
													>
														<Checkbox checked={personName.indexOf(type) > -1} />
														<ListItemText primary={type.title} />
													</MenuItem>
												)
											})}
											{/* </div> */}
										</Select>
									</FormControl>
									{genFormik.touched.reportTypeId &&
										genFormik.errors.reportTypeId && (
											<div style={{ color: 'red' }} className='fv-help-block'>
												{genFormik.errors.reportTypeId}
											</div>
										)}
									{/* </TextField> */}
								</div>
							</div>
						</div>
						<div className='row mb-6 align-items-center'>
							<label className='col-lg-4 col-form-label fw-bold fs-6'>
								{intl.formatMessage({ id: 'REPORT_CREATE_DATE' })}
							</label>
							<div className='col-lg-8 fv-row'>
								<div style={{ padding: '1rem' }}>
									<LocalizationProvider
										dateAdapter={AdapterDate}
										// locale={locale === 'kz' ? 'kk' : locale}
									>
										<DatePicker
											views={['month', 'year']}
											label={intl.formatMessage({
												id: 'REPORT_CREATE_DATE',
											})}
											open={openPicker}
											onOpen={() => setOpenPicker(true)}
											onClose={() => setOpenPicker(false)}
											inputFormat='YYYY-MM-DD'
											// minDate={moment(
											// 	moment().format('YYYY'),
											// 	'YYYY-MM-DD'
											// )}
											maxDate={moment(moment().format('YYYY'), 'YYYY-MM-DD')
												.add(1, 'y')
												.subtract(1, 'M')}
											value={
												genFormik.values.reportDate
													? genFormik.values.reportDate
													: null
											}
											onChange={newValue => {
												genFormik.setFieldValue(
													'reportDate',
													moment(newValue).format('YYYY-MM-DD')
												)
											}}
											renderInput={params => (
												<TextField
													fullWidth
													{...params}
													error={
														Boolean(genFormik.touched.reportDate) &&
														Boolean(genFormik.errors.reportDate)
													}
													InputLabelProps={{
														shrink:
															Boolean(genFormik.values.reportDate) && true,
													}}
													inputProps={{
														value: genFormik.values.reportDate
															? moment(genFormik.values.reportDate).format(
																	'MMMM - YYYY'
															  )
															: '',
													}}
													InputProps={{
														endAdornment: (
															<>
																<IconButton
																	onClick={() =>
																		genFormik.setFieldValue('reportDate', '')
																	}
																>
																	<DeleteIcon />
																</IconButton>
																<IconButton onClick={() => setOpenPicker(true)}>
																	<CalendarTodayIcon />
																</IconButton>
															</>
														),
													}}
													helperText={null}
												/>
											)}
										/>
									</LocalizationProvider>
									{genFormik.touched.reportDate && genFormik.errors.reportDate && (
										<div style={{ color: 'red' }} className='fv-help-block'>
											{genFormik.errors.reportDate}
										</div>
									)}
								</div>
							</div>
						</div>
						<div className='d-flex justify-content-between'>
							<div></div>
							{/* <button className='btn btn-success me-5'>Скачать</button> */}

							<button
								disabled={!genFormik.isValid || loading}
								type='submit'
								className='btn btn-success me-5'
							>
								{loading ? (
									<span>
										<span
											className='spinner-border spinner-border-sm me-3'
											role='status'
											aria-hidden='true'
										></span>
										<span>Загрузка...</span>
									</span>
								) : (
									'Скачать'
								)}
							</button>
						</div>
					</Box>
				</div>
			</Box>
		</Modal>
	)
}

export default ReportGenerateModal
