import { useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { IStatus } from '../../models/tasks/tasksModel'
import { RootState } from '../../setup'
import { getStatusesAction } from '../../store/actions/statusActions'
import { useIntl } from 'react-intl'

interface statusProps {
	status: number[]
	setStatus: React.Dispatch<React.SetStateAction<number[]>>
}

const StatusFilter: React.FC<statusProps> = ({ setStatus, status }) => {
	const dispatch = useDispatch()
	const intl = useIntl()
	const statuses: IStatus[] = useSelector<RootState, IStatus[]>(
		state => state.status.statuses,
		shallowEqual
	)

	useEffect(() => {
		dispatch(getStatusesAction())
	}, [])

	const statusChange = (e: any) => {
		if (e.target.checked) {
			setStatus([...status, +e.target.value])
		} else {
			setStatus([...status.filter(data => +e.target.value !== data)])
		}
	}

	const statusChangeAll = (e: any) => {
		if (e.target.checked) {
			setStatus(statuses.map((item: any) => item.id))
		} else {
			setStatus([])
		}
	}

	return (
		<div
			className='me-2'
			style={{
				border: '1px solid  #A1A5B7',
				borderRadius: '4px',
				height: '2.7em',
				color: 'red',
			}}
		>
			<Dropdown autoClose='outside'>
				<Dropdown.Toggle
					style={{
						height: '2.7em',
						padding: '0 15px',
						borderRadius: '0.25em',
						color: 'red',
						// border: '1px solid #EBEDF3',
					}}
					className='text-gray-600 fw-normal fs-7'
					variant='outline-secondary'
					id='dropdown-autoclose-outside'
				>
					{intl.formatMessage({ id: 'TASK.STATUS' })}
				</Dropdown.Toggle>

				<Dropdown.Menu style={{ height: '200px', overflowY: 'scroll' }}>
					<div className='  d-flex align-items-center dropdown-item'>
						<label
							style={{ margin: 0 }}
							className='text-gray-900 text-hover-primary fw-normal fs-8  d-flex align-items-center'
						>
							<div className='form-check form-check-custom form-check-solid  d-flex align-items-center me-2'>
								<input
									checked={status?.length === statuses?.length}
									onChange={statusChangeAll}
									className='form-check-input'
									type='checkbox'
									value={''}
								/>
							</div>
							{'Выбрать все'}
						</label>
					</div>

					{statuses?.map(item => {
						return (
							<div
								key={item.id}
								className='  d-flex align-items-center dropdown-item'
							>
								<label
									style={{ margin: 0 }}
									className='text-gray-800 text-hover-primary fw-normal fs-8  d-flex align-items-center'
								>
									<div className='form-check form-check-custom form-check-solid  d-flex align-items-center me-2'>
										<input
											onChange={statusChange}
											checked={!!status.find((e: any) => e === item.id)}
											className='form-check-input'
											type='checkbox'
											value={item.id}
										/>
									</div>
									{item.code}
								</label>
							</div>
						)
					})}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}

export default StatusFilter
