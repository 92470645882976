import { CHECK_ADMIN_PAGE_NAME, CREATE_ADMIN_PAGE, DELETE_ADMIN_PAGE, GET_ADMIN_PAGE, GET_ADMIN_PAGE_BY_ID, GET_ADMIN_PAGE_BY_ID_SUCCESS, GET_ADMIN_PAGE_ERROR, GET_ADMIN_PAGE_SUCCESS, UPDATE_ADMIN_PAGE, VALIDATE_ADMIN_PAGE } from "../pageTypes"

export const getAdminPage = (pages: any) => ({ type: GET_ADMIN_PAGE, pages })
export const getAdminPageSuccessAction = (pages: any) => ({ type: GET_ADMIN_PAGE_SUCCESS, pages })
export const getAdminPageErrorAction = (error: any) => ({ type: GET_ADMIN_PAGE_ERROR, error })
export const createAdminPageAction = (page: any) => ({ type: CREATE_ADMIN_PAGE, page })

export const getAdminPageById = (id: any) => ({ type: GET_ADMIN_PAGE_BY_ID, id })
export const getAdminPageByIdSuccess = (page: any) => ({ type: GET_ADMIN_PAGE_BY_ID_SUCCESS, page })

export const updateAdminPageAction = (page: any) => ({ type: UPDATE_ADMIN_PAGE, page })

export const deleteAdminPageAction = (pageId: { page: any, adminPageId: any }) => ({ type: DELETE_ADMIN_PAGE, pageId })

export const validateAdminPageAction = (symbols:string) => ({ type: VALIDATE_ADMIN_PAGE, symbols })
export const checkAdminPageAction = (pageName:boolean) => ({ type: CHECK_ADMIN_PAGE_NAME, pageName })



