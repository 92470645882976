import { AnyAction } from 'redux'
import { Cashier, OFD, Shift } from '../../models/cashier/FileModel'
import {
    CASHIER_ERROR,
    GET_CASHIER_LIST_SUCCESS,
    GET_LIST_OFD_SUCCESS,
    GET_SHIFT_LIST_SUCCESS,
    SET_ORG_NAME_FOR_FILTER,
    SET_PROPS_TO_FILTER_CASHIER
} from '../actionTypes'

export interface CashierState {
    cashierList: Cashier[]
    totalPages: number
    totalItems: number
    oragNameForFilter: string
    ofdList: OFD[]
    propsForFilter: string
    shift: {
        list: Shift[]
        totalPages: number
        totalItems: number
    }
    error: any
}
const initialState: CashierState = {
    cashierList: [],
    totalItems: 0,
    totalPages: 0,
    oragNameForFilter: '',
    ofdList: [],
    propsForFilter: '',
    shift: {
        list: [],
        totalItems: 0,
        totalPages: 0
    },
    error: null
}
const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case CASHIER_ERROR:
            return { ...state, error: action.error }
        case GET_CASHIER_LIST_SUCCESS:
            return { ...state, cashierList: action.data.list, totalItems: action.data.totalItems, totalPages: action.data.totalPages }
        case SET_ORG_NAME_FOR_FILTER:
            return { ...state, oragNameForFilter: action.name }
        case GET_LIST_OFD_SUCCESS:
            return { ...state, ofdList: action.list }
        case SET_PROPS_TO_FILTER_CASHIER:
            return { ...state, propsForFilter: action.props }
        case GET_SHIFT_LIST_SUCCESS:
            return { ...state, shift: { list: action.data.list, totalItems: action.data.totalItems, totalPages: action.data.totalPages } }

        default:
            return state
    }
}
export default reducer