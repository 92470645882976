import { Card, Modal, Tabs, Tab } from 'react-bootstrap'
import OrgInfoCashierTab from './OrgInfoCashierTab'
import OrgInfoMainTab from './OrgInfoMainTab'
import OrgInfoUserTab from './OrgInfoUserTab'

type props = {
    orgId: number
    show: boolean
    close: CallableFunction
    orgName: string
}

const OrgInfoModal = ({
    orgId,
    show,
    close,
    orgName
}: props) => {
    return (
        <Modal
            show={show}
            onHide={() => close()}
            centered
            size={'lg'}
        >
            <Card className='organization-modal-box'>
                <Tabs defaultActiveKey="main" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="main" title="Информация об организации">
                        <OrgInfoMainTab orgId={orgId} />
                    </Tab>
                    <Tab eventKey="users" title="Пользователи">
                        <OrgInfoUserTab orgId={orgId} />
                    </Tab>
                    <Tab eventKey="cashiers" title="Кассы" >
                        <OrgInfoCashierTab orgId={orgId} orgName={orgName} />
                    </Tab>
                </Tabs>
            </Card>
        </Modal>
    )
}
export default OrgInfoModal