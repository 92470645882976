import React, { useEffect, useState } from 'react'
import { Card, Col, Modal, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import { Permission } from '../../models/coomonOFD/FileModel'
import { createOfdUserRequest } from '../../store/actions/commonOFDAction'

type Props = {
    show: boolean
    close: CallableFunction
}
type State = {
    fullName: string
    email: string
    login: string
    code: number
    permissions: string[]
}

const CreateOfdUserModal = ({ show, close }: Props) => {
    const dispatch = useDispatch()
    const permissions = useSelector<RootState, Permission[]>(state => state.commonOFD.permissions)
    const propsForUsersFilter = useSelector<RootState, string>(state => state.commonOFD.propsForUsersFilter)
    const [canSubmit, setCanSubmit] = useState<boolean>(false)
    const [state, setState] = useState<State>({
        fullName: '',
        email: '',
        login: '',
        code: 0,
        permissions: []
    })
    const emailValidation = (email: string) => {
        if (email.match(/\@/)) {
            if (email.match(/\./)) return true
            else return false
        }
        else return false
    }
    useEffect(() => {
        if (state.fullName === '' ||
            state.email === '' ||
            !emailValidation(state.email) ||
            state.login === '' ||
            state.code === 0) setCanSubmit(false)
        else setCanSubmit(true)
    }, [state])
    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const name = e.target.name
        if (name === 'code' && parseInt(value) > 0) setState({ ...state, code: parseInt(value) })
        else if (name !== 'code') setState({ ...state, [name]: value })
    }
    const changePermissionsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name
        const copyStatePerm = [...state.permissions]
        const isPermission = copyStatePerm.find(item => item === name)
        if (isPermission) {
            const index = copyStatePerm.indexOf(name)
            copyStatePerm.splice(index, 1)
        }
        else copyStatePerm.push(name)
        setState({ ...state, permissions: copyStatePerm })
    }
    const submitHanlder = () => {
        dispatch(createOfdUserRequest(state, propsForUsersFilter))
        close()
    }
    return (
        <Modal
            show={show}
            onHide={() => close()}
            centered
            size={'lg'}
        >
            <Card className='organization-modal-box'>
                <h6 className='organization-list-title'>
                    Создание нового пользователю для организации
                </h6>
                <div className='m-b-40-px d-flex space-betwwen'>
                    <div className='cashier-create-column'>
                        <Form.Label>ФИО</Form.Label>
                    </div>
                    <div className='cashier-create-column'>
                        <Form.Control
                            type='text'
                            placeholder='ФИО'
                            isInvalid={state.fullName === ''}
                            isValid={state.fullName !== ''}
                            className='oraganization-input-create'
                            name='fullName'
                            value={state.fullName}
                            onChange={changeHandler}
                        />
                    </div>
                </div>
                <div className='m-b-40-px d-flex space-betwwen'>
                    <div className='cashier-create-column'>
                        <Form.Label>Логин</Form.Label>
                    </div>
                    <div className='cashier-create-column'>
                        <Form.Control
                            type='text'
                            placeholder='Логин'
                            isValid={state.login !== ''}
                            isInvalid={state.login === ''}
                            className='oraganization-input-create'
                            name='login'
                            value={state.login}
                            onChange={changeHandler}
                        />
                    </div>
                </div>
                <div className='m-b-40-px d-flex space-betwwen'>
                    <div className='cashier-create-column'>
                        <Form.Label>Email</Form.Label>
                    </div>
                    <div className='cashier-create-column'>
                        <Form.Control
                            type='text'
                            placeholder='Email'
                            isValid={emailValidation(state.email)}
                            isInvalid={!emailValidation(state.email)}
                            className='oraganization-input-create'
                            name='email'
                            value={state.email}
                            onChange={changeHandler}
                        />
                    </div>
                </div>
                <div className='m-b-40-px d-flex space-betwwen'>
                    <div className='cashier-create-column'>
                        <Form.Label>Code</Form.Label>
                    </div>
                    <div className='cashier-create-column'>
                        <Form.Control
                            type='number'
                            className='oraganization-input-create'
                            isInvalid={state.code === 0}
                            isValid={state.code !== 0}
                            name='code'
                            value={state.code}
                            onChange={changeHandler}
                        />
                    </div>
                </div>
                <div className='m-b-40-px d-flex space-betwwen organization-permissions-list'>
                    <div className='cashier-create-column'>
                        <Form.Label>Разрешения пользователя</Form.Label>
                    </div>
                    <div className='cashier-create-column'>
                        {permissions.map(item => {
                            return (
                                <div key={item.name} className='d-flex space-betwwen'>
                                    <Form.Check id={item.name} type='checkbox' name={item.name} onChange={changePermissionsHandler} />
                                    <Form.Label htmlFor={item.name}>{item.description}</Form.Label>
                                </div>
                            )
                        })}

                    </div>
                </div>
                <Col className='d-flex space-betwwen ' >
                    <button
                        onClick={() => close()}
                        className='cashier-btn-create'>
                        Отмена
                    </button>
                    <button
                        className='cashier-btn-create'
                        disabled={!canSubmit}
                        onClick={submitHanlder}
                    >
                        Отправить
                    </button>
                </Col>
            </Card>
        </Modal>
    )
}
export default CreateOfdUserModal