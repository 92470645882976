import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Switch } from 'react-router-dom'
import { logoutAction } from '../../store/actions/authActions'

export function Logout() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(logoutAction())

  }, [dispatch])



  return (
    <Switch>
      {/* <Redirect to='/auth/login' /> */}
    </Switch>
  )
}
