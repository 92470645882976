import React, { useEffect, useState } from 'react'
import {
	Checkbox,
	createFilterOptions,
	TextField,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Autocomplete from '@mui/lab/Autocomplete'
import Box from '@mui/material/Box'

interface propsMul {
	limitTags?: any
	items?: any
	selectedValues?: any
	getOptionLabel?: any
	label?: any
	placeholder?: any
	selectAllLabel?: any
	noOptionsText?: any
	onChange?: any
	formik?: any
	style: any
	size?: any
	sx?: any
	value?: any
}

const MultiSelectCustom = ({
	sx,
	items,
	label,
	placeholder,
	selectAllLabel,
	noOptionsText,
	limitTags,
	onChange,
	formik,
	selectedValues,
	style,
	size,
	getOptionLabel,
	value,
}: propsMul) => {
	const [selectedOptions, setSelectedOptions] = useState([])
	const allSelected = items.length === selectedOptions.length

	useEffect(() => {
		if (value) {
			setSelectedOptions(value)
		}
	}, [value])

	const handleToggleOption = (selectedOptions: any) =>
		setSelectedOptions(selectedOptions)

	const handleClearOptions = () => {
		onChange([])
		return setSelectedOptions([])
	}

	const getOptionLabel2 = (option: any) => {
		return `${option.fullName} `
	}
	const handleSelectAll = (isSelected: any) => {
		if (isSelected) {
			setSelectedOptions(items)
		} else {
			handleClearOptions()
		}
	}

	const handleToggleSelectAll = () => {
		handleSelectAll && handleSelectAll(!allSelected)
	}

	const handleChange = (event: any, selectedOptions: any, reason: any) => {
		if (reason === 'selectOption' || reason === 'removeOption') {
			if (selectedOptions.find((option: any) => option.id === 'All')) {
				handleToggleSelectAll()
				let result = []
				result = items.filter((el: any) => el.id !== 'All')
				return onChange(result)
			} else {
				handleToggleOption && handleToggleOption(selectedOptions)
				return onChange(selectedOptions)
			}
		} else if (reason === 'clear') {
			handleClearOptions && handleClearOptions()
		}
	}
	const optionRenderer = (props: any, option: any, { selected }: any) => {
		const selectAllProps =
			option.id === 'All' // To control the state of 'select-all' checkbox
				? { checked: allSelected }
				: {}
		return (
			<Box
				component='li'
				{...props}
				key={option.id}
				style={{ paddingLeft: '5px', position: 'relative', zIndex: '100000' }}
			>
				<Checkbox
					color='primary'
					icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
					checkedIcon={<CheckBoxIcon fontSize='small' />}
					// style={{ marginLeft: 0 }}
					checked={selected}
					{...selectAllProps}
				/>
				{getOptionLabel ? getOptionLabel(option) : getOptionLabel2(option)}
			</Box>
		)
	}
	const inputRenderer = (params: any) => (
		<TextField
			{...params}
			label={label}
			style={{
				width: size.width ? size.width : '',
				height: '2.09em',
			}}
			sx={{
				'& .MuiOutlinedInput-root': {
					// zIndex: 11,
					// position: 'relative',
					background: 'white',
				},
			}}
			// InputProps={{

			// 	...params.InputProps,
			// 	startAdornment: <SearchIcon />,
			// }}
			placeholder={placeholder}
			variant={style}
			error={
				formik &&
				Boolean(formik.touched.legalEmail) &&
				Boolean(formik.errors.legalEmail)
			}
		/>
	)
	const getOptionSelected = (option: any, anotherOption: any) =>
		option.id === anotherOption.id
	const filter = createFilterOptions()
	return (
		<Autocomplete
			multiple
			size='small'
			style={{
				position: 'relative',
				zIndex: '1',
				background: '#fff',
			}}
			limitTags={limitTags}
			options={items}
			value={selectedOptions}
			sx={sx ? sx : { height: '40px' }}
			disableCloseOnSelect
			getOptionLabel={getOptionLabel ? getOptionLabel : getOptionLabel2}
			isOptionEqualToValue={getOptionSelected}
			noOptionsText={noOptionsText ? noOptionsText : 'Пусто'}
			// filterOptions={(options: any, params: any) => {
			// 	const filtered = filter(options, params)
			// 	return [
			// 		{ id: 'All', fullName: 'Выбрать всех', email: 'select-all' },
			// 		...filtered,
			// 	]
			// }}
			onChange={handleChange}
			renderOption={optionRenderer}
			renderInput={inputRenderer}
		/>
	)
}

MultiSelectCustom.defaultProps = {
	limitTags: 1,
	items: [],
	selectedValues: [],
	// getOptionLabel: (value: any) => value,
}

export default MultiSelectCustom
