import axios from '../../setup/axios/axios-config'
import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'
import { ITaskStatusPage } from '../../models/tasks/tasksModel'
import {
	getTaskStatusesErrorAction,
	getTaskStatusesSuccessAction,
} from '../actions/taskStatusAction'
import {
	CREATE_TASK_STATUS,
	GET_TASK_STATUS_REQUEST,
	UPDATE_TASK_STATUS,
} from '../taskStatusTypes'

/////////////////////////////////////////////////////////
const requestTaskStatusApi = (page: any) =>
	axios.get(`/api/task/status/page?page=${page.page}`).then(res => {
		return res.data
	})

function* getTaskStatus(action: AnyAction) {
	try {
		const taskStatusPageResponse: ITaskStatusPage = yield call(
			requestTaskStatusApi,
			action.taskStatusPage
		)
		yield put(getTaskStatusesSuccessAction(taskStatusPageResponse))
	} catch (e) {
		yield put(getTaskStatusesErrorAction(e))
	}
}

/////////////////////////////////////////////////////////

const createTaskStatusApi = (help: any) =>
	axios.post('/api/task/status', help).then(res => res.data)

function* createTaskStatus(action: AnyAction) {

	try {

		yield call(createTaskStatusApi, action.taskStatus)
		const taskStatusPageResponse: ITaskStatusPage = yield call(
			requestTaskStatusApi,
			action.page
		)
		yield put(getTaskStatusesSuccessAction(taskStatusPageResponse))
	} catch (e) {
		yield put(getTaskStatusesErrorAction(true))
	}
}
//////////////////////////////////////////////////////////////////////////////////////////
//  const deleteHelpApi = (id: any) => axios.delete('/api/help/' + (+id)).then(res => res.data)

//  function* deleteHelp(action: AnyAction) {
// 	console.log(action)
// 	try {
// 	  yield call(deleteHelpApi, action.help.id)
// 	  const help: IHelp = yield call(taskHelpApi, action.help.meta)
// 	  yield put(getTaskHelpSuccessAction(help))
// 	} catch (e) {
// 	  yield put(getTaskHelpErrorAction(e))
// 	  Swal.fire({
// 		 icon: 'error',
// 		 title: 'Oops...',
// 		 text: 'Something went wrong!',
// 	  })
// 	}
//  }

//////////////////////////////////////////////////////////////////
const updateTaskStatusApi = (taskStatus: any, taskStatusId: any) => axios.put(`/api/task/status/${taskStatusId}`, taskStatus)

function* updateTaskStatus(action: AnyAction) {
	try {
		yield call(updateTaskStatusApi, action.taskStatus, action.taskStatusId)
		const taskStatusResponse: ITaskStatusPage = yield call(requestTaskStatusApi, action.page)
		yield put(getTaskStatusesSuccessAction(taskStatusResponse))
	} catch (e) {
		yield put(getTaskStatusesErrorAction(e))
	}
}

function* taskStatusWatcher() {
	yield takeLatest(GET_TASK_STATUS_REQUEST, getTaskStatus)
	yield takeLatest(CREATE_TASK_STATUS, createTaskStatus)
	// yield takeLatest(DELETE_TASK_STATUS, deleteHelp)
	yield takeLatest(UPDATE_TASK_STATUS, updateTaskStatus)
}
export default taskStatusWatcher
