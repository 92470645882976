import { FC, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { IUserModel } from '../../../../models/personal/paginationCustom'
import { IprofileData } from '../../../../modules/accounts/components/Profile'
import { RootState } from '../../../../setup'
import { Languages } from './Languages'
import { useIntl } from 'react-intl'
import { getUserAction } from '../../../../store/actions/authActions'

interface props {
	userInfo: IUserModel
}

const HeaderUserMenu: FC<props> = ({ userInfo }: props) => {
	const dispatch = useDispatch()

	const user: IprofileData = useSelector<RootState, IprofileData>(
		state => state.personals.user,
		shallowEqual
	)
	useEffect(() => {
		dispatch(getUserAction())
	}, [])
	const intl = useIntl()

	return (
		<div
			className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
			data-kt-menu='true'
		>
			<div className='menu-item px-3'>
				<div className='menu-content d-flex align-items-center px-3'>
					<div className='symbol symbol-50px me-5'>
						<img
							alt='Logo'
							src={
								userInfo.personalInfo?.imagePath
									? userInfo.personalInfo?.imagePath
									: '/media/avatars/blank.png'
							}
						/>
					</div>

					<div className='d-flex flex-column'>
						<Link
							to={
								'/crafted/account/profile/' +
								(userInfo?.personalInfo?.id
									? userInfo?.personalInfo?.id
									: user.userId)
							}
						>
							<div className='fw-bolder d-flex align-items-center fs-5'>
								{userInfo?.personalInfo?.fullName}
							</div>
						</Link>
						<a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
							{userInfo?.personalInfo?.email}
						</a>
					</div>
				</div>
			</div>

			<div className='separator my-2'></div>

			<div className='menu-item px-5'>
				<Link
					to={'/crafted/account/profile/' + (userInfo?.personalInfo?.id || 1)}
					className='menu-link px-5'
				>
					{intl.formatMessage({ id: 'PERSONAL.MYPROFILE' })}
				</Link>
			</div>

			<div className='menu-item px-5'>
				{/* <a href='#' className='menu-link px-5'> */}
				<Link to={'/crafted/pages/employees'} className='menu-link px-5'>
					{intl.formatMessage({ id: 'PERSONAL.EMPLOYEE' })}
				</Link>
				<Link to={'/camera'} className='menu-link px-5'>
					Camera
				</Link>
				<Link to={'/Ksmr'} className='menu-link px-5'>
					Ksmr
				</Link>
				{/* <span className='menu-text'></span>
          <span className='menu-badge'> */}
				{/* <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span> */}
				{/* </span> */}
				{/* </a> */}
			</div>

			{/* <div className='menu-item px-5'> */}
			{/* <a href='#' className='menu-link px-5'> */}
			{/* <Link to={'/tasksRegister'} className='menu-link px-5'>
          Task register
        </Link> */}
			{/* <span className='menu-text'></span>
          <span className='menu-badge'> */}
			{/* <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span> */}
			{/* </span> */}
			{/* </a> */}
			{/* </div> */}

			<div
				className='menu-item px-5'
				data-kt-menu-trigger='hover'
				data-kt-menu-placement='left-start'
				data-kt-menu-flip='bottom'
			>
				<a href='#' className='menu-link px-5'>
					<span className='menu-title'>UI Admin</span>
					<span className='menu-arrow'></span>
				</a>

				<div className='menu-sub menu-sub-dropdown w-175px py-4'>
					<div>
						{/* <div className='menu-item px-3'>
						{/* <a href='#' className='menu-link px-5'>
							Status
						</a> */}
						<Link to='/status' className='menu-link px-5'>
							{intl.formatMessage({ id: 'TASK.STATUS' })}
						</Link>
					</div>
					<div className='menu-item px-3'>
						{/* <a href='#' className='menu-link px-5'>
							Status
						</a> */}
						<Link to='/pages' className='menu-link px-5'>
							Pages
						</Link>
					</div>
					<div className='menu-item px-3'>
						{/* <a href='#' className='menu-link px-5'>
							Status
						</a> */}
						<Link to='/timeTracker' className='menu-link px-5'>
							Трекер
						</Link>
					</div>

					{/* <div className='separator my-2'></div> */}
				</div>
			</div>

			{/* <div className='menu-item px-5'>
				<a href='#' className='menu-link px-5'>
					My Statements
				</a>
			</div> */}

			<div className='separator my-2'></div>

			<Languages />

			<div className='menu-item px-5 my-1'>
				<Link to='/crafted/account/settings' className='menu-link px-5'>
					{intl.formatMessage({ id: 'TEXT.SETTINGS' })}
				</Link>
			</div>

			<div className='menu-item px-5'>
				<Link to='/logout' className='menu-link px-5'>
					{intl.formatMessage({ id: 'TEXT.SIGNOUT' })}
				</Link>
			</div>
		</div>
	)
}

export { HeaderUserMenu }
