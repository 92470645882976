import { InstructionModel, StatusModel } from '../../models/instruction/InstructionModel';
import { AnyAction } from 'redux';
import {
	INSTRUCTIONS_ERROR,
	INSTRUCTIONS_REQUEST, INSTRUCTIONS_SUCCESS,
	STATUSES_ERROR,
	STATUSES_REQUEST,
	STATUSES_SUCCESS
} from '../actionTypes';

export interface InstructionState {
	instructions: InstructionModel[],
	statuses: StatusModel[],
	types: string[],
	instructionError: any
}

const initialState: InstructionState = {
	instructions: [],
	statuses: [],
	types: [],
	instructionError: ''
};

const reducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case STATUSES_REQUEST:
			return {
				...state,
				statuses: [],
				instructionError: ''
			};
		case STATUSES_ERROR:
			return { ...state, instructionError: action.error }
		case STATUSES_SUCCESS:
			return { ...state, statuses: action.statuses }
		case INSTRUCTIONS_REQUEST:
			return {
				...state,
				instructions: [],
				instructionError: ''
			};
		case INSTRUCTIONS_ERROR:
			return { ...state, instructionError: action.error }
		case INSTRUCTIONS_SUCCESS:
			return { ...state, instructions: action.instructions }
		default:
			return state;
	}
};

export default reducer;