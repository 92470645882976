import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import PublicMasterLayout from '../components/layout/PublicMasterLayout'
import PublicTasks from '../pages/TasksRegister/PublicTasks'
import PublicTaskDetailsPage from '../pages/TasksRegister/PublicTaskDetailsPage'
import KsmrPage from '../pages/KSMR/KsmrPage'
import PaymentStatus from '../pages/payment-status/PaymentStatus'
import ChatReplyAnonymouse from '../pages/chat/ChatReply/ChatReplyAnonymouse'
import AboutUs from '../pages/about-us/AboutUs'
import ContactUs from '../pages/contact-us/ContactUs'

const PublicRoutesWrapper = () => {

	return (
		<PublicMasterLayout>
			<Switch>
				<Route path='/payment-status/:id' component={PaymentStatus} />
				<Route path='/mail/:chatUuid' component={ChatReplyAnonymouse} />
				<Route path='/mail/:chatUuid' component={ChatReplyAnonymouse} />
				<Route path='/task'>
					<Switch>
						<Route exact path='/task/secret/:id' component={PublicTasks} />
						<Route
							exact
							path='/task/details/:id'
							component={PublicTaskDetailsPage}
						/>
						<Route exact path='/task/camera/' component={KsmrPage} />
						<Route render={() => <h1> Not found</h1>} />
					</Switch>
				</Route>
				<Route path='/about/us' component={AboutUs} />
				<Route path='/contacts/us' component={ContactUs} />
				{/* <Route path='/auth/login' component={AuthPage} /> */}
				{/* <Route path='/status/:id' component={PaymentStatus} /> */}
				<Route exact path='/identity/kcmr/' component={KsmrPage} />
				<Redirect to='/auth/login' />
			</Switch>
		</PublicMasterLayout>
	)
}

export default PublicRoutesWrapper
