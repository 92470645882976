import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import CashierTable from '../cashier/CashierTable'
import '../oraganization/OrganizationTable.scss'
import OfdUsersTable from '../ofdUsers/OfdUsersTable'

const PersonalCabinet = () => {

    return (
        <>
            <Tabs defaultActiveKey="cashiers" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="cashiers" title="Кассы" >
                    <CashierTable />
                </Tab>
                <Tab eventKey="users" title="Кассиры">
                    <OfdUsersTable />
                </Tab>
            </Tabs>
        </>
    )
}
export default PersonalCabinet