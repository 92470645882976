import axios from '../../setup/axios/axios-config'
import { takeLatest, put, call } from 'redux-saga/effects'
import { getTimeTrackeErrorAction, getTimeTrackerInfoSuccess, getTimeTrackerSuccessAction } from '../actions/timeTrackerAction';
import { ITimeTracker, ITimeTrackerContent } from '../../models/timeTracker/timeModel';
import { AnyAction } from 'redux';
import { GET_ORGANIZATIONS_TIMES, GET_TIMES_TRACKER_INFO } from '../timeTrackerTypes';

const fetchTimeTrackerApi = (filters: any) => axios.get(`/api/time-tracker/users?username=${filters.username}&companyName=${filters.companyName}&page=${filters.page}`).then(res => res.data)
const fetchTimeTrackerInfoApi = async (filters: any) => {

    const res = await axios.get(`/api/time-tracker/users/${filters.userId || 1}/info?page=${filters.page}&date=${filters.date}`);
    return res.data;
}

function* getUsersTimeTracker({ filters }: AnyAction) {
    try {
        const timeTrackerUsers: ITimeTracker = yield call(fetchTimeTrackerApi, filters)
        yield put(getTimeTrackerSuccessAction(timeTrackerUsers))
    } catch (e) {
        yield put(getTimeTrackeErrorAction(e))
    }
}
function* getTimeTrackerInfo({ filters }: AnyAction) {
    try {
        const timeTrackerUsers: ITimeTrackerContent = yield call(fetchTimeTrackerInfoApi, filters)
        yield put(getTimeTrackerInfoSuccess(timeTrackerUsers))
    } catch (e) {
        yield put(getTimeTrackeErrorAction(e))
    }
}

// function* uploadFile(action: AnyAction) {
//     try {
//         const file: FileOrFolderModel = yield call(fetchUploadFile, action.file)
//         yield put(uploadFileAction(file))
//     } catch (e) {
//         yield put(errorStorage(e))
//     }
// }

// function* createFolder(action: AnyAction) {
//     try {
//         const folder: FileOrFolderModel = yield call(fetchCreateFolder, action.folderName, action.parentId)
//         yield put(createFolderAction(folder))
//     } catch (e) {
//         yield put(errorStorage(e))
//     }
// }
export function* watchTimeTracker() {
    yield takeLatest(GET_ORGANIZATIONS_TIMES, getUsersTimeTracker)
    yield takeLatest(GET_TIMES_TRACKER_INFO, getTimeTrackerInfo)
    // yield takeLatest(UPLOAD_FILE_REQUEST, uploadFile)
    // yield takeLatest(CREATE_FOLDER_REQUEST, createFolder)
}