import React, { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { IprofileData } from '../../modules/accounts/components/Profile'
import { RootState } from '../../setup'
import { IPaginationModelTasks } from '../../models/personal/paginationCustom'
import { getPublicTasks } from '../../store/actions/calendarTaskAction'
import { ITasks } from '../../models/tasks/tasksModel'
import ReactPaginate from 'react-paginate'
import classnames from 'classnames/bind'
import paginationStyles from '../Task/paginationStyles.module.scss'
import { useParams } from 'react-router'
import { useIntl } from 'react-intl'
import { Box, Card, Divider, Stack } from '@mui/material'
import PublicTaskItem from './PublicTaskItem'

let cnPage = classnames.bind(paginationStyles)

interface Props {
	className: string
	employees?: IprofileData[]
}

const PublicTasks: React.FC<Props> = ({}: Props) => {
	const dispatch = useDispatch()
	const intl = useIntl()
	let { id }: any = useParams()
	const [period, setPeriod] = useState<any>({
		id,
		page: 0,
	})
	const [taskToUpdateId, setTaskToUpdateId] = useState<number>()

	const [isOpenTaskUpdateDrawer, setOpenTaskUpdateDrawer] =
		useState<boolean>(false)

	const paginationTask: IPaginationModelTasks = useSelector<
		RootState,
		IPaginationModelTasks
	>(state => state.tasks.tasksPage, shallowEqual)
	useEffect(() => {
		dispatch(getPublicTasks(period))
	}, [period])

	const handlePageClick = (e: any) => {
		setPeriod({ ...period, page: e.selected })
	}

	const handleOpenTaskUpdateDrawer = useCallback((id: number | undefined) => {
		setTaskToUpdateId(id)
		setOpenTaskUpdateDrawer(true)
	}, [])

	const handleCloseTaskUpdateDrawer = useCallback(() => {
		setTaskToUpdateId(undefined)
		setOpenTaskUpdateDrawer(false)
	}, [])

	return (
		<>
			<Card>
				<Box sx={{ p: 3 }}>
					<span className='card-label fw-bolder fs-3 mb-1'>
						{intl.formatMessage({ id: 'TASK.TASKLIST' })}
					</span>
				</Box>
				<Divider light></Divider>
				<Box sx={{ p: 3 }}>
					<Stack spacing={1}>
						{paginationTask?.content?.map((employ: ITasks) => {
							return (
								<PublicTaskItem
									key={employ.id}
									task={employ}
									handleUpdateDrawer={handleOpenTaskUpdateDrawer}
									filter={period}
								/>
							)
						})}
					</Stack>

					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							marginTop: '20px',
						}}
					>
						<div className='fs-6 fw-bold text-gray-700'>
							Showing {paginationTask.pageable?.pageSize} to{' '}
							{paginationTask.pageable?.offset} of{' '}
							{paginationTask?.totalElements} entries
						</div>
						<ReactPaginate
							previousLabel={'<'}
							nextLabel={'>'}
							pageCount={paginationTask.totalPages}
							onPageChange={handlePageClick}
							containerClassName={cnPage('pagination')}
							previousLinkClassName={cnPage('pagination__link')}
							nextLinkClassName={cnPage('pagination__link')}
							disabledClassName={cnPage('pagination__link--disabled')}
							activeClassName={cnPage('pagination__link--active', {})}
							pageRangeDisplayed={2}
							marginPagesDisplayed={2}
						/>
					</div>
				</Box>
			</Card>

			{/* <TaskUpdateDrawer
				data={taskToUpdateId}
				show={isOpenTaskUpdateDrawer}
				handleClose={handleCloseTaskUpdateDrawer}
				filter={period}
			/> */}
		</>
	)
}

export default PublicTasks
