import { FC, useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { LayoutSplashScreen } from '../../../components/layout/core'
import { RootState } from '../../../setup'
import { getUserAction } from '../../../store/actions/authActions';

const AuthInit: FC = ({ children }) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const userError: any = useSelector<RootState, any>(state => state.auth.userProfileError)
  const token = localStorage.getItem('token')

  useEffect(() => {
    const requestUser = () => {
      try {
        dispatch(getUserAction())
      } catch (error) {
        console.error(error)
      } finally {
        setShowSplashScreen(false)
      }
      return () => (didRequest.current = true)
    }

    if (token && token !== 'null') {
      requestUser()
    } else {
      setShowSplashScreen(false)
    }
  }, [dispatch, token, userError])

  return showSplashScreen ? <><LayoutSplashScreen /></> : <>{children}</>
}

export default AuthInit
