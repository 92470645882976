import {
	Avatar,
	Box,
	Button,
	Divider,
	Typography,
} from '@mui/material'
import { FC, memo, useState } from 'react'
import {
	ICorrespondenceMessagesContent,
} from '../../../store/reducers/correspondenceReducer'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import moment from 'moment'
import { TaskImageSumb } from '../../../components/partials/layout/TaskImageSumb'
import ReplyIcon from '@mui/icons-material/Reply'
import { useDispatch } from 'react-redux'

const BASE_URL = process.env.REACT_APP_BASE_URL

interface ChatReplyItemProps {
	formScroll: (e: any, messageId: number, fullName: string) => void
	chatId?: number
	anonymouseName?: string
	message: ICorrespondenceMessagesContent
	reply?: boolean
}

const ChatReplyItem: FC<ChatReplyItemProps> = memo(
	({ formScroll, chatId, anonymouseName, message, reply}) => {
		const dispatch = useDispatch()
		const [isFullContent, toogleFullContent] = useState<boolean>(true)

		const handleFullContent = () => {
			toogleFullContent(!isFullContent)
		}

		function createMarkup() {
			return { __html: message?.content }
		}

		const handleReply = (e: any) => {
			e.stopPropagation()
			formScroll(e, message?.id, message?.createdBy.fullName)
		}

		return (
			<>
				<Box
					onClick={handleFullContent}
					sx={{ px: 5, py: 3, cursor: 'pointer' }}
				>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Avatar
							sx={{ width: 56, height: 56, mr: 3 }}
							src={
								message?.createdBy?.fullName === 'Anonymous'
									? '/media/avatars/blank.png'
									: message?.createdBy?.imagePath
							}
							variant='rounded'
						/>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
							}}
						>
							<Box
								sx={{
									display: 'flex',

									alignItems: 'center',
									height: 1 / 2,
								}}
							>
								{message.createdBy?.fullName === 'Anonymous' ? (
									<a href='#'>{message?.anonymousName}</a>
								) : (
									<a href='#'>{message?.createdBy?.fullName}</a>
								)}

								{/* <FiberManualRecordIcon fontSize='small' color='success' /> */}
								{/* <Typography>1 Day ago</Typography> */}
							</Box>
							<Box sx={{ display: 'flex', height: 1 / 2 }}>
								{isFullContent ? (
									<Box display='flex' flexDirection='row'>
										<Typography>Закрыть</Typography>

										<KeyboardArrowUpIcon />
									</Box>
								) : (
									<Box display='flex' flexDirection='row'>
										<Typography>Открыть</Typography>
										<KeyboardArrowDownIcon />
									</Box>
								)}
							</Box>
						</Box>
						<Box marginLeft='auto'>
							{moment(message?.createdAt).format('MMM DD, YYYY, HH:mm a')}
						</Box>
					</Box>

					{/* //===========================================Message  */}

					{isFullContent && (
						<Box display='flex' flexDirection='column' marginTop='10px'>
							<Typography
								component={'div'}
								variant={'body2'}
								sx={{ fontSize: '13px' }}
							>
								<div dangerouslySetInnerHTML={createMarkup()} />
							</Typography>
							{!reply && (
								<Button
									sx={{ ml: 'auto' }}
									onClick={handleReply}
									variant='outlined'
									startIcon={<ReplyIcon />}
								>
									Ответить
								</Button>
							)}

							<Box>
								{message?.attachments?.map((file: any) => {
									return (
										<TaskImageSumb
											key={file.id}
											img={`${BASE_URL}/minio/file/media/${file.uuid}`}
											name={file.name}
											size={file.size}
											date={file.lastModified}
										/>
									)
								})}
							</Box>
						</Box>
					)}
				</Box>
				<Divider variant='fullWidth'></Divider>
			</>
		)
	}
)

export default ChatReplyItem
