import React, { useEffect, useState } from 'react'
import { Card, Col, Spinner, Modal, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import { EditOrganization, ActivityClassifier, TaxationType } from '../../models/organization/FileModel'
import { editOrganizationRequest, getOneOrganizationSuccess } from '../../store/actions/organizationAction'

type props = {
    modalShow: boolean
    closeModal: CallableFunction
}

const EditOrganizationModal = ({
    modalShow,
    closeModal
}: props) => {
    const dispatch = useDispatch()
    const [canSubmit, setCanSubmit] = useState<boolean>(false)
    const [firstOpen, setFirstOpen] = useState<boolean>(true)
    const editOrganization = useSelector<RootState, EditOrganization>(state => state.organization.editOrganization)
    const activityClassifierList = useSelector<RootState, ActivityClassifier[]>(state => state.organization.activityClassifier)
    const taxationTypeList = useSelector<RootState, TaxationType[]>(state => state.organization.taxationType)
    const propsForFilter = useSelector<RootState, string>(state => state.organization.propsForFilter)
    const changeOrganizationHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const name = e.target.name
        setFirstOpen(false)
        if (name === 'blocked') dispatch(getOneOrganizationSuccess({ ...editOrganization, blocked: !editOrganization.blocked }))
        else dispatch(getOneOrganizationSuccess({ ...editOrganization, [name]: value }))
    }
    const selectOrganizationHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value
        const name = e.target.name
        setFirstOpen(false)
        dispatch(getOneOrganizationSuccess({ ...editOrganization, [name]: value }))
    }
    const enableToSubmit = () => {
        if (editOrganization.activityClassifierCode === '' ||
            editOrganization.juridicalAddress === '' ||
            editOrganization.name === '' ||
            editOrganization.taxationTypeCode === '' ||
            editOrganization.xin === '' || firstOpen) setCanSubmit(false)
        else setCanSubmit(true)
    }
    useEffect(() => {
        enableToSubmit()
    }, [editOrganization])
    const closeModalHandler = () => {
        setCanSubmit(false)
        setFirstOpen(true)
        closeModal()
    }
    const editOrganizationHanlder = () => {
        dispatch(editOrganizationRequest(editOrganization, propsForFilter))
        closeModalHandler()
    }
    return (
        <Modal
            show={modalShow}
            onHide={() => closeModalHandler()}
            centered
            size={'lg'}
        >
            <Card className='organization-modal-box'>
                {(editOrganization.id === 0 || activityClassifierList.length === 0 || taxationTypeList.length === 0) ?
                    <div className='d-flex justify-content-center align-items-center spinner-wrapper'>
                        <Spinner animation='border' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                        </Spinner>
                    </div>
                    :
                    <div>
                        <h6 className='organization-list-title'>
                            Информация об организации
                        </h6>
                        <div className='m-b-40-px d-flex space-betwwen'>
                            <div className='cashier-create-column'>
                                <Form.Label>ИИН/БИН организации</Form.Label>
                            </div>
                            <div className='cashier-create-column'>
                                <Form.Control
                                    type='text'
                                    placeholder='ИИН/БИН'
                                    className='oraganization-input-create'
                                    name='xin'
                                    value={editOrganization.xin}
                                    onChange={changeOrganizationHandler}
                                />
                            </div>
                        </div>
                        <div className='m-b-40-px d-flex space-betwwen'>
                            <div className='cashier-create-column'>
                                <Form.Label>Название организации</Form.Label>
                            </div>
                            <div className='cashier-create-column'>
                                <Form.Control
                                    type='text'
                                    placeholder='Название'
                                    className='oraganization-input-create'
                                    name='name'
                                    value={editOrganization.name}
                                    onChange={changeOrganizationHandler}
                                />
                            </div>
                        </div>
                        <div className='m-b-40-px d-flex space-betwwen'>
                            <div className='cashier-create-column'>
                                <Form.Label>Юридический адрес организации</Form.Label>
                            </div>
                            <div className='cashier-create-column'>
                                <Form.Control
                                    type='text'
                                    placeholder='Юридический адрес'
                                    className='oraganization-input-create'
                                    name='juridicalAddress'
                                    value={editOrganization.juridicalAddress}
                                    onChange={changeOrganizationHandler}
                                />
                            </div>
                        </div>
                        <div className='m-b-40-px d-flex space-betwwen'>
                            <div className='cashier-create-column'>
                                <Form.Label>ОКЭД</Form.Label>
                            </div>
                            <div className='cashier-create-column'>
                                <Form.Select
                                    placeholder='ОКЭД'
                                    className='oraganization-input-create'
                                    name='activityClassifierCode'
                                    isInvalid={editOrganization.activityClassifierCode === ''}
                                    isValid={editOrganization.activityClassifierCode !== ''}
                                    value={editOrganization.activityClassifierCode}
                                    onChange={selectOrganizationHandler}
                                >
                                    <option value={''}></option>
                                    {activityClassifierList.map(item => {
                                        return (
                                            <option key={item.code} value={item.code}>{item.name}</option>
                                        )
                                    })}

                                </Form.Select>
                            </div>
                        </div>
                        <div className='m-b-40-px d-flex space-betwwen'>
                            <div className='cashier-create-column'>
                                <Form.Label>Тип налогооблажения</Form.Label>
                            </div>
                            <div className='cashier-create-column'>
                                <Form.Select
                                    placeholder='Тип налогооблажения'
                                    className='oraganization-input-create'
                                    name='taxationTypeCode'
                                    isInvalid={editOrganization.taxationTypeCode === ''}
                                    isValid={editOrganization.taxationTypeCode !== ''}
                                    value={editOrganization.taxationTypeCode}
                                    onChange={selectOrganizationHandler}
                                >
                                    <option value={''}></option>
                                    {taxationTypeList.map(item => {
                                        return (
                                            <option key={item.code} value={item.code}>{item.name + ' | ' + item.description}</option>
                                        )
                                    })}
                                </Form.Select>
                            </div>
                        </div>
                        {/* <div className='m-b-40-px d-flex space-betwwen'>
                            <div className='cashier-create-column'>
                                <Form.Label htmlFor='organization-blocked'>Заблокировать</Form.Label>
                            </div>
                            <div className='cashier-create-column'>
                                <Form.Check id='organization-blocked' type='checkbox' checked={editOrganization.blocked} name='blocked' onChange={changeOrganizationHandler} />
                            </div>
                        </div> */}
                        <Col className='d-flex space-betwwen ' >
                            <button
                                onClick={() => closeModalHandler()}
                                className='cashier-btn-create'>
                                Отмена
                            </button>
                            <button
                                className='cashier-btn-create'
                                disabled={!canSubmit}
                                onClick={editOrganizationHanlder}
                            >
                                Сохранить
                            </button>
                        </Col>
                    </div>
                }

            </Card>
        </Modal>
    )
}
export default EditOrganizationModal