import { call, put, takeLatest } from '@redux-saga/core/effects'
import axios from '../../setup/axios/axios-config'
import { IprofileData } from '../../modules/accounts/components/Profile'
import {
	getPersonalByIdAction,
	getPersonalsErrorAction,
	getPersonalsSuccessAction,
	getPersonalWithFilterErrorAction,
	getPersonalWithFilterSuccessAction,
} from '../actions/personalsActions'
import {
	CREATE_PERSONAL,
	DELETE_PERSONAL_SUCCESS,
	GET_PERSONALS,
	GET_PERSONAL_BY_ID_SAGA,
	GET_PERSONAL_WITH_FILTER_REQUEST,
	UPDATE_PERSONAL,
} from '../personalTypes'
import { AnyAction } from 'redux'
import Swal from 'sweetalert2'

const fetchPersonals = () => axios.get('/api/personal/info/all').then(res => {
  return res.data
})
const getPeranalbyIdApi = (id: any) => axios.get(`/api/personal/info/${id || ""}`).then(res => res.data)
// const getPersonalPagination = (page: any) => axios.get(`/api/personal/info/page?page=${page}&size=10`).then(res => res.data)
const createPersonalApi = (values: any) => axios.post(`/api/personal/info/`, values).then(res => res.data)
const deletePersonalApi = (id: any) => (axios.delete(`/api/personal/info/${id}`).then(res => res.data))
const updatePersonalApi = (values: any) => axios.put(`api/personal/info/${values.values.userId}`, values.values)

function* getAllPersonal() {
	try {
		const personals: IprofileData[] = yield call(fetchPersonals)
		yield put(getPersonalsSuccessAction(personals))
	} catch (e) {
		yield put(getPersonalsErrorAction(`${e} Произошла ошибка получение данных`))
	}
}

//=========================================== PERSONAL FILTER
const requiestFilteredPersonalApi = (page: number, filter?: any) => {
	return axios
		.get('/api/personal/info/search', {
			params: { page: page, fullName: filter?.fullName },
		})
		.then(res => res.data)
}

function* getPersonalWithFilter(action: AnyAction) {
	try {
		const response: IprofileData = yield call(
			requiestFilteredPersonalApi,
			action.page,
			action.filter
		)
		yield put(getPersonalWithFilterSuccessAction(response))
	} catch (e: any) {
		yield put(
			getPersonalWithFilterErrorAction(
				e.response.data.message ||
					'Ошибка ! Не удалось получить список пользователей'
			)
		)
	}
}

//===========================================

function* updatePersonal(action: AnyAction) {
	try {
		yield call(updatePersonalApi, action)

		const response: IprofileData = yield call(
			requiestFilteredPersonalApi,
			action.page,
			action.filter
		)
		yield put(getPersonalWithFilterSuccessAction(response))
		// const page: IPaginationModel = yield call(getPersonalPagination, 0)
		// yield put(getPaginationAction(page))
	} catch (e: any) {
		// yield put(getPersonalsErrorAction(e))
		yield put(
			getPersonalWithFilterErrorAction(
				e.response.data.message ||
					'Ошибка ! Не удалось получить список пользователей'
			)
		)
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'Something went wrong!',
		})
	}
}
function* deletePersonal(action: AnyAction) {
	try {
		yield call(deletePersonalApi, action.id)
	} catch (e) {
		console.log(e)
		yield put(getPersonalsErrorAction(e))
	}
}
function* createPersonal(action: AnyAction) {
	try {
		yield call(createPersonalApi, action.values)
		yield call(getAllPersonal)
		Swal.fire({
			position: 'center',
			icon: 'success',
			title: 'New member created',
			showConfirmButton: false,
			timer: 1500,
		})
	} catch (e) {
		yield put(
			getPersonalsErrorAction(`${e} Произошла ошибка при создании данных`)
		)
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'Something went wrong!',
		})
	}
}
// function* getPage(action: AnyAction) {
// 	try {
// 		const page: IPaginationModel = yield call(
// 			getPersonalPagination,
// 			action.page
// 		)
// 		yield put(getPaginationAction(page))
// 	} catch (e) {
// 		yield put(getPersonalsErrorAction(e))
// 	}
// }

function* getPersonalbyId(action: AnyAction) {
	try {
		const user: IprofileData = yield call(getPeranalbyIdApi, action.id)
		yield put(getPersonalByIdAction(user))
	} catch (e) {
		console.log(e)
		yield put(getPersonalsErrorAction(e))
	}
}
export function* watchPersonal() {
	// yield takeLatest(GET_PAGINATION, getPage)
	yield takeLatest(CREATE_PERSONAL, createPersonal)
	yield takeLatest(UPDATE_PERSONAL, updatePersonal)
	yield takeLatest(GET_PERSONALS, getAllPersonal)
	yield takeLatest(GET_PERSONAL_BY_ID_SAGA, getPersonalbyId)
	yield takeLatest(DELETE_PERSONAL_SUCCESS, deletePersonal)
	yield takeLatest(GET_PERSONAL_WITH_FILTER_REQUEST, getPersonalWithFilter)
}
