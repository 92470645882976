import React, { useState } from 'react'
import { Card, Col, Modal, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { createNewLinkForConfirmation } from '../../store/actions/organizationAction'

type props = {
    modalShow: boolean
    closeModal: CallableFunction
}
type stateProps = {
    login: string
}

const UserUpdateConfirmationModal = ({
    modalShow,
    closeModal
}: props) => {
    const dispatch = useDispatch()
    const [state, setState] = useState<stateProps>({
        login: ''
    })
    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const name = e.target.name
        setState({ ...state, [name]: value })
    }
    const submitHanlder = () => {
        dispatch(createNewLinkForConfirmation(state.login))
        closeModal()
    }
    return (
        <Modal
            show={modalShow}
            onHide={() => closeModal()}
            centered
        >
            <Card className='organization-modal-box'>
                <h6 className='organization-list-title'>
                    Форма для переотправки ссылки подтверждения пользователю на почту
                </h6>
                <div className='m-b-40-px d-flex space-betwwen'>
                    <div className='cashier-create-column'>
                        <Form.Label>Логин</Form.Label>
                    </div>
                    <div className='cashier-create-column'>
                        <Form.Control
                            type='text'
                            placeholder='Логин'
                            className='oraganization-input-create'
                            name='login'
                            value={state.login}
                            onChange={changeHandler}
                        />
                    </div>
                </div>
                <Col className='d-flex space-betwwen ' >
                    <button
                        onClick={() => closeModal()}
                        className='cashier-btn-create'>
                        Отмена
                    </button>
                    <button
                        className='cashier-btn-create'
                        disabled={state.login === ''}
                        onClick={submitHanlder}
                    >
                        Отправить
                    </button>
                </Col>
            </Card>
        </Modal>
    )
}
export default UserUpdateConfirmationModal