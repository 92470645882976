import { Box, Button, IconButton, Typography } from '@mui/material'
import { FC, memo, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'
import { formatBytes } from '../../components/helpers/herpers'

interface FileUploadButtonProps {
	onChange: (e: any) => void
	fullWidth?: boolean
}

const FileUploadButton: FC<FileUploadButtonProps> = memo(
	({ fullWidth, onChange }) => {
		const intl = useIntl()
		const fileInput = useRef<any>()
		const [files, setFiles] = useState<any[]>([])
		const [showFiles, setShowFiles] = useState(false)
		useEffect(() => {
			onChange(files)
		}, [files])

		const handleFileClick = () => {
			fileInput.current.click()
		}

		const handleShowFiles = () => {
			setShowFiles(!showFiles)
		}

		const handleDelete = (e: any, name: string) => {
			e.stopPropagation()

			if (files?.length) {
				const afterRemove = files.filter(item => item.name !== name)
				setFiles(afterRemove)
			}
		}

		const handleDeleteAll = () => {
			setFiles([])
		}

		return (
			<Box>
				<input
					ref={fileInput}
					multiple
					type='file'
					style={{ display: 'none' }}
					onClick={() => (fileInput.current.value = '')}
					onChange={(e: any) => {
						if (e.target.files.length) {
							const all = [...files, ...e.target.files]
							const arrayUniqueByKey = [
								...new Map(all.map(item => [item['name'], item])).values(),
							]
							setFiles(arrayUniqueByKey)
						}
					}}
				/>

				<Box>
					<Button
						onClick={handleFileClick}
						variant='outlined'
						endIcon={<AttachFileIcon />}
						sx={{ mr: 2 }}
						fullWidth={fullWidth}
					>
						Прикрепить файлы
					</Button>
					{!!files.length && (
						<Button
							onClick={handleDeleteAll}
							variant='outlined'
							endIcon={<DeleteOutlineIcon />}
							fullWidth={fullWidth}
							sx={{ mt: fullWidth ? '6px' : '' }}
						>
							Убрать все
						</Button>
					)}

					{!!files?.length && (
						<Button
							endIcon={
								<span style={{ fontSize: '12px' }}>{files?.length}</span>
							}
							variant='outlined'
							sx={{ position: 'relative', mt: fullWidth ? '6px' : '', ml: fullWidth ? '' : '16px' }}
							onClick={handleShowFiles}
							fullWidth={fullWidth}
						>
							Файлы
							{!!files?.length && (
								<Box
									sx={{
										display: showFiles ? 'block' : 'none',
										width: fullWidth ? '100%' : '600px',
										bgcolor: '#ffffff',
										mt: 1,
										position: 'absolute',
										top: fullWidth ? '100%' : '',
										bottom: fullWidth ? '' : '120%',
										left: '50%',
										transform: 'translateX(-50%)',
										zIndex: '11111',
										borderRadius: '5px',
										border: '1px solid rgba(25, 118, 210, 0.5)',
										maxHeight: '300px',
										overflow: 'scroll',
									}}
								>
									{files?.map((file: any) => {
										return (
											<Box
												key={file?.name}
												sx={{
													display: 'flex',
													justifyContent: 'space-between',
													p: 1,
													alignItems: 'center',
												}}
											>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center',
														width: 4 / 12,
														overflow: 'hidden',
														whiteSpace: 'nowrap',
														textOverflow: 'ellipsis',
														mr: 3,
													}}
												>
													<InsertDriveFileIcon
														sx={{ marginRight: 1 }}
														color='primary'
													/>
													<Typography>{file?.name}</Typography>
												</Box>

												<Typography sx={{ width: 3.5 / 12 }}>
													{formatBytes(file?.size)}
												</Typography>
												<Typography sx={{ width: 3.5 / 12 }}>
													{moment(file?.lastModifiedDate).format(
														'DD MMM YYYY hh:mm a'
													)}
												</Typography>
												<Box sx={{ width: 1 / 12 }}>
													<IconButton
														color='primary'
														onClick={(e: any) => handleDelete(e, file?.name)}
													>
														<DeleteIcon />
													</IconButton>
												</Box>
											</Box>
										)
									})}
								</Box>
							)}
						</Button>
					)}
				</Box>
			</Box>
		)
	}
)

export default FileUploadButton
