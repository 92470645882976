import { Paper } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { Bar } from 'react-chartjs-2'
import { IStatistic } from '../../store/reducers/statisticReducer'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import { requestStatisticAction } from '../../store/actions/statisticAction'
import moment from 'moment'
import { ru } from 'date-fns/locale'
import DatePickerBib from 'react-datepicker'

const DashboardWrapper: FC = () => {
	const dispatch = useDispatch()
	const intl = useIntl()

	const statistics: IStatistic = useSelector<RootState, IStatistic>(
		state => state.statistics.statistics,
		shallowEqual
	)

	// const [startDate, setStartDate] = useState<Date>(new Date())
	const [dateRange, setDateRange] = useState<any>([null, null])
	const [startDate, endDate] = dateRange
	useEffect(() => {
		dispatch(
			requestStatisticAction({
				start: moment().startOf('month').format('YYYY-MM-DD'),
				end: moment().endOf('month').format('YYYY-MM-DD'),
			})
		)
	}, [])

	useEffect(() => {
		if (startDate && endDate) {
			dispatch(
				requestStatisticAction({
					start: moment(startDate).format('YYYY-MM-DD'),
					end: moment(endDate).format('YYYY-MM-DD'),
				})
			)
		}
	}, [dateRange])

	//========================================================================

	const TaskLabels = ['Количество созданных задач, пользователей, документов']

	const TaskOptions = {
		responsive: true,
		scale: {
			ticks: {
				min: 0,

				stepSize: 1,
				fontSize: 18,
			},
		},
		layout: {
			padding: 3,
		},

		plugins: {
			legend: {
				position: 'top' as const,
				labels: {
					font: {
						size: 14,
					},
				},
			},
			title: {
				display: true,
				text: 'Статистика',
				font: {
					size: 20,
				},
			},
		},
	}

	const TaskData = {
		labels: TaskLabels,
		datasets: [
			{
				label: 'Задачи',
				data: [statistics.taskCount],
				backgroundColor: 'rgba(255, 99, 132, 0.9)',
			},
			{
				label: 'Документы вручную',
				data: [statistics.reportManuallyCount],
				backgroundColor: 'rgba(15, 10, 222,0.9)',
			},
			{
				label: 'Документы автоматом',
				data: [statistics.reportAutoCount],
				backgroundColor: 'rgba(0,255,255,0.9)',
			},
			{
				label: 'Пользователи',
				data: [statistics.userCount],
				backgroundColor: 'rgba(255, 127, 80, 0.9)',
			},
		],
	}
	return (
		<>
			{/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle> */}
			<div className='container'>
				{/* begin::Row */}
				<div className='row gy-5 g-xl-8 mb-md-5'>
					<div className='col-xxl-6'>
						<Paper sx={{ p: 3 }}>
							<Bar options={TaskOptions} data={TaskData} height='200px' />

							<div className='datePickerCustomStyles small mt-3'>
								<DatePickerBib
									locale={ru}
									selectsRange={true}
									startDate={startDate}
									endDate={endDate}
									onChange={update => {
										setDateRange(update)
									}}
									isClearable={true}
									monthsShown={2}
									placeholderText='Начало - Конец'
									dateFormat={'dd.M.yyyy'}
								/>
							</div>

							{/* <LocalizationProvider dateAdapter={AdapterDateMoment}>
								<DatePicker
									label='Дата'
									value={startDate}
									onChange={newValue => {
										if (newValue) {
											setStartDate(new Date(newValue))
										}
									}}
									renderInput={(params: any) => (
										<TextField
											{...params}
											fullWidth
											size='small'
											sx={{ mt: 3 }}
										/>
									)}
								/>
							</LocalizationProvider> */}

							{/* <DatePicker
							locale={}
				
								selected={startDate}
								onChange={(date: Date) => setStartDate(date)}
							/> */}
						</Paper>
					</div>
				</div>
				<div className='row gy-5 gx-xl-8'>
					<div className='col-xxl-4'>
						{/* <Paper sx={{ p: 3 }}>
							<Bar options={reportOptions} data={reportData} />
						</Paper> */}
					</div>
				</div>
			</div>
		</>
	)
}

export { DashboardWrapper }
