import { FC, useState } from 'react'
import { KTSVG } from '../../helpers/KTSVG'
import { toAbsoluteUrl } from '../../../helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { createPersonalsAction } from '../../../store/actions/personalsActions'
import axios from '../../../setup/axios/axios-config'
import Schudle from '../../../modules/accounts/Schudle'
import { useIntl } from 'react-intl'
import BootstrapDropdown from '../../BootstrapDropdown'
import { phoneRegExp } from '../../helpers/herpers'


const NewMember: FC = () => {
	const intl = useIntl()

	const [photo, setPhoto] = useState(null)
	const [schedule, setSchedule] = useState<any[]>([])

	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const handleChangePhoto = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			const file = e.target.files[0]
			const formData = new FormData()
			formData.append('file', file)
			formData.append('name', file.name)
			if (file) {
				axios
					.post('minio/file', formData)
					.then(res => setPhoto(res.data?.baseFileUrl))
			}
		}
	}

	const data = {
		address: '',
		email: '',
		fullName: '',
		imagePath: photo,
		organization: '',
		phone: '',
		agentWorkplace: '',
		organizationPin: '',
		// userId: "",
		schedules: [],
		roleIds: [],
	}

	const newShed = schedule.reduce((acc, rec) => {
		return { ...acc, ...rec }
	}, {})
	const formik = useFormik({
		initialValues: { ...data },
		validationSchema: Yup.object().shape({
			fullName: Yup.string().required('Full name is required'),
			organization: Yup.string().required('Organization name is required'),
			email: Yup.string()
				.email(intl.formatMessage({ id: 'PERSONAL.ERROR.EMAIL.VALID' }))
				.max(255)
				.required(intl.formatMessage({ id: 'PERSONAL.ERROR.EMAIL' })),
			address: Yup.string().required('Addres is required'),
			phone: Yup.string()
				.matches(phoneRegExp, intl.formatMessage({ id: 'PERSONAL.ERROR.PHONE.NOT_VALID' }))
				.required(intl.formatMessage({ id: 'PERSONAL.ERROR.PHONE' })),
			agentWorkplace: Yup.string().required('Agent work place is required'),
			organizationPin: Yup.string().required('Organization pin is required'),
		}),

		onSubmit: (values, reset) => {
			setLoading(true)

			setTimeout(() => {
				let newData = {
					address: values.address,
					agentWorkplace: values.agentWorkplace,
					email: values.email,
					fullName: values.fullName,
					imagePath: photo,
					organization: values.organization,
					organizationPin: values.organizationPin,
					phone: values.phone,
					roleIds: values.roleIds,
					// userId: values.userId,
					schedules: schedule,
				}

				dispatch(createPersonalsAction(newData))
				setLoading(false)
				formik.resetForm()
			}, 1000)
		},
	})


	return (
		<div className='modal fade' id='kt_modal_new_member' aria-hidden='true'>
			<div className='modal-dialog modal-dialog-centered mw-900px'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h2>{intl.formatMessage({ id: 'PERSONAL.NEWEMPLOYEE' })}</h2>

						<div
							className='btn btn-sm btn-icon btn-active-color-primary'
							data-bs-dismiss='modal'
						>
							<KTSVG
								path='/media/icons/duotune/arrows/arr061.svg'
								className='svg-icon-1'
							/>
						</div>
					</div>

					<div className='modal-body py-lg-10 px-lg-10'>
						<form onSubmit={formik.handleSubmit} noValidate className='form'>
							<div className='card-body p-9'>
								<div className='row mb-6'>
									<label className='col-lg-4 col-form-label fw-bold fs-6'>
										{intl.formatMessage({ id: 'PERSONAL.AVATAR' })}
									</label>
									<div className='col-lg-8'>
										<div
											className='image-input image-input-outline'
											data-kt-image-input='true'
											style={{
												backgroundImage: `url(${toAbsoluteUrl(
													`${photo || `/media/avatars/150-2.jpg`}`
												)})`,
											}}
										>
											<div
												className='image-input-wrapper w-125px h-125px'
												style={{
													backgroundImage: `url(${toAbsoluteUrl(
														'/media/avatars/blank.png'
													)})`,
												}}
											>
												<label
													style={{ width: '150px', height: '150px' }}
													htmlFor='file3'
												>
													<input
														name='file'
														id='file3'
														hidden={true}
														type='file'
														accept='image/*'
														onChange={handleChangePhoto}
													/>

													<img
														style={{ borderRadius: '3px' }}
														width={120}
														height={120}
														src={photo ?? `/media/avatars/blank.png`}
														alt=''
													/>
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className='row mb-6'>
									<label className='col-lg-4 col-form-label required fw-bold fs-6'>
										{intl.formatMessage({ id: 'PERSONAL.FULLNAME' })}
									</label>

									<div className='col-lg-8 fv-row'>
										<input
											type='text'
											className='form-control form-control-lg form-control-solid'
											placeholder={intl.formatMessage({
												id: 'PERSONAL.FULLNAME',
											})}
											{...formik.getFieldProps('fullName')}
										/>
										{formik.touched.fullName && formik.errors.fullName && (
											<div className='fv-plugins-message-container danger'>
												<div
													className='fv-help-block'
													style={{ color: 'darkred' }}
												>
													{intl.formatMessage({
														id: 'PERSONAL.ERROR.FULLNAME',
													})}
												</div>
											</div>
										)}
									</div>
								</div>
								<div className='row mb-6'>
									<label className='col-lg-4 col-form-label required fw-bold fs-6'>
										{intl.formatMessage({ id: 'PERSONAL.ORGANIZATION' })}
									</label>

									<div className='col-lg-8 fv-row'>
										<input
											type='text'
											className='form-control form-control-lg form-control-solid'
											placeholder={intl.formatMessage({
												id: 'PERSONAL.ORGANIZATION',
											})}
											{...formik.getFieldProps('organization')}
										/>
										{formik.touched.organization && formik.errors.organization && (
											<div className='fv-plugins-message-container'>
												<div
													className='fv-help-block'
													style={{ color: 'darkred' }}
												>
													{intl.formatMessage({
														id: 'PERSONAL.ERROR.ORGANIZATION',
													})}
												</div>
											</div>
										)}
									</div>
								</div>
								{/* //=========================== RMA === */}
								<div className='row mb-6'>
									<label className='col-lg-4 col-form-label required fw-bold fs-6'>
										Логин (РМА)
									</label>

									<div className='col-lg-8 fv-row'>
										<input
											type='text'
											className='form-control form-control-lg form-control-solid'
											placeholder={'Логин (РМА)'}
											{...formik.getFieldProps('agentWorkplace')}
										/>
										{formik.touched.agentWorkplace &&
											formik.errors.agentWorkplace && (
												<div className='fv-plugins-message-container'>
													<div
														className='fv-help-block'
														style={{ color: 'darkred' }}
													>
														Нужно указать логин (РМА)
													</div>
												</div>
											)}
									</div>
								</div>
								{/* //========================== PIN ==== */}
								<div className='row mb-6'>
									<label className='col-lg-4 col-form-label required fw-bold fs-6'>
										Пин организации
									</label>

									<div className='col-lg-8 fv-row'>
										<input
											type='text'
											className='form-control form-control-lg form-control-solid'
											placeholder={'Пин организации'}
											{...formik.getFieldProps('organizationPin')}
										/>
										{formik.touched.organizationPin &&
											formik.errors.organizationPin && (
												<div className='fv-plugins-message-container'>
													<div
														className='fv-help-block'
														style={{ color: 'darkred' }}
													>
														Нужно указать пин организации
													</div>
												</div>
											)}
									</div>
								</div>
								<div className='row mb-6'>
									<label className='col-lg-4 col-form-label fw-bold fs-6'>
										Роли
									</label>

									<div className='col-lg-8 fv-row'>
										<BootstrapDropdown title='Роли' formik={formik} />

										{/* {formik.touched.roleIds &&
											formik.errors.roleIds && (
												<div className='fv-plugins-message-container'>
													<div
														className='fv-help-block'
														style={{ color: 'darkred' }}
													>
														Требуется указать роль
													</div>
												</div>
											)} */}
									</div>
								</div>

								{/* new field addres */}
								<div className='row mb-6'>
									<label className='col-lg-4 col-form-label required fw-bold fs-6'>
										{intl.formatMessage({ id: 'PERSONAL.ADRESS' })}
									</label>
									<div className='col-lg-8 fv-row'>
										<input
											type='text'
											className='form-control form-control-lg form-control-solid'
											placeholder={intl.formatMessage({
												id: 'PERSONAL.ADRESS',
											})}
											{...formik.getFieldProps('address')}
										/>
										{formik.touched.address && formik.errors.address && (
											<div className='fv-plugins-message-container'>
												<div
													className='fv-help-block'
													style={{ color: 'darkred' }}
												>
													{intl.formatMessage({ id: 'PERSONAL.ERROR.ADRESS' })}
												</div>
											</div>
										)}
									</div>
								</div>

								<div className='row mb-6'>
									<label className='col-lg-4 col-form-label fw-bold fs-6'>
										<span className='required'>
											{intl.formatMessage({ id: 'PERSONAL.PHONE' })}
										</span>
									</label>

									<div className='col-lg-8 fv-row'>
										<input
											type='tel'
											className='form-control form-control-lg form-control-solid'
											placeholder={intl.formatMessage({ id: 'PERSONAL.PHONE' })}
											{...formik.getFieldProps('phone')}
										/>
										{formik.touched.phone && formik.errors.phone && (
											<div className='fv-plugins-message-container'>
												<div
													className='fv-help-block'
													style={{ color: 'darkred' }}
												>
													{formik.errors.phone}
												</div>
											</div>
										)}
									</div>
								</div>

								<div className='row mb-6'>
									<label className='col-lg-4 col-form-label fw-bold fs-6'>
										<span className='required'>Email</span>
									</label>

									<div className='col-lg-8 fv-row'>
										<input
											type='email'
											className='form-control form-control-lg form-control-solid'
											placeholder='Email сотрудника'
											{...formik.getFieldProps('email')}
										/>
										{formik.touched.email && formik.errors.email && (
											<div className='fv-plugins-message-container'>
												<div
													className='fv-help-block'
													style={{ color: 'darkred' }}
												>
													{formik.errors.email}
												</div>
											</div>
										)}
									</div>
								</div>
								<Schudle setSchedule={setSchedule} />
							</div>
							{/* <Alert color="success" >this is success</Alert> */}
							<div className='card-footer d-flex justify-content-end py-6 px-9'>
								<button
									type='submit'
									className='btn btn-primary'
									disabled={loading}
									data-bs-dismiss={formik.isValid ? 'modal' : null}
								>
									{/* data-bs-dismiss={'modal'} */}
									{!loading && intl.formatMessage({ id: 'TEXT.CREATE' })}
									{loading && (
										<span
											className='indicator-progress'
											style={{ display: 'block' }}
										>
											Please wait...{' '}
											<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
										</span>
									)}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export { NewMember }
