import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import ReactTable from '../../components/partials/react-table/ReactTable'
import ReactPaginate from 'react-paginate'
import { getOfdUsersRequest, setPropsToFilterUsers } from '../../store/actions/commonOFDAction'
import { OfdUsersDataRes } from '../../models/coomonOFD/FileModel'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { findPermission } from '../../helpers/findPermissionHelper'
import { Permission } from '../../models/coomonOFD/FileModel'
import { getUserPermissionsRequest } from '../../store/actions/commonOFDAction'
import UserUpdateConfirmationModal from '../oraganization/UserUpdateConfirmationModal'
import CreateOfdUserModal from '../oraganization/CreateOfdUserModal'
import LoaderOfd from '../../components/partials/loader/LoaderOfd'

type State = {
    currentPage: number
    size: string
    search: {
        login: string
        fullName: string
        email: string
        code: number
    }
    sort: {
        name: string
        method: string
    },
    fetchNeed: boolean
}

const OfdUsersTable = () => {
    const dispatch = useDispatch()
    const permissions = useSelector<RootState, Permission[]>(state => state.commonOFD.permissions)
    const ofdUsers = useSelector<RootState, OfdUsersDataRes>(state => state.commonOFD.users)
    const [updateConfirmationModal, setUpdateConfirmationModal] = useState<boolean>(false)
    const [createOfdUserModal, setCreateOfdUserModal] = useState<boolean>(false)
    const ofdLoading = useSelector<RootState, boolean>(state => state.commonOFD.loading)
    const [state, setState] = useState<State>({
        currentPage: 1,
        size: '20',
        search: {
            login: '',
            fullName: '',
            email: '',
            code: 0
        },
        sort: {
            name: '',
            method: 'default'
        },
        fetchNeed: true
    })
    const setFiltersToDefault = () => {
        setState({
            currentPage: 1,
            size: '20',
            search: {
                login: '',
                fullName: '',
                email: '',
                code: 0
            },
            sort: {
                name: '',
                method: 'default'
            },
            fetchNeed: true
        })
    }
    const CustomToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
        <button
            ref={ref}
            className='cashier-btn-create cashier-list-btn'
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            &#x25bc;
        </button>
    ));
    const firstSimbolCheck = (props: string) => {
        if (props.length === 0) return '?'
        return '&'
    }
    const createPropsString = () => {
        let props = ''
        const search = { ...state.search }
        const sort = { ...state.sort }
        props += firstSimbolCheck(props)
        props += `page=${state.currentPage}`
        if (state.size !== '20') {
            props += firstSimbolCheck(props)
            props += `size=${state.size}`
        }
        if (search.login.length > 2) {
            props += firstSimbolCheck(props)
            props += `search=login=${search.login}`
        }
        if (search.fullName.length > 2) {
            props += firstSimbolCheck(props)
            props += `search=fullName=${search.fullName}`
        }
        if (search.email.length > 2) {
            props += firstSimbolCheck(props)
            props += `search=email=${search.email}`
        }
        if (search.code !== 0) {
            props += firstSimbolCheck(props)
            props += `search=code=${search.code}`
        }
        if (sort.method !== 'default' && sort.name !== '') {
            props += firstSimbolCheck(props)
            props += `sort=${sort.name}=${sort.method}`
        }
        dispatch(setPropsToFilterUsers(props))
        return props
    }
    const makePropsForCashierListHandler = () => {
        const props = createPropsString()
        if (state.fetchNeed) {
            dispatch(getOfdUsersRequest(props))
        }
    }
    useEffect(() => {
        if (permissions.length === 0) dispatch(getUserPermissionsRequest())
    }, [])
    useEffect(() => {
        makePropsForCashierListHandler()
    }, [state.currentPage, state.search, state.size, state.sort])
    const columns: any[] = useMemo(() => [
        {
            Header: 'Login',
            accessor: 'login',
        },
        {
            Header: 'ФИО',
            accessor: 'fullName',
        },
        {
            Header: 'Почта',
            accessor: 'email',
        },
        {
            Header: 'Code',
            accessor: 'code',
        },
    ], [])
    const handlePageClick = (e: any) => {
        setState({ ...state, currentPage: e.selected + 1 })
    }
    const changeFilterHandler = (value: string, name: string) => {
        if (name === 'fullName' || name === 'login' || name === 'email') {
            if (value.length > 2) setState({ ...state, search: { ...state.search, [name]: value }, fetchNeed: true })
            else setState({ ...state, search: { ...state.search, [name]: value }, fetchNeed: false })
        }
        // else if (name === 'code') setState({ ...state, search: { ...state.search, code: parseInt(value) }, fetchNeed: true })
        else if (name === 'size') setState({ ...state, size: value, fetchNeed: true })
    }
    const sortHandler = (name: string) => {
        if (state.sort.name !== name) {
            setState({ ...state, sort: { name, method: 'asc' }, fetchNeed: true })
        }
        else if (state.sort.name === name && state.sort.method === 'asc') {
            setState({ ...state, sort: { name, method: 'desc' }, fetchNeed: true })
        }
        else if (state.sort.name === name && state.sort.method === 'desc') {
            setState({ ...state, sort: { name: '', method: 'default' }, fetchNeed: true })
        }
    }
    const sortableColumnHanlder = (column: any) => {
        return (
            <span
                className={
                    state.sort.name === column.id ?
                        state.sort.method === 'asc' ?
                            'cashier-th-sort-asc cashier-th-sort'
                            :
                            state.sort.method === 'desc' ?
                                'cashier-th-sort-desc cashier-th-sort'
                                :
                                'cashier-th-sort'
                        :
                        'cashier-th-sort'}
                onClick={() => sortHandler(column.id)}
            >{column.render('Header')}</span>
        )
    }
    const columnFilterRender = (column: any) => {
        if (column.Header === 'Code') {
            return (
                <>
                    {sortableColumnHanlder(column)}
                    <input className='cashier-th-filter-input' disabled />
                </>
            )
        }
        else {
            const searchCopy: any = { ...state.search }
            let inputValue = ''
            Object.keys(searchCopy).map((item: string) => {
                if (item === column.id) inputValue = searchCopy[item]
            })
            return (
                <>
                    {sortableColumnHanlder(column)}
                    <input
                        type='text'
                        value={inputValue}
                        className='cashier-th-filter-input'
                        onChange={(e) => changeFilterHandler(e.target.value, column.id)}
                        placeholder={`Введите 3 символа`}
                    />
                </>
            )
        }
    }
    const createOfdUserHandler = () => {
        setCreateOfdUserModal(true)
    }
    const closeCreateOfdUserModal = () => {
        setCreateOfdUserModal(false)
    }
    return (
        <>
            {ofdLoading && <LoaderOfd />}
            <UserUpdateConfirmationModal  //модалка для отправки повторной ссылки на почту юзеру
                modalShow={updateConfirmationModal}
                closeModal={() => setUpdateConfirmationModal(false)}
            />
            <CreateOfdUserModal  //модалка создания нового юзера для организации
                show={createOfdUserModal}
                close={() => closeCreateOfdUserModal()} />
            <Row className='d-flex space-betwwen ' >
                <Col className='m-b-20-px'>
                    <h6 className='cashier-list-title'>
                        Кассиры вашей организации
                    </h6>
                </Col>
                <Col className='d-flex justify-content-end m-b-20-px align-items-center'>

                    <select
                        className='cashier-list-dd'
                        onChange={(e) => changeFilterHandler(e.target.value, 'size')}
                    >
                        <option value={20}>20 записей</option>
                        <option value={50}>50 записей</option>
                    </select>
                    {(findPermission('UPDATE_CONFIRMATION', permissions) || findPermission('CREATE_USER', permissions)) &&
                        <Dropdown className='m-l-10px'>
                            <Dropdown.Toggle
                                id="dropdown-custom-components" as={CustomToggle}
                            >
                                Пользователи <span></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='w-250px'>
                                {findPermission('UPDATE_CONFIRMATION', permissions) && (
                                    <Dropdown.Item eventKey="1" className='fw-bold' onClick={() => setUpdateConfirmationModal(true)}>
                                        Письмо с подтверждением
                                    </Dropdown.Item>
                                )}
                                {findPermission('CREATE_USER', permissions) && (
                                    <>
                                        <Dropdown.Divider />
                                        <Dropdown.Item eventKey="2" className='fw-bold' onClick={() => createOfdUserHandler()}>
                                            Создание пользователя
                                        </Dropdown.Item>
                                    </>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </Col>
                <Col className='d-flex justify-content-end m-b-20-px align-items-center '>
                    <button
                        className='cashier-btn-create cashier-list-btn cashier-list-header-btn'
                        onClick={setFiltersToDefault}
                    >
                        Сбросить фильтры
                    </button>

                </Col>
            </Row>
            <Card>
                {/* <div className='organization-info-box'> */}
                <ReactTable
                    // data={cashierListMemorized}
                    data={ofdUsers.list}
                    columns={columns}
                    tableWrapperClassName='cashier-table-wrapper '
                    tableClassName='cashier-table'
                    thClassName='cashier-table-td cashier-table-th'
                    thClassNameRight='cashier-table-td-right'
                    tdClassName='cashier-table-td'
                    tdClassNameRight='cashier-table-td-right'
                    trClassNameHover='cashier-table-td-hover'
                    columnFilterRender={columnFilterRender}
                />
            </Card>
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                pageCount={ofdUsers.totalPages}  //количество страниц
                onPageChange={handlePageClick}
                breakLabel='...'
                pageRangeDisplayed={2}
                containerClassName='cashier-pagination-box'
                previousLinkClassName='cashier-pagination-prevlink cashier-pagination-mainlink'
                nextLinkClassName='cashier-pagination-nextlink cashier-pagination-mainlink'
                pageLinkClassName='cashier-pagination-mainlink'
                activeLinkClassName='cashier-pagination-activelink'
                breakLinkClassName='cashier-pagination-breaklink'
                marginPagesDisplayed={2}
            // forcePage={period.page}
            />
        </>
    )
}
export default OfdUsersTable
