import React, { FC } from 'react'
import { ErrorMessage } from 'formik'
import { Avatar, Typography } from '@mui/material'
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import { useGlobalContext } from '../cameraContext'

export interface OcrTypes {
	id: number
	docType: string
	passNo: string
	name?: string
	surname?: string
	lastName?: string
	birthDate?: string
	birthPlace: string
	nationality: string
	issueAuthority: string
	issueDate: string
	validityDate: string
	iin: string
	sex: string
	createdAt: string
	photoBase64: string
	signBase64: string
	fullyScanned: boolean
	frontScanned: boolean
	backScanned: boolean
}

interface Stepper2Props {
	face: any
	handleSelfie: (e: any) => void
	ocrData: OcrTypes
}

const IdentifyStep2: FC<Stepper2Props> = ({ face, handleSelfie }) => {
	const { camera, setCamera, step, setStep, ocrData } = useGlobalContext()
	return (
		<div className='w-100'>
			<div className='pb-10 pb-lg-15 d-flex flex-column align-items-center'>
				<h2 className='fw-bolder d-flex align-items-center text-dark'>
					Сфотографируйте свое лицо
					{/* <i
						className='fas fa-exclamation-circle ms-2 fs-7'
						data-bs-toggle='tooltip'
						title='Billing is issued based on your selected account type'
					/> */}
				</h2>

				<div className='text-gray-400 fw-bold fs-6'>
					Следите чтобы ваше лицо было в кругу
					{/* <a href='#' className='link-primary fw-bolder'>
						{' '}
						Help Page
					</a> */}
					.
				</div>
			</div>

			<div className='fv-row d-flex justify-content-center'>
				<div className='row'>
					{/* <div className='col-lg-6'>
						<Field
							type='radio'
							className='btn-check'
							name='accountType'
							value='personal'
							id='kt_create_account_form_account_type_personal'
						/>
						<label
							className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
							htmlFor='kt_create_account_form_account_type_personal'
						>
							<KTSVG
								path='/media/icons/duotune/communication/com005.svg'
								className='svg-icon-3x me-5'
							/>

							<span className='d-block fw-bold text-start'>
								<span className='text-dark fw-bolder d-block fs-4 mb-2'>
									Personal Account
								</span>
								<span className='text-gray-400 fw-bold fs-6'>
									If you need more info, please check it out
								</span>
							</span>
						</label>
					</div> */}

					<div className='col-lg-6 '>
						{/* <Field
							type='radio'
							className='btn-check'
							name='accountType'
							value='corporate'
							id='kt_create_account_form_account_type_corporate'
						/>
						<label
							className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
							htmlFor='kt_create_account_form_account_type_corporate'
						>
							<KTSVG
								path='/media/icons/duotune/finance/fin006.svg'
								className='svg-icon-3x me-5'
							/>

							<span className='d-block fw-bold text-start'>
								<span className='text-dark fw-bolder d-block fs-4 mb-2'>
									Corporate Account
								</span>
								<span className='text-gray-400 fw-bold fs-6'>
									Create corporate account to mane users
								</span>
							</span>
						</label> */}
						<div className='symbol symbol-75px symbol-circle  d-flex flex-column align-items-center'>
							<Avatar
								alt='Remy Sharp'
								src={face ?? '/media/avatars/blank.png'}
								sx={{ width: 100, height: 100 }}
							/>
							<h3 className='fw-bolder d-flex align-items-center text-dark'>{`${
								ocrData?.data?.name
							} ${ocrData?.data?.surname} ${
								ocrData?.data?.lastName || ''
							} `}</h3>
							<h3 className='fw-bolder d-flex align-items-center text-dark'>{`ИИН:  ${ocrData?.data?.iin} `}</h3>
							<button
								onClick={() => {
									setCamera('face')
									setStep('step2')
								}}
								color='primary'
								aria-label='upload picture'
								// component='span'
								style={{ width: 130 }}
								className='btn btn-primary my-2  btn-sm'
							>
								<PhotoCamera />
								<Typography className='ms-2' variant='caption'>
									Камера
								</Typography>
							</button>
							{/* <div style={{ width: 130 }}>
								<input
									lang='ru'
									type='file'
									name='file'
									className='form-control form-control-md form-control-solid fs-5'
									placeholder='File'
									onChange={handleSelfie}
								/>
							</div> */}
							{/* <input type='file' onChange={handleSelfie} /> */}
							{/* {formik.touched.photoFace && formik.errors.photoFace && (
								<div className='fv-plugins-message-container'>
									<div className='fv-help-block text-danger'>
										{formik.errors.photoFace}
									</div>
								</div>
							)} */}
						</div>
					</div>

					<div className='fv-plugins-message-container invalid-feedback'>
						<ErrorMessage name='accountType' />
					</div>
				</div>
			</div>
		</div>
	)
}

export { IdentifyStep2 }
