import { AnyAction } from "redux"
import { REQUEST_MENU, REQUEST_MENU_SUCCESS } from "../menuTypes";


export interface menuState {
	menus: {}[],
}

const initialState : menuState = {
	menus: [],
}

const menuReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case REQUEST_MENU:
			return {...state};
		case REQUEST_MENU_SUCCESS:
			return {...state, menus: action.menus};
		default:
			return state;

	}
}

export default menuReducer;