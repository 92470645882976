import axios from '../setup/axios/axios-config'
import React, { FC, memo, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import Swal from 'sweetalert2'

interface BootstrapDropdownProps {
	title: string
	formik: any,
}

const BootstrapDropdown: FC<BootstrapDropdownProps> = memo(({title, formik }) => {
	const [roles, setRoles] = useState<any>([])

	useEffect(() => {
		requestRoles()
	}, [])

	const requestRoles = async () => {
		try {
			const roles = await axios.get('/api/role/all')
			setRoles(roles.data)
		} catch (error: any) {
			Swal.fire({
				title: error.response.data.message,
				icon: 'error',
				timer: 3000,
			})
		}
	}

	const roleChange = (e: any) => {
		if (e.target.checked) {
			formik.setFieldValue('roleIds', [
				...formik.values.roleIds,
				+e.target.value,
			])
		} else {
			formik.setFieldValue('roleIds', [
				...formik.values.roleIds.filter(
					(data: any) => +e.target.value !== data
				),
			])
		}
	}

	const roleChangeAll = (e: any) => {
		if (e.target.checked) {
			formik.setFieldValue(
				'roleIds',
				roles.map((item: any) => item.id)
			)
		} else {
			formik.setFieldValue('roleIds', [])
		}
	}

	return (
		<Dropdown autoClose='outside'>
			<Dropdown.Toggle
				className='form-control form-control-lg form-control-solid w-100 text-muted fw-bold fs-6 d-flex align-self-baseline justify-content-between align-items-center'
				variant='outline-secondary'
				id='dropdown-autoclose-outside'
			>
				{title}
			</Dropdown.Toggle>

			<Dropdown.Menu
				style={{
					width: '100%',
					height: '200px',
					overflowY: 'scroll',
					border: '.5px solid #ccc',
					borderTop: 'none'
				}}
			>
				<div className='d-flex align-items-center dropdown-item'>
					<label
						style={{ margin: 0, width: '100%' }}
						className='text-gray-800 text-hover-primary fw-normal fs-8  d-flex align-items-center'
					>
						<div className='form-check form-check-custom form-check-solid  d-flex align-items-center me-2'>
							<input
								checked={formik.values.roleIds?.length === roles?.length}
								onChange={roleChangeAll}
								className='form-check-input'
								name='assign'
								type='checkbox'
								value=''
							/>
						</div>
						<p className='w-100 m-0'>All</p>
					</label>
				</div>
				{!!roles.length &&
					roles?.map((item: any) => {
						return (
							<div
								key={item.id}
								className='w-100 d-flex align-items-center dropdown-item'
							>
								<label
									style={{ margin: 0 }}
									className='w-100 text-gray-800 text-hover-primary fw-normal fs-8  d-flex align-items-center'
								>
									<div className=' form-check form-check-custom form-check-solid  d-flex align-items-center me-2'>
										<input
											onChange={roleChange}
											className='form-check-input'
											checked={
												!!formik.values?.roleIds?.find((e: any) => e === item.id)
											}
											name='roleIds'
											type='checkbox'
											value={item.id}
										/>
									</div>
									<p className='w-100 m-0'>{item.title}</p>
								</label>
							</div>
						)
					})}
			</Dropdown.Menu>
		</Dropdown>
	)
})

export default BootstrapDropdown
