import {
	STATUSES_SUCCESS,
	STATUSES_REQUEST,
	STATUSES_ERROR,
	INSTRUCTIONS_SUCCESS,
	INSTRUCTIONS_ERROR, INSTRUCTIONS_REQUEST
} from '../actionTypes';
import { InstructionModel, StatusModel } from '../../models/instruction/InstructionModel';

export const getStatusAction = (statuses: StatusModel[]) => ({ type: STATUSES_SUCCESS, statuses })
export const getStatusErrorAction = (error: any) => ({type: STATUSES_ERROR, error})
export const statusRequestAction = () => ({type: STATUSES_REQUEST})

export const getInstructionsAction = (instructions: InstructionModel[]) => ({ type: INSTRUCTIONS_SUCCESS, instructions })
export const getInstructionsErrorAction = (error: any) => ({type: INSTRUCTIONS_ERROR, error})
export const instructionRequestAction = () => ({type: INSTRUCTIONS_REQUEST})
