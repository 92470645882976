import React, { FC, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { UserModel } from '../../../../modules/auth/models/auth/UserModel'
import ChatInboxItemSmall from '../../../../pages/chat/ChatInbox/ChatInboxItem/ChatInboxItemSmall'
import { RootState } from '../../../../setup'
import { requestCorrespondenceAction, requestCorrespondenceNotOpenCountAction } from '../../../../store/actions/correspondenceAction'
import { ICorrespondence } from '../../../../store/reducers/correspondenceReducer'
import { KTSVG } from '../../../helpers/KTSVG'

const DrawerMessenger: FC = () => {
	const dispatch = useDispatch()
	const history = useHistory()

	const user: UserModel = useSelector<RootState, UserModel>(
		state => state.auth.user,
		shallowEqual
	)

	const correspondences: ICorrespondence = useSelector<
		RootState,
		ICorrespondence
	>(state => state.correspondence.correspondences, shallowEqual)

	useEffect(() => {
		dispatch(requestCorrespondenceAction())
		dispatch(requestCorrespondenceNotOpenCountAction())
	}, [])

	const handleGetInCorrespondence = (chatId: number) => {
		history.push(`chat/${chatId}`)
	}

	return (
		<div
			id='kt_drawer_chat'
			className='bg-white'
			data-kt-drawer='true'
			data-kt-drawer-name='chat'
			data-kt-drawer-activate='true'
			data-kt-drawer-overlay='true'
			data-kt-drawer-width="{default:'300px', 'md': '800px'}"
			data-kt-drawer-direction='end'
			data-kt-drawer-toggle='#kt_drawer_chat_toggle'
			data-kt-drawer-close='#kt_drawer_chat_close'
		>
			<div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
				<div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
					<div className='card-title'>
						<div className='d-flex justify-content-center flex-column me-3'>
							<a
								href='#'
								className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
							>
								{user?.personalInfo?.fullName}
							</a>

							<div className='mb-0 lh-1'>
								<span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
								<span className='fs-7 fw-bold text-gray-400'>Онлайн</span>
							</div>
						</div>
					</div>

					<div className='card-toolbar'>
						{/* <div className='me-2'>
							<button
								className='btn btn-sm btn-icon btn-active-light-primary'
								data-kt-menu-trigger='click'
								data-kt-menu-placement='bottom-end'
								data-kt-menu-flip='top-end'
							>
								<i className='bi bi-three-dots fs-3'></i>
							</button>
						</div> */}

						<div
							className='btn btn-sm btn-icon btn-active-light-primary'
							id='kt_drawer_chat_close'
						>
							<KTSVG
								path='/media/icons/duotune/arrows/arr061.svg'
								className='svg-icon-2'
							/>
						</div>
					</div>
				</div>

				<div>
					{correspondences?.content?.map(chat => {
						return (
							<ChatInboxItemSmall
								opened={chat.opened}
								title={chat.title}
								createdAt={chat.createdAt}
								handleGetInCorrespondence={handleGetInCorrespondence}
								id={chat.id}
								key={chat.id}
								createdBy={chat.createdBy}
								publicUrl={chat.publicUrl}
								lastMessage={chat.lastMessage}
							/>
						)
					})}
				</div>

				{/* <ChatInner isDrawer={true} /> */}
			</div>
		</div>
	)
}

export { DrawerMessenger }
