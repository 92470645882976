import React, { useEffect, useState } from 'react'
import {
	Checkbox,
	createFilterOptions,
	TextField,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Autocomplete from '@mui/lab/Autocomplete'

interface propsMul {
	limitTags?: any
	items?: any
	selectedValues?: any
	getOptionLabel?: any
	label?: any
	placeholder?: any
	selectAllLabel?: any
	noOptionsText?: any
	onChange?: any
	formik?: any
	style: any
}

const MultiSelect = ({
	items,
	label,
	placeholder,
	selectAllLabel,
	noOptionsText,
	limitTags,
	onChange,
	formik,
	selectedValues,
	style,
}: propsMul) => {
	const [selectedOptions, setSelectedOptions] = useState([])
	const allSelected = items.length === selectedOptions.length
	const handleToggleOption = (selectedOptions: any) =>
		setSelectedOptions(selectedOptions)
	const handleClearOptions = () => {
		onChange([])
		return setSelectedOptions([])
	}
	const getOptionLabel = (option: any) => {
		return `${option.fullName} (${option.id})`
	}
	useEffect(() => {
		setSelectedOptions(selectedValues)
	}, [selectedValues])
	const handleSelectAll = (isSelected: any) => {
		if (isSelected) {
			setSelectedOptions(items)
		} else {
			handleClearOptions()
		}
	}

	const handleToggleSelectAll = () => {
		handleSelectAll && handleSelectAll(!allSelected)
	}

	const handleChange = (event: any, selectedOptions: any, reason: any) => {
		if (reason === 'selectOption' || reason === 'removeOption') {
			if (selectedOptions.find((option: any) => option.id === 'All')) {
				handleToggleSelectAll()
				let result = []
				result = items.filter((el: any) => el.email !== 'All')
				return onChange(result)
			} else {
				handleToggleOption && handleToggleOption(selectedOptions)
				return onChange(selectedOptions)
			}
		} else if (reason === 'clear') {
			handleClearOptions && handleClearOptions()
		}
	}
	const optionRenderer = (props: any, option: any, { selected }: any) => {
		const selectAllProps =
			option.id === 'All' // To control the state of 'select-all' checkbox
				? { checked: allSelected }
				: {}
		return (
			<li
				style={{
					paddingLeft: '5px',
					backgroundColor: option.blocked ? 'yellow' : '',
				}}
				{...props}
			>
				<Checkbox
					color='primary'
					icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
					checkedIcon={<CheckBoxIcon fontSize='small' />}
					// style={{ marginLeft: 0 }}
					checked={selected}
					{...selectAllProps}
				/>
				{getOptionLabel(option)}{' '}
				{option.blocked ? (
					<span style={{ color: 'darkred' }}>( Заблокирован )</span>
				) : (
					null
				)}
			</li>
		)
	}
	const inputRenderer = (params: any) => (
		<TextField
			{...params}
			label={label}
			placeholder={placeholder}
			variant={style}
			error={
				formik &&
				Boolean(formik.touched.legalEmail) &&
				Boolean(formik.errors.legalEmail)
			}
		/>
	)
	const getOptionSelected = (option: any, anotherOption: any) =>
		option.id === anotherOption.id
	const filter = createFilterOptions()
	return (
		<Autocomplete
			multiple
			size='medium'
			limitTags={limitTags}
			options={items}
			value={selectedOptions}
			disableCloseOnSelect
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={getOptionSelected}
			noOptionsText={noOptionsText}
			filterOptions={(options: any, params: any) => {
				const filtered = filter(options, params)
				if (filtered.length === 0) {
					return []
				}
				return [
					{ id: 'All', fullName: 'Выбрать всех', email: 'select-all' },
					...filtered,
				]
			}}
			onChange={handleChange}
			renderOption={optionRenderer}
			renderInput={inputRenderer}
		/>
	)
}

MultiSelect.defaultProps = {
	limitTags: 1,
	items: [],
	selectedValues: [],
	getOptionLabel: (value: any) => value,
	noOptionsText: 'Такого контрагента нет в системе',
}

export default MultiSelect
