import { MenuInnerWithSub } from './MenuInnerWithSub'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { useLang } from '../../../i18n/Metronici18n'
import { Link } from 'react-router-dom'

export function MenuInner() {
	const locale = useLang()

	const menus: any[] = useSelector<RootState, any[]>(
		state => state.menus.menus,
		shallowEqual
	)

	return (
		<>
			{menus &&
				menus.map(menu => {
					return (
						<MenuInnerWithSub
							key={menu.id}
							title={
								locale === 'ru'
									? menu.titleRu
									: locale === 'kz'
										? menu.titleKz
										: menu.titleEn
							}
							to={menu.path}
							menuPlacement='bottom-start'
							menuTrigger='click'
							children={menu.children}
						/>
					)
				})}
			<Link to='/fiscalization/cabinet' className='menu-lg-row menu-item me-lg-1 '>
				<span className='menu-item menu-link'>Личный кабинет</span></Link>
		</>
	)
}
