import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import ReactTable from '../../components/partials/react-table/ReactTable'
import ReactPaginate from 'react-paginate'
import { getOfdUsersRequest } from '../../store/actions/commonOFDAction'
import { OfdUsersDataRes } from '../../models/coomonOFD/FileModel'

type props = {
    orgId: number
}
type State = {
    currentPage: number
}

const OrgInfoUserTab = ({ orgId }: props) => {
    const dispatch = useDispatch()
    const [state, setState] = useState<State>({ currentPage: 1 })
    const ofdUsers = useSelector<RootState, OfdUsersDataRes>(state => state.commonOFD.users)

    useEffect(() => {
        dispatch(getOfdUsersRequest(`${orgId === 0 ?
            state.currentPage === 1 ? '' : `?page=${state.currentPage}`
            :
            `?organizationId=${orgId}${state.currentPage === 1 ? '' : `&page=${state.currentPage}`}`}`))
    }, [state.currentPage, orgId])
    const columns: any[] = useMemo(() => [
        {
            Header: 'ФИО',
            accessor: 'fullName',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
    ], [orgId])
    const handlePageClick = (e: any) => {
        setState({ ...state, currentPage: e.selected + 1 })
    }
    return (
        <div className='organization-info-box'>
            <ReactTable
                // data={cashierListMemorized}
                data={ofdUsers.list}
                columns={columns}
                tableWrapperClassName='cashier-table-wrapper organization-info-content'
                tableClassName='cashier-table'
                thClassName='cashier-table-td cashier-table-th'
                thClassNameRight='cashier-table-td-right'
                tdClassName='cashier-table-td'
                tdClassNameRight='cashier-table-td-right'
                trClassNameHover='cashier-table-td-hover'
                columnFilterRender={(column: any) => <span className='cashier-th-sort organization-cursor-default'>{column.render('Header')}</span>}
            />
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                pageCount={ofdUsers.totalPages}  //количество страниц
                onPageChange={handlePageClick}
                breakLabel='...'
                pageRangeDisplayed={1}
                containerClassName='cashier-pagination-box'
                previousLinkClassName='cashier-pagination-prevlink cashier-pagination-mainlink'
                nextLinkClassName='cashier-pagination-nextlink cashier-pagination-mainlink'
                pageLinkClassName='cashier-pagination-mainlink'
                activeLinkClassName='cashier-pagination-activelink'
                breakLinkClassName='cashier-pagination-breaklink'
                marginPagesDisplayed={1}
            // forcePage={period.page}
            />
        </div>
    )
}
export default OrgInfoUserTab