import { createContext, useContext } from "react"
export type GlobalContent = {
  camera: string
  setCamera:(c: string) => void
  step: string
  setStep:(c: string) => void
  ocrData: any
  setOcrData:(c: string) => void
}
export const MyGlobalContext = createContext<GlobalContent>({
  camera: '', // set a default value
  setCamera: () => {},
  step: '', // set a default value
  setStep: () => {},
  ocrData: null, // set a default value
  setOcrData: () => {},
})
export const useGlobalContext = () => useContext(MyGlobalContext)