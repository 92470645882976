import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from './components/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from './components/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import { Routes } from './routes/Routes'
import { ThemeProvider } from '@mui/material/styles'
import PublicRoutesWrapper from './routes/PublicRoutesWrapper'
import { muiTheme } from './helpers/customMaterialFont'
import { useSelector } from 'react-redux'
import { RootState } from './setup'

type Props = {
	basename: string
}

const App: React.FC<Props> = ({ basename }) => {
	const isAuthorized = useSelector<RootState>(({ auth }) => auth.user)
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<BrowserRouter basename={basename}>
				<ThemeProvider theme={muiTheme}>
					<I18nProvider>
						<LayoutProvider>
							{
								!isAuthorized
								&& <PublicRoutesWrapper />}
							<AuthInit>
								<Routes />
							</AuthInit>
						</LayoutProvider>
					</I18nProvider>
				</ThemeProvider>
			</BrowserRouter>
		</Suspense>
	)
}

export { App }
