import {
	createStyles,
	LinearProgress,
	Typography,
	withStyles,
} from '@material-ui/core'
import React from 'react'
import { FileError } from 'react-dropzone'
import { FileHeader } from './FileHeader'

export interface UploadErrorProps {
	file: File
	onDelete: (file: File) => void
	errors: FileError[]
	setTotalFilesUploadStatus: (status: any) => void
}

const ErrorLinearProgress = withStyles(theme =>
	createStyles({
		bar: {
			backgroundColor: theme.palette.error.main,
		},
	})
)(LinearProgress)

export function UploadError({ file, onDelete, errors ,setTotalFilesUploadStatus}: UploadErrorProps) {
	return (
		<React.Fragment>
			<div style={{
					marginTop: '10px',
					marginBottom: '10px',
					borderRadius: '6px',
					border: '1px solid #B5B5C3',
					padding: '10px',
				}}>
			<FileHeader setTotalFilesUploadStatus={setTotalFilesUploadStatus} file={file} onDelete={onDelete} />
			<ErrorLinearProgress variant='determinate' value={100} />
			{errors.map(error => (
				<div key={error.code}>
					<Typography color='error'>{"Файл больше 1 мб"}</Typography>
				</div>
			))}
			</div>
		</React.Fragment>
	)
}
