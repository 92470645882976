import {IReport, IReportGroup, IReportGroupContent, IRestartReport} from '../../models/report/reportModel'
import {

    ANNULATE_REPORT_ERROR,
    ANNULATE_REPORT_REQUEST,
    ANNULATE_REPORT_SUCCESS,
    CREATE_MULTIPLE_REPORTS,


    CREATE_REPORTS,
    CREATE_REPORT_ERROR,
    CREATE_REPORT_REQUEST,
    CREATE_REPORT_SUCCESS,
    DELETE_REPORTS,
    GET_LEGALS_PARENT,
    GET_LEGALS_PARENT_ERROR,
    GET_LEGALS_PARENT_SUCCESS,
    GET_LEGAL_BY_ID,
    GET_LEGAL_BY_ID_ERROR,
    GET_LEGAL_BY_ID_SUCCESS,
    GET_REPORTS,
    GET_REPORTS_CONTRACTORS,
    GET_REPORTS_CONTRACTORS_ERROR,
    GET_REPORTS_CONTRACTORS_SUCCESS,
    GET_REPORTS_ERROR,
    GET_REPORTS_SUCCESS,
    GET_REPORT_TYPES,
    GET_REPORT_TYPES_ERROR,
    GET_REPORT_TYPES_SUCCESS,
    RESEND_REPORT,
    RESEND_REPORT_REQUEST,
    UPDATE_REPORTS,
    REPORTS_LOADING,
    GET_REPORTS_GROUP,
    GET_REPORTS_GROUP_SUCCESS,
    GET_REPORTS_GROUP_BY_ID
} from '../reportTypes'

export const getReportsAction = (filters: any) => ({
    type: GET_REPORTS,
    filters,
})
export const getReportsSuccessAction = (report: IReport) => ({
    type: GET_REPORTS_SUCCESS,
    report,
})
export const getReportsErrorAction = (error: any) => ({
    type: GET_REPORTS_ERROR,
    error,
})

export const createReportsAction = (report: { report: any; meta: any }) => ({
    type: CREATE_REPORTS,
    report,
})
export const updateReportspAction = (report: { report: any; meta: any }) => ({
    type: UPDATE_REPORTS,
    report,
})
export const deleteReportsAction = (report: { id: any; meta: any }) => ({
    type: DELETE_REPORTS,
    report,
})

export const getReportsContractorsAction = () => ({
    type: GET_REPORTS_CONTRACTORS,
})
export const getReportsContractorsSuccessAction = (contractors: any) => ({
    type: GET_REPORTS_CONTRACTORS_SUCCESS,
    contractors,
})
export const getReportsContractorsErrorAction = (error: any) => ({
    type: GET_REPORTS_CONTRACTORS_ERROR,
    error,
})

export const resendReportRequestAction = (payload: boolean) => ({type: RESEND_REPORT_REQUEST, payload})
export const resendReportAction = (payload: IRestartReport, successText: string, errorText: string) => ({
    type: RESEND_REPORT,
    payload,
    successText,
    errorText
})


// /==================================================== CREATE REPORT
export const createReportRequestAction = (report: any) => ({type: CREATE_REPORT_REQUEST, report});
export const createReportSuccessAction = (reports: any) => ({type: CREATE_REPORT_SUCCESS, reports});
export const createReportErrorAction = (message: any) => ({type: CREATE_REPORT_ERROR, message});

// /==================================================== ANNULATE REPORT
export const annulateReportRequestAction = (reportId: number, filter: any) => ({
    type: ANNULATE_REPORT_REQUEST,
    reportId,
    filter
});
export const annulateReportSuccessAction = (reports: any) => ({type: ANNULATE_REPORT_SUCCESS, reports});
export const annulateReportErrorAction = (message: any) => ({type: ANNULATE_REPORT_ERROR, message});
//====================================================

// export const createReportAction = (report: any) => ({ type: CREATE_REPORT, report });

export const createMultipleReport = (report: any) => ({type: CREATE_MULTIPLE_REPORTS, report})


export const reportsLoading = (loading: boolean) => ({type: REPORTS_LOADING, loading})


export const getReportsTypes = () => ({type: GET_REPORT_TYPES});
export const getReportsTypesSuccess = (reportTypes: []) => ({type: GET_REPORT_TYPES_SUCCESS, reportTypes});
export const getReportsTypesError = (error: any) => ({type: GET_REPORT_TYPES_ERROR, error});
//=============================================================LEGALS
/////////////LEGALS BY PARENT ID
export const getLegalsParent = (legalId: number) => ({type: GET_LEGALS_PARENT, legalId});
export const getLegalsParentSuccess = (legal: any) => ({type: GET_LEGALS_PARENT_SUCCESS, legal})
export const getLegalsParentError = (error: any) => ({type: GET_LEGALS_PARENT_ERROR, error})

/////////////LEGALS BY ID
export const getLegalById = (legalId: number) => ({type: GET_LEGAL_BY_ID, legalId});
export const getLegalByIdSuccess = (legal: any) => ({type: GET_LEGAL_BY_ID_SUCCESS, legal});
export const getLegalByIdError = (error: any) => ({type: GET_LEGAL_BY_ID_ERROR, error});

/////////////REPORTS GROUP
export const getReportsGroup = (groupfilters: any) => ({type: GET_REPORTS_GROUP, groupfilters});
export const getReportsGroupSuccess = (group: IReportGroup) => ({type: GET_REPORTS_GROUP_SUCCESS, group});
export const getReportsGroupById = (group: IReportGroupContent) => ({type: GET_REPORTS_GROUP_BY_ID, group});
