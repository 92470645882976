import { IHelp, IHelpCreate, } from "../../models/tasks/tasksModel"
import { DELETE_TASK_HELP, GET_TASK_HELP, GET_TASK_HELP_ERROR, GET_TASK_HELP_SUCCESS, GREATE_TASK_HELP, UPDATE_TASK_HELP } from "../taskHelpTypes"


export const getTaskHelpAction = (helpPage: any) => ({ type: GET_TASK_HELP, helpPage })
export const getTaskHelpSuccessAction = (help: IHelp) => ({ type: GET_TASK_HELP_SUCCESS, help })
export const getTaskHelpErrorAction = (error: any) => ({ type: GET_TASK_HELP_ERROR, error })

export const createTaskHelpAction = (help: { help: IHelpCreate, meta: any }) => ({ type: GREATE_TASK_HELP, help })
export const updateTaskHelpAction = (help: { help: any, meta: any }) => ({ type: UPDATE_TASK_HELP, help })
export const deleteTaskHelpAction = (help: { id: any, meta: any }) => ({ type: DELETE_TASK_HELP, help })


