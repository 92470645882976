import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'
import instructionReducer from '../../store/reducers/instructionReducer'
import * as auth from '../../modules/auth'
import { watchInstruction } from '../../store/sagas/instructionSagas'
import watchAuth from '../../store/sagas/authSagas'
import authReducer from '../../store/reducers/authReducer'
import { watchPersonal } from '../../store/sagas/personalsSaga'
import personalsReducer from '../../store/reducers/personalsRedurers'
import { watchNotes } from '../../store/sagas/noteSaga'
import noteReducer from '../../store/reducers/noteReducer'
import calendarTasksReducer from '../../store/reducers/calendarTasksReducer'
import { watchTasksSaga } from '../../store/sagas/calendarSaga'
import { watchMenus } from '../../store/sagas/MenuSaga'
import statusReducer from '../../store/reducers/statusReducer'
import { watchStatus } from '../../store/sagas/statusSaga'
import taskHelpWatcher from '../../store/sagas/helpTaskSaga'
import taskHelp from '../../store/reducers/taskHelpReducer'
import menuReducer from '../../store/reducers/menuReducer'
import taskStatusReducer from '../../store/reducers/taskStatusReducer'
import taskStatusWatcher from '../../store/sagas/taskStatusSaga'
import { watchAdminPages } from '../../store/sagas/adminPageSaga'
import adminPagesReducer from '../../store/reducers/adminPageReducer'
import storageReducer from '../../store/reducers/storageReducer'
import { watchStorage } from '../../store/sagas/storageSagas'
import { watchReports } from '../../store/sagas/reportSaga'
import reportsReducer from '../../store/reducers/reportsReducer'
import timeTrackerReducer from '../../store/reducers/timeTrackerReducer'
import { watchTimeTracker } from '../../store/sagas/timeTrackerSaga'
import cashierReducer from '../../store/reducers/cashierReducer'
import { watchCashier } from '../../store/sagas/cashierSaga'
import { watchOrganization } from '../../store/sagas/organizationSaga'
import organizationReducer from '../../store/reducers/organizationReducer'
import correspondenceReducer from '../../store/reducers/correspondenceReducer'
import correspondenceWatcher from '../../store/sagas/correspondenceSaga'
import commonOFDReducer from '../../store/reducers/commonOFDReducer'
import { watchCommonOFD } from '../../store/sagas/commonOFDSaga'
import { watchStatistics } from '../../store/sagas/statisticSaga'
import statisticReducer from '../../store/reducers/statisticReducer'

export const rootReducer = combineReducers({
	auth: authReducer,
	instruction: instructionReducer,
	storage: storageReducer,
	personals: personalsReducer,
	notes: noteReducer,
	tasks: calendarTasksReducer,
	status: statusReducer,
	taskHelp: taskHelp,
	menus: menuReducer,
	taskStatus: taskStatusReducer,
	adminPage: adminPagesReducer,
	reports: reportsReducer,
	tracker: timeTrackerReducer,
	cashier: cashierReducer,
	organization: organizationReducer,
	correspondence: correspondenceReducer,
	commonOFD: commonOFDReducer,
	statistics: statisticReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
	yield all([
		auth.saga(),
		watchInstruction(),
		watchAuth(),
		watchPersonal(),
		watchNotes(),
		watchTasksSaga(),
		watchMenus(),
		watchStatus(),
		taskStatusWatcher(),
		taskHelpWatcher(),
		watchAdminPages(),
		watchStorage(),
		watchReports(),
		watchTimeTracker(),
		watchCashier(),
		watchOrganization(),
		correspondenceWatcher(),
		watchCommonOFD(),
		watchStatistics(),
	])
}
