import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import { EditOrganization, ActivityClassifier, TaxationType } from '../../models/organization/FileModel'
import { Spinner } from 'react-bootstrap'
import { getOneOrganizationRequest } from '../../store/actions/organizationAction'

type props = {
    orgId: number
}

const OrgInfoMainTab = ({ orgId }: props) => {
    const editOrganization = useSelector<RootState, EditOrganization>(state => state.organization.editOrganization)
    const activityClassifierList = useSelector<RootState, ActivityClassifier[]>(state => state.organization.activityClassifier)
    const taxationTypeList = useSelector<RootState, TaxationType[]>(state => state.organization.taxationType)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getOneOrganizationRequest(orgId))
    }, [])
    return (
        <div className='d-flex align-items-center justify-content-center organization-info-box'>
            {(editOrganization.id === 0 || activityClassifierList.length === 0 || taxationTypeList.length === 0) ?
                <div className='d-flex justify-content-center align-items-center spinner-wrapper'>
                    <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                </div>
                :
                <div className='organization-w-100'>
                    <div className='m-b-40-px d-flex space-betwwen '>
                        <div className='cashier-create-column'>
                            <h4>ИИН/БИН организации</h4>
                        </div>
                        <div className='cashier-create-column'>
                            <h4>{editOrganization.xin}</h4>
                        </div>
                    </div>
                    <div className='m-b-40-px d-flex space-betwwen'>
                        <div className='cashier-create-column'>
                            <h4>Название организации</h4>
                        </div>
                        <div className='cashier-create-column'>
                            <h4>{editOrganization.name}</h4>
                        </div>
                    </div>
                    <div className='m-b-40-px d-flex space-betwwen'>
                        <div className='cashier-create-column'>
                            <h4>Юридический адрес организации</h4>
                        </div>
                        <div className='cashier-create-column'>
                            <h4>{editOrganization.juridicalAddress}</h4>
                        </div>
                    </div>
                    <div className='m-b-40-px d-flex space-betwwen'>
                        <div className='cashier-create-column'>
                            <h4>ОКЭД</h4>
                        </div>
                        <div className='cashier-create-column'>
                            <h4>{activityClassifierList.find(item => item.code === editOrganization.activityClassifierCode)?.name}</h4>
                        </div>
                    </div>
                    <div className='m-b-40-px d-flex space-betwwen'>
                        <div className='cashier-create-column'>
                            <h4>Тип налогооблажения</h4>
                        </div>
                        <div className='cashier-create-column'>
                            <h4>{taxationTypeList.find(item => item.code === editOrganization.taxationTypeCode)?.name + ' | ' +
                                taxationTypeList.find(item => item.code === editOrganization.taxationTypeCode)?.description}</h4>
                        </div>
                    </div>
                    <div className=' d-flex space-betwwen'>
                        <div className='cashier-create-column'>
                            <h4>Заблокирована</h4>
                        </div>
                        <div className='cashier-create-column'>
                            <h4>{editOrganization.blocked ? 'Да' : 'Нет'}</h4>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}
export default OrgInfoMainTab