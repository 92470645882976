import * as React from 'react'
import { FC } from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import {
	Avatar,
	Card,
	Grid,
	Stack,
	Typography,
	useMediaQuery,
} from '@mui/material'
import { TaskImageSumb } from '../../components/partials/layout/TaskImageSumb'
import { useIntl } from 'react-intl'
import { ITaskComment } from '../../models/tasks/tasksModel'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import {
	createTaskComment,
	deleteTaskComment,
	getTaskComment,
	updateTaskComment,
} from '../../store/actions/calendarTaskAction'
import { IUserModel } from '../../models/personal/paginationCustom'

import Swal from 'sweetalert2'
import './sweetContainer.scss'
import TaskCommentsList from './TaskCommentsList'
import { Editor } from '@tinymce/tinymce-react'
import classnames from 'classnames/bind'
import paginationStyles from './paginationStyles.module.scss'
import ReactPaginate from 'react-paginate'
import { Spinner } from 'react-bootstrap'
import { useTheme } from '@mui/material/styles'
let cnPage = classnames.bind(paginationStyles)

const menubar = false

const plugins =
	'advlist autolink lists image link tinydrive imagetools charmap print preview anchor ' +
	'searchreplace visualblocks code fullscreen ' +
	'insertdatetime media table paste code help wordcount emoticons '

const toolbar =
	'undo redo | formatselect | emoticons | ' +
	'bold italic backcolor | alignleft aligncenter ' +
	'alignright alignjustify |  image link | bullist numlist outdent indent | ' +
	'removeformat | help '

const BASE_URL = process.env.REACT_APP_BASE_URL

interface TaskDetailsDrawerProps {
	show: boolean
	handleClose: () => void
	data: any
	setShow: any
	user: IUserModel
}

const TaskDetailsDrawer: FC<TaskDetailsDrawerProps> = React.memo(
	({ show, data, handleClose, user }) => {
		const dispatch = useDispatch()
		const intl = useIntl()

		const theme = useTheme()
		const matches = useMediaQuery(theme.breakpoints.up('sm'))
		const [comment, setComment] = React.useState('')
		// const [commentPage, setCommentPage] = React.useState({ page: 0 })
		const [editorKey, setEditorKey] = React.useState(4)
		const taskComment: ITaskComment = useSelector<RootState, ITaskComment>(
			state => state.tasks.taskComment,
			shallowEqual
		)
		const tasksCommentLoading: boolean = useSelector<RootState, boolean>(
			state => state.tasks.tasksCommentLoading,
			shallowEqual
		)

		const editorRef = React.useRef<any>()
		// React.useEffect(() => {
		// 	dispatch(getUserAction())
		// }, [show])
		const clearEditor = () => {
			const newKey = editorKey * 43
			setEditorKey(newKey)
		}
		function createMarkup() {
			return { __html: data?.description }
		}

		React.useEffect(() => {
			if (show) {
				dispatch(getTaskComment({ userId: user.id, taskId: data.id, page: 0 }))
			}
		}, [show])

		const handleSubmit = (e: any) => {
			e.preventDefault()
			dispatch(
				createTaskComment({ taskId: data.id, text: comment, userId: user.id })
			)

			setComment('')
			clearEditor()
		}

		const handleChangeComment = (arg: { commentId: any; text: any }) => {
			dispatch(
				updateTaskComment({
					taskId: data.id,
					userId: user.id,
					commentId: arg.commentId,
					text: arg.text,
				})
			)
		}

		const handleDeleteComment = (comment: any) => {
			Swal.fire({
				title: `${intl.formatMessage({ id: 'WARNING.DELETE.COMMENT' })}`,
				text: ``,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: `${intl.formatMessage({
					id: 'WARNING.DELETE.SUBMIT',
				})}`,
				cancelButtonText: `${intl.formatMessage({
					id: 'WARNING.DELETE.CANCEL',
				})}`,
			}).then(result => {
				if (result.isConfirmed) {
					dispatch(
						deleteTaskComment({
							taskId: data.id,
							userId: user.id,
							commentId: comment.id,
						})
					)

					Swal.fire({
						title: intl.formatMessage({
							id: 'NOTE.DELETE.COMMENT.SUCCESS',
						}),
						icon: 'success',
						timer: 1500,
					})
				}
			})
		}
		const handleChange = (e: any) => {
			let html = e.target.getContent()
			// setContentText(html)
			setComment(html)
		}
		const token = localStorage.token

		const handlePageClick = (e: any) => {
			dispatch(
				getTaskComment({ userId: user.id, taskId: data.id, page: e.selected })
			)
		}

		function example_image_upload_handler(
			blobInfo: any,
			success: any,
			failure: any,
			progress: any
		) {
			var xhr: XMLHttpRequest, formData

			xhr = new XMLHttpRequest()
			// xhr.withCredentials = true
			xhr.open('POST', `${BASE_URL}/minio/file`)
			xhr.setRequestHeader('Authorization', `Bearer ${token}`)

			xhr.upload.onprogress = function (e) {
				progress((e.loaded / e.total) * 100)
			}
			xhr.onload = function () {
				var json

				if (xhr.status === 403) {
					failure('HTTP Error: ' + xhr.status, { remove: true })
					return
				}

				if (xhr.status < 200 || xhr.status >= 300) {
					failure('HTTP Error: ' + xhr.status)
					return
				}
				json = JSON.parse(xhr.responseText)

				if (!json || typeof json.baseFileUrl != 'string') {
					failure('Invalid JSON: ' + xhr.responseText)
					return
				}
				try {
					success(json?.baseFileUrl)
				} catch (e: any) {}
			}

			xhr.onerror = function () {
				failure(
					'Image upload failed due to a XHR Transport error. Code: ' +
						xhr.status
				)
			}

			formData = new FormData()
			formData.append('file', blobInfo.blob(), blobInfo.filename())
			formData.append('name', blobInfo.filename())

			xhr.send(formData)
		}

		const DrawerContent = () => (
			<Box
				sx={{
					padding: 5,
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
				role='presentation'
			>
				<Box sx={{ flexGrow: 1 }}>
					<Stack spacing='20px'>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							{matches ? (
								<>
									<Box sx={{ width: 3 / 12, padding: 1 }}>
										<Typography fontWeight='medium'>
											{intl.formatMessage({ id: 'TASK.MODAL.TITLE' })}
										</Typography>
									</Box>
									<Box sx={{ width: 9 / 12, padding: 1 }}>
										<Typography>
											{data.title ||
												intl.formatMessage({ id: 'TASK.DEFAULT.TEXT' })}
										</Typography>
									</Box>
								</>
							) : (
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<Box sx={{}}>
										<Typography fontWeight='medium'>
											{intl.formatMessage({ id: 'TASK.MODAL.TITLE' })}
										</Typography>
									</Box>
									<Box sx={{}}>
										<Typography>
											{data.title ||
												intl.formatMessage({ id: 'TASK.DEFAULT.TEXT' })}
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
						<Box sx={{ display: 'flex' }}>
							{matches ? (
								<>
									{' '}
									<Box sx={{ width: 3 / 12, padding: 1 }}>
										<Typography fontWeight='medium' sx={{ pb: 1 }}>
											Описание
										</Typography>
									</Box>
									<Box sx={{ width: 9 / 12, padding: 1 }}>
										{/* {data.description ||
									intl.formatMessage({ id: 'TASK.DEFAULT.TEXT' })} */}
										<Typography component={'div'} variant={'body2'}>
											<div
												style={{ fontSize: '1rem' }}
												dangerouslySetInnerHTML={createMarkup()}
											/>
										</Typography>
									</Box>
								</>
							) : (
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									{' '}
									<Box>
										<Typography fontWeight='medium' sx={{ pb: 1 }}>
											Описание
										</Typography>
									</Box>
									<Box>
										{/* {data.description ||
									intl.formatMessage({ id: 'TASK.DEFAULT.TEXT' })} */}
										<Typography component={'div'} variant={'body2'}>
											<div
												style={{ fontSize: '1rem' }}
												dangerouslySetInnerHTML={createMarkup()}
											/>
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
						{/* //==============================DATE */}
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{matches ? (
								<>
									<Box sx={{ width: 3 / 12, padding: 1 }}>
										<Typography fontWeight='medium'>
											{intl.formatMessage({ id: 'TASK.MODAL.START' })}
										</Typography>
									</Box>
									<Box sx={{ width: 9 / 12, padding: 1 }}>
										<Typography>{data.start || 'No date'}</Typography>
									</Box>
								</>
							) : (
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<Box>
										<Typography fontWeight='medium'>
											{intl.formatMessage({ id: 'TASK.MODAL.START' })}
										</Typography>
									</Box>
									<Box>
										<Typography>{data.start || 'No date'}</Typography>
									</Box>
								</Box>
							)}
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{matches ? (
								<>
									<Box sx={{ width: 3 / 12, padding: 1 }}>
										<Typography fontWeight='medium'>
											{intl.formatMessage({ id: 'TASK.MODAL.END' })}
										</Typography>
									</Box>
									<Box sx={{ width: 9 / 12, padding: 1 }}>
										<Typography>
											{data.end ||
												intl.formatMessage({ id: 'TASK.DEFAULT.DATE' })}
										</Typography>
									</Box>
								</>
							) : (
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<Box>
										<Typography fontWeight='medium'>
											{intl.formatMessage({ id: 'TASK.MODAL.END' })}
										</Typography>
									</Box>
									<Box>
										<Typography>
											{data.end ||
												intl.formatMessage({ id: 'TASK.DEFAULT.DATE' })}
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{matches ? (
								<>
									<Box sx={{ width: 3 / 12, padding: 1 }}>
										<Typography fontWeight='medium'>
											{intl.formatMessage({ id: 'TASK.MODAL.STATUS' })}
										</Typography>
									</Box>
									<Box sx={{ width: 9 / 12, padding: 1 }}>
										<Typography>
											{data.status?.code ||
												intl.formatMessage({ id: 'TASK.DEFAULT.STATUS' })}
										</Typography>
									</Box>
								</>
							) : (
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<Box>
										<Typography fontWeight='medium'>
											{intl.formatMessage({ id: 'TASK.MODAL.STATUS' })}
										</Typography>
									</Box>
									<Box>
										<Typography>
											{data.status?.code ||
												intl.formatMessage({ id: 'TASK.DEFAULT.STATUS' })}
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{matches ? (
								<>
									<Box sx={{ width: 3 / 12, padding: 1 }}>
										<Typography fontWeight='medium'>
											{intl.formatMessage({ id: 'TASK.MODAL.CREATOR' })}
										</Typography>
									</Box>
									<Box sx={{ width: 9 / 12, padding: 1 }}>
										<Typography>
											{data.creator?.fullName ||
												intl.formatMessage({ id: 'TASK.DEFAULT.CREATOR' })}
										</Typography>
									</Box>
								</>
							) : (
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<Box>
										<Typography fontWeight='medium'>
											{intl.formatMessage({ id: 'TASK.MODAL.CREATOR' })}
										</Typography>
									</Box>
									<Box>
										<Typography>
											{data.creator?.fullName ||
												intl.formatMessage({ id: 'TASK.DEFAULT.CREATOR' })}
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{matches ? (
								<>
									<Box sx={{ width: 3 / 12, padding: 1 }}>
										<Typography fontWeight='medium'>
											{intl.formatMessage({ id: 'TASK.MODAL.ASSIGNEE' })}
										</Typography>
									</Box>
									<Box sx={{ width: 9 / 12, padding: 1 }}>
										<Typography>
											{data.assignee?.fullName ||
												intl.formatMessage({ id: 'TASK.DEFAULT.ASSIGNEE' })}
										</Typography>
									</Box>
								</>
							) : (
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<Box>
										<Typography fontWeight='medium'>
											{intl.formatMessage({ id: 'TASK.MODAL.ASSIGNEE' })}
										</Typography>
									</Box>
									<Box>
										<Typography>
											{data.assignee?.fullName ||
												intl.formatMessage({ id: 'TASK.DEFAULT.ASSIGNEE' })}
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{matches ? (
								<>
									<Box sx={{ width: 3 / 12, padding: 1 }}>
										<Typography fontWeight='medium'>Наблюдатели</Typography>
									</Box>
									<Box sx={{ width: 9 / 12, padding: 1 }}>
										<Typography>
											{data.watchers?.length === 0
												? 'Никого нет'
												: data.watchers?.map((watcher: any) => {
														return (
															<Typography key={watcher?.id}>
																{watcher?.fullName}
															</Typography>
														)
												  })}
										</Typography>
									</Box>
								</>
							) : (
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<Box>
										<Typography fontWeight='medium'>Наблюдатели</Typography>
									</Box>
									<Box>
										<Typography>
											{data.watchers?.length === 0
												? 'Никого нет'
												: data.watchers?.map((watcher: any) => {
														return (
															<Typography key={watcher?.id}>
																{watcher?.fullName}
															</Typography>
														)
												  })}
										</Typography>
									</Box>
								</Box>
							)}
						</Box>

						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{matches ? (
								<>
									<Box sx={{ width: 3 / 12, padding: 1 }}>
										<Typography fontWeight='medium'>Теги</Typography>
									</Box>
									<Box sx={{ width: 9 / 12, padding: 1 }}>
										<Typography>
											{data.tags?.length === 0
												? 'Нет тегов'
												: data.tags?.map((tag: any) => {
														return (
															<Typography key={tag?.id}>{tag?.name}</Typography>
														)
												  })}
										</Typography>
									</Box>
								</>
							) : (
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<Box>
										<Typography fontWeight='medium'>Теги</Typography>
									</Box>
									<Box>
										<Typography>
											{data.tags?.length === 0
												? 'Нет тегов'
												: data.tags?.map((tag: any) => {
														return (
															<Typography key={tag?.id}>{tag?.name}</Typography>
														)
												  })}
										</Typography>
									</Box>
								</Box>
							)}
						</Box>

						{/* //==============================ATTACHMENTS */}
						<Box>
							{matches ? (
								<>
									<Typography fontWeight='medium' sx={{ p: 1 }}>
										{intl.formatMessage({ id: 'TASK.MODAL.ATTACHMENTS' })}
									</Typography>
									<Card variant='outlined' sx={{ p: 1, fontWeight: 'regular' }}>
										{data.attachments?.length === 0
											? intl.formatMessage({ id: 'TASK.DEFAULT.ATTACHMENTS' })
											: data.attachments?.map((element: any) => {
													return (
														<TaskImageSumb
															key={element.id}
															img={`${BASE_URL}/minio/file/media/${element.uuid}`}
															name={element.name}
															size={element.size}
															date={element.lastModified}
														/>
													)
											  })}
									</Card>
								</>
							) : (
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<Typography fontWeight='medium' sx={{ p: 1 }}>
										{intl.formatMessage({ id: 'TASK.MODAL.ATTACHMENTS' })}
									</Typography>
									<Card variant='outlined' sx={{ p: 1, fontWeight: 'regular' }}>
										{data.attachments?.length === 0
											? intl.formatMessage({ id: 'TASK.DEFAULT.ATTACHMENTS' })
											: data.attachments?.map((element: any) => {
													return (
														<TaskImageSumb
															key={element.id}
															img={`${BASE_URL}/minio/file/media/${element.uuid}`}
															name={element.name}
															size={element.size}
															date={element.lastModified}
														/>
													)
											  })}
									</Card>
								</Box>
							)}
						</Box>
						<Typography fontWeight='medium' sx={{ pb: 1 }}>
							Комментарии
						</Typography>
						{data.creator?.id == user.personalInfo.userId ||
						data.assignee?.id == user.personalInfo.userId ||
						data.watchers?.some(
							(watcher: any) => watcher.id === user.personalInfo.userId
						) ? (
							<Grid container wrap='nowrap'>
								<Grid sx={{ pr: 2 }} item>
									<Avatar
										alt='Remy Sharp'
										src={user?.personalInfo?.imagePath || ''}
									/>
								</Grid>

								<Grid
									component='form'
									justifyContent='left'
									item
									xs
									zeroMinWidth
									noValidate
									autoComplete='off'
									onSubmit={handleSubmit}
								>
									<div className='form-group border radius-5'>
										<Editor
											onInit={(evt, editor) => (editorRef.current = editor)}
											// onInit={onInitEditor}
											key={editorKey}
											apiKey='x933w4qvw7ckm4dwx6qfe39t1e0qq0dj6wc19z4a2wet0gbg'
											// initialValue={item.content}

											init={{
												plugins,
												toolbar,
												menubar,
												height: 300,
												visual: false,
												powerpaste_allow_local_images: true,
												language: 'ru',
												images_upload_url: `${BASE_URL}/minio/file`,
												images_upload_handler: example_image_upload_handler,
												file_browser_callback_types: 'file image media',
												path_absolute: '/',
												tinydrive_token_provider: `${BASE_URL}/minio/file`,
												file_picker_callback: function (cb, value, meta) {
													var input = document.createElement('input')
													input.setAttribute('type', 'file')
													var xhr: XMLHttpRequest, formData
													xhr = new XMLHttpRequest()
													input.onchange = function (e: any) {
														var file = e.target.files[0]
														xhr.open('POST', `${BASE_URL}/minio/file`)
														xhr.setRequestHeader(
															'Authorization',
															`Bearer ${token}`
														)
														xhr.onload = function () {
															var json
															if (xhr.status === 403) {
																// failure('HTTP Error: ' + xhr.status, { remove: true })
																return
															}
															if (xhr.status < 200 || xhr.status >= 300) {
																// failure('HTTP Error: ' + xhr.status)
																return
															}
															json = JSON.parse(xhr.responseText)

															if (
																!json ||
																typeof json.baseFileUrl != 'string'
															) {
																// failure('Invalid JSON: ' + xhr.responseText)
																return
															}
															cb(json.baseFileUrl, {
																alt: json.name,
																title: json.name,
																text: json.name,
															})
														}

														xhr.onerror = function () {
															// failure(
															// 	'Image upload failed due to a XHR Transport error. Code: ' +
															// 		xhr.status
															// )
														}

														formData = new FormData()
														formData.append('file', file)
														formData.append('name', file.name)

														xhr.send(formData)
													}

													input.click()
												},
											}}
											onChange={handleChange}
										/>
									</div>

									<button type='submit' className='btn btn-primary my-5 btn-sm'>
										{' '}
										Отправить
									</button>
								</Grid>
							</Grid>
						) : null}

						<Grid style={{ position: 'relative' }}>
							{taskComment?.content?.map(item => {
								return (
									<TaskCommentsList
										user={user}
										key={item.id}
										handleChangeComment={handleChangeComment}
										handleDeleteComment={handleDeleteComment}
										item={item}
									/>
								)
							})}

							{tasksCommentLoading && (
								<div
									style={{
										position: 'absolute',
										top: '30%',
										left: '45%',
										width: '150px',
										height: '70px',
										background: 'white',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										borderRadius: '12px',
										boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
									}}
									className='loader'
								>
									<div
										className='loader-body'
										style={{ display: 'flex', alignItems: 'center' }}
									>
										<Spinner animation='border' role='status'></Spinner>
										<span className='ps-5 fs-4'>Загрузка</span>
									</div>
								</div>
							)}
						</Grid>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '20px',
							}}
						>
							<div className='fs-6 fw-bold text-gray-700'>
								{/* {intl.formatMessage({ id: 'PAGINATION.ENTRIES' })}{' '}
								 */}
								Всего комментариев
								{'  ' + taskComment?.totalElements}
								{/* {intl.formatMessage({ id: 'PAGINATION.ENTRIES' })}{' '}
								 */}
								{/* Всего на странице
								{'  ' + taskComment?.content?.length} */}
							</div>

							<ReactPaginate
								previousLabel={'<'}
								nextLabel={'>'}
								pageCount={taskComment.totalPages}
								onPageChange={handlePageClick}
								containerClassName={cnPage('pagination')}
								previousLinkClassName={cnPage('pagination__link')}
								nextLinkClassName={cnPage('pagination__link')}
								disabledClassName={cnPage('pagination__link--disabled')}
								activeClassName={cnPage('pagination__link--active')}
								pageRangeDisplayed={2}
								marginPagesDisplayed={2}
								forcePage={taskComment.page}
							/>
						</div>
					</Stack>
				</Box>
				<Box sx={{ my: '20px' }}>
					<Stack>
						<Button
							className='mb-10'
							onClick={handleClose}
							variant='contained'
							size='small'
						>
							{intl.formatMessage({ id: 'TEXT.CLOSE' })}
						</Button>
					</Stack>
				</Box>
			</Box>
		)

		return (
			<div>
				<>
					<Drawer
						sx={{ '& .MuiDrawer-paper': { width: matches ? '50%' : '100%' } }}
						disableEnforceFocus={true}
						anchor={'right'}
						open={show}
						onClose={handleClose}
					>
						{DrawerContent()}
					</Drawer>
				</>
			</div>
		)
	}
)

export default TaskDetailsDrawer
