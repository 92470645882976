import { makeStyles } from '@material-ui/core'
import { useField } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { FileError, FileRejection, useDropzone } from 'react-dropzone'
import { SingleFileUploadWithProgress } from './SingleFileUploadWithProgress'
import {
	LinearProgress,
	Box,
} from '@mui/material'

import { UploadError } from './uploadError'

let currentId = 0

function getNewId() {
	return ++currentId
}

export interface UploadableFile {
	id: number
	file: File
	errors: FileError[]
	url?: any
}

const useStyles = makeStyles(theme => ({
	dropzone: {
		border: `2px dashed #50CD89`,
		borderRadius: theme.shape.borderRadius,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: theme.palette.background.default,
		height: theme.spacing(10),
		outline: 'none',
	},
}))

export function MultipleFileUploadField({
	name,
	setFileErr,
}: {
	name: string
	setFileErr: any
}) {
	const [_, __, helpers] = useField(name)
	const classes = useStyles()

	const [files, setFiles] = useState<UploadableFile[]>([])
	const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
		const mappedAcc = accFiles.map(file => ({
			file,
			errors: [],
			id: getNewId(),
		}))
		const mappedRej = rejFiles.map(r => ({ ...r, id: getNewId() }))
		setFiles(curr => [...curr, ...mappedAcc, ...mappedRej])
	}, [])

	const [totalFilesUploadStatus, setTotalFilesUploadStatus] = useState([])
	const [totalFileUploadedProgress, setTotalFileUploadedProgress] = useState(0)

	useEffect(() => {
		helpers.setValue(files)
		const isError = files.every((item: any) => item?.url?.errors === null)
		if (files.length == 0) {
			setFileErr(false)
			return
		}

		if (isError) {
			setFileErr(true)
			// helpers.setError("dsdsdsd");
		} else {
			setFileErr(false)
		}

		// helpers.setTouched(true);
	}, [files, setFileErr])

	useEffect(() => {
		if (totalFilesUploadStatus.length) {
			calculateAllFileUploadProgress()
		}
	}, [totalFilesUploadStatus])

	function onUpload(file: File, url: string) {
		setFiles(curr =>
			curr.map(fw => {
				if (fw.file === file) {
					return { ...fw, url }
				}
				return fw
			})
		)
	}

	function onDelete(file: File) {
		setFiles(curr => curr.filter(fw => fw.file !== file))
	}

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,

		maxSize: 1024 * 1024, // 300KB
	})

	const calculateAllFileUploadProgress = () => {
		const result = (totalFilesUploadStatus.length / files.length) * 100
		setTotalFileUploadedProgress(result)
	}

	return (
		<React.Fragment>
			<div>
				<div {...getRootProps({ className: classes.dropzone })}>
					<input {...getInputProps()} />

					<p>Переместите файлы сюда или нажмите чтобы выбрать</p>
				</div>
			</div>
			{files.length > 0 && (
				<>
					<div style={{ padding: '10px 0px', fontWeight: 'bold' }}>
						Общее количество файлов {files.length}
					</div>
					<Box sx={{ width: '100%' }}>
						<LinearProgress
							variant='determinate'
							value={totalFileUploadedProgress}
						/>
					</Box>
				</>
			)}

			{/* <form onChange={handleChange}> */}
			{files.map((fileWrapper, index) => (
				<div
					style={{
						marginTop: '10px',
						marginBottom: '10px',
						borderRadius: '6px',
						border: '1px solid #B5B5C3',
						padding: '10px',
					}}
					key={fileWrapper.id}
				>
					{fileWrapper.errors.length ? (
						<UploadError
						setTotalFilesUploadStatus={setTotalFilesUploadStatus}
							file={fileWrapper.file}
							errors={fileWrapper.errors}
							onDelete={onDelete}
						/>
					) : (
						<>
							{index + 1}
							<SingleFileUploadWithProgress
								setTotalFilesUploadStatus={setTotalFilesUploadStatus}
								onDelete={onDelete}
								onUpload={onUpload}
								file={fileWrapper.file}
							/>
							{/* <div className='col-lg-8 fv-row'>
									<div style={{ padding: '1rem' }}>
										<TextField
											type={'email'}
											// required
											// id={fileWrapper?.url?.legal?.fullName}
											name={fileWrapper?.url?.legal?.fullName}
											// onChange={handleChange}
											// onChange={handleEmailChange}
											fullWidth
											label={'email'}
											// defaultValue={fileWrapper?.url?.legal?.email}
											value={fileWrapper?.url?.legal?.email}
											variant='outlined'
											// error={!emailValid}
										/>
									</div>
								</div> */}
						</>
					)}
				</div>
			))}
			{/* </form> */}
		</React.Fragment>
	)
}
