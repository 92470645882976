import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { IHelp } from '../../models/tasks/tasksModel'
import { RootState } from '../../setup'
import paginationStyles from '../Task/paginationStyles.module.scss'
import classNames from 'classnames/bind'
import { getTaskHelpAction } from '../../store/actions/taskhelpAction'
import { KTSVG } from '../../components/helpers/KTSVG'
import HelpModal from './HelpModal'
import { HelpModalUpdate } from './HelpModalUpdate'
import { Spinner } from 'react-bootstrap'
import { PageTitle } from '../../components/layout/core'
import { useIntl } from 'react-intl'
import Tree from '../../components/partials/layout/Tree'
import HelpModalAdd from './HekpModalAdd'
let cnPage = classNames.bind(paginationStyles)

const TaskHelp = () => {
	const [helpModal, setHelpModal] = useState(false)
	const [helpModalAdd, setHelpModalAdd] = useState(false)
	const [helpModalUpdate, setHelpModalUpdate] = useState(false)
	const [parentId, setParentId] = useState(null)

	const taskHelp: IHelp = useSelector<RootState, IHelp>(
		state => state.taskHelp.help,
		shallowEqual
	)
	const loading: any = useSelector<RootState, any>(
		state => state.taskHelp.loading,
		shallowEqual
	)
	const intl = useIntl()
	const [page, setPage] = useState({ page: 0 })
	const [data, setData] = useState({})

	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(getTaskHelpAction(page))
	}, [page])

	const handlePageClick = (e: any) => {
		setPage({ ...page, page: e.selected })
	}

	return (
		<>
			<PageTitle breadcrumbs={[]}>
				{intl.formatMessage({ id: 'HELP.BOT.TITLE' })}
			</PageTitle>
			<div className={`card `}>
				{/* begin::Header */}
				<div className='card-header border-0 pt-5'>
					<h3 className='card-title align-items-start flex-column'>
						<span className='card-label fw-bolder fs-3 mb-1'>
							{intl.formatMessage({ id: 'HELP.BOT.TITLE' })}
						</span>
						{/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span> */}
					</h3>
					<div
						onClick={() => setHelpModal(true)}
						className='card-toolbar'
						data-bs-toggle='tooltip'
						data-bs-placement='top'
						data-bs-trigger='hover'
						title='Click to add a user'
					>
						<span className='btn btn-sm btn-success'>
							<KTSVG
								path='media/icons/duotune/arrows/arr075.svg'
								className='svg-icon-3'
							/>
							{intl.formatMessage({ id: 'TEXT.CREATE' })}
						</span>
					</div>
				</div>
				{/* end::Header */}
				{/* begin::Body */}
				<div className='card-body py-3'>
					{/* begin::Table container */}
					<div className='table-responsive'>
						{/* begin::Table */}
						<div className='container'>
							<div className='row table_header p-5'>
								{/* <div className='col fw-bolder text-muted'>
									{intl.formatMessage({ id: 'TEXT.CODE' })}
								</div> */}
								<div className='col-2 fw-bolder text-muted'>
									{intl.formatMessage({ id: 'TASK.MODAL.TITLE' })}
								</div>
								<div className='col-2 fw-bolder text-muted'>
									{intl.formatMessage({ id: 'TASK.MODAL.DESCRIPTION' })}
								</div>
								<div className='col-2 fw-bolder text-muted'>
									{intl.formatMessage({ id: 'TEXT.PARENT' })}
								</div>
								<div className='col-4 fw-bolder text-muted'>
									{intl.formatMessage({ id: 'TEXT.RESPONSE' })}
								</div>
								{/* <div className='col-2 fw-bolder text-muted'>
									{intl.formatMessage({ id: 'TASK.STATUS' })}
								</div> */}
								<div
									className='col-2 fw-bolder text-muted'
									style={{ textAlign: 'end' }}
								>
									{/* <span className="text-right">{intl.formatMessage({ id: 'TEXT.ACTIONS' })}</span> */}
									{intl.formatMessage({ id: 'TEXT.ACTIONS' })}
								</div>
							</div>
							<div className="row table_body'">
								{
									<Tree
										data={taskHelp?.content}
										setParentId={setParentId}
										setData={setData}
										setHelpModalAdd={setHelpModalAdd}
										setHelpModalUpdate={setHelpModalUpdate}
										page={page}
									/>
								}
							</div>
						</div>
						{/* <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'> */}
						{/* begin::Table head */}
						{/* <thead>
								<tr className='fw-bolder text-muted'>
									<th className='min-w-130px'>
										{intl.formatMessage({ id: 'TEXT.CODE' })}
									</th>
									<th className='min-w-150px'>
										{intl.formatMessage({ id: 'TASK.MODAL.TITLE' })}
									</th>
									<th className='min-w-120px'>
										{intl.formatMessage({ id: 'TASK.MODAL.DESCRIPTION' })}
									</th>
									<th className='min-w-100px '>
										{intl.formatMessage({ id: 'TEXT.PARENT' })}
									</th>
									<th className='min-w-100px '>
										{intl.formatMessage({ id: 'TEXT.RESPONSE' })}
									</th>
									<th className='min-w-100px '>
										{intl.formatMessage({ id: 'TASK.STATUS' })}
									</th>
									<th className='min-w-100px text-end'>
										{intl.formatMessage({ id: 'TEXT.ACTIONS' })}
									</th>
								</tr>
							</thead> */}
						{/* end::Table head */}
						{/* begin::Table body */}
						{/* <tbody>
								{
									<Tree
										data={taskHelp?.content}
										setData={setData}
										setHelpModalUpdate={setHelpModalUpdate}
										page={page}
									/>
								} */}

						{/* {taskHelp?.content?.map((help: IHelpContent) => {
                return <TaskHelpList setData={setData} setHelpModalUpdate={setHelpModalUpdate} page={page} key={help.id} help={help} />
              })
              } */}
						{/* </tbody> */}
						{/* end::Table body */}
						{/* </table> */}
						{/* {
							<Tree
								data={taskHelp?.content}
								setData={setData}
								setHelpModalUpdate={setHelpModalUpdate}
								page={page}
							/>
						} */}
						{/* end::Table */}
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: '20px',
							}}
						>
							<div className='fs-6 fw-bold text-gray-700'>
								{intl.formatMessage({ id: 'PAGINATION.ENTRIES' })}{' '}
								{taskHelp?.totalElements}
							</div>
							<ReactPaginate
								previousLabel={'<'}
								nextLabel={'>'}
								pageCount={taskHelp?.totalPages}
								onPageChange={handlePageClick}
								containerClassName={cnPage('pagination')}
								previousLinkClassName={cnPage('pagination__link')}
								nextLinkClassName={cnPage('pagination__link')}
								disabledClassName={cnPage('pagination__link--disabled')}
								activeClassName={cnPage('pagination__link--active', {})}
								pageRangeDisplayed={2}
								marginPagesDisplayed={2}
							/>
						</div>
					</div>
					{/* end::Table container */}
				</div>
				{/* begin::Body */}
				{loading && (
					<div className='d-flex justify-content-center'>
						<Spinner animation='border' role='status'>
							<span className='sr-only'>Loading...</span>
						</Spinner>
					</div>
				)}
				<HelpModal
					taskHelp={taskHelp}
					page={page}
					show={helpModal}
					handleClose={() => setHelpModal(false)}
				/>
				<HelpModalUpdate
					taskHelp={taskHelp}
					data={data}
					setData={setData}
					page={page}
					show={helpModalUpdate}
					handleClose={() => setHelpModalUpdate(false)}
				/>
				<HelpModalAdd
					parentId={parentId}
					page={page}
					show={helpModalAdd}
					handleClose={() => setHelpModalAdd(false)}
				/>
			</div>
		</>
	)
}

export default TaskHelp
