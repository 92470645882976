import {useEffect, useState} from 'react'
import ReactPaginate from 'react-paginate'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../setup'
import paginationStyles from '../Task/paginationStyles.module.scss'
import classNames from 'classnames/bind'
import {Spinner} from 'react-bootstrap'
import {PageTitle} from '../../components/layout/core'
import {useIntl} from 'react-intl'
import axios from '../../setup/axios/axios-config'
import './datPickerCustomStyles.scss'
import {
    getReportsAction,
    getReportsContractorsAction,
    getReportsGroup,
} from '../../store/actions/reportsActions'
import {
    IReport,
    IReportContent,
    IReportGroup,
} from '../../models/report/reportModel'
import {logsModel, ReportPreviewModal} from './ReportPreview'
import AdapterDateFns from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import CloseIcon from '@mui/icons-material/Close'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import {
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Snackbar,
    Autocomplete,
    Stack,
} from '@mui/material'
import moment from 'moment'
import ReportModal from './ReportModal'
import ReportModalListlUpload from './ReportModalListlUpload'
import 'moment/locale/kk'
import 'moment/locale/ru'
import 'moment/locale/en-au'
import ru from 'date-fns/locale/ru';
import {useLang} from '../../components/i18n/Metronici18n'
import DeleteIcon from '@mui/icons-material/Delete'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import {IUserModel} from '../../models/personal/paginationCustom'
import ReportGroupList from './ReportGroupList'
import {checkRoleExist} from '../../components/helpers/herpers'
import {DatePicker, LoadingButton} from '@mui/lab'
import {IprofileData} from '../../modules/accounts/components/Profile'
import {getPersonalsAction} from '../../store/actions/personalsActions'
import Swal from 'sweetalert2'
import DatePickerBib from 'react-datepicker'
import ReportList from './ReportList'

let cnPage = classNames.bind(paginationStyles)

moment.locale('ru')

const Report = () => {
    const locale = useLang()

    const [reportModal, setReportModal] = useState(false)

    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
    const [openCreateModalList, setOpenCreateModalList] = useState<boolean>(false)
    const [helpModalUpdate, setHelpModalUpdate] = useState(false)
    const [langDate, setLangDate] = useState('ru')
    const report: IReport = useSelector<RootState, IReport>(
        state => state.reports.reports,
        shallowEqual
    )

    const reportGroup: IReportGroup = useSelector<RootState, IReportGroup>(
        state => state.reports.reportsGroup,
        shallowEqual
    )

    const [docStatuses, setDocStatuses] = useState([])
    const [types, setTypes] = useState([])

    const user: IUserModel = useSelector<RootState, IUserModel>(
        state => state.auth.user,
        shallowEqual
    )

    const {reportTypes, loadingPage}: any = useSelector<RootState, any>(
        state => state.reports,
        shallowEqual
    )

    const contractors: any = useSelector<RootState, any>(
        state => state.reports.contractors,
        shallowEqual
    )

    const loading: any = useSelector<RootState, any>(
        state => state.reports.loading,
        shallowEqual
    )

    const [exelLoading, setExelLoading] = useState<boolean>(false)

    const intl = useIntl()
    const [data, setData] = useState<any | undefined>()
    const [logs, setLogs] = useState<logsModel[]>([])
    const [group, setGroup] = useState<string>('')
    const [groupView, setGroupView] = useState<string>('list')
    const [openSnack, setOpenSnack] = useState(false)
    const [dateRange, setDateRange] = useState<any>([null, null]);
    const [startDate, endDate] = dateRange;
    const [openPicker, setOpenPicker] = useState<boolean>(false)
    const [period, setPeriod] = useState<any>(null)
    const [typeFilter, setTypeFilter] = useState<any>('')

    const [legalParents, setLegalParents] = useState([])
    const [selectedLegalParent, setSelectedLegalParent] = useState('')

    const [page, setPage] = useState({
        page: 0,
        name: '',
        startTime: '',
        endTime: '',
        legal: '',
        status: '',
        groupId: '',
        month: '',
        year: '',
        creationType: '',
        creatorId: '',
        parentLegalPin: '',
    })

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getReportsContractorsAction())
        requestCreationTypes()
        requestDocStatuses()
        dispatch(getPersonalsAction())
        requestLegalParents()
    }, [])

    useEffect(() => {
        dispatch(getReportsAction(page))
    }, [page])
    useEffect(() => {
        if (groupView === 'group') {
            dispatch(getReportsGroup(page))
        }
    }, [page, groupView])
    const handlePageClick = (e: any) => {
        if (group !== '') {
            setPage({...page, page: e.selected, groupId: group})
        } else {
            setPage({...page, page: e.selected})
        }
        setGroupView('list')
    }
    const handlePageGroupClick = (e: any) => {
        setPage({...page, page: e.selected})
        setGroupView('group')
    }

    const reportModalHandle = (report: IReportContent) => {
        setData(report)
        setReportModal(true)
    }
    const personals: IprofileData[] = useSelector<RootState, IprofileData[]>(
        state => state.personals.personals,
        shallowEqual
    )

    const handleAutoCompliteLegal = (event: any, newValue: any) => {
        setPage({...page, legal: newValue?.id, page: 0})
    }
    const handleCreatorChange = (event: any, newValue: any) => {
        setPage({...page, creatorId: newValue?.userId, page: 0})
    }
    const handleAutoCompliteStatus = (event: any, newValue: any) => {
        setGroupView(event.target.value)
        setGroup('')
        setPage({...page, page: 0, groupId: '', legal: ''})
    }

    const handleChange = (e: any) => {
        setPage({...page, [e.target.name]: e.target.value, page: 0})
    }
    const handleChangeType = (e: any) => {
        setTypeFilter(e.target.value)
    }
    useEffect(() => {

        if (startDate !== null && endDate !== null) {
            setPage({
                ...page,
                startTime: moment(startDate)?.format('YYYY-MM-DD HH:mm'),
                endTime: moment(endDate)?.format('YYYY-MM-DD HH:mm'),
            })
        } else {
            setPage({
                ...page,
                startTime: '',
                endTime: '',
            })
        }
    }, [dateRange])

    useEffect(() => {
        setPage({
            ...page,
            creationType: typeFilter,
        })
    }, [typeFilter])
    useEffect(() => {
        if (period !== null || period !== 'Invalid date') {
            setPage({
                ...page,
                year: isNaN(moment(period).get('year'))
                    ? ''
                    : moment(period).get('year').toString(),
                month: isNaN(moment(period).get('month') + 1)
                    ? ''
                    : (moment(period).get('month') + 1).toString(),
            })
        }
    }, [period])

    const getLogs = async () => {
        const logsData = await axios.get(`/api/documents/${data?.id}/log`)
        setLogs(await logsData.data)
    }

    useEffect(() => {
        if (data) {
            getLogs()
        }
    }, [data])
    useEffect(() => {
        if (group !== '') {
            setPage({...page, page: 0, groupId: group})
            setGroupView('list')
        }
    }, [group])

    useEffect(() => {
        setPage({...page, parentLegalPin: selectedLegalParent})
    }, [selectedLegalParent])

    const handleClick = () => {
        setOpenSnack(true)
    }

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return
        }

        setOpenSnack(false)
    }

    const handleOpenCreateModal = () => {
        setOpenCreateModal(true)
    }

    const handleCloseCreateModal = () => {
        setOpenCreateModal(false)
    }
    const handleCloseCreateListModal = () => {
        setOpenCreateModalList(false)
    }

    const action = (
        <>
            <Button color='secondary' size='small' onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size='small'
                aria-label='close'
                color='inherit'
                onClick={handleClose}
            >
                <CloseIcon fontSize='small'/>
            </IconButton>
        </>
    )

    const handleDownloadExelFile = () => {
        requestDownloadExelFile(page)
    }

    const requestDownloadExelFile = async (page: any) => {
        try {
            setExelLoading(true)
            const response = await axios
                .get('/api/documents/export', {
                    responseType: 'blob',
                    params: {
                        creationType: page.creationType,
                        endTime: page.endTime,
                        groupId: page.groupId,
                        legal: page.legal,
                        month: page.month,
                        startTime: page.startTime,
                        status: page.status,
                        year: page.year,
                    },
                })
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${Date.now()}.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                    setExelLoading(false)
                })
        } catch (error: any) {
            setExelLoading(false)
            Swal.fire({
                title: 'Ошибка',
                text: error.response.data.message || 'Ошибка сервера',
                icon: 'error',
                timer: 1500,
            })
        }
    }

    const requestLegalParents = async () => {
        try {
            const response: any = await axios
                .get('/api/legals/parents')
                .then(res => res.data)
            setLegalParents(response)
        } catch (error: any) {
            Swal.fire({
                title: 'Ошибка',
                text: error.response.data.message || 'Ошибка сервера',
                icon: 'error',
                timer: 1500,
            })
        }
    }

    const requestDocStatuses = async () => {
        const statuses = await axios
            .get('/api/documents/status')
            .then(res => res.data)
        setDocStatuses(statuses)
    }
    const requestCreationTypes = async () => {
        const statuses = await axios
            .get('/api/documents/creation-types')
            .then(res => res.data)
        setTypes(statuses)
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>Документация</PageTitle>
            <div className={`card `}>
                <div className='card-header pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>Документация</span>
                    </h3>

                    <Stack spacing={1} direction='row' alignItems='center'>
                        {groupView === 'list' && (
                            <LoadingButton
                                size='small'
                                color='secondary'
                                onClick={() => handleDownloadExelFile()}
                                endIcon={<DownloadForOfflineIcon/>}
                                loading={exelLoading}
                                loadingPosition='end'
                                variant='contained'
                            >
                                Выгрузить excel файл
                            </LoadingButton>
                        )}
                        {checkRoleExist(user?.personalInfo?.roles, [
                            'ROLE_DOCUMENT_CREATOR',
                            'ROLE_DOCUMENT_EDITOR',
                        ]) && (
                            <>
                                <Button
                                    size='small'
                                    variant='contained'
                                    onClick={() => setOpenCreateModalList(true)}
                                >
                                    Добавить список
                                </Button>
                                <Button
                                    size='small'
                                    variant='contained'
                                    onClick={() => setOpenCreateModal(true)}
                                >
                                    Добавить
                                </Button>
                            </>
                        )}
                    </Stack>
                </div>
                <div className='card-body py-3'>
                    <form>
                        <div className='d-flex justify-content-start py-5 align-items-center'>
                            {groupView === 'list' && (
                                <Autocomplete
                                    className='me-5'
                                    onChange={handleAutoCompliteLegal}
                                    getOptionLabel={(option: any) => option.fullName}
                                    renderOption={(props, option: any) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                {option.fullName}
                                            </li>
                                        )
                                    }}
                                    disablePortal
                                    id='combo-box-demo'
                                    options={contractors}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            sx={{width: 200}}
                                            label='Контрагент'
                                        />
                                    )}
                                />
                            )}
                            {groupView === 'group' && (
                                <Autocomplete
                                    className='me-5'
                                    onChange={handleCreatorChange}
                                    value={personals.find(item => item.userId === page.creatorId)}
                                    getOptionLabel={(option: any) => option.fullName}
                                    renderOption={(props, option: any) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                {option.fullName}
                                            </li>
                                        )
                                    }}
                                    disablePortal
                                    id='combo-box-demo'
                                    options={personals}
                                    renderInput={(params: any) => (
                                        <TextField {...params} sx={{width: 200}} label='Автор'/>
                                    )}
                                />
                            )}

                            {groupView === 'list' && (
                                <FormControl className='me-5 w-100px'>
                                    <InputLabel id='demo-simple-select-label'>Статус</InputLabel>
                                    <Select
                                        value={page.status}
                                        label='Статус'
                                        onChange={handleChange}
                                        name='status'
                                    >
                                        <MenuItem value=''>
                                            <em>Все</em>
                                        </MenuItem>
                                        {!!docStatuses?.length &&
                                            docStatuses.map((status: any) => {
                                                return (
                                                    <MenuItem key={status.code} value={status.code}>
                                                        {status.title}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            )}
                            <FormControl className='me-5 w-100px'>
                                <InputLabel id='demo-simple-select-label'>Вид</InputLabel>
                                <Select
                                    value={groupView}
                                    label='Вид'
                                    onChange={handleAutoCompliteStatus}
                                >
                                    <MenuItem value={'list'}>Список</MenuItem>
                                    <MenuItem value={'group'}>Группа</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className='me-5 w-100px'>
                                <InputLabel id='demo-simple-select-label'>Тип</InputLabel>
                                <Select
                                    value={typeFilter}
                                    label='Тип'
                                    onChange={handleChangeType}
                                >
                                    <MenuItem value={''}>
                                        <em>Все</em>
                                    </MenuItem>
                                    {!!types?.length &&
                                        types.map(item => {
                                            if (item === 'AUTO') {
                                                return (
                                                    <MenuItem key={item} value={item}>
                                                        Автоматически
                                                    </MenuItem>
                                                )
                                            }
                                            return (
                                                <MenuItem key={item} value={item}>
                                                    Вручную
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>

                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={locale === 'kz' ? 'kk' : locale}
                            >
                                <DatePicker
                                    views={['year', 'month']}
                                    label={intl.formatMessage({
                                        id: 'REPORT_CREATE_DATE',
                                    })}
                                    open={openPicker}
                                    onOpen={() => setOpenPicker(true)}
                                    onClose={() => setOpenPicker(false)}
                                    inputFormat='YYYY-MM-DD'
                                    maxDate={moment(moment().format('YYYY'), 'YYYY-MM-DD')
                                        .add(1, 'y')
                                        .subtract(1, 'M')}
                                    value={period ? period : null}
                                    onChange={newValue => {
                                        setPeriod(newValue)
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            sx={{width: 170}}
                                            {...params}
                                            InputLabelProps={{
                                                shrink: Boolean(period) && true,
                                            }}
                                            inputProps={{
                                                value: period
                                                    ? moment(period).format('MMMM - YYYY')
                                                    : '',
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <>
                                                        <IconButton
                                                            sx={{p: 0.3}}
                                                            onClick={() => setPeriod('')}
                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                        <IconButton
                                                            sx={{p: 0.3}}
                                                            onClick={() => setOpenPicker(true)}
                                                        >
                                                            <CalendarTodayIcon/>
                                                        </IconButton>
                                                    </>
                                                ),
                                            }}
                                            helperText={null}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <div className='datePickerCustomStyles'>
                                <DatePickerBib
                                    locale={ru}
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    placeholderText="Начало - Конец"
                                    dateFormat={'dd.M.yyyy'}
                                />
                            </div>


                            <TextField
                                sx={{minWidth: '150px', ml: 2}}
                                select
                                label='Агент'
                                value={selectedLegalParent}
                                onChange={(e: any) => setSelectedLegalParent(e.target.value)}
                            >
                                <MenuItem value={''}>Все</MenuItem>
                                {legalParents?.map((option: any) => (
                                    <MenuItem key={option?.id} value={option}>
                                        {option?.fullName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </form>
                    {page.status === 'REJECTED' && (
                        <span style={{color: 'darkred'}}>
							Документы со статусом Rejected - это документы, отклоненные со
							стороны другого агента.
						</span>
                    )}
                    {page.status === 'ANNULLED' && (
                        <span style={{color: 'darkred'}}>
							Документы со статусом Annulled - это документы, отклоненные со
							стороны Paydala.
						</span>
                    )}

                    {loading && (
                        <div
                            className='d-flex  w-100 align-items-center text-color-prinary '
                            style={{margin: '20px'}}
                        >
                            <Spinner animation='border' role='status' size='sm'></Spinner>
                            <span className='mx-5'>Отчеты загружаются</span>
                        </div>
                    )}
                    {group !== '' && groupView !== 'group' && (
                        <>
                            <a
                                className='text-primary fw-bolder text-hover-primary fs-6'
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                    setGroup('')

                                    setPage({...page, page: 0, groupId: ''})
                                    setGroupView('group')
                                }}
                            >
                                {' '}
                                Назад
                            </a>
                            <span className='card-label fw-bolder fs-7 mb-1'>
								{' '}
							</span>
                        </>
                    )}

                    <div
                        className='table-responsive'
                        style={{position: 'relative', paddingBottom: '20px'}}
                    >
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>

                            {groupView === 'group' && (
                                <thead>
                                <tr className='fw-bolder text-muted'>
                                    <th className='min-w-130px'>Номер документа</th>
                                    <th className='min-w-80px'>Тип отправки</th>
                                    <th className='min-w-80px'>Автор</th>
                                    <th className='min-w-100px '>Период</th>
                                    <th className='min-w-150px '>Дата создания</th>
                                </tr>
                                </thead>
                            )}
                            {groupView === 'list' && (
                                <thead>
                                <tr className='fw-bolder text-muted'>
                                    <th className='min-w-50px'>ID</th>
                                    <th className='min-w-150px'>Контрагент</th>
                                    <th className='min-w-150px'>Файлы</th>
                                    <th className='min-w-150px'>Тип отправки</th>
                                    <th className='min-w-100px'>Период</th>
                                    <th className='min-w-150px'>Дата создания</th>
                                    <th className='min-w-100px text-center'>Статус</th>
                                </tr>
                                </thead>
                            )}

                            <tbody>
                            {groupView === 'list' &&
                                report?.content?.map((report: IReportContent) => {
                                    return (
                                        <ReportList
                                            reportModalHandle={reportModalHandle}
                                            setData={setData}
                                            setHelpModalUpdate={setHelpModalUpdate}
                                            page={page}
                                            key={report.id}
                                            report={report}
                                        />
                                    )
                                })}
                            {groupView === 'group' &&
                                reportGroup.content?.map(item => {
                                    return (
                                        <ReportGroupList
                                            setData={setData}
                                            setHelpModalUpdate={setHelpModalUpdate}
                                            key={item.id}
                                            page={page}
                                            report={item}
                                            group={group}
                                            setGroup={setGroup}
                                        />
                                    )
                                })}
                            </tbody>
                        </table>
                        {loadingPage && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '30%',
                                    left: '45%',
                                    width: '150px',
                                    height: '70px',
                                    background: 'white',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '12px',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                }}
                                className='loader'
                            >
                                <div
                                    className='loader-body'
                                    style={{display: 'flex', alignItems: 'center'}}
                                >
                                    <Spinner animation='border' role='status'></Spinner>
                                    <span className='ps-5 fs-4'>Загрузка</span>
                                </div>
                            </div>
                        )}
                        {groupView === 'list' && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: '20px',
                                }}
                            >
                                <div className='fs-6 fw-bold text-gray-700'>
                                    {intl.formatMessage({id: 'PAGINATION.ENTRIES'})}{' '}
                                    {report?.totalElements}
                                </div>
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    pageCount={report?.totalPages}
                                    onPageChange={handlePageClick}
                                    containerClassName={cnPage('pagination')}
                                    previousLinkClassName={cnPage('pagination__link')}
                                    nextLinkClassName={cnPage('pagination__link')}
                                    disabledClassName={cnPage('pagination__link--disabled')}
                                    activeClassName={cnPage('pagination__link--active', {})}
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                    forcePage={page.page}
                                />
                            </div>
                        )}
                        {groupView === 'group' && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: '20px',
                                }}
                            >
                                <div className='fs-6 fw-bold text-gray-700'>
                                    {intl.formatMessage({id: 'PAGINATION.ENTRIES'})}{' '}
                                    {reportGroup?.totalElements}
                                </div>
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    pageCount={reportGroup?.totalPages}
                                    onPageChange={handlePageGroupClick}
                                    containerClassName={cnPage('pagination')}
                                    previousLinkClassName={cnPage('pagination__link')}
                                    nextLinkClassName={cnPage('pagination__link')}
                                    disabledClassName={cnPage('pagination__link--disabled')}
                                    activeClassName={cnPage('pagination__link--active', {})}
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                    forcePage={page.page}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ReportPreviewModal
                openSnack={handleClick}
                handleClose={() => setReportModal(false)}
                show={reportModal}
                data={data}
                logs={logs}
                filter={page}
            />
            <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleClose}
                message='Copied! to clipBoard'
                action={action}
            />
            <ReportModal
                show={openCreateModal}
                handleClose={handleCloseCreateModal}
            />
            <ReportModalListlUpload
                show={openCreateModalList}
                handleClose={handleCloseCreateListModal}
            />
        </>
    )
}

export default Report
