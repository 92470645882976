import React from 'react'



const PublicMasterLayout: React.FC = ({ children }) => {
  return (
    <div className='page d-flex flex-row flex-column-fluid'>
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>

          <div
            className={'d-flex justify-content-center flex-column-fluid align-items-center container'}
            id='kt_post'
          >
            <div className="content flex-row-fluid">
              {children}
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicMasterLayout
