import { Paper, Typography } from '@mui/material'
import React from 'react'
import { PageTitle } from '../../components/layout/core'

const ContactUs = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Контакты</PageTitle>
			<Paper sx={{ p: 2 }}>
				<Typography variant='h3'>Контакты</Typography>
				<Typography variant='h4' sx={{ py: 2, fontSize: '22px' }}>
					По всем вопросам{' '}
					<a href='mailto:info@313.kz' style={{ color: '#eaac50', fontSize: '22px'  }}>
						info@313.kz
					</a>
				</Typography>
				<Typography variant='h5' sx={{ py: 2, fontSize: '22px' }}>
					По вопросам отмены платежей{' '}
					<a href='mailto:info@313.kz' style={{ color: '#eaac50', fontSize: '22px'  }}>
						otmena@313.kz
					</a>
				</Typography>
			</Paper>
		</>
	)
}

export default ContactUs
