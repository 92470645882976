import axios from '../../setup/axios/axios-config'
import { takeLatest, put, call } from 'redux-saga/effects'
import { FileOrFolderModel, FileVersionModel, UsersOfSharedFile, SharedFileModel } from "../../models/storage/FileModel";
import {
    createFolderAction,
    errorStorage,
    getFilesAndFoldersAction,
    imagePreviewAction,
    uploadFileAction,
    requestFilesAndFolderAction,
    enablePublicLinkAction,
    updatePdfPreviewLink,
    fileVersionSuccess,
    getSharedFilesSuccess,
    getUsersOfSharedFileSuccess,
    validateUsernameSuccess,
    getUsersOfSharedFileRequest
} from "../actions/storageActions";
import {
    CREATE_FOLDER_REQUEST,
    PREVIEW_IMAGE_REQUEST,
    REQUEST_FILES_AND_FOLDERS,
    UPLOAD_FILE_REQUEST,
    MOVE_TO_FOLDER_REQUEST,
    ENABLE_PUBLIC_LINK_REQUEST,
    DISABLE_PUBLIC_LINK_REQUEST,
    DELETE_FILE_REQUEST,
    DELETE_FOLDER_REQUEST,
    FILE_VERSION_LIST_REQUEST,
    GET_SHARED_FILES_REQUEST,
    GET_USERS_OF_SHARED_FILE_REQUEST,
    DELETE_USER_ACCESS_TO_SHARED_FILE_REQUSET,
    VALIDATE_USERNAME_REQUEST,
    SHARE_FILE_TO_USER_REQUEST,
    UPDATE_SHARE_FILE_REQUEST
} from "../actionTypes";
import { AnyAction } from "redux";
import saveAsFile from 'save-as-file'

const fetchFilesAndFolders = (folderDestination: string) => axios.get(`/disk/${folderDestination}`).then(res => res.data)
const fetchUploadFile = (file: FormData, parentId: number) => axios.post(`/disk/file${(parentId === -1 || parentId === 0) ? '' : `?parentId=${parentId}`}`, file).then(res => res.data)
const fetchCreateFolder = (folder: string, parentId: number) => axios.post(`/disk/folder?name=${folder}${(parentId === -1 || parentId === 0) ? '' : `&parentId=${parentId}`}`).then(res => res.data)

const fetchImageForPreview = (id: number, versionReq: boolean) => axios.get(`/disk/file${versionReq ? `/version?fileVersionId=${id}` : `?id=${id}`}`, { responseType: 'blob' }).then(res => res.data)
const fetchMoveToFolder = (path: string, id: number, folderDestination: string) => axios.patch(`/disk/${path}/move?id=${id}${folderDestination}`).then(res => res)

const fetchEnablePublicLink = (id: number) => axios.put(`/disk/file/link/enable?accessRight=READ&id=${id}`).then(res => res.data)
const fetchDisablePublicLink = (id: number) => axios.put(`/disk/file/link/disable?id=${id}`).then(res => res.data)

const fetchDeleteFile = (id: number) => axios.delete(`disk/file?id=${id}`).then(res => res)
const fetchDeleteFolder = (id: number) => axios.delete(`disk/folder?id=${id}`).then(res => res)

const fetchFileVersionList = (id: number) => axios.get(`disk/file/version/list?id=${id}`).then(res => res.data)

const fetchSharedFilesList = () => axios.get(`disk/share`).then(res => res.data)
const fetchUsersOfSharedFile = (fileId: number) => axios.get(`disk/file/share/user?id=${fileId}`).then(res => res.data.accessRights)
const fetchDeleteUserAccessToFile = (userId: number, fileId: number) => axios.delete(`disk/file/share/user?id=${fileId}&userId=${userId}`).then(res => res.data)
const fetchValidateUsername = (username: string) => axios.get(`disk/user/exists?username=${username}`).then(res => res.data)
const fetchShareFileToUser = (fileId: number, userId: number, isWriteAccess: boolean) => axios.post(`disk/file/share/user?accessRight=${isWriteAccess ? 'READ_WRITE' : 'READ'}&id=${fileId}&userId=${userId}`).then(res => res.data)
const fetchUploadSharedFile = (file: FormData, id: number) => axios.post(`disk/file/share?id=${id}`, file).then(res => res.data)

function* getAllFilesAndFolders(action: AnyAction) {
    try {
        let folderDestination = ''
        if (action.parentId !== 0 && action.parentId !== -1) {
            folderDestination = `?parentId=${action.parentId}`
        }
        const filesAndFolders: FileOrFolderModel[] = yield call(fetchFilesAndFolders, folderDestination)
        yield put(getFilesAndFoldersAction(filesAndFolders))
    } catch (e) {
        yield put(errorStorage(e))
    }
}

function* uploadFile(action: AnyAction) {
    try {
        const file: FileOrFolderModel = yield call(fetchUploadFile, action.file, action.parentId)
        yield put(uploadFileAction(file))
    } catch (e) {
        yield put(errorStorage(e))
    }
}

function* createFolder(action: AnyAction) {
    try {
        const folder: FileOrFolderModel = yield call(fetchCreateFolder, action.folderName, action.parentId)
        yield put(createFolderAction(folder))
    } catch (e) {
        yield put(errorStorage(e))
    }
}

function* moveToFolder(action: AnyAction) {
    try {
        let folderDestination = '' //для переноса в начальную папку
        if (action.parentId !== -1 && action.parentId !== -2) {
            folderDestination = `&parentId=${action.parentId}`
        }
        const path = action.isFolder ? 'folder' : 'file'
        yield call(fetchMoveToFolder, path, action.id, folderDestination)
        yield put(requestFilesAndFolderAction(action.folderId))
    } catch (e) {
        yield put(errorStorage(e))
    }
}
function* enablePublicLink(action: AnyAction) {
    try {
        const link: string = yield call(fetchEnablePublicLink, action.id)
        yield put(enablePublicLinkAction(link))
    } catch (e) {
        yield put(errorStorage(e))
    }
}
function* disablePublicLink(action: AnyAction) {
    try {
        yield call(fetchDisablePublicLink, action.id)
    } catch (e) {
        yield put(errorStorage(e))
    }
}
function* deleteFile(action: AnyAction) {
    try {
        yield call(fetchDeleteFile, action.id)
    } catch (e) {
        yield put(errorStorage(e))
    }
}
function* deleteFolder(action: AnyAction) {
    try {
        yield call(fetchDeleteFolder, action.id)
    } catch (e) {
        yield put(errorStorage(e))
    }
}
function* getFileVersionList(action: AnyAction) {
    try {
        const list: FileVersionModel[] = yield call(fetchFileVersionList, action.id)
        yield put(fileVersionSuccess(list))

    } catch (e) {
        yield put(errorStorage(e))
    }
}
function* getUsersOfSharedFile(action: AnyAction) {
    try {
        const list: UsersOfSharedFile[] = yield call(fetchUsersOfSharedFile, action.fileId)
        yield put(getUsersOfSharedFileSuccess(list))

    } catch (e) {
        yield put(errorStorage(e))
    }
}
function* deleteUserAccessToFile(action: AnyAction) {
    try {
        yield call(fetchDeleteUserAccessToFile, action.userId, action.fileId)
        const list: UsersOfSharedFile[] = yield call(fetchUsersOfSharedFile, action.fileId)
        yield put(getUsersOfSharedFileSuccess(list))

    } catch (e) {
        yield put(errorStorage(e))
    }
}
function* getSharedFiles(action: AnyAction) {
    try {
        const list: SharedFileModel[] = yield call(fetchSharedFilesList)
        yield put(getSharedFilesSuccess(list))

    } catch (e) {
        yield put(errorStorage(e))
    }
}
function* validateUsername(action: AnyAction) {
    try {
        const user: { userId: 0, username: '', exists: boolean } = yield call(fetchValidateUsername, action.username)
        yield put(validateUsernameSuccess(user))
    } catch (e) {
        yield put(errorStorage(e))
    }
}
function* shareFileToUser(action: AnyAction) {
    try {
        yield call(fetchShareFileToUser, action.fileId, action.userId, action.isWriteAccess)
        yield put(getUsersOfSharedFileRequest(action.fileId))
    } catch (e) {
        yield put(errorStorage(e))
    }
}
function* updateShareFile(action: AnyAction) {
    try {
        yield call(fetchUploadSharedFile, action.file, action.id)
    } catch (e) {
        yield put(errorStorage(e))
    }
}
function* imageForPreview(action: AnyAction) {
    try {
        if (action.id !== 0) {
            const image: { url: string, name: string, show: boolean, blob: Blob } = {
                url: '',
                name: '',
                show: action.show,
                blob: new Blob()
            }
            yield put(imagePreviewAction(image))
            const file: Blob = yield call(fetchImageForPreview, action.id, action.versionReq)
            if (action.download) {
                saveAsFile(file, action.name)
                const image: { url: string, name: string, show: boolean, blob: Blob } = {
                    url: '',
                    name: '',
                    show: false,
                    blob: new Blob()
                }
                yield put(imagePreviewAction(image))
            }
            else {
                if (file.type.match(/png/) || file.type.match(/jpeg/) || file.type.match(/svg/) || file.type.match(/x-icon/)) {
                    const url = URL.createObjectURL(file)
                    const image: { url: string, name: string, show: boolean, blob: Blob } = {
                        url,
                        name: action.name,
                        show: action.show,
                        blob: file
                    }
                    yield put(imagePreviewAction(image))
                }
                else if (file.type.match(/pdf/)) {
                    const image: { url: string, name: string, show: boolean, blob: Blob } = {
                        url: '',
                        name: action.name,
                        show: action.show,
                        blob: file
                    }
                    yield put(imagePreviewAction(image))
                    const fileURL = URL.createObjectURL(file);
                    yield put(updatePdfPreviewLink(fileURL))
                }
                else {
                    const image: { url: string, name: string, show: boolean, blob: Blob } = {
                        url: '',
                        name: '',
                        show: false,
                        blob: new Blob()
                    }
                    yield put(imagePreviewAction(image))
                }
            }


        }
        else {
            const image: { url: string, name: string, show: boolean, blob: Blob } = {
                url: '',
                name: '',
                show: false,
                blob: new Blob()
            }
            yield put(imagePreviewAction(image))
        }
    }
    catch (e) {
        yield put(errorStorage(e))
        const image: { url: string, name: string, show: boolean, blob: Blob } = {
            url: '',
            name: '',
            show: false,
            blob: new Blob()
        }
        yield put(imagePreviewAction(image))
    }
}
export function* watchStorage() {
    yield takeLatest(REQUEST_FILES_AND_FOLDERS, getAllFilesAndFolders)
    yield takeLatest(UPLOAD_FILE_REQUEST, uploadFile)
    yield takeLatest(CREATE_FOLDER_REQUEST, createFolder)
    yield takeLatest(PREVIEW_IMAGE_REQUEST, imageForPreview)
    yield takeLatest(MOVE_TO_FOLDER_REQUEST, moveToFolder)
    yield takeLatest(ENABLE_PUBLIC_LINK_REQUEST, enablePublicLink)
    yield takeLatest(DISABLE_PUBLIC_LINK_REQUEST, disablePublicLink)
    yield takeLatest(DELETE_FILE_REQUEST, deleteFile)
    yield takeLatest(DELETE_FOLDER_REQUEST, deleteFolder)
    yield takeLatest(FILE_VERSION_LIST_REQUEST, getFileVersionList)
    yield takeLatest(GET_SHARED_FILES_REQUEST, getSharedFiles)
    yield takeLatest(GET_USERS_OF_SHARED_FILE_REQUEST, getUsersOfSharedFile)
    yield takeLatest(DELETE_USER_ACCESS_TO_SHARED_FILE_REQUSET, deleteUserAccessToFile)
    yield takeLatest(VALIDATE_USERNAME_REQUEST, validateUsername)
    yield takeLatest(SHARE_FILE_TO_USER_REQUEST, shareFileToUser)
    yield takeLatest(UPDATE_SHARE_FILE_REQUEST, updateShareFile)
}