import { Permission } from "../models/coomonOFD/FileModel";

export const findPermission = (name: string, permissions: Permission[]) => {
    let res = false
    permissions.find(item => {
        const search = item.name.search(':') + 1
        const resName = item.name.slice(search)
        if (resName === name) res = true
        else if (res === true) res = true
        else res = false
    })
    return res
}