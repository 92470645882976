import * as Yup from 'yup'

export interface ICreateAccount {
  passportBack: any,
	passportFront: any,
  docType: string,
  photoFace:any
}

const createAccountSchemas = [
  Yup.object({
    passportBack: Yup.string().required('Нет фото пасспорта').nullable(),
	passportFront: Yup.string().required('Нет фото пасспорта').nullable(),


  }),
  Yup.object({
    photoFace: Yup.string().required('Нет фото лица').nullable(),
  }),
  
]

const inits = {
	passportBack: null,
	passportFront: null,
docType: "1",
photoFace:null,
 
}
const toBase64 = (file: File) =>
new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
})
async function dataUrlToFile(dataUrl: any, fileName: string): Promise<File> {
    const res: Response = await fetch(dataUrl)
    const blob: Blob = await res.blob()
    const file = new File([blob], fileName, { type: 'image/jpeg' })

    return file
}

export {createAccountSchemas, inits, toBase64, dataUrlToFile}