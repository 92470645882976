import style from './taskStyles.module.scss'
import { Avatar, Box, Grid, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ITaskCommentContent } from '../../models/tasks/tasksModel'
import { Editor } from '@tinymce/tinymce-react'
import { IUserModel } from '../../models/personal/paginationCustom'
import moment from 'moment'
import { useIntl } from 'react-intl'

const menubar = false

const plugins =
	'advlist autolink lists link image charmap print preview anchor ' +
	'searchreplace visualblocks code fullscreen ' +
	'insertdatetime media table paste code help wordcount emoticons '

const toolbar =
	'undo redo | formatselect | emoticons | ' +
	'bold italic backcolor | alignleft aligncenter | ' +
	'alignright alignjustify | link image | bullist numlist outdent indent | ' +
	'removeformat | help '

interface commentListProps {
	handleDeleteComment: (arg: any) => void
	handleChangeComment: (arg: { commentId: any; text: any }) => void
	item: ITaskCommentContent
	user?: IUserModel
}

const TaskCommentsList = ({
	handleDeleteComment,
	item,
	handleChangeComment,
	user,
}: commentListProps) => {
	const [edit, setEdit] = useState(false)
	const [editText, setEditText] = useState('')
	const intl = useIntl()
	const handleChangeBtn = () => {
		setEdit(true)
	}

	const handleChangeSubmit = () => {
		handleChangeComment({ commentId: item.id, text: editText })
		setEdit(false)
	}

	useEffect(() => {
		if (edit) {
			setEditText(item.text)
		}
	}, [edit])

	function createMarkup() {
		return { __html: item?.text }
	}
	const handleChange = (e: any) => {
		let html = e.target.getContent()
		// setContentText(html)
		setEditText(html)
	}

	return (
		<div className='d-flex w-100 mb-5'>
			<Grid sx={{ pr: 2 }} item>
				<Avatar alt='Remy Sharp' src={item?.createdBy?.imagePath || ''} />{' '}
			</Grid>
			<Paper style={{ padding: '20px', width: '100%' }}>
				<Grid container wrap='nowrap' spacing={2}>
					<Grid justifyContent='left' item xs zeroMinWidth>
						<div className='d-flex '>
							<h4 style={{ margin: 0, textAlign: 'left' }}>
								{item?.createdBy?.fullName}
							</h4>
							<Box sx={{ pt: 0.4, ml: 2 }}>
								<Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
									{item.createdAt
										? moment(item.createdAt).format('DD MMMM YYYY, hh:mm a')
										: intl.formatMessage({ id: 'TASK.DEFAULT.DATE' })}
								</Typography>
							</Box>
						</div>

						{!edit && <div dangerouslySetInnerHTML={createMarkup()} />}
						{/* <p style={{ textAlign: 'left', color: 'gray' }}>
                
            </p> */}
						{edit && (
							<Grid
								className='my-5'
								component='form'
								justifyContent='left'
								item
								xs
								zeroMinWidth
								noValidate
								autoComplete='off'
								onSubmit={handleChangeSubmit}
							>
								<div className='form-group border radius-5'>
									<Editor
										// onInit={(evt, editor) => (editorRef.current = editor)}
										apiKey='x933w4qvw7ckm4dwx6qfe39t1e0qq0dj6wc19z4a2wet0gbg'
										initialValue={item.text}
										init={{
											plugins,
											toolbar,
											menubar,
											height: 150,
											visual: false,
											// content_style:
											// 	'body{padding-left:40px;padding-right:40px}',
										}}
										onChange={handleChange}
										// value={contentText}
									/>
								</div>
								<button type='submit' className='btn btn-primary my-5 btn-sm'>
									{' '}
									Изменить
								</button>
								<button
									type='submit'
									className='btn btn-secondary my-5 btn-sm ms-3'
									onClick={() => setEdit(false)}
								>
									{' '}
									Отмена
								</button>
							</Grid>
						)}
						{!edit && user?.id === +item.createdBy?.id && (
							<div className={style.editComment}>
								<p onClick={handleChangeBtn}>Изменить</p>
								<p onClick={() => handleDeleteComment(item)}>Удалить</p>
							</div>
						)}
					</Grid>
				</Grid>
			</Paper>
		</div>
	)
}

export default TaskCommentsList
