import { FC, memo, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import {
	Autocomplete,
	LinearProgress,
	Stack,
	TextField,
	Typography,
} from '@mui/material'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import moment from 'moment'
import { DateTimePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateMoment from '@mui/lab/AdapterMoment'
import { IprofileData } from '../../../modules/accounts/components/Profile'
import { RootState } from '../../../setup'
import axios from '../../../setup/axios/axios-config'
import { useForm, Controller } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { createTaskRequestAction } from '../../../store/actions/calendarTaskAction'
import FileUploadButton from '../../../pages/Task/FileUploadButton'
import Swal from 'sweetalert2'
import { checkIfFilesAreTooBig } from '../../helpers/herpers'
import MultiSelect from '../MultiSelect'
import { Editor } from '@tinymce/tinymce-react'
import MultiSelectTags from '../MultiSelectTags'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
const menubar = false

const plugins =
	'advlist autolink lists image link tinydrive imagetools charmap print preview anchor ' +
	'searchreplace visualblocks code fullscreen ' +
	'insertdatetime media table paste code help wordcount emoticons '

const toolbar =
	'undo redo | formatselect | emoticons | ' +
	'bold italic backcolor | alignleft aligncenter ' +
	'alignright alignjustify |  image link | bullist numlist outdent indent | ' +
	'removeformat | help '

const BASE_URL = process.env.REACT_APP_BASE_URL

interface TaskCreateDrawerProps {
	show: boolean
	handleClose: () => void
	filter?: any
	currentDate?: any
	data?: any
}

const schema = yup.object().shape({
	title: yup.string().required('Обязательное поле'),
	description: yup.string(),
	attachments: yup
		.mixed()
		.test('fileSize', 'Файл не должен превышать 1мб', checkIfFilesAreTooBig),
})

// const [edit, setEdit] = useState(false)

// const initialState =

const TaskCreateDrawer: FC<TaskCreateDrawerProps> = memo(
	({ show, handleClose, filter, currentDate, data }) => {
		//===========================================HOOOOKS
		const theme = useTheme()
		const matches = useMediaQuery(theme.breakpoints.up('sm'))
		const intl = useIntl()
		const dispatch = useDispatch()
		const [editText, setEditText] = useState('')
		const [epic, setEpic] = useState([])
		const token = localStorage.token
		const handleChange = (e: any) => {
			let html = e.target.getContent()
			// setContentText(html)
			setEditText(html)
		}
		const personals: IprofileData[] = useSelector<RootState, IprofileData[]>(
			state => state.personals.personals,
			shallowEqual
		)
		const [tag, setTag] = useState([])
		const [watchers, setWatchers] = useState([])
		const [assignee, setAssignee] = useState<any>('')
		const { createTaskLoading, createTaskError }: any = useSelector<
			RootState,
			any
		>(state => state.tasks, shallowEqual)
		useEffect(() => {
			const tags = async () => {
				const tag = await axios.get('/api/tags')
				setEpic(await tag.data)
			}
			tags()
		}, [])

		const { getValues, formState, handleSubmit, control, setValue, reset } =
			useForm({
				defaultValues: {
					title: '',
					description: '',
					assignee: '',
					watchersId: [],
					attachments: '',
					tagIds: [],
					start: data?.start ? data?.start : data?.start,
					end: data?.end ? data?.end : moment().format('YYYY-MM-DD HH:mm'),
				},
				resolver: yupResolver(schema),
				mode: 'onChange',
			})

		useEffect(() => {
			if (data?.start && data?.end) {
				setValue('start', moment(data.start).format('YYYY-MM-DD HH:mm'))
				setValue('end', moment(data.end).format('YYYY-MM-DD HH:mm'))
			}
		}, [data])
		useEffect(() => {
			setValue('watchersId', watchers)
		}, [watchers])
		useEffect(() => {
			setValue('assignee', assignee.toString())
		}, [assignee])
		useEffect(() => {
			setValue('description', editText)
		}, [editText])
		useEffect(() => {
			setValue('tagIds', tag)
		}, [tag])

		useEffect(() => {
			!show &&
				reset({
					title: '',
					description: '',
					assignee: '',
					watchersId: [],
					attachments: '',
					tagIds: [],
					start:
						data && data?.end
							? moment(data.start).format('YYYY-MM-DD HH:mm')
							: moment().format('YYYY-MM-DD HH:mm'),
					end:
						data && data?.end
							? moment(data.end).format('YYYY-MM-DD HH:mm')
							: moment().format('YYYY-MM-DD HH:mm'),
				})
		}, [show])
		//===========================================STATES

		//===========================================FUNCTIONS

		function example_image_upload_handler(
			blobInfo: any,
			success: any,
			failure: any,
			progress: any
		) {
			var xhr: XMLHttpRequest, formData

			xhr = new XMLHttpRequest()
			// xhr.withCredentials = true
			xhr.open('POST', `${BASE_URL}/minio/file`)
			xhr.setRequestHeader('Authorization', `Bearer ${token}`)

			xhr.upload.onprogress = function (e) {
				progress((e.loaded / e.total) * 100)
			}
			xhr.onload = function () {
				var json

				if (xhr.status === 403) {
					failure('HTTP Error: ' + xhr.status, { remove: true })
					return
				}

				if (xhr.status < 200 || xhr.status >= 300) {
					failure('HTTP Error: ' + xhr.status)
					return
				}

				json = JSON.parse(xhr.responseText)

				if (!json || typeof json.baseFileUrl != 'string') {
					failure('Invalid JSON: ' + xhr.responseText)
					return
				}
				try {
					success(json?.baseFileUrl)
				} catch (e: any) {
					console.error(e)
				}
			}

			xhr.onerror = function () {
				failure(
					'Image upload failed due to a XHR Transport error. Code: ' +
					xhr.status
				)
			}

			formData = new FormData()
			formData.append('file', blobInfo.blob(), blobInfo.filename())
			formData.append('name', blobInfo.filename())

			xhr.send(formData)
		}

		const onSubmit = (data: any) => {
			dispatch(createTaskRequestAction({ newTask: data, meta: currentDate }))
			if (!createTaskLoading) {
				reset({
					title: '',
					description: '',
					assignee: '',
					watchersId: [],
					attachments: '',
					tagIds: [],
					start: data?.start ? data.start : moment().format('YYYY-MM-DD HH:mm'),
					end: data?.end ? data?.end : moment().format('YYYY-MM-DD HH:mm'),
				})
				handleClose()
				Swal.fire({
					title: intl.formatMessage({ id: 'NOTE_TASK_CREATED' }),
					icon: 'success',
					timer: 1500,
				})
			}
		}

		//==================================================DrawerContent

		const DrawerContent = () => (
			<Box
				sx={{
					padding: 5,
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
				role='presentation'
				component='form'
				autoComplete='off'
				onSubmit={handleSubmit(onSubmit)}
			>
				<Stack spacing={5} sx={{ flexGrow: 1 }}>
					<Box>
						<Controller
							name='title'
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									fullWidth
									label={intl.formatMessage({
										id: 'TASK.MODAL.TITLE',
									})}
									value={value || ''}
									onChange={onChange}
									variant='standard'
								/>
							)}
						/>
						{formState?.errors?.title && (
							<Box sx={{ display: 'flex', mt: 1 }}>
								<ErrorOutlineIcon color='error' sx={{ mr: 1 }} />
								<Typography color='red' sx={{ fontSize: '14px' }}>
									{formState?.errors?.title?.message}
								</Typography>
							</Box>
						)}
					</Box>

					<Box>
						<Typography color='#00000099' sx={{ pb: 1, fontSize: '14px' }}>
							Описание
						</Typography>
						<Editor
							// onInit={(evt, editor) => (editorRef.current = editor)}
							// onInit={onInitEditor}
							// key={editorKey}
							apiKey='x933w4qvw7ckm4dwx6qfe39t1e0qq0dj6wc19z4a2wet0gbg'
							// initialValue={item.content}

							init={{
								plugins,
								toolbar,
								menubar,
								height: 300,
								visual: false,
								powerpaste_allow_local_images: true,
								language: 'ru',
								images_upload_url: `${BASE_URL}/minio/file`,
								images_upload_handler: example_image_upload_handler,
								file_browser_callback_types: 'file image media',
								path_absolute: '/',
								// file_browser_callback: myFileBrowser,
								tinydrive_token_provider: `${BASE_URL}/minio/file`,
								file_picker_callback: function (cb, value, meta) {
									var input = document.createElement('input')
									input.setAttribute('type', 'file')
									var xhr: XMLHttpRequest, formData
									xhr = new XMLHttpRequest()
									input.onchange = function (e: any) {
										var file = e.target.files[0]
										xhr.open('POST', `${BASE_URL}/minio/file`)
										xhr.setRequestHeader('Authorization', `Bearer ${token}`)
										xhr.onload = function () {
											var json
											if (xhr.status === 403) {
												// failure('HTTP Error: ' + xhr.status, { remove: true })
												return
											}
											if (xhr.status < 200 || xhr.status >= 300) {
												// failure('HTTP Error: ' + xhr.status)
												return
											}
											json = JSON.parse(xhr.responseText)

											if (!json || typeof json.baseFileUrl != 'string') {
												// failure('Invalid JSON: ' + xhr.responseText)
												return
											}
											cb(json.baseFileUrl, {
												alt: json.name,
												title: json.name,
												text: json.name,
											})
										}

										xhr.onerror = function () {
											// failure(
											// 	'Image upload failed due to a XHR Transport error. Code: ' +
											// 		xhr.status
											// )
										}

										formData = new FormData()
										formData.append('file', file)
										formData.append('name', file.name)

										xhr.send(formData)
									}

									input.click()
								},
							}}
							onChange={handleChange}
						/>
						{formState?.errors?.description && (
							<Box sx={{ display: 'flex', mt: 1 }}>
								<ErrorOutlineIcon color='error' sx={{ mr: 1 }} />
								<Typography color='red' sx={{ fontSize: '14px' }}>
									{formState?.errors?.description?.message}
								</Typography>
							</Box>
						)}
					</Box>

					<LocalizationProvider dateAdapter={AdapterDateMoment}>
						<Box>
							<Controller
								name='start'
								control={control}
								render={({ field: { onChange, value } }) => (
									<DateTimePicker
										minDateTime={moment(moment().format('YYYY MM DD HH:mm'))}
										label={intl.formatMessage({ id: 'TASK.MODAL.START' })}
										value={value}
										onChange={(e: any) => {
											onChange(moment(e?._d)?.format('YYYY-MM-DD HH:mm'))
										}}
										renderInput={(params: JSX.IntrinsicAttributes) => (
											<TextField
												{...params}
												variant='standard'
												className='me-2'
												fullWidth
											/>
										)}
									/>
								)}
							/>

							{formState?.errors?.start && (
								<Box sx={{ display: 'flex', mt: 1 }}>
									<ErrorOutlineIcon color='error' sx={{ mr: 1 }} />
									<Typography color='red' sx={{ fontSize: '14px' }}>
										{formState?.errors?.start?.message}
									</Typography>
								</Box>
							)}
						</Box>
						<Box>
							<Controller
								name='end'
								control={control}
								render={({ field: { onChange, value } }) => (
									<DateTimePicker
										label={intl.formatMessage({ id: 'TASK.MODAL.END' })}
										value={value}
										minDateTime={moment(getValues('start'))}
										onChange={(e: any) => {
											onChange(moment(e?._d)?.format('YYYY-MM-DD HH:mm'))
										}}
										renderInput={(params: JSX.IntrinsicAttributes) => (
											<TextField
												{...params}
												variant='standard'
												className='me-2'
												fullWidth
											/>
										)}
									/>
								)}
							/>
							{formState?.errors?.end && (
								<Box sx={{ display: 'flex', mt: 1 }}>
									<ErrorOutlineIcon color='error' sx={{ mr: 1 }} />
									<Typography color='red' sx={{ fontSize: '14px' }}>
										{formState?.errors?.end?.message}
									</Typography>
								</Box>
							)}
						</Box>
					</LocalizationProvider>
					<Box>
						<MultiSelectTags
							// formik={formik}
							style='standard'
							items={epic}
							// getOptionDisabled={getOptionDisabled}
							label='Теги'
							placeholder='Введите для поиска'
							selectAllLabel='Select all'
							onChange={setTag}
						/>
						{formState?.errors?.watchersId && (
							<Box sx={{ display: 'flex', mt: 1 }}>
								<ErrorOutlineIcon color='error' sx={{ mr: 1 }} />
								<Typography color='red' sx={{ fontSize: '14px' }}>
									{formState?.errors?.assignee?.message}
								</Typography>
							</Box>
						)}
					</Box>

					<Box>
						<Autocomplete
							className='me-5'
							fullWidth
							onChange={(e: any, props: any) => {
								setAssignee(props.userId)
							}}
							getOptionLabel={(option: any) => {
								return option?.fullName
							}}
							renderOption={(props, option: any) => {
								return (
									<li {...props} key={option.id}>
										{option.fullName}
									</li>
								)
							}}
							disablePortal
							id='combo-box-demo'
							options={personals || []}
							renderInput={(params: any) => (
								<TextField
									{...params}
									variant='standard'
									placeholder='Введите для поиска'
									label='Исполнитель'
								/>
							)}
						/>

						{formState?.errors?.assignee && (
							<Box sx={{ display: 'flex', mt: 1 }}>
								<ErrorOutlineIcon color='error' sx={{ mr: 1 }} />
								<Typography color='red' sx={{ fontSize: '14px' }}>
									{formState?.errors?.assignee?.message}
								</Typography>
							</Box>
						)}
					</Box>
					<Box>
						<MultiSelect
							// formik={formik}
							style='standard'
							items={personals}
							// getOptionDisabled={getOptionDisabled}
							label='Набюдатели'
							placeholder='Введите для поиска'
							selectAllLabel='Select all'
							onChange={setWatchers}
						/>
						{formState?.errors?.watchersId && (
							<Box sx={{ display: 'flex', mt: 1 }}>
								<ErrorOutlineIcon color='error' sx={{ mr: 1 }} />
								<Typography color='red' sx={{ fontSize: '14px' }}>
									{formState?.errors?.assignee?.message}
								</Typography>
							</Box>
						)}
					</Box>

					<Box>
						<Controller
							name='attachments'
							control={control}
							render={({ field: { onChange, value } }) => (
								<FileUploadButton onChange={onChange} />
							)}
						/>
						{formState?.errors?.attachments && (
							<Box sx={{ display: 'flex', mt: 1 }}>
								<ErrorOutlineIcon color='error' sx={{ mr: 1 }} />
								<Typography color='red' sx={{ fontSize: '14px' }}>
									{formState?.errors?.attachments?.message}
								</Typography>
							</Box>
						)}
					</Box>
				</Stack>
				<Box sx={{ my: '30px' }}>
					<Stack spacing={2}>
						{createTaskLoading && <LinearProgress color='success' />}

						<Button
							type='submit'
							color='success'
							disabled={createTaskLoading}
							variant='contained'
							size='small'
						>
							{!false && intl.formatMessage({ id: 'TEXT.SAVE' })}
						</Button>
						<Button onClick={handleClose} variant='contained' size='small'>
							{intl.formatMessage({ id: 'TEXT.CLOSE' })}
						</Button>
					</Stack>
				</Box>
			</Box>
		)

		return (
			<div>
				<>
					<Drawer
						sx={{ '& .MuiDrawer-paper': { width: matches ? '50%' : '100%' } }}
						anchor={'right'}
						open={show}
						onClose={handleClose}
						disableEnforceFocus={true}
					>
						{DrawerContent()}
					</Drawer>
				</>
			</div>
		)
	}
)

export default TaskCreateDrawer
