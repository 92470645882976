import { Avatar, Divider, Stack, Typography } from '@mui/material'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toAbsoluteUrl } from '../../../../helpers'
import { IprofileData } from '../../../../modules/accounts/components/Profile'
import { RootState } from '../../../../setup'
import { getPersonalsAction } from '../../../../store/actions/personalsActions'
import { KTSVG } from '../../../helpers/KTSVG'

const ActivityDrawer: FC = () => {
	const dispatch = useDispatch()

	const personals: IprofileData[] = useSelector<RootState, IprofileData[]>(
		state => state.personals.personals
	)

	useEffect(() => {
		dispatch(getPersonalsAction())
	}, [])

	return (
		<div
			id='kt_activities'
			className='bg-white'
			data-kt-drawer='true'
			data-kt-drawer-name='activities'
			data-kt-drawer-activate='true'
			data-kt-drawer-overlay='true'
			data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
			data-kt-drawer-direction='end'
			data-kt-drawer-toggle='#kt_activities_toggle'
			data-kt-drawer-close='#kt_activities_close'
		>
			<div className='card shadow-none rounded-0 w-100'>
				<div className='card-header' id='kt_activities_header'>
					<h3 className='card-title fw-bolder text-dark'>Последние действия</h3>

					<div className='card-toolbar'>
						<button
							type='button'
							className='btn btn-sm btn-icon btn-active-light-primary me-n5'
							id='kt_activities_close'
						>
							<KTSVG
								path='/media/icons/duotune/arrows/arr061.svg'
								className='svg-icon-1'
							/>
						</button>
					</div>
				</div>
				<div className='card-body position-relative' id='kt_activities_body'>
					<div
						id='kt_activities_scroll'
						className='position-relative scroll-y me-n5 pe-5'
						data-kt-scroll='true'
						data-kt-scroll-height='auto'
						data-kt-scroll-wrappers='#kt_activities_body'
						data-kt-scroll-dependencies='#kt_activities_header, #kt_activities_footer'
						data-kt-scroll-offset='5px'
					>
						<Stack spacing={3} sx={{ width: '100%' }}>
							{personals?.map(person => {
								return (
									<div key={person.id}>
										<Stack direction='row' alignItems='center' spacing={2}>
											<Avatar
												alt={person.fullName}
												src={toAbsoluteUrl(
													`${
														person.imagePath
															? person.imagePath
															: ' /media/avatars/blank.png'
													}`
												)}
											/>
											<Typography>{person.fullName}</Typography>
										</Stack>
										<Divider sx={{mt: 2}}/>
									</div>
								)
							})}
						</Stack>

						{/* <div className='timeline'>
            <Item1 />
            <Item2 />
            <Item3 />
            <Item4 />
            <Item5 />
            <Item6 />
            <Item7 />
            <Item8 />
          </div> */}
					</div>
				</div>
				{/* <div className='card-footer py-5 text-center' id='kt_activities_footer'>
        <Link to='/crafted/pages/profile' className='btn btn-bg-white text-primary'>
          View All Activities
          <KTSVG
            path='/media/icons/duotune/arrows/arr064.svg'
            className='svg-icon-3 svg-icon-primary'
          />
        </Link>
      </div> */}
			</div>
		</div>
	)
}

export { ActivityDrawer }
