import { yupResolver } from '@hookform/resolvers/yup'
import {
	Box,
	Card,
	CircularProgress,
	Divider,
	IconButton,
	LinearProgress,
	Paper,
	Stack,
	TextField,
	Typography,
} from '@mui/material'
import { Editor } from '@tinymce/tinymce-react'
import classnames from 'classnames/bind'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from '../../../setup'
import {
	replyPublicMessageAction,
	reqeustPublicCorrespondenceMessagesAction,
	requestPublicCorrespondenceAnonymName,
	requestPublicCorrespondenceInfo,
	sendPublicCorrespondenceMessageAction,
} from '../../../store/actions/correspondenceAction'
import {
	ICorrespondenceContent,
	ICorrespondenceMessages,
	ICorrespondenceMessagesContent,
} from '../../../store/reducers/correspondenceReducer'
import { IMessageFilter } from './ChatReply'
import ChatReplyToolbar from './ChatReplyToolbar'
import * as yup from 'yup'
import paginationStyles from '../../Task/paginationStyles.module.scss'
import Swal from 'sweetalert2'
import { LoadingButton } from '@mui/lab'
import SendIcon from '@mui/icons-material/Send'
import TreeView from '../TreeView/TreeView'
import CloseIcon from '@mui/icons-material/Close'
import FileUploadButton from '../../Task/FileUploadButton'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { checkIfFilesAreTooBig } from '../../../components/helpers/herpers'
import emitter from '../../../setup/redux/emitter'

const schema = yup.object().shape({
	content: yup.string().required('Обязательное поле'),
	attachments: yup
		.mixed()
		.test('fileSize', 'Файл не должен превышать 200 мб', checkIfFilesAreTooBig),
})

let cnPage = classnames.bind(paginationStyles)

const ChatReplyAnonymouse = () => {
	let { chatUuid } = useParams<any>()
	const dispatch = useDispatch()
	const intl = useIntl()
	const form = useRef<any>(null)

	const correspondenceMessages: ICorrespondenceMessages = useSelector<
		RootState,
		ICorrespondenceMessages
	>(state => state.correspondence.correspondenceMessages)

	const correspondenceMessagesLoading: boolean = useSelector<
		RootState,
		boolean
	>(state => state.correspondence.correspondenceMessagesLoading)

	const createPublicCorrespondenceLoading: boolean = useSelector<
		RootState,
		boolean
	>(state => state.correspondence.createPublicCorrespondenceLoading)

	const createPublicCorrespondenceError: string = useSelector<
		RootState,
		string
	>(state => state.correspondence.createPublicCorrespondenceError)

	const correspondenceInfo: ICorrespondenceContent = useSelector<
		RootState,
		ICorrespondenceContent
	>(state => state.correspondence.correspondenceInfo)

	const anonymName: string = useSelector<RootState, string>(
		state => state.correspondence.anonymName
	)

	const [messageFilter, setMessageFilter] = useState<IMessageFilter>({
		page: 0,
	})
	const [anonymousName, setAnonymousName] = useState(anonymName)
	const [filePercentage, setFilePercentage] = useState<any>(0)
	const [nameToReply, setNameToReply] = useState<string>('')
	const { getValues, formState, handleSubmit, control, setValue, reset } =
		useForm({
			defaultValues: {
				content: '',
				anonymousName: '',
				messageId: 0,
				attachments: null,
			},
			resolver: yupResolver(schema),
			mode: 'onChange',
		})

	useEffect(() => {
		if (anonymName) {
			setValue('anonymousName', anonymName)
			setAnonymousName(anonymName)
		}
	}, [anonymName])

	useEffect(() => {
		if (createPublicCorrespondenceError) {
			Swal.fire({
				title: 'Ошибка',
				text: 'Не удалось отправить сообщение',
				icon: 'error',
			})
		}
	}, [createPublicCorrespondenceError])
	useEffect(() => {
		if (chatUuid) {
			dispatch(requestPublicCorrespondenceInfo(chatUuid))
		}
		emitter.on('FILE_PERCENTAGE', percent => {
			setFilePercentage(percent)
		})

		const refresh = setInterval(() => {
			dispatch(
				reqeustPublicCorrespondenceMessagesAction(chatUuid, messageFilter)
			)
		}, 3000)

		return () => {
			emitter.off('FILE_PERCENTAGE')
			clearInterval(refresh)
		}
	}, [])
	//===========================================
	useEffect(() => {
		if (correspondenceInfo?.id) {
			dispatch(requestPublicCorrespondenceAnonymName(correspondenceInfo?.id))
		}
	}, [correspondenceMessages])

	//===========================================

	useEffect(() => {
		dispatch(reqeustPublicCorrespondenceMessagesAction(chatUuid, messageFilter))
	}, [messageFilter])

	const onSubmit = (data: any) => {
		if (getValues('messageId')) {
			dispatch(replyPublicMessageAction(chatUuid, getValues('messageId'), data))

			setValue('messageId', 0)
			setNameToReply('')
		} else {
			dispatch(
				sendPublicCorrespondenceMessageAction(
					correspondenceInfo?.id,
					chatUuid,
					data
				)
			)
		}

		Swal.fire({
			title: 'Сообщение отправлено',
			icon: 'success',
			timer: 800,
		})
		setValue('content', '')
	}

	const handleChangePage = (e: any) => {
		setMessageFilter({ ...messageFilter, page: e.selected })
	}

	const handleEditorChange = (content: any, editor: any) => {
		setValue('content', content)
	}

	const handleScrollToForm = (e: any, messageId: number, fullName: string) => {
		e.stopPropagation()
		form.current.scrollIntoView()
		setValue('messageId', messageId)
		setNameToReply(fullName)
	}

	const handleCancelReply = () => {
		setNameToReply('')
		setValue('messageId', 0)
	}

	return (
		<Box
			sx={{
				ml: '2rem',
				flexGrow: 4,
				flexShrink: 1,
				flexBasis: '0%',
				mt: '100px',
			}}
		>
			<Card>
				{/* //================ INBOX TOOL BAR */}

				<ChatReplyToolbar
					totalElements={correspondenceMessages.totalElements}
					numberOfElements={correspondenceMessages.numberOfElements}
					page={correspondenceMessages.page}
					totalPages={correspondenceMessages.totalPages}
					setMessageFilter={setMessageFilter}
					messageFilter={messageFilter}
				/>
				<Divider />
				<Stack direction='row' p={5} spacing={2}>
					<Typography
						sx={{
							padding: '4px 10px',
							borderRadius: 2,
							bgcolor: 'rgba(0, 0, 255, 0.1)',
						}}
					>
						{'Тема: ' + correspondenceInfo.title}
					</Typography>
					<Typography
						sx={{
							m: 0,
							padding: '4px 10px',
							borderRadius: 2,
							bgcolor: 'rgba(255, 0, 0, 0.2)',
						}}
					>
						{'Дата: ' + correspondenceInfo.createdAt}
					</Typography>
				</Stack>

				<Box sx={{ padding: '2rem 2.5rem' }}>
					{/* ///================================Chat Reply Body Title */}

					{/* //=========================================== Title end */}
					{correspondenceMessages?.content?.map(
						(message: ICorrespondenceMessagesContent) => {
							return (
								<TreeView
									key={message.id}
									message={message}
									formScroll={handleScrollToForm}
									chatId={chatUuid}
									anonymouseName={anonymousName}
									reply={false}
								/>
								// <ChatReplyItem

								// 	formScroll={handleScrollToForm}
								// 	key={message.id}
								// 	anonymouseName={anonymousName}
								// 	message={message}
								// />
							)
						}
					)}
					{correspondenceMessagesLoading && (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<CircularProgress />
						</Box>
					)}
					<Paper elevation={10} sx={{ p: 3, mt: 7 }}>
						{!!filePercentage && (
							<LinearProgress variant='determinate' value={filePercentage} />
						)}
						<Box ref={form} component='form' onSubmit={handleSubmit(onSubmit)}>
							{nameToReply && (
								<Stack m={1} direction='row' alignItems='center' gap={1}>
									<Typography
										sx={{
											borderRadius: '10px',
											py: 1,
											px: 2,
											bgcolor: 'rgba(255, 127, 80, 0.5)',
										}}
									>
										Ответить: {nameToReply}
									</Typography>

									<IconButton onClick={handleCancelReply} color='primary'>
										<CloseIcon />
									</IconButton>
								</Stack>
							)}
							<Controller
								name='anonymousName'
								control={control}
								render={({ field: { onChange, value } }) => {
									return (
										<TextField
											onChange={onChange}
											value={value}
											label={'Имя'}
											sx={{ mb: 3 }}
										/>
									)
								}}
							/>
							<Controller
								name='attachments'
								control={control}
								render={({ field: { onChange, value } }) => (
									<FileUploadButton fullWidth={true} onChange={onChange} />
								)}
							/>
							{formState?.errors?.attachments && (
								<Box sx={{ display: 'flex', mt: 1 }}>
									<ErrorOutlineIcon color='error' sx={{ mr: 1 }} />
									<Typography color='red' sx={{ fontSize: '14px' }}>
										{formState?.errors?.attachments?.message}
									</Typography>
								</Box>
							)}
							<Editor
								apiKey='x933w4qvw7ckm4dwx6qfe39t1e0qq0dj6wc19z4a2wet0gbg'
								init={{ height: 300, statusbar: false, language: 'ru' }}
								value={getValues('content')}
								onEditorChange={handleEditorChange}
							/>
							<Box
								sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}
							>
								{/* <Button variant='contained' sx={{ mb: 1 }} type='submit'>
									Отправить
								</Button> */}
								<LoadingButton
									size='small'
									sx={{ mb: 1 }}
									type='submit'
									endIcon={<SendIcon />}
									loading={createPublicCorrespondenceLoading}
									loadingPosition='end'
									variant='contained'
								>
									Отправить
								</LoadingButton>
							</Box>
						</Box>
					</Paper>
				</Box>
				{/* <div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						margin: '20px 10px 0px',
					}}
				>
					<div className='fs-6 fw-bold text-gray-700'>
						{intl.formatMessage({ id: 'PAGINATION.ENTRIES' })}{' '}
						{correspondenceMessages?.totalElements}
					</div>
					<ReactPaginate
						previousLabel={'<'}
						nextLabel={'>'}
						pageCount={correspondenceMessages.totalPages}
						onPageChange={handleChangePage}
						containerClassName={cnPage('pagination')}
						previousLinkClassName={cnPage('pagination__link')}
						nextLinkClassName={cnPage('pagination__link')}
						disabledClassName={cnPage('pagination__link--disabled')}
						activeClassName={cnPage('pagination__link--active')}
						pageRangeDisplayed={2}
						marginPagesDisplayed={2}
						forcePage={messageFilter.page}
					/>
				</div> */}
			</Card>
		</Box>
	)
}

export default ChatReplyAnonymouse
